import i18n from "./i18n/i18n";
import GetFlag from "../helpers/GetFlag";
import { Avatar, Badge, Box } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LanguageIcon from "@material-ui/icons/Language";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PhoneIcon from "@material-ui/icons/Phone";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import lod_ from "lodash";

export const AvatarProfile = ({ user, role, classes, badge, onlineUsers }) => {
	return (
		<>
			<Box mr={3}>
				<Badge
					classes={{
						badge: lod_.includes(onlineUsers, user._id) ? classes.onlineBadge : classes.offlineBadge
					}}
					overlap="rectangular"
					badgeContent=" "
				>
					<Avatar
						alt={`${user.name} ${user.name}`}
						src={user.picture?.url || ""}
						style={{ width: 150, height: 150 }}
					>
						{user.name}
					</Avatar>
				</Badge>
			</Box>
			<Box flexGrow={1}>
				<Box fontSize="h5.fontSize" fontWeight="fontWeightBold" m={1}>
					{`${user.name}
                    ${user.surname}`}
				</Box>
				<Box fontSize="h6.fontSize" m={1}>
					{role}
				</Box>
			</Box>
		</>
	);
};

export const UserProfileComponent = ({ settings, user, signature, displayPhone, classes }) => {
	return (
		<>
			{settings.getDisplayState("email") && (
				<Box my={2} display="flex" alignItems="center">
					<EmailIcon />
					<Box fontWeight="fontWeightBold" mx={1}>
						Email :
					</Box>
					<Box>{user._id}</Box>
				</Box>
			)}
			{settings.getDisplayState("name") && (
				<Box my={2} display="flex" alignItems="center">
					<PersonIcon />
					<Box fontWeight="fontWeightBold" mx={1}>
						{`${i18n.t("EDIT_FORM.name")} : `}
					</Box>
					<Box>{user.name}</Box>
				</Box>
			)}
			{settings.getDisplayState("surname") && (
				<Box my={2} display="flex" alignItems="center">
					<PersonIcon />
					<Box fontWeight="fontWeightBold" mx={1}>
						{`${i18n.t("EDIT_FORM.surname")} : `}
					</Box>
					<Box>{user.surname}</Box>
				</Box>
			)}
			{settings.getDisplayState("title") && (
				<Box my={2} display="flex" alignItems="center">
					<PersonIcon />
					<Box fontWeight="fontWeightBold" mx={1}>
						{`${i18n.t("EDIT_FORM.title")} : `}
					</Box>
					<Box>{user.title}</Box>
				</Box>
			)}
			{settings.getDisplayState("openingHours") && (
				<Box my={2} display="flex" alignItems="center">
					<DateRangeIcon />
					<Box fontWeight="fontWeightBold" mx={1}>
						{`${i18n.t("EDIT_FORM.openingHours")} : `}
					</Box>
					<Box>{user.openingHours || i18n.t("EDIT_FORM.none")}</Box>
				</Box>
			)}
			{settings.getDisplayState("language") && (
				<Box my={2} display="flex" alignItems="center">
					<LanguageIcon />
					<Box fontWeight="fontWeightBold" mx={1}>
						{`${i18n.t("EDIT_FORM.language")} : `}
					</Box>
					<Box display="flex" alignItems="center">
						<Box style={{ marginRight: 10, marginTop: 1 }}>
							{i18n.t(`EDIT_FORM.languages.${user?.language}`)}
						</Box>
						<Box style={{ marginBottom: 1 }}>
							<img
								src={GetFlag(user?.language?.toUpperCase())}
								alt="UsersManager_flag"
								width="20"
								height="20"
								style={{ objectFit: "contain" }}
							/>
						</Box>
					</Box>
				</Box>
			)}
			{displayPhone && settings.getDisplayState("mobilePhone") && (
				<Box my={2} display="flex" alignItems="center">
					<PhoneAndroidIcon />
					<Box fontWeight="fontWeightBold" mx={1}>
						{`${i18n.t("EDIT_FORM.mobilePhone")} : `}
					</Box>
					<Box>{user.mobilePhone || i18n.t("EDIT_FORM.none")}</Box>
				</Box>
			)}
			{displayPhone && settings.getDisplayState("directPhone") && (
				<Box my={2} display="flex" alignItems="center">
					<PhoneIcon />
					<Box fontWeight="fontWeightBold" mx={1}>
						{`${i18n.t("EDIT_FORM.directPhone")} : `}
					</Box>
					<Box>{user.directPhone || i18n.t("EDIT_FORM.none")}</Box>
				</Box>
			)}
			{settings.getDisplayState("signature") && (
				<Box my={2} display="flex" alignItems="center">
					<Box fontWeight="fontWeightBold" mx={1}>
						<svg
							className="MuiSvgIcon-root"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 576 512"
						>
							<path
								d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 
                                            11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 
                                            16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 
                                            16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 
                                            0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 
                                            121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 
                                            346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z"
							/>
						</svg>
					</Box>
					<Box fontWeight="fontWeightBold" m={1}>
						{`${i18n.t("EDIT_FORM.signature")} : `}
					</Box>

					<Box style={{ alignSelf: "center" }} className={classes.imgSign}>
						{signature ? parse(`${signature}`) : i18n.t("EDIT_FORM.noSignature")}
					</Box>
				</Box>
			)}
		</>
	);
};
