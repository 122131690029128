import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import {
	Box,
	Button,
	Collapse,
	Divider,
	Grid,
	InputAdornment,
	InputBase,
	ListItem,
	ListItemIcon,
	ListItemButton,
	ListItemText,
	TextField
} from "../../../node_modules/@material-ui/core/index";
import { Paper, IconButton } from "../../../node_modules/@material-ui/core/index";
import {
	Add,
	Clear,
	ExpandLess,
	ExpandMore,
	Remove
} from "../../../node_modules/@material-ui/icons/index";

export default function ArrayList(props) {
	const { name, listLabel, arrayList, onChange, addFieldPlaceholder } = props;
	const [list, setList] = React.useState([]);
	const [newValue, setNewValue] = React.useState("");
	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		setList(arrayList);
	}, [arrayList]);

	function addDataToList(e, itemList) {
		if (itemList !== "") {
			let newList = [...list];
			newList.push(itemList);
			setList(newList);
			onChange(convertToDefEventParam(name, newList));
			setNewValue("");
		}
	}

	function remove(e, listIndex) {
		let newList = [...list];
		newList.splice(listIndex, 1);
		setList(newList);
		onChange(convertToDefEventParam(name, newList));
	}

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	const handleClickOpen = () => {
		setOpen(!open);
	};

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<Box width="100%">
				<Button onClick={handleClickOpen} fullWidth>
					<ListItemText primary={listLabel + " (" + list.length + ")"} />
					{open ? <ExpandLess /> : <ExpandMore />}
				</Button>
				<Collapse in={open} timeout="auto" unmountOnExit collapsedSize="100%">
					<Box minHeight="50px" width="100%" padding="5px" bgcolor="#ecf0f1">
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="baseline"
							//xs={12}
						>
							{list &&
								list.map(item => (
									<div
										key={list.indexOf(item)}
										style={{
											backgroundColor: "#3f51b5",
											paddingLeft: "15px",
											borderRadius: "10px",
											margin: "2px"
										}}
									>
										<span style={{ color: "white" }}>
											{item}{" "}
											<IconButton onClick={e => remove(e, list.indexOf(item))}>
												<Clear style={{ color: "white" }} />
											</IconButton>
										</span>
									</div>
								))}
						</Grid>
					</Box>
				</Collapse>
			</Box>
			<TextField
				variant="outlined"
				margin="dense"
				fullWidth
				value={newValue}
				placeholder={addFieldPlaceholder}
				onChange={e => setNewValue(e.target.value)}
				{...{
					InputProps: {
						endAdornment: (
							<InputAdornment position="end">
								<Add onClick={e => addDataToList(e, newValue)} />
							</InputAdornment>
						)
					}
				}}
			/>
		</div>
	);
}
