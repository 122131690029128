/* eslint-disable no-case-declarations */
import C from "../../constants/releaseNotes";

const releaseNotesReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.GET_RELEASE_NOTES:
			return action.payload;

		default:
			return currentState;
	}
};

export default releaseNotesReducers;
