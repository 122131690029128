import { createSlice } from "@reduxjs/toolkit";
import { api } from "redux/actions/api";

// TODO Migrate cockpitReducers to slicers

const initialState = {
	loading: false,
	selectedConversation: null,
	sendEnabled: true,
	sendSucceed: false,
	elementFeedbackChange: { element: "", message: "", feedback: "" }
};

export const conversation = initialState;

const slice = createSlice({
	name: "conversation",
	initialState,
	reducers: {
		clearConversation: state => {
			return initialState;
		},
		setSendEnabled: (state, action) => {
			state.sendEnabled = action.payload;
		},
		setSendSucceed: (state, action) => {
			state.sendSucceed = action.payload;
		},
		setElementFeedback: (state, action) => {
			state.elementFeedbackChange = action.payload;
		}
	}
});

export default slice.reducer;

// ACTIONS
export const { clearConversation, setSendEnabled, setSendSucceed, setElementFeedback } =
	slice.actions;
