/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "../assets/css/HistoricList.css";
import lod_ from "lodash";
import ChannelUserInfoMembershipDisplayer from "./../helpers/ChannelUserInfoMembershipDisplayer";
import MessageItem from "../pages/Cockpit/Message/MessageItem";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import i18n from "i18n";
import { NotesComponent } from "pages/Cockpit/CockpitTabInfo/Notes";
import { getLabelOf, handleSpecialDisplayDate } from "./utilities";
import { getConvItemSelector } from "redux/selectors/cockpitSelectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import { infoMsg } from "redux/reducers/snackMsgsReducers";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginBottom: "10px",
		borderRadius: "15%"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: "33.33%",
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: "black"
	},
	AccordionDetails: {
		marginBottom: "10px"
	}
}));

export default function HistoricList({ minimalWidth, call, ...props }) {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);
	const dispatch = useDispatch();

	const { userLanguage, dateFormat } = useSelector(getConvItemSelector, shallowEqual);

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	let conversation = props.conversation;
	let header = conversation?.header;
	let messages = conversation?.messages || [];
	let messagesWithConvID = messages.map(obj => ({
		...obj,
		Id: conversation.id,
		note: props.Notes
	}));
	let convHistory = props.history && props.number ? props.history[props.number] : props.history[0];

	let state = header?.state || "";
	let agent =
		conversation?.agent?.name && conversation?.agent?.name !== ""
			? conversation?.agent?.name
			: conversation?.agent?.uid || "";

	let title = conversation?.meta?.cockpit?.title ? getLabelOf(conversation.meta.cockpit.title) : "";

	let displayState = i18n.t(`HISTORY.${state}`);

	const computeDateHistory = header => {
		if (header.datetime) {
			return header.datetime.substring(0, 16);
		} else if (header?.dateCreated?.datetime) {
			return header.dateCreated.datetime.substring(0, 16);
		} else if (header?.dateReceived?.datetime) {
			return header.dateReceived.datetime.substring(0, 16);
		} else {
			return "";
		}
	};

	return (
		<Box data-component-name="HistoricList">
			<Accordion
				className={classes.root}
				TransitionProps={{ unmountOnExit: true }}
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
				>
					<Box className={classes.heading} width="50%">
						{convHistory && header && (
							<ChannelUserInfoMembershipDisplayer
								convHistory={convHistory}
								channelImage={conversation?.meta?.cockpit?.channelCode || ""}
								//contactPicture={header.picture}
								contactNameOrEmail={conversation?.meta?.cockpit?.contact || ""}
								connected={conversation?.meta?.cockpit?.connected || false}
								//contactMembership={convHistory.contact.membership}
								FRU={conversation?.contact?.uid}
								channel={header.channel}
								fID={header.fID}
								assistantID={header.assistantID}
							/>
						)}
						<Grid container width="100%">
							<Grid
								item
								xs={8}
								container
								justifyContent="flex-start"
								direction="row"
								alignItems="center"
							>
								{convHistory !== undefined && (
									<Box style={{ width: "25em" }}>{title && title !== "" && `${title} `}</Box>
								)}
								{convHistory !== undefined && (
									<Box style={{ width: "25em" }}>
										<Tooltip title={i18n.t("COC.CopyToClipboard")} placement="top">
											<Box
												onClick={event => {
													event.stopPropagation();
													navigator.clipboard.writeText(header.fID);
													dispatch(infoMsg(i18n.t("COC.CopyToClipboard")));
												}}
												onFocus={event => event.stopPropagation()}
											>
												{header?.fID ? `${header.fID} ` : ""}
											</Box>
										</Tooltip>
									</Box>
								)}
								{convHistory !== undefined && (
									<Box style={{ width: "25em" }}>
										{displayState ? ` ${displayState}` : ""} {agent && agent !== "" && `- ${agent}`}
									</Box>
								)}
							</Grid>
						</Grid>
					</Box>
					<Box
						xs={minimalWidth ? 2 : 4}
						width="80%"
						justifyContent="center"
						direction="column"
						alignItems="center"
					>
						{messagesWithConvID && (
							<Box textAlign="center" style={minimalWidth ? {} : { width: "25em" }}>
								{convHistory.meta?.cockpit?.ts
									? handleSpecialDisplayDate(convHistory.meta?.cockpit.ts, dateFormat, userLanguage)
									: convHistory?.meta?.cockpit?.datetime
										? convHistory?.meta?.cockpit?.datetime.substring(0, 16)
										: messagesWithConvID?.[0]?.header
											? computeDateHistory(messagesWithConvID?.[0]?.header)
											: ""}
							</Box>
						)}
						{messagesWithConvID?.[0]?.header &&
							messagesWithConvID?.[0]?.header.ticket &&
							messagesWithConvID?.[0]?.header.ticket !== "" && (
								<Box style={minimalWidth ? {} : { width: "20em" }} textAlign="center">
									{messagesWithConvID[0] &&
										messagesWithConvID[0].body &&
										messagesWithConvID[0].body.topic}
								</Box>
							)}
					</Box>
					<Grid
						container
						display="flex"
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
					>
						{messages && messages[lod_.findLastIndex(messages, "body.attachments[0].size")] && (
							<AttachFileOutlinedIcon />
						)}
						{props.history[props.number] !== undefined &&
							props.history[props.number].notes &&
							props.history[props.number].notes.length !== 0 && (
								<NoteOutlinedIcon style={{ transform: "rotate(270deg)" }} />
							)}
					</Grid>
				</AccordionSummary>
				<AccordionDetails className={classes.AccordionDetails}>
					{conversation.header.type === "call" ? (
						<>
							{!lod_.isNil(conversation?.meta?.cockpit?.summary) &&
							!lod_.isEmpty(conversation?.meta?.cockpit?.summary) ? (
								<Box>{conversation.meta.cockpit.summary}</Box>
							) : (
								<>
									{!lod_.isEmpty(conversation.Notes) ? (
										<Box bgcolor={"#c7c9c9"}>
											<NotesComponent notes={conversation.Notes} />
										</Box>
									) : (
										<Box
											bgcolor={"#c7c9c9"}
											height="100%"
											display="flex"
											justifyContent="center"
											alignItems="center"
											fontStyle="italic"
										>
											{i18n.t("COC.emptyNotesSummary")}
										</Box>
									)}
								</>
							)}
						</>
					) : (
						messagesWithConvID.map((mess, index) => (
							<Box key={`accordionHistoric${index}`} bgcolor={"#c7c9c9"}>
								<MessageItem
									key={`$historic-message} -${index} `}
									message={mess}
									msgIndex={index}
									fromHistoric={true}
									convHistory={convHistory}
								/>
							</Box>
						))
					)}
				</AccordionDetails>
			</Accordion>
		</Box>
	);
}
