const { realNowFromTS } = require("helpers/utilities");

module.exports = {
	transformLightToHeavyMessage
};

/**
 * We want to display the light message as a heavy message so we need to convert it
 * It's the opposite function of part of function conversationToConversationLightMessages(header, conversation, messages, format) {} found in exportdata and neworchestrator
 * @param {object} message
 */
function transformLightToHeavyMessage(message) {
	try {
		return {
			header: {
				mID: message.header.mID,
				source: message.header.source,
				from: {
					id: message.header.actor
				},
				externalInfos: {
					dateCreated: realNowFromTS(new Date(message.header.createdAt).getTime())
				}
			},
			payload: {
				payload: {
					text: {
						plain_text: message.payload.text,
						html_text: message.payload.htmlText,
						// parts: {} // To activate toggle cleaned text... but not very usefull
					}
				}
			}
		};
	} catch (error) {
		return null;
	}
}
