/* eslint-disable no-fallthrough */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@material-ui/core/Box";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cockpitActions from "../../../redux/actions/cockpitActions";
import {
	formatDate,
	handleSpecialDisplayDate,
	regexMail,
	regexPhoneNumber,
	shallowEqual
} from "../../../helpers/utilities";
import i18n from "i18next";
import C from "../../../constants/cockpit";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
	Collapse,
	Grid,
	IconButton,
	makeStyles,
	Select,
	TextField,
	Tooltip
} from "@material-ui/core";
import {
	KeyboardDatePicker,
	KeyboardDateTimePicker,
	MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { Anchorme } from "react-anchorme";
import lod_ from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import useCopyToClipboard from "helpers/useCopyToClipboard";
import { AttachFile, VisibilityOutlined } from "@material-ui/icons";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import { Button, Link, List, ListItem, ListItemText, MenuItem } from "@mui/material";
import ConfirmationDialog from "components/ConfirmationDialog";

const useStyles = makeStyles(theme => ({
	masked: {
		transition: "all .25s cubic-bezier(0.07, 0.85, 0.58, 1)",
		color: "transparent",
		textShadow: "0 0 5px rgba(0,0,0,0.5)"
	},
	displayed: {
		color: "inherit",
		textShadow: "inherit"
	}
}));

/**
 *
 */
export const Infos = ({
	contactAnsDocSearchFilter,
	insertRichAnswerToEditor,
	editionMode,
	openRightMenu
}) => {
	const dispatch = useDispatch();
	const { selectedConversation, currentMessage } = useSelector(state => state.cockpit);
	const { assistantconfig } = useSelector(state => state);
	const { auth } = useSelector(state => state.userStatus);
	const [contextInfo, setContextInfo] = useState("");
	const [infosExpanded, setInfosExpanded] = useState(/*`panel_${-1}`*/ editionMode ? true : false);
	const [, handleCopy] = useCopyToClipboard(2000);
	const [expandSens, setExpandSens] = useState(true);
	const [contact, setContact] = useState();
	const [context, setContext] = useState();
	const ongoingConv = selectedConversation.header.state === "ongoing";
	const resolvedConv = selectedConversation.header.state === "resolved";
	const assignedConv = selectedConversation.agent?.uid === auth.user.email;
	const userLanguage = auth?.user?.language?.toLowerCase() || "fr";
	const dateFormat =
		assistantconfig?.cockpit?.date_format?.format &&
		assistantconfig?.cockpit?.datetime_format?.format
			? {
					datetime: assistantconfig.cockpit.datetime_format.format,
					date: assistantconfig.cockpit.date_format.format
			  }
			: false;
	const classes = useStyles();

	/**
	 * Reset expand on conversation change
	 */
	useEffect(() => {
		setInfosExpanded(true);
		setExpandSens(true);
	}, [selectedConversation.header.fID]);

	/**
	 * To update the display when receive action's result.
	 */
	useEffect(() => {
		setContact(lod_.cloneDeep(selectedConversation.contact));
		setContext(lod_.cloneDeep(selectedConversation.context));
		if (assistantconfig.dictionary) {
			const values = {
				contact: {
					...selectedConversation.contact
				}
			};
			/**
			 * PIKA: Sub optimal processing to get the context info into context object
			 */
			Object.entries(selectedConversation.context).forEach(([key, val]) => {
				if (typeof val === "object") return (values[key] = val);
				if (!values.context) values.context = {};
				values.context[key] = val;
			});

			const dataConfig = mergeContextValuesIntoContextConfig(values, assistantconfig.dictionary);

			setContextInfo({ ...dataConfig });
		}
	}, [selectedConversation.header.fID, selectedConversation.context, selectedConversation.contact]);

	/**
	 *
	 * @param {*} panel
	 */
	const handleChange = panel => {
		if (Array.isArray(infosExpanded)) {
			if (infosExpanded.includes(panel)) {
				//delete from array
				setInfosExpanded(infosExpanded.filter(i => i !== panel));
			} else {
				//add to array
				setInfosExpanded([...infosExpanded, panel]);
			}
		} else {
			setInfosExpanded([panel]);
		}
	};

	/**
	 *  Past into textfied(richText) or rich editor(htmlText) context informations
	 * @param {function} contactItem
	 */

	const handleAddContactItem = (contactItem, type) => {
		switch (type) {
			case "url":
				contactItem = `<a href="${contactItem}">${contactItem}</a>`;
				break;
			case "email":
				contactItem = `<a href="mailto:${contactItem}">${contactItem}</a>`;
				break;
			case "datetime":
				contactItem = handleSpecialDisplayDate(contactItem, dateFormat["datetime"], userLanguage);
				break;
			case "date":
				contactItem = handleSpecialDisplayDate(contactItem, dateFormat["date"], userLanguage);
		}

		if (selectedConversation?.header?.type === "conversational") {
			if (contactItem !== undefined) {
				dispatch({
					type: C.SET_CONTENT_MESSAGE,
					payload: contactItem
				});
			}
		} else {
			insertRichAnswerToEditor(contactItem, []);
		}
	};

	/**
	 * Update a contact or context data on edit mode
	 * We can edit a field from CockpitTabInfo if the action "edit" is enabled in config
	 * We can also edit from ContextCallinterface
	 */
	const handleSaveInfo = () => {
		//Save contact
		let newContact = Object.assign(lod_.cloneDeep(selectedConversation.contact), contact);
		newContact = Object.fromEntries(Object.entries(newContact).filter(([_, v]) => v != null));
		//Check for changes
		if (!shallowEqual(newContact, selectedConversation.contact)) {
			//	setContact(newContact);
			dispatch(cockpitActions.updateConversationContact(newContact, selectedConversation.header));
			if (newContact.FRU)
				dispatch(cockpitActions.updateContact(newContact, assistantconfig.assistantID));
		}
		//save context
		let newContext = Object.assign(lod_.cloneDeep(selectedConversation.context), context);
		newContext = Object.fromEntries(Object.entries(newContext).filter(([_, v]) => v != null));
		//Check for changes
		if (!shallowEqual(newContext, selectedConversation.context)) {
			//	setContext(newContext);
			dispatch(cockpitActions.updateContext(newContext, selectedConversation.header));
		}
	};

	/**
	 *
	 * @param {*} param0
	 */
	const InfoContentByType = ({ config, leaf, title, displayActions }) => {
		const [attachmentLoading, setAttachmentLoading] = useState(false);
		const conversationAgent = selectedConversation.agent.uid;
		const isConvAgent = conversationAgent === auth.user.email;
		let ts;
		const type = config["type"];
		const value = config["value"];
		const actions = config["actions"];
		const label = config["label"];
		const accessor = config["accessor"];
		const blacklist = config["blacklist"];
		const whitelist = config["whitelist"];
		const isArray = config["isArray"];
		const codes = config["codes"];

		const ContentElement = memo(({ value = "", index }) => {
			let component;

			const [isValid, setIsValid] = useState();
			const [text, setText] = useState(value);

			/**
			 *
			 */
			useEffect(() => {
				setText(value);
			}, [value]);

			/**
			 *
			 * @param {*} e
			 * @param {*} type
			 */
			const handleChange = (e, type) => {
				const leafValue = e?.target?.value || e;
				let validation;
				if (type === "email") {
					validation = !regexMail(leafValue) ? "Mail invalide" : null;
				} else if (type === "tel") {
					validation = !regexPhoneNumber(leafValue) ? "Numéro invalide" : null;
				} else if (!lod_.isNil(blacklist) && blacklist.length > 0) {
					if (blacklist.includes(leafValue)) {
						validation = "Valeur blacklistée";
					}
				}
				setIsValid(validation);
				if (lod_.isNil(isValid)) {
					if (accessor.split(".")[0] === "contact") {
						let pathWithoutFirst = leaf.split(".")?.shift()?.join(".");
						if (!lod_.isNil(index)) {
							pathWithoutFirst = pathWithoutFirst + `[${index}]`;
						}

						setContact(lod_.set(contact, pathWithoutFirst, leafValue));
					} else {
						//context
						let accessorLeaf = accessor;
						if (!lod_.isNil(index)) {
							accessorLeaf = accessorLeaf + `[${index}]`;
						}

						setContext(lod_.set(context, accessorLeaf, leafValue));
					}
				}
			};

			/**
			 *
			 * @param {*} type
			 * @returns
			 */
			const textFieldComponent = type => {
				if (!lod_.isNil(whitelist) && whitelist.length > 0) {
					return (
						<Select
							fullWidth
							variant="outlined"
							defaultValue={value}
							onChange={e => handleChange(e, type)}
							onBlur={() => lod_.isNil(isValid) && handleSaveInfo()}
						>
							{whitelist.map((listItem, index) => (
								<MenuItem key={index} value={listItem}>
									{listItem}
								</MenuItem>
							))}
						</Select>
					);
				} else if (type === "code" && !lod_.isNil(codes) && !lod_.isEmpty(codes)) {
					return (
						<Select
							fullWidth
							variant="outlined"
							defaultValue={value}
							onChange={e => handleChange(e, type)}
							onBlur={() => lod_.isNil(isValid) && handleSaveInfo()}
						>
							{Object.keys(codes).map(code => (
								<MenuItem key={code} value={code}>
									{codes[code]}
								</MenuItem>
							))}
						</Select>
					);
				}
				return (
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						onBlur={() => lod_.isNil(isValid) && handleSaveInfo()}
						error={!lod_.isNil(isValid)}
						value={text}
						onChange={e => {
							setText(e.target.value);
							handleChange(e.target.value, type);
						}}
						helperText={isValid}
					/>
				);
			};
			// Temp fix : We sometimes have email in an array
			if (Array.isArray(value) && (!isArray || lod_.isNil(isArray))) {
				return (
					<Box>
						<ul>
							{value.map((key, index) => (
								<li key={`l-${index}`}>{key}</li>
							))}
						</ul>
					</Box>
				);
			}

			switch (type) {
				case "object":
					component = (
						<List>
							{Object.keys(value).map((key, index) => (
								<ListItem key={index}>
									<ListItemText primary={key} secondary={String(value[key])} />
								</ListItem>
							))}
						</List>
					);
					break;
				case "tel":
					if (actions.edit && isConvAgent) {
						component = textFieldComponent("tel");
					} else component = value;
					break;
				case "date":
					ts = new Date(value).getTime();
					if (actions.edit && isConvAgent) {
						component = (
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Grid style={{ width: "86%" }}>
									<TextField value={text} style={{ display: "none" }} />
									<KeyboardDatePicker
										autoOk
										onBlur={() => handleSaveInfo()}
										variant="inline"
										inputVariant="outlined"
										format="dd/MM/yyyy"
										onChange={date => {
											setText(date);
											handleChange(date, type);
										}}
										value={text === "" ? null : text}
										InputAdornmentProps={{ position: "start" }}
									/>
								</Grid>
							</MuiPickersUtilsProvider>
						);
					} else
						component = isNaN(ts)
							? formatDate(value)
							: dateFormat
							? handleSpecialDisplayDate(ts, dateFormat["date"], userLanguage)
							: formatDate(value);
					break;
				case "datetime":
					ts = new Date(value).getTime();

					if (actions.edit && isConvAgent) {
						component = (
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Grid>
									<TextField value={value} style={{ display: "none" }} />
									<KeyboardDateTimePicker
										variant="inline"
										value={value === "" ? null : value}
										inputVariant="outlined"
										onBlur={() => handleSaveInfo()}
										onChange={date => {
											setText(date);
											handleChange(date, type);
										}}
										format="dd/MM/yyyy HH:mm"
										InputAdornmentProps={{ position: "start" }}
									/>
								</Grid>
							</MuiPickersUtilsProvider>
						);
					} else {
						component = isNaN(ts)
							? formatDate(value)
							: dateFormat
							? handleSpecialDisplayDate(ts, dateFormat["datetime"], userLanguage)
							: formatDate(value);
					}
					break;
				case "email":
					if (actions.edit && isConvAgent) {
						component = textFieldComponent("email");
					} else component = value;
					break;
				case "url":
					if (actions.edit && isConvAgent) {
						component = textFieldComponent("url");
					} else {
						if (lod_.isNil(value)) {
							component = <></>;
						} else
							component = (
								<Link target="_blank" rel="noreferrer noopener" href={value}>
									{i18n.t("TabInfo.clickHere")}
								</Link>
							);
					}
					break;
				case "boolean": //TODO: switch or checkbox
					if (actions.edit && isConvAgent) {
						component = (
							<TextField
								variant="outlined"
								onBlur={() => handleSaveInfo()}
								onChange={e => handleChange(e, type)}
								defaultValue={value ? i18n.t("TabInfo.true") : i18n.t("TabInfo.false")}
							/>
						);
					} else
						component = (
							<Anchorme target="_blank" rel="noreferrer noopener" truncate={20}>
								{value ? i18n.t("TabInfo.true") : i18n.t("TabInfo.false")}
							</Anchorme>
						);
					break;
				default:
					if (actions.edit && isConvAgent) {
						component = textFieldComponent(type);
					} else
						component = (
							<Anchorme target="_blank" rel="noreferrer noopener">
								{value}
							</Anchorme>
						);
					break;
			}
			return component;
		});

		return (
			<>
				<Box
					display="flex"
					flexDirection={!editionMode || !openRightMenu ? "row" : "column"}
					style={{ margin: "1%" }}
				>
					<Box
						style={
							openRightMenu
								? { margin: "0 0 0 0", paddingTop: "3px" }
								: { margin: "0 0 0 0", paddingTop: "3px", width: "50%", alignSelf: "center" }
						}
						xs={2}
						flexDirection="row"
					>
						{!editionMode && displayActions && (
							<>
								{!lod_.isNil(value) && (
									<Tooltip title={i18n.t("TabInfo.addInEditor")} placement="top">
										<IconButton onClick={() => handleAddContactItem(value, type)} size="small">
											<NoteAddOutlinedIcon />
										</IconButton>
									</Tooltip>
								)}

								{type == "url" && !lod_.isNil(value) && (
									<Tooltip title={i18n.t("TabInfo.addAttachment")} placement="top">
										<IconButton
											onClick={() => {
												setAttachmentLoading(true);
												dispatch(
													cockpitActions.clickToUpload(
														value,
														auth.user.email,
														assistantconfig.assistantID,
														currentMessage.mID || ""
													)
												);
											}}
											size="small"
										>
											{attachmentLoading && <CircularProgress size="20px" />}
											{!attachmentLoading && <AttachFile />}
										</IconButton>
									</Tooltip>
								)}
							</>
						)}
						<Tooltip title={title} placement="top">
							<Button
								className="detailsContent-label"
								style={{ textAlign: "left" }}
								onClick={() => {
									handleCopy(value);
									dispatch(infoMsg(i18n.t("info copiée")));
								}}
							>
								{label[userLanguage] + ": "}
							</Button>
						</Tooltip>
					</Box>
					<Box style={{ width: "100%" /*, textAlign: "right"*/, alignSelf: "end" }} xs={9}>
						<span
							className={
								value === "masked"
									? "detailsContent-data " + classes.masked
									: "detailsContent-data " + classes.displayed
							}
						>
							{isArray ? (
								Array.isArray(value) && (
									<List>
										{value.map((v, index) => (
											<ListItem key={index}>
												{" "}
												<ContentElement value={v} index={index} />
											</ListItem>
										))}
									</List>
								)
							) : (
								<ContentElement value={value} />
							)}
						</span>
					</Box>
				</Box>
			</>
		);
	};

	const MemorizedInfoContentByType = memo(InfoContentByType);

	const RecursiveComponent = ({ title, config, displayActions }) => {
		const [showLevel, setShowLevel] = useState([]);
		let children = config["items"];

		/**
		 *
		 * @param {*} item
		 */
		const handleClick = item => {
			//Adds/removes item clicked from state
			if (showLevel.indexOf(item) === -1) {
				setShowLevel([...showLevel, item]);
			} else {
				const items = showLevel.filter(i => i !== item);
				setShowLevel(items);
			}
		};

		const shouldDisplayItem = itemKey => {
			const item = children[itemKey];
			return item["display"] === true;
		};

		if (children) {
			return (
				<>
					{Object.keys(children)
						.sort((a, b) => children[a]["order"] - children[b]["order"])
						.map((child, index) => {
							if (shouldDisplayItem(child))
								return (
									<Box className={`levelWrapper levelWrapper-${index}`} key={index}>
										{children[child]["type"] === "level" ? (
											<>
												<div
													className="levelWrapper-details"
													style={
														Object.entries(children[child]["items"]).length === 0
															? { display: "none" }
															: { display: "block" }
													}
												>
													<div
														className="levelWrapper-details-titleWrapper"
														onClick={() => {
															handleClick(child);
														}}
													>
														<div className={`titleLevel titleLevel-${index}`}>
															{children[child]["label"][userLanguage]}
														</div>
														<div className="levelWrapper-titleLevelCollapseIconWrapper">
															<button className="levelWrapper-titleLevelCollapseIcon">
																{showLevel.indexOf(child) === -1 ? (
																	<ExpandLessIcon />
																) : (
																	<ExpandMoreIcon />
																)}
															</button>
														</div>
													</div>
													<Collapse
														in={showLevel.indexOf(child) === -1}
														timeout="auto"
														unmountOnExit
													>
														<RecursiveComponent
															config={children[child]}
															displayActions={displayActions}
															title={title}
															key={`r-${index}`}
														/>
													</Collapse>
												</div>
											</>
										) : (
											!lod_.isNil(children[child]["value"]) &&
											children[child]["value"] !== "" &&
											(editionMode ||
												contactAnsDocSearchFilter?.trim().length === 0 ||
												children[child]["label"][userLanguage].toLowerCase().includes(
													contactAnsDocSearchFilter?.toLowerCase() //this is for info search
												)) && (
												<>
													<div className="levelWrapper-details">
														<div className="detailsContent">
															<MemorizedInfoContentByType
																leaf={child}
																config={children[child]}
																title={title}
																displayActions={displayActions}
															/>
														</div>
													</div>
												</>
											)
										)}
									</Box>
								);
						})}
				</>
			);
		} else return null;
	};

	return (
		<>
			<Box>
				{!editionMode && (
					<div className="btnShow">
						<ShowHiddenFields />
					</div>
				)}
				{editionMode && (
					<Box textAlign="right">
						<Button
							size="small"
							color="primary"
							onClick={() => {
								setInfosExpanded(!expandSens);
								setExpandSens(!expandSens);
							}}
						>
							{expandSens ? i18n.t("TabInfo.closeAll") : i18n.t("TabInfo.openAll")}
						</Button>
					</Box>
				)}
				<Grid container spacing={1}>
					{Object.keys(contextInfo).map((key, index) => {
						let isEmpty = true;
						if (
							contextInfo[key]["items"] &&
							Object.entries(contextInfo[key]["items"]) &&
							Object.entries(contextInfo[key]["items"]).length > 0
						) {
							isEmpty = false;
						}
						return (
							<Grid item xs={editionMode ? 6 : 12} key={index}>
								<Accordion
									expanded={
										expandSens
											? (Array.isArray(infosExpanded) &&
													!infosExpanded?.includes(`panel-${index}`)) ||
											  infosExpanded === true
											: (Array.isArray(infosExpanded) &&
													infosExpanded?.includes(`panel-${index}`)) ||
											  infosExpanded === true
									}
									className="cockpitTabInfo"
									style={isEmpty ? { display: "none" } : { display: "block" }}
								>
									<AccordionSummary
										onClick={() => handleChange(`panel-${index}`)}
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`panel_${key}bh-content`}
										id={`panel_${key}d-header`}
										key={`acs-${index}`}
									>
										<div className="logoContainer">
											<img className="logoActions" alt={"logo"} src={contextInfo[key]["logo"]} />
										</div>
										<span className="titleAccordion">
											{contextInfo[key]["label"][userLanguage]}
										</span>
									</AccordionSummary>
									<AccordionDetails key={`acd-${index}`}>
										<RecursiveComponent
											key={index}
											item={key}
											config={contextInfo[key]}
											displayActions={(ongoingConv || resolvedConv) && assignedConv}
											title={i18n.t("COC.CopyToClipboard")}
										/>
									</AccordionDetails>
								</Accordion>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};

/**
 * Merge data of conversation into the context config
 * @param {*} values
 * @param {*} config
 * @param {*} parentPath
 * @returns
 */
const mergeContextValuesIntoContextConfig = (values, config, parentPath, firstLoop = true) => {
	for (const key of Object.keys(config)) {
		if (config[key]["items"]) {
			let newKey;
			if (firstLoop === true && key === "context") newKey = "";
			else newKey = parentPath ? `${parentPath}.${key}` : key;
			mergeContextValuesIntoContextConfig(values, config[key]["items"], newKey, false);
		} else {
			const accessor = parentPath ? parentPath + "." + key : key; // avoid ".xxx" When we have data directly in conv.context
			config[key]["value"] = lod_.get(values, accessor);
			config[key]["accessor"] = accessor;
		}
	}
	const { context, ...rest } = config;
	return { ...rest, ...context?.items };
};

/**
 *
 * @returns
 */
const ShowHiddenFields = () => {
	const selectedConversation = useSelector(state => state.cockpit.selectedConversation);

	const [openConfirm, setOpenConfirm] = useState(false);
	const [disable, setDisable] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setDisable(false);
	}, [selectedConversation?.header?.fID]);

	const handleClose = () => {
		setOpenConfirm(false);
	};

	const handleGetHiddenFields = () => {
		setDisable(true);
		dispatch(cockpitActions.selectConversation(selectedConversation, true));
		handleClose();
	};

	return (
		<>
			<Tooltip title={i18n.t("TabInfo.showHiddenFields")}>
				<IconButton disabled={disable} onClick={() => setOpenConfirm(true)}>
					<VisibilityOutlined />
				</IconButton>
			</Tooltip>
			{openConfirm && (
				<ConfirmationDialog
					open={openConfirm}
					handleClose={handleClose}
					title={i18n.t("TabInfo.showHiddenConfirmTitle")}
					message={i18n.t("TabInfo.showHiddenConfirm")}
					handleValidate={handleGetHiddenFields}
					cancel={i18n.t("COC.disagree")}
					validate={i18n.t("COC.agree")}
				/>
			)}
		</>
	);
};
