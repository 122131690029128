import React, { useEffect, useState } from "react";
import i18n from "i18next";

import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Button,
	Typography
} from "@material-ui/core";
import lod_ from "lodash";
import { useDispatch } from "react-redux";
import { infoMsg } from "redux/reducers/snackMsgsReducers";

const ScrappingDataInput = ({ anchorEl, handleClose, handleSubmit, actionObject }) => {
	const [searchField, setSearchField] = useState("");
	const [showTooltip, setShowTooltip] = useState(false);
	const [coockieSession, setCoockieSession] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		handleGetCoockie(actionObject?.config?.actionExt);
	}, []);

	function handleValidate() {
		const regex = actionObject?.config?.regex;
		let regexString = new RegExp(regex);

		if (lod_.isNil(actionObject?.config?.regex) || lod_.isEmpty(actionObject?.config?.regex)) {
			handleSubmit({}, actionObject.action);
		} else if (regexString.test(searchField)) {
			handleSubmit({ url: searchField, coockie: coockieSession }, actionObject.action);
		} else {
			setShowTooltip(true);
		}
	}

	function handleGetCoockie(action) {
		if (!lod_.isNil(action)) {
			const message = { action };
			const event = new CustomEvent("toFaibrikExtension", { detail: message });
			document.dispatchEvent(event);
		} else {
			dispatch(infoMsg(i18n.t("COC.ActionEXTManquante")));
		}
	}

	document.addEventListener("fromFaibrikExtension", event => {
		const data = event.detail;
		if (lod_.isNil(data?.cookie) && !lod_.isNil(actionObject?.config?.urlExt)) {
			window.open(actionObject.config.urlExt, "_blank");
		} else if (!lod_.isNil(data?.cookie) && !lod_.isEmpty(data?.cookie)) {
			setCoockieSession(data.cookie);
		} else {
			dispatch(infoMsg(i18n.t("COC.ImpossibleRecupSession")));
		}
	});

	return (
		<Dialog
			fullWidth={true}
			onClose={handleClose}
			maxWidth={"sm"}
			open={open}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{i18n.t("COC.ACTIONS.scrappingAction")}</DialogTitle>
			<DialogContent dividers>
				<Box display="flex" flexDirection="row" alignItems="center" padding="10px">
					{lod_.isEmpty(coockieSession) ? (
						<Box
							display="flex"
							style={{ flexDirection: "column", width: "100%", alignItems: "center" }}
						>
							<Typography>
								Pour récupérer les informations, veuillez vous connecter à LinkedIn
							</Typography>

							<Button
								color="primary"
								simple
								onClick={() => handleGetCoockie(actionObject?.config?.actionExt)}
								variant="contained"
							>
								Raffraichir
							</Button>
						</Box>
					) : (
						<TextField
							variant="outlined"
							type="text"
							fullWidth
							value={searchField}
							error={showTooltip}
							helperText={showTooltip === true ? i18n.t("COC.urlValid") : ""}
							label={"URL"}
							onChange={event => setSearchField(event.target.value)}
							InputLabelProps={{
								shrink: true
							}}
						/>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
					{i18n.t("CD.cancel")}
				</Button>
				<Button
					onClick={() => handleValidate()}
					style={{ margin: "2%" }}
					color="primary"
					disabled={lod_.isEmpty(searchField) || lod_.isEmpty(coockieSession)}
				>
					{i18n.t("CD.validate")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ScrappingDataInput;
