import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import cockpitActions from "../../../redux/actions/cockpitActions";
import C from "../../../constants/cockpit";

/**
 * Subject input component
 * Draft subject management
 * @returns
 */
export const SubjectInputComponent = ({ setLoading }) => {
	const { topicDraft, selectedAssistantID, selectedfID, substate } = useSelector(state => {
		return {
			topicDraft: state?.cockpit?.topicDraft,
			selectedAssistantID: state?.cockpit?.selectedConversation?.header?.assistantID,
			selectedfID: state?.cockpit?.selectedConversation?.header?.fID,
			substate: state?.cockpit?.selectedConversation?.header?.substate
		};
	});
	const dispatch = useDispatch();

	const [subjectValue, setSubjectValue] = useState(topicDraft);
	const [isTyping, setIsTyping] = useState(false);

	/**
	 * topicDraft has been updated by an answer item insertion or forward or other reasons..
	 */
	useEffect(() => {
		if (typeof topicDraft === "string") setSubjectValue(topicDraft);
	}, [topicDraft]);

	/**
	 * AutoSave the topic (?)
	 */
	useEffect(() => {
		if (subjectValue !== null) {
			const timeoutId = setTimeout(() => {
				const fID = selectedfID;
				const assistantID = selectedAssistantID;
				const newDraftValues = {
					draftTopic: subjectValue
				};
				dispatch(cockpitActions.postDraft(fID, assistantID, substate, newDraftValues));
				dispatch({ type: C.SET_TOPIC_DRAFT, payload: subjectValue });
				setIsTyping(false);
				setLoading(true);
			}, 1000);

			return () => clearTimeout(timeoutId);
		}
	}, [subjectValue]);

	/**
	 * Change value of subject
	 * @param {*} event
	 */
	const handleChangeInputSubject = event => {
		let value = event.currentTarget.value;
		setSubjectValue(value);
		setLoading(false);
		setIsTyping(true);
	};

	return (
		<TextField
			fullWidth
			value={subjectValue || ""}
			variant="outlined"
			onChange={handleChangeInputSubject}
			inputProps={{
				style: {
					height: "0",
					padding: "1em",
					fontSize: "16px"
				}
			}}
			style={{ width: "100%" }}
		/>
	);
};
