import React, { Component } from "react";
import i18n from "i18n";

class DragAndDrop extends Component {
	/**
	 * Need to use state to update in render
	 */
	state = {
		drag: false
	};

	/**
	 * Parent element
	 */
	dropRef = React.createRef();

	/**
	 * Keep div open while dragging
	 */
	dragCounter = 0;

	/**
	 * To don't open image/file when drop on page
	 * @param {*} e
	 */
	handleDrag = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	/**
	 * Handle drag when attachment hover in area
	 * @param {*} e
	 */
	handleDragIn = e => {
		e.preventDefault();
		e.stopPropagation();

		this.dragCounter++;

		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState({ drag: true });
		}
	};

	/**
	 * Handle drag when attachment hover out area
	 * @param {*} e
	 */
	handleDragOut = e => {
		e.preventDefault();
		e.stopPropagation();

		this.dragCounter--;

		if (this.dragCounter === 0) {
			this.setState({ drag: false });
		}
	};

	/**
	 * Handle when drop attachment
	 * @param {*} e
	 */
	handleDrop = e => {
		e.preventDefault();
		e.stopPropagation();

		this.setState({ drag: false });

		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this.props.handleDrop(e.dataTransfer.files);
			e.dataTransfer.clearData();
			this.dragCounter = 0;
		}
	};

	/**
	 * Bind event listener
	 */
	componentDidMount() {
		if (this.props.currentChannel == "SMS") {
			return;
		}

		let div = this.dropRef.current;
		div.addEventListener("dragenter", this.handleDragIn);
		div.addEventListener("dragleave", this.handleDragOut);
		div.addEventListener("dragover", this.handleDrag);
		div.addEventListener("drop", this.handleDrop);
	}

	/**
	 * Unbind event listener
	 */
	componentWillUnmount() {
		let div = this.dropRef.current;
		div.removeEventListener("dragenter", this.handleDragIn);
		div.removeEventListener("dragleave", this.handleDragOut);
		div.removeEventListener("dragover", this.handleDrag);
		div.removeEventListener("drop", this.handleDrop);
	}

	render() {
		return (
			<div style={{ position: "relative", height: "100%" }} ref={this.dropRef}>
				{this.state.drag && (
					<div
						style={{
							border: "dashed grey 4px",
							backgroundColor: "rgba(255,255,255,.8)",
							position: "absolute",
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							zIndex: 9999
						}}
					>
						<div
							style={{
								position: "absolute",
								top: "50%",
								right: 0,
								left: 0,
								textAlign: "center",
								color: "grey",
								fontSize: 36
							}}
						>
							<div>{i18n.t("COC.Attachements")}</div>
						</div>
					</div>
				)}
				{this.props.children}
			</div>
		);
	}
}
export default DragAndDrop;
