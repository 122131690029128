import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { List, ListItem, ListItemText } from "../../../node_modules/@material-ui/core/index";
import { Menu, MenuItem } from "../../../node_modules/@material-ui/core/index";

export default function FormMenu(props) {
	const { name, choiceLabel, options, selectedIndex, primary, secondary, onChange } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClickListItem = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (event, index) => {
		setAnchorEl(null);
		onChange(convertToDefEventParam(name, options[index]));
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	return (
		<div>
			<Typography>{choiceLabel}</Typography>
			<List component="nav" aria-label="Device settings" sx={{ bgcolor: "background.paper" }}>
				<ListItem
					button
					id="lock-button"
					aria-haspopup="listbox"
					aria-controls="lock-menu"
					aria-label={name}
					aria-expanded={open ? "true" : undefined}
					onClick={handleClickListItem}
				>
					<ListItemText
						primary={primary ? primary[selectedIndex] : ""}
						secondary={secondary ? secondary[selectedIndex] : ""}
					/>
				</ListItem>
			</List>
			<Menu
				id="lock-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenu}
				MenuListProps={{
					"aria-labelledby": "lock-button",
					role: "listbox"
				}}
			>
				{primary.map((option, index) => (
					<MenuItem
						key={index}
						selected={index === selectedIndex}
						onClick={event => handleMenuItemClick(event, index)}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
