/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import "date-fns";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "../../../../redux/actions/cockpitActions";
import { Box, List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { endOfToday, getTime, startOfMonth, startOfToday, startOfWeek, sub } from "date-fns";
import i18n from "i18n";
import C from "../../../../constants/cockpit.js";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		overflow: "auto"
	},
	ul: {
		backgroundColor: "inherit",
		padding: 0
	},
	container: {
		display: "flex",
		flexDirection: "column"
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120
	},
	line: {
		padding: theme.spacing(2)
	}
}));

function DateFilterDialog({ openDialog, handleCloseDateFilterDialog }) {
	const { convStartDateFilter, convEndDateFilter } = useSelector(state => state.cockpit);
	const classes = useStyles();
	const startDateFilter = convStartDateFilter !== null ? convStartDateFilter - 24 * 60 * 60 : null;
	const [startDate, setStartDate] = React.useState(startDateFilter || new Date());
	const [endDate, setEndDate] = React.useState(convEndDateFilter || new Date());
	const [inputError, setInputError] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const open = Boolean(anchorEl);
	const [options, setOptions] = React.useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setOptions([
			`${i18n.t("COC.relativeDateFilter.today")}`,
			`${i18n.t("COC.relativeDateFilter.currentWeek")}`,
			`${i18n.t("COC.relativeDateFilter.lastSevenDays")}`,
			`${i18n.t("COC.relativeDateFilter.currentMonth")}`,
			`${i18n.t("COC.relativeDateFilter.lastThirtyDays")}`,
			`${i18n.t("COC.relativeDateFilter.chooseManually")}`
		]);
	}, [openDialog]);

	const handleClickListItem = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setAnchorEl(null);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	const handleStartDateChange = date => {
		setStartDate(date);
	};

	const handleEndDateChange = date => {
		setEndDate(date);
	};

	const handleClear = () => {
		setStartDate(null);
		setEndDate(null);
		let timestampStartDate = null;
		let timestampEndDate = null;
		handleCloseDateFilterDialog();
		handleSubmitDateFilterDialog(timestampStartDate, timestampEndDate);
	};

	const handleClose = () => {
		setStartDate(startDateFilter);
		setEndDate(convEndDateFilter);
		handleCloseDateFilterDialog();
	};

	const handleSave = () => {
		let timestampStartDate;
		let timestampEndDate;
		switch (selectedIndex) {
			case 0:
				// Today
				timestampEndDate = getTime(startOfToday());
				timestampStartDate = getTime(endOfToday());
				break;
			case 1:
				// Current Week
				timestampEndDate = getTime(startOfWeek(new Date()));
				timestampStartDate = getTime(endOfToday());
				break;
			case 2:
				// Last 7 days
				timestampEndDate = getTime(sub(startOfToday(), { days: 7 }));
				timestampStartDate = getTime(endOfToday());
				break;
			case 3:
				// Current month
				timestampEndDate = getTime(startOfMonth(new Date()));
				timestampStartDate = getTime(endOfToday());
				break;
			case 4:
				// Last 30 days
				timestampEndDate = getTime(sub(startOfToday(), { days: 30 }));
				timestampStartDate = getTime(endOfToday());
				break;
			case 5:
				// Choose manually
				if (startDate === null) {
					timestampStartDate = null;
				} else {
					let timezoneOffset = startDate.getTimezoneOffset();
					if (timezoneOffset > 0) {
						startDate.setMinutes(24 * 60 - (timezoneOffset + 1));
					} else {
						startDate.setMinutes(-timezoneOffset);
					}
					timestampStartDate = startDate.setUTCHours(0, 0, 0, 0);
				}
				if (endDate === null) {
					timestampEndDate = null;
				} else {
					let timezoneOffset = endDate.getTimezoneOffset();
					if (timezoneOffset > 0) {
						endDate.setMinutes(24 * 60 - (timezoneOffset + 1));
					} else {
						endDate.setMinutes(-timezoneOffset);
					}
					timestampEndDate = endDate.setUTCHours(0, 0, 0, 0);
				}
				break;
		}
		handleSubmitDateFilterDialog(timestampStartDate, timestampEndDate);
	};

	/**
	 *
	 * @param {*} sfilter
	 * @param {*} efilter
	 */
	const handleSubmitDateFilterDialog = (sfilter, efilter) => {
		dispatch([
			{
				type: C.SET_CONV_START_DATE_FILTER,
				payload: sfilter
			},
			{
				type: C.SET_CONV_END_DATE_FILTER,
				payload: efilter
			}
		]);
		handleCloseDateFilterDialog();
	};

	return (
		<div>
			<Dialog
				fullWidth={true}
				maxWidth="sm"
				open={openDialog}
				onClose={handleCloseDateFilterDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{i18n.t("COC.DateFilter")}</DialogTitle>

				<DialogContent dividers>
					<div>
						<List component="nav" aria-label="Device settings" sx={{ bgcolor: "background.paper" }}>
							<ListItem
								button
								id="lock-button"
								aria-haspopup="listbox"
								aria-controls="lock-menu"
								aria-label={i18n.t("COC.relativeDateFilter.chooseDateFilter")}
								aria-expanded={open ? "true" : undefined}
								onClick={handleClickListItem}
							>
								<ListItemText
									primary={i18n.t("COC.relativeDateFilter.chooseDateFilter")}
									secondary={options[selectedIndex]}
								/>
							</ListItem>
						</List>
						<Menu
							id="lock-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleCloseMenu}
							MenuListProps={{
								"aria-labelledby": "lock-button",
								role: "listbox"
							}}
						>
							{options.map((option, index) => (
								<MenuItem
									key={option}
									selected={index === selectedIndex}
									onClick={event => handleMenuItemClick(event, index)}
								>
									{option}
								</MenuItem>
							))}
						</Menu>
					</div>{" "}
					{selectedIndex === 5 ? (
						<Grid
							justifyContent="space-around"
							container
							className={classes.line}
							spacing={2}
							alignItems="center"
						>
							<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="dd/MM/yyyy"
									margin="normal"
									label={i18n.t("COC.AfterDate")}
									focused={true}
									autoOk="true"
									value={endDate}
									onChange={handleEndDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date"
									}}
									onError={error => {
										setInputError(error !== "");
									}}
									disableFuture={true}
								/>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="dd/MM/yyyy"
									margin="normal"
									label={i18n.t("COC.BeforeDate")}
									focused={true}
									autoOk="true"
									value={startDate}
									onChange={handleStartDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date"
									}}
									onError={error => {
										setInputError(error !== "");
									}}
									minDate={endDate}
									disableFuture={true}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					) : (
						<Box></Box>
					)}
				</DialogContent>

				<DialogActions>
					<Button
						disabled={startDate === null && endDate === null}
						onClick={handleClear}
						color="primary"
					>
						{`${i18n.t("COC.ClearAll")}`}
					</Button>
					<Button onClick={handleClose} color="secondary">
						{i18n.t("COC.Cancel")}
					</Button>
					<Button onClick={handleSave} color="primary" disabled={inputError}>
						{i18n.t("COC.ApplyFilters")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}
export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(DateFilterDialog);
