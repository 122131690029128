/* eslint-disable no-fallthrough */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import AvTimerOutlinedIcon from "@material-ui/icons/AvTimerOutlined";
import BallotOutlinedIcon from "@material-ui/icons/BallotOutlined";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import i18n from "i18next";
import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import ChannelUserInfoMembershipDisplayer from "../../../helpers/ChannelUserInfoMembershipDisplayer";
import { getLabelOf, getPriorityIcon, getSentimentIcon } from "../../../helpers/utilities";
import { getConvItemSelector } from "../../../redux/selectors/cockpitSelectors";
import { formatDate, handleSpecialDisplayDate } from ".././../../helpers/utilities";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { AssignConversation, RejectConversation } from "../ConvActions/ConvItemComponents";
import { Edit } from "@material-ui/icons";
import lod_ from "lodash";

const ConvItemRenderer = ({ measure, conv, handleSelectConversation }) => {
	const { selectedConversation, userLanguage, dateFormat, email, role, switchTabOnAssignation } =
		useSelector(getConvItemSelector, shallowEqual);
	const { groups } = useSelector(state => state.userGroup);
	const { assistantconfig } = useSelector(state => state);

	// user cockpit settings
	let cockpitSettings = useSelector(state => state?.userStatus?.auth?.user?.cockpitSettings); // TODO: PIKA 2023.05.17 --> Quid des users qui n'ont pas cet attribut ???

	const isCall = conv?.header?.type === "call";

	const handleDisplayDateListConv = (dateFormat, userLanguage) => {
		if (conv?.meta?.cockpit?.datetime && conv?.meta?.cockpit?.ts) {
			if (dateFormat) {
				return handleSpecialDisplayDate(conv.meta?.cockpit.ts, dateFormat, userLanguage);
			} else {
				return formatDate(conv.meta?.cockpit.datetime);
			}
		} else {
			return "";
		}
	};
	//{ measure, selectedConversation, conv, handleSelectConversation, action }
	//to remeasure dynamically the height of the row
	React.useEffect(() => {
		measure();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conv]);

	const SubStateIconActive = ({ substate, state }) => {
		switch (substate) {
			case "waiting_client":
				return (
					<Tooltip title={i18n.t("convItemRender.waitingClient")} placement="right-start">
						<IconButton size="small">
							<AvTimerOutlinedIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				);

			case "waiting_action":
				return (
					<Tooltip title={i18n.t("convItemRender.waitingAction")} placement="right-start">
						<IconButton size="small">
							<BallotOutlinedIcon fontSize="small" style={{ color: "black" }} />
						</IconButton>
					</Tooltip>
				);
			case "alreadyResolved":
			case "resolved":
				return (
					<Tooltip title={i18n.t("convItemRender.resolved")} placement="right-start">
						<IconButton size="small">
							<DoneOutlineOutlinedIcon fontSize="small" style={{ color: "green" }} />
						</IconButton>
					</Tooltip>
				);

			case "watchlist":
				return (
					<Tooltip title={i18n.t("convItemRender.watchlist")} placement="right-start">
						<IconButton size="small">
							<RemoveRedEyeOutlinedIcon fontSize="small" style={{ color: "orange" }} />
						</IconButton>
					</Tooltip>
				);

			case "expecting_answer":
				if (state !== "waiting")
					return (
						<Tooltip title={i18n.t("convItemRender.expectingAnswer")} placement="right-start">
							<IconButton size="small">
								<HelpOutlineIcon fontSize="small" style={{ color: "blue" }} />
							</IconButton>
						</Tooltip>
					);
				else return null;

			case "reopen":
				return (
					<Tooltip title={i18n.t("convItemRender.reopen")} placement="right-start">
						<IconButton size="small">
							<SettingsBackupRestoreIcon fontSize="small" style={{ color: "black" }} />
						</IconButton>
					</Tooltip>
				);

			case "error":
				return (
					<Tooltip title={i18n.t("convItemRender.error")} placement="right-start">
						<IconButton size="small">
							<ErrorOutlineIcon fontSize="small" style={{ color: "red" }} />
						</IconButton>
					</Tooltip>
				);

			case "sending":
				return (
					<Tooltip title={i18n.t("convItemRender.sending")} placement="right-start">
						<IconButton size="small">
							<ScheduleIcon
								fontSize="small"
								style={{
									color: "black"
								}}
							/>
						</IconButton>
					</Tooltip>
				);
			case "draft":
				return (
					<Tooltip title={i18n.t("COC.InDraft")} placement="right-start">
						<IconButton size="small">
							<Edit fontSize="small" />
						</IconButton>
					</Tooltip>
				);
			default:
				return null;
		}
	};

	// GET GROUPS NAME WITH GROUP IN STORE
	function getGroupName(conv) {
		let escalationGroup = conv?.header?.escalationGroup[0];

		if (lod_.isNil(escalationGroup)) {
			return "";
		} else {
			let findGroup = groups.find(group => group?._id === escalationGroup);

			return findGroup?.name || "";
		}
	}

	function displayTitleConv(conv) {
		if (cockpitSettings?.defaultTopicOrSubject) {
			let title = cockpitSettings.defaultTopicOrSubject;
			let lastSubject = conv.subject;

			if (title === "subject") {
				return (
					<Box component="span" style={{ fontFamily: "Roboto" }} mx={0.5}>
						{lastSubject}
					</Box>
				);
			} else if (title === "topic") {
				return (
					<Box component="span" style={{ fontFamily: "Roboto" }} mx={0.5}>
						{getLabelOf(conv.meta?.topic)}
					</Box>
				);
			} else {
				return (
					<Box component="span" style={{ fontFamily: "Roboto" }} mx={0.5}>
						{getLabelOf(conv.meta?.cockpit.title)}
					</Box>
				);
			}
		}

		if (conv.meta?.cockpit?.title) {
			return (
				<Box component="span" style={{ fontFamily: "Roboto" }} mx={0.5}>
					{getLabelOf(conv.meta?.cockpit.title)}
				</Box>
			);
		}
	}

	// see log message at the end
	if (conv === null || conv === undefined) return <></>;
	return (
		<ListItem
			button
			selected={selectedConversation?.header?.fID === conv.header.fID}
			onClick={() => {
				handleSelectConversation(conv);
			}}
		>
			<ListItemText>
				<Box width={1}>
					<Box display="flex" alignItems="center">
						<ChannelUserInfoMembershipDisplayer
							channelImage={conv.meta?.cockpit?.channelCode || ""}
							channel={conv?.header?.channel}
							connected={conv.meta?.cockpit?.connected || false}
							contactNameOrEmail={conv.meta?.cockpit?.contact || ""}
							substate={conv.header.substate || null}
							FRU={conv?.contact?.uid}
							assistantID={conv.header.assistantID}
						/>
						<Box>
							<Grid container alignItems="center">
								{conv.header.state === "waiting" && !isCall && (
									<AssignConversation conversation={conv} />
								)}
								{conv.header.state === "ongoing" && !isCall && (
									<>
										{conv.agent.isBot ? (
											<AssignConversation conversation={conv} isBot={conv.agent.isBot} />
										) : (
											(email === conv.agent.uid ||
												role === "supervisor" ||
												role === "soloUser" ||
												assistantconfig?.cockpit?.agentCanAssignationConv === true) &&
											conv.header.substate != "draft" && <RejectConversation conversation={conv} />
										)}
									</>
								)}
							</Grid>
						</Box>
					</Box>
					<Box fontWeight="fontWeightBold" display="flex">
						{conv.meta?.priority && <Box>{getPriorityIcon(conv.meta?.priority)}</Box>}

						{displayTitleConv(conv)}
						{conv.meta?.sentiment && (
							<Box component="span" mx={0.5}>
								{getSentimentIcon(conv.meta?.sentiment)}
							</Box>
						)}
					</Box>
					<Box display="flex" alignItems="center" flexWrap="wrap">
						<Box flexGrow={1} fontSize={14} fontFamily="Roboto">
							{handleDisplayDateListConv(dateFormat, userLanguage)}
						</Box>
						{conv.header.fID ? (
							<Box flexGrow={6} fontSize={14} fontFamily="Roboto">
								{conv.header.fID}
							</Box>
						) : (
							<></>
						)}
						<Box>
							{conv.meta?.cockpit?.hasAttachment && <AttachFileOutlinedIcon />}
							{conv.meta?.cockpit?.hasNote && (
								<NoteOutlinedIcon style={{ transform: "rotate(270deg)" }} />
							)}
						</Box>
						<Box fontSize={11}>
							{conv.header?.isEscalated && (
								<Tooltip title={getGroupName(conv)} placement="top">
									<TrendingUpIcon size="small" />
								</Tooltip>
							)}
						</Box>
						<Box fontSize={11}>
							{conv.header?.escalateDone && (
								<TrendingUpIcon size="small" style={{ color: "green" }} />
							)}
						</Box>
						<Box>
							{conv.header.humanRequest ? (
								<Tooltip title={i18n.t("convItemRender.waitingAction")} placement="right-start">
									<IconButton size="small">
										<HelpOutlineIcon fontSize="small" style={{ color: "red" }} />
									</IconButton>
								</Tooltip>
							) : (
								<>
									{(conv.header.substate !== "" || null) && (
										<SubStateIconActive substate={conv.header.substate} state={conv.header.state} />
									)}
								</>
							)}
						</Box>
					</Box>
				</Box>
			</ListItemText>
		</ListItem>
		//  ) : (
		/**
		 * TODO:
		 * If there is nothing to display, don't display
		 */
		// <ListItem>
		// 	<ListItemText>Conversation could not be rendered</ListItemText>
		// </ListItem>
	);
};

const MemoizedConvItemRenderer = memo(ConvItemRenderer);
export default MemoizedConvItemRenderer;
