/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint consistent-return: 2*/
/* eslint-disable no-mixed-operators */
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";
import { Box, Button, Menu, MenuItem, TextField } from "@material-ui/core";
import { api } from "redux/actions/api";
import { useDispatch } from "react-redux";
import GridItem from "components/Grid/GridItem";

function AddToListDialog(props) {
	const dispatch = useDispatch();

	const [lists, setLists] = useState([]);
	const [selectedText, setSelectedText] = useState(props.t("CONTACT.chooseList"));
	const [selected, setSelected] = useState("none");

	const [anchorEl, setAnchorEl] = React.useState(null);

	const openMenu = Boolean(anchorEl);

	/**
	 * Close modal ContactEditDialog
	 **/
	const handleClose = () => {
		props.handleCloseAddListDialog();
	};

	/**
	 * Click on "Create list" button
	 */
	const handleCreateList = () => {
		props.handleCloseAddListDialog();
		props.handleCreateList();
	};

	/**
	 * Fetch all lists
	 */
	useEffect(() => {
		(async () => {
			await getContactLists();
		})();
	}, []);

	/**
	 * Fetch all lists
	 */
	function getContactLists() {
		dispatch(
			api({
				type: "getContactLists",
				url: "/api/v1/" + props.selectedAssistantID + "/lists",
				data: { selectedAssistantID: props.selectedAssistantID },
				onSuccess: res => {
					setLists(res.elements);
				}
			})
		);
	}

	/**
	 * Handle change on select
	 * @param {*} event
	 */
	const onSelectChange = event => {
		setSelected(event.target.value);
	};

	/**
	 * Handle click on "Add" button
	 */
	function handleAddToList() {
		var contactsString;

		if (props.directAdded == null) {
			contactsString = props.selectedContacts
				.map(function (contact) {
					return contact.email;
				})
				.join(",");
		} else {
			contactsString = props.directAdded.email;
		}

		props.resetCheckedData();

		dispatch(
			api({
				type: "handleAddToList",
				url: "/api/v1/lists",
				method: "PUT",
				data: {
					assistantID: props.selectedAssistantID,
					name: selected,
					emails: contactsString
				},
				onSuccess: () => {
					props.handleCloseAddListDialog();
					props.refreshLists();
					return { type: "SUCCESS" };
				},
				onFailure: () => {
					return { type: "ERROR" };
				}
			})
		);
	}

	const handleClickMenu = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Dialog onClose={handleClose} open={true} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
				<Box display="flex" width="800px" flexDirection={"column"}>
					<Box display="flex" flexDirection={"column"} width={"100%"}>
						<DialogTitle
							id="form-dialog-title"
							maxwidth={"md"}
							height="401px"
							style={{
								borderBottom: "1px solid",
								borderColor: "#d8d6d6"
							}}
						>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								wrap="nowrap"
							>
								{props.t("CONTACT.addToList")}
							</Grid>
						</DialogTitle>

						<Grid container item xs={9} style={{ maxWidth: "100%" }}>
							<DialogContent style={{ overflowY: "hidden" }}>
								{lists.length == 0 && <>{props.t("CONTACT.noListCreated")}</>}

								{lists.length > 0 && (
									<>
										<h4 style={{ fontWeight: "bold" }}>{props.t("CONTACT.chooseList")}</h4>

										<Button
											id="basic-button"
											aria-controls={openMenu ? "basic-menu" : undefined}
											aria-haspopup="true"
											aria-expanded={openMenu ? "true" : undefined}
											onClick={handleClickMenu}
											color="primary"
										>
											{selectedText}
										</Button>
										<Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={openMenu}
											onClose={handleCloseMenu}
										>
											{lists.map((list, index) => {
												return (
													<MenuItem
														key={index}
														onClick={() => {
															setSelectedText(list.name);
															setSelected(list.name);
															handleCloseMenu();
														}}
													>
														{list.name}
													</MenuItem>
												);
											})}
										</Menu>
									</>
								)}

								<h4 style={{ paddingTop: "2%", fontWeight: "bold" }}>
									{props.t("CONTACT.contacts")}
								</h4>

								<Grid className="ulSquare">
									{props.directAdded != null && (
										<GridItem className="liElement" key="1">
											{props.directAdded.email}
										</GridItem>
									)}
									{props.directAdded == null &&
										props.selectedContacts?.map((item, index) => {
											return (
												<GridItem className="liElement" key={index}>
													{item.email}
												</GridItem>
											);
										})}
								</Grid>
							</DialogContent>
						</Grid>

						<Grid
							item
							container
							direction="row"
							justifyContent="flex-end"
							style={{ paddingTop: "2%" }}
						>
							<Button onClick={handleCreateList} style={{ margin: "2%" }} color="primary">
								{props.t("CONTACT.createNewList")}
							</Button>
							<Button onClick={handleClose} style={{ margin: "2%", color: "red" }}>
								{props.t("CONTACT.cancel")}
							</Button>
							<Button
								onClick={handleAddToList}
								style={{ margin: "2%" }}
								color="primary"
								disabled={selected != "none" ? false : true}
							>
								{props.t("CONTACT.addButton")}
							</Button>
						</Grid>
					</Box>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(AddToListDialog);
