/* eslint-disable no-case-declarations */
import C from "../../constants/assistantsConfig.js";

const assistantsconfigReducers = function (currentState = {}, action) {
	switch (action.type) {
		/**
		 * GET CONFIGURATION FROM TABLE assistantConfig
		 */

		case C.GET_CONFIG:
			let assistantconfig = action.payload;
			return assistantconfig;

		case C.GET_CONTEXT:
			let context = action.payload;
			return { ...currentState, context: context };

		/**
		 * CRUD FOR CHANNEL
		 */

		case C.GET_CHANNEL:
			let reloadChannel = action.payload;
			return { ...currentState, channels: reloadChannel };

		case C.SAVE_CHANNEL:
			let newChannel = action.payload;
			return {
				...currentState,
				channels: [...currentState.channels, newChannel]
			};

		case C.UPDATE_CHANNEL:
			let channelToUpdate = action.payload;

			return {
				...currentState,
				channels: currentState.channels.map(chan =>
					chan.code === channelToUpdate.code ? channelToUpdate : chan
				)
			};

		case C.DELETE_CHANNEL:
			let channelToDelete = action.payload;
			return {
				...currentState,
				channels: currentState.channels.filter(chan => chan.code !== channelToDelete.code)
			};

		/**
		 * CRUD FOR API
		 */
		case C.SAVE_API:
			let newApi = action.payload.api;
			return {
				...currentState,
				APIs: [...currentState.APIs, newApi]
			};

		case C.UPDATE_API:
			let apiToUpdate = action.payload.api;
			return {
				...currentState,
				APIs: currentState.APIs.map(api => (api.name === apiToUpdate.name ? apiToUpdate : api))
			};

		case C.DELETE_API:
			let apiToDelete = action.payload.api;
			return {
				...currentState,
				APIs: currentState.APIs.filter(api => api.name !== apiToDelete.name)
			};

		/**
		 * Get all intents
		 */
		case C.GET_INTENTS:
			let intents = action.payload;
			return { ...currentState, intents: intents };

		default:
			return currentState;
	}
};

export default assistantsconfigReducers;
