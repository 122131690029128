/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {
	Dialog,
	DialogContent,
	DialogTitle,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	DialogActions,
	IconButton,
	Box
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import lod_ from "lodash";
import { useSelector } from "react-redux";

/**
 * Eminza specific: The dialog to create an litige, with the data to set agent,
 * and send to enrich to creat this
 */
function CreationLitige({
	action,
	actionObject,
	handleClose,
	handleSubmit,
	resumeFromSummary
}) {
	const [open] = React.useState(true);
	const [orderIDCase, setOrderIDCase] = useState("");
	const [resumeCase, setResumeCase] = useState(resumeFromSummary);
	const [comment, setComment] = useState("");
	const [resolution, setResolution] = useState("");
	const [resolutionList, setResolutionList] = useState([]);

	const { selectedConversation } = useSelector(state => state.cockpit);

	useEffect(() => {
		if (!lod_.isNil(actionObject?.config?.resolutionList)) {
			setResolutionList(actionObject.config.resolutionList);
		}
		if (!lod_.isNil(actionObject?.config?.orderIDPath)) {
			setOrderIDCase(lod_.get(selectedConversation, actionObject.config.orderIDPath));
		}
	}, []);

	const checkValue = () => {
		if (lod_.isEmpty(orderIDCase) || lod_.isEmpty(resumeCase) || lod_.isEmpty(resolution)) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div>
			<Dialog
				fullWidth={true}
				onClose={handleClose}
				maxWidth={"sm"}
				open={open}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{i18n.t("TabAction.actionCreateCase")}</DialogTitle>
				<DialogContent dividers>
					{/* Famille */}
					<Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
						<Box>
							<TextField
								id="custom-text"
								label={i18n.t("TabAction.caseNumber")}
								value={orderIDCase}
								onChange={e => setOrderIDCase(e.target.value)}
								fullWidth
								InputProps={{
									endAdornment: !lod_.isEmpty(orderIDCase) && (
										<IconButton
											onClick={() => {
												setOrderIDCase("");
											}}
											size="small"
										>
											<Close />
										</IconButton>
									)
								}}
								variant="outlined"
								error={lod_.isEmpty(orderIDCase) ? true : false}
							/>
						</Box>

						<Box pt={2}>
							<TextField
								id="custom-text-case"
								label={i18n.t("TabAction.caseResume")}
								value={resumeCase}
								multiline
								minRows={10}
								onChange={e => setResumeCase(e.target.value)}
								fullWidth
								InputProps={{
									endAdornment: !lod_.isEmpty(resumeCase) && (
										<IconButton
											onClick={() => {
												setResumeCase("");
											}}
											size="small"
										>
											<Close />
										</IconButton>
									)
								}}
								variant="outlined"
								error={lod_.isEmpty(resumeCase) ? true : false}
							/>
						</Box>
						<Box pt={2}>
							<FormControl fullWidth variant="outlined">
								<InputLabel
									id="demo-simple-select-label"
									style={{ color: lod_.isEmpty(resolution) ? "red" : null }}
								>
									{i18n.t("TabAction.caseResolution")}
								</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={resolution}
									label={i18n.t("TabAction.caseResolution")}
									error={lod_.isEmpty(resolution) ? true : false}
									onChange={e => setResolution(e.target.value)}
								>
									{resolutionList
										.sort((a, b) => a.localeCompare(b))
										.map((item, index) => {
											return (
												<MenuItem key={index} value={item}>
													{item}
												</MenuItem>
											);
										})}
								</Select>
							</FormControl>
						</Box>
						<Box pt={2}>
							<TextField
								id="outlined-multiline-static"
								label={i18n.t("TabAction.commentary")}
								multiline
								minRows={10}
								variant="outlined"
								fullWidth
								value={comment}
								InputProps={{
									endAdornment: !lod_.isEmpty(comment) && (
										<IconButton
											onClick={() => {
												setComment("");
											}}
											size="small"
										>
											<Close />
										</IconButton>
									)
								}}
								onChange={e => setComment(e.target.value)}
								InputLabelProps={{ shrink: comment }}
							/>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
						{i18n.t("CD.cancel")}
					</Button>
					<Button
						onClick={() => handleSubmit({ orderIDCase, resumeCase, resolution, comment }, action)}
						style={{ margin: "2%" }}
						color="primary"
						disabled={checkValue()}
					>
						{i18n.t("CD.validate")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default CreationLitige;
