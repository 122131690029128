/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { Dialog, DialogContent, DialogTitle, Button, DialogActions, Box } from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import lod_, { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import cockpitActions from "../../../redux/actions/cockpitActions";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import InputType from "./InputType";
import { regexMail } from "helpers/utilities";
import { validatePhone } from "helpers/utilities";
import { isValid } from "date-fns";

/**
 * Form specific: Build input with type and options given
 */
function ActionFormDynamic({ action, actionObject, handleClose, handleSubmit }) {
	const [open, setOpen] = React.useState(false);
	const [title, setTitle] = useState("");
	const [dictionary, setDictionary] = useState({});
	const [valueDictionary, setValueDictionary] = useState({});
	const { selectedConversation } = useSelector(state => state.cockpit);
	const { userStatus } = useSelector(state => state);
	const [customErrors, setCustomErrors] = useState({});

	const dispatch = useDispatch();

	useEffect(() => {
		const onSuccess = res => {
			if (!lod_.isNil(res?.payload?.dictionary) && !lod_.isEmpty(res?.payload?.dictionary)) {
				setDictionary(res.payload.dictionary);

				let language = userStatus?.auth?.user?.language?.toLowerCase() || "fr";

				if (!lod_.isNil(res.payload.dictionary?.label[language])) {
					setTitle(res.payload.dictionary?.label[language]);
				}
			} else {
				setDictionary({});
			}

			if (
				!lod_.isNil(res?.payload?.valueDictionary) &&
				!lod_.isEmpty(res?.payload?.valueDictionary)
			) {
				setValueDictionary(res.payload.valueDictionary);
			} else {
				setValueDictionary({});
			}

			setOpen(true);
		};

		const onFailure = res => {
			dispatch(errorMsg(i18n.t(`COC.actionForm.${res?.response?.data?.error?.code}`)));
			handleClose();
		};

		dispatch(
			cockpitActions.getActionForm(
				{
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID,
					actionCode: actionObject?.config?.actionCode
				},
				onSuccess,
				onFailure
			)
		);
	}, []);

	const checkType = type => {
		if (!["email", "string", "number", "code", "date", "phone", "boolean"].includes(type)) {
			return false;
		} else {
			return true;
		}
	};

	const checkValueChangeGestion = (value, path, config) => {
		if (config?.isRequired === true && lod_.isNil(value)) {
			setCustomErrors(prev => {
				return {
					...prev,
					[path]: i18n.t(`COC.actionForm.FORMS.required`)
				};
			});
		} else if (
			config?.isRequired === true &&
			lod_.isEmpty(value) &&
			["string", "email"].includes(config.type)
		) {
			setCustomErrors(prev => {
				return {
					...prev,
					[path]: i18n.t(`COC.actionForm.FORMS.required`)
				};
			});
		} else if (!lod_.isEmpty(value) && config.type === "email" && regexMail(value)) {
			setCustomErrors(prev => {
				return {
					...prev,
					[path]: i18n.t(`COC.actionForm.FORMS.invalidEmail`)
				};
			});
		} else if (config.type === "phone" && !lod_.isEmpty(value) && !validatePhone(value)) {
			setCustomErrors(prev => {
				return {
					...prev,
					[path]: i18n.t(`COC.actionForm.FORMS.invalidPhone`)
				};
			});
		} else if (config.type === "date" && !lod_.isNil(value) && !isValid(value)) {
			setCustomErrors(prev => {
				return {
					...prev,
					[path]: i18n.t(`COC.actionForm.FORMS.invalidDate`)
				};
			});
		} else if (!lod_.isNil(customErrors[path])) {
			setCustomErrors(prev => {
				delete prev[path];
				return prev;
			});
		}
	};

	const checkValueChangeType = (value, config) => {
		if (config.type === "number") {
			const parsed = parseFloat(value);
			if (!lod_.isNaN(parsed)) {
				return parsed;
			}
			return null;
		} else if (config.type === "email") {
			return value.toLowerCase();
		} else {
			return value;
		}
	};

	const handleChange = (value, path, config) => {
		checkValueChangeGestion(value, path, config);

		value = checkValueChangeType(value, config);

		setValueDictionary(prev => {
			let result = cloneDeep(prev);
			lod_.set(result, path, value);
			return result;
		});
	};

	return (
		<div>
			<Dialog
				fullWidth={true}
				onClose={handleClose}
				maxWidth={"sm"}
				open={open}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">{title}</DialogTitle>
				<DialogContent dividers>
					{/* BUILD FORM */}

					{!lod_.isNil(dictionary.items) && !lod_.isEmpty(dictionary.items) ? (
						<Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
							<>
								{Object.keys(dictionary.items)
									.sort((a, b) => dictionary.items[a]?.order - dictionary.items[b]?.order)
									.map((child, key) => {
										if (
											checkType(dictionary.items[child]?.type) &&
											dictionary.items[child]?.active &&
											dictionary.items[child]?.display
										)
											return (
												<Box key={key}>
													<InputType
														config={dictionary.items[child]}
														value={lod_.get(valueDictionary, child)}
														language={userStatus?.auth?.user?.language?.toLowerCase() || "fr"}
														onChange={handleChange}
														path={child}
														customErrors={lod_.get(customErrors, child)}
														setCustomErrors={setCustomErrors}
														setValueDictionary={setValueDictionary}
													/>
												</Box>
											);
									})}
							</>
						</Box>
					) : (
						<Box style={{ display: "flex", justifyContent: "center", width: "100%" }}>
							{i18n.t(`COC.actionForm.noDicoFound`)}
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
						{i18n.t("CD.cancel")}
					</Button>
					<Button
						onClick={() => handleSubmit({ valueDictionary }, action)}
						style={{ margin: "2%" }}
						color="primary"
						disabled={
							!lod_.isEmpty(customErrors) ||
							lod_.isNil(dictionary.items) ||
							lod_.isEmpty(dictionary.items)
						}
					>
						{i18n.t("CD.validate")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default ActionFormDynamic;
