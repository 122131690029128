import isNil from "lodash/isNil";
import forEach from "lodash/forEach";
/* eslint-disable no-fallthrough */
import C from "../../constants/labels.js";

const labelsReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.RESET_LABELS: {
			return {
				...currentState,
				labelsMap: new Map()
			};
		}

		case C.GET_LABELS: {
			const labels = action.payload.labels;
			const assistantID = action.payload.assistantID;

			/**
			 * Now that we have all the labels for an assistantID
			 * Let's put them in a easy accessible Map where each label can be directly accessed by a key
			 * assistantID + '_' + label.intent
			 */
			let labelsMap = new Map();
			labels.forEach(aLabel => {
				if (!isNil(aLabel.intent)) {
					aLabel.intent = aLabel.intent.toLowerCase();
					labelsMap.set(assistantID + "_" + aLabel.intent, aLabel);
				}
			});
			return {
				...currentState,
				labelsMap
			};
		}

		default:
			return currentState;
	}
};

export default labelsReducers;
