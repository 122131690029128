import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	lastNotification: 0
};

export const notification = initialState;

const slice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		newNotification: state => {
			state.lastNotification += 1;
		}
	}
});

export default slice.reducer;
