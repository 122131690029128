/* eslint-disable react/prop-types */

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import i18n from "i18next";

import React, { useEffect, useState } from "react";
import { materialRenderers, materialCells } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";

function FormAction(props) {
	const [data, setData] = useState();
	const [errors, setErrors] = useState([]);
	const [required, setRequired] = useState({ dataPath: "", none: "" });

	let schema = props.schema;
	let uiSchema = props.uiSchema;

	useEffect(() => {
		validateData(props.data);
		setData(props.data);
	}, [props.data]);

	const handleSubmit = () => {
		let nbre = errors && errors.length;
		if (nbre > 0) {
			let tab = [];
			for (let index = 0; index < errors.length; index++) {
				let err = errors[index].dataPath;
				if (err !== "") {
					tab.push(err);
					setRequired({ ...required, dataPath: tab, none: "" });
				} else {
					setRequired({ ...required, dataPath: "", none: [`${i18n.t("CH.noEmpty")}`] });
				}
			}
		} else {
			setRequired("");
			props.handleSubmit(data, props.action);
		}
	};

	const validateData = newData => {
		let newErrors = [];
		if (props?.action === "HSHexportConversationToGed") {
			if (!newData) {
				newErrors.push("no data");
			} else {
				if (!newData?.TOTIE_COD) {
					newErrors.push("no TOTIE_COD");
				}
				if (!newData?.TOTIE_LIB || newData?.TOTIE_LIB === " ") {
					newErrors.push("no TOTIE_LIB");
				}
				if (!newData?.affairNumber) {
					newErrors.push("no affairNumber");
				}
			}
		}
		setErrors(newErrors);
	};

	const handleChange = dataUpd => {
		validateData(dataUpd);
		setData(dataUpd);
		setRequired({ ...required, none: "" });
	};

	return (
		<Grid item xs={12} container direction="column" data-cy="formContainer">
			<div>
				{required && required.dataPath && required.dataPath.length > 0 ? (
					<Alert severity="error">
						{i18n.t("CH.required")} : {required.dataPath.map(e => e).join(",  ")}
					</Alert>
				) : (
					required &&
					required.none.length > 0 && <Alert severity="error">{required.none.map(e => e)}</Alert>
				)}
			</div>

			<JsonForms
				data={data}
				schema={schema}
				uischema={uiSchema}
				renderers={materialRenderers}
				onChange={({ data, errors }) => handleChange(data, errors)}
				cells={materialCells}
			/>
			<Grid item container direction="row" justifyContent="flex-end" style={{ paddingTop: "2%" }}>
				<Button onClick={props.handleCancel} style={{ margin: "2%" }} color="secondary">
					{i18n.t("CD.cancel")}
				</Button>
				<Button
					onClick={handleSubmit}
					style={{ margin: "2%" }}
					color="primary"
					disabled={errors && Array.isArray(errors) && errors.length !== 0}
				>
					{i18n.t("CD.validate")}
				</Button>
			</Grid>
		</Grid>
	);
}

export default FormAction;
