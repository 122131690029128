/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import {
	Grid,
	IconButton,
	TextField,
	Popover,
	Box,
	Button,
	Tooltip,
	Chip
} from "@material-ui/core";
import lod_ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { bindActionCreators, compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import cockpitActions from "../../redux/actions/cockpitActions";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import i18n from "i18n";
import SuggestionsManager from "../../components/SuggestionsManager";

function EmailCCList(props) {
	const [emailCCAndBCCInput, setEmailCCAndBCCInput] = React.useState("");
	const [validEmail, setValidEmail] = React.useState(false);
	const [emailToSuggestions, setEmailToSuggestions] = React.useState([]);
	const [reset, setReset] = React.useState(true); //this var is only used to rerender autocomplete after clic on CC & BCC (coz cannot find a way to clean text)
	const { selectedAssistantID, selectedfID, substate } = useSelector(state => {
		return {
			selectedAssistantID: state?.cockpit?.selectedConversation?.header?.assistantID,
			selectedfID: state?.cockpit?.selectedConversation?.header?.fID,
			substate: state?.cockpit?.selectedConversation?.header?.substate
		};
	});
	const dispatch = useDispatch();

	const user = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;
	const Suggestions = new SuggestionsManager(props.selectedAssistantID, user, emailCCAndBCCInput);

	function validateEmails(emails) {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		var emailsArray = emails.split(",");

		emailsArray = emailsArray.filter(function (email) {
			return email.trim().length > 0;
		});

		for (let email of emailsArray) {
			email = email.trim();
			if (!re.test(String(email).toLowerCase())) {
				return false;
			}
		}

		return true;
	}

	const addEmailCC = () => {
		if (validateEmails(emailCCAndBCCInput)) {
			let newEmailCCList = lod_.cloneDeep(props.emailCCList);
			var emailsArray = emailCCAndBCCInput.split(",");
			emailsArray = emailsArray.filter(function (email) {
				return email.trim().length > 0;
			});
			for (let email of emailsArray) {
				email = email.trim();
				if (!newEmailCCList.includes(email)) {
					newEmailCCList.push(email);
				}
			}
			props.setEmailCCList(newEmailCCList);
			props.setLengthCC(newEmailCCList.length);
		}
		setEmailCCAndBCCInput("");
		setReset(!reset);
	};

	const removeEmailCC = (evt, mailToRemove) => {
		let newEmailCCList = lod_.cloneDeep(props.emailCCList);

		newEmailCCList = newEmailCCList.filter(value => {
			return value !== mailToRemove;
		});
		props.setEmailCCList(newEmailCCList);
		props.setLengthCC(newEmailCCList.length);
	};

	const addEmailBCC = () => {
		if (validateEmails(emailCCAndBCCInput)) {
			let newEmailBCCList = lod_.cloneDeep(props.emailBCCList);
			var emailsArray = emailCCAndBCCInput.split(",");
			emailsArray = emailsArray.filter(function (email) {
				return email.trim().length > 0;
			});
			for (let email of emailsArray) {
				email = email.trim();
				if (!newEmailBCCList.includes(email)) {
					newEmailBCCList.push(email);
				}
			}
			props.setEmailBCCList(newEmailBCCList);
			props.setLengthBCC(newEmailBCCList.length);
		}
		setEmailCCAndBCCInput("");
		setReset(!reset);
	};

	const removeEmailBCC = (evt, mailToRemove) => {
		let newEmailBCCList = lod_.cloneDeep(props.emailBCCList);

		newEmailBCCList = newEmailBCCList.filter(value => {
			return value !== mailToRemove;
		});
		props.setEmailBCCList(newEmailBCCList);
		props.setLengthBCC(newEmailBCCList.length);
	};

	const onClose = () => {
		props.setAnchorCCPopover(null);

		if (validateEmails(emailCCAndBCCInput)) {
			addEmailCC();
		} else {
			setEmailCCAndBCCInput("");
			props.setEmailCCList(props.emailCCList);
			props.setEmailBCCList(props.emailBCCList);
		}

		if (props.handleSaveDraftEmailCC) {
			props.handleSaveDraftEmailCC();
		}

		const fID = selectedfID;
		const assistantID = selectedAssistantID;
		const newDraftValues = {
			emailCC: props.emailCCList,
			emailBCC: props.emailBCCList
		};
		dispatch(cockpitActions.postDraft(fID, assistantID, substate, newDraftValues));
	};

	const handleEmailInputChange = evt => {
		let value = evt.target.value;
		setEmailCCAndBCCInput(value);
	};

	const handleEmailKeyDown = evt => {
		if (evt.key === "Enter") {
			if (validEmail) {
				addEmailCC();
			}
		}
	};

	const handleAutocompleteChange = (evt, newValue) => {
		if (!newValue?.email) {
			return;
		}
		setEmailCCAndBCCInput(Suggestions.format(newValue));
	};

	useEffect(() => {
		if (emailCCAndBCCInput) {
			const timeoutId = setTimeout(async () => {
				await Suggestions.loadSuggestions(suggestions => {
					setEmailToSuggestions(suggestions);
				});
			}, 500);
			return () => clearTimeout(timeoutId);
		}
		// eslint-disable-next-line
	}, [emailCCAndBCCInput]);

	useEffect(() => {
		if (validateEmails(emailCCAndBCCInput)) {
			setValidEmail(true);
		} else {
			setValidEmail(false);
		}
	}, [emailCCAndBCCInput]);

	function displayList(listName, list, removeElement) {
		const [open, setOpen] = React.useState(false);

		const handleClickOpen = () => {
			setOpen(!open);
		};

		return (
			<Box width="100%">
				<ListItemButton onClick={handleClickOpen}>
					<ListItemText primary={listName + " (" + list.length + ")"} />
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={open} timeout="auto" unmountOnExit collapsedSize="100%">
					<Box minHeight="50px" width="100%" padding="10px">
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="baseline"
							spacing={2}
							//xs={12}
						>
							{list.map(listElement => (
								<Chip
									key={listElement}
									label={listElement}
									onDelete={e => removeElement(e, listElement)}
								/>
							))}
						</Grid>
					</Box>
				</Collapse>
			</Box>
		);
	}

	return (
		<Popover
			id="emailCCPopover"
			anchorEl={props.anchorCCPopover}
			open={Boolean(props.anchorCCPopover)}
			onClose={onClose}
			anchorOrigin={{
				vertical: "center",
				horizontal: "left"
			}}
			transformOrigin={{
				vertical: "center",
				horizontal: "right"
			}}
		>
			<Box
				width="500px"
				maxHeight="250px"
				padding="10px"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				<Box sx={{ flexGrow: 1 }} overflow="auto">
					<Box>{displayList(i18n.t("COC.CC"), props.emailCCList, removeEmailCC)}</Box>
					<Box>{displayList(i18n.t("COC.BCC"), props.emailBCCList, removeEmailBCC)}</Box>
				</Box>
				<Box display="flex" flexDirection="row" alignItems="center" padding="10px">
					<Autocomplete
						inputValue={emailCCAndBCCInput}
						id="autocomplete-recipient"
						freeSolo
						options={emailToSuggestions}
						style={{ minWidth: "300px" }}
						getOptionLabel={option => option.email}
						onChange={handleAutocompleteChange}
						key={reset}
						filterOptions={(options, state) => options} //FilterOptions is made in authDev, no filter needed front side
						renderOption={(suggestedValue, option) => Suggestions.display(suggestedValue, option)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("COC.addCC")}
								variant="outlined"
								size="medium"
								onChange={handleEmailInputChange}
								onKeyDown={handleEmailKeyDown}
								className="CCAndBCCInput"
							/>
						)}
					/>

					<Button onClick={addEmailCC} disabled={!validEmail}>
						<AddIcon fontSize="small" />
						<Box>{i18n.t("COC.CC")}</Box>
					</Button>
					<Button onClick={addEmailBCC} disabled={!validEmail}>
						<AddIcon fontSize="small" />
						<Box>{i18n.t("COC.BCC")}</Box>
					</Button>
					{/* <Grid item container direction="row" className="emailCCInput" >
						
					</Grid> */}
				</Box>
			</Box>
		</Popover>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(EmailCCList);
