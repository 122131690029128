import i18n from "i18n";

function getVinatisPossibleStates() {
	let possibleStatesVinatis = {
		19: i18n.t(`clientSpecific.vinatis.orderStates.${19}`)
	};

	return possibleStatesVinatis;
}

export default getVinatisPossibleStates;
