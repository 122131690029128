import "./i18n";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import { createBrowserHistory } from "history";
import Admin from "./pages/Admin";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Router, Switch } from "react-router-dom";
import config from "./auth_config.json";
import PrivateRoute from "./routes/PrivateRoute";
import { Auth0Provider } from "./react-auth0-wrapper";
import store from "./redux/store";
import "./assets/css/loader.css";
import "./assets/css/CockpitTabInfo.css";
import "./assets/css/Forms.css";
import "./assets/css/SunEditor.css";
import { ProvideAuthFaibrik } from "./context/Context";
import ErrorBoundary from "./helpers/ErrorBoundary";

const hist = createBrowserHistory();
// require("dotenv").config();

const onRedirectCallback = appState => {
	window.history.replaceState(
		{},
		document.title,
		appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
	);
};

ReactDOM.render(
	<ErrorBoundary>
		<Auth0Provider
			domain={config.domain}
			client_id={config.clientId}
			redirect_uri={config.redirect_uri}
			audience={config.audience}
			onRedirectCallback={onRedirectCallback}
		>
			<Provider store={store}>
				<ProvideAuthFaibrik>
					<Suspense
						fallback={
							<div className="loader">
								<div className="circle"></div>
								<div className="circle"></div>
								<div className="circle"></div>
							</div>
						}
					>
						<Router history={hist}>
							<Switch>
								<PrivateRoute path="/admin" component={Admin} />
								<Redirect from="/" to="/admin/cockpit" />
							</Switch>
						</Router>
					</Suspense>
				</ProvideAuthFaibrik>
			</Provider>
		</Auth0Provider>
	</ErrorBoundary>,
	document.getElementById("root")
);

/* if (window.Cypress) {
  window.store = store
} */
