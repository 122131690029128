/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Fade, Grow, IconButton, ListItem, Paper, Popover, Tooltip } from "@material-ui/core";
import { Adjust, TranslateRounded, Visibility } from "@material-ui/icons";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AndroidIcon from "@material-ui/icons/Android";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PhotoFilterIcon from "@material-ui/icons/PhotoFilter";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SubjectIcon from "@material-ui/icons/Subject";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CategoriesDisplay from "components/CategoriesDisplay/CategoriesDisplay";
import DropMenu from "components/Menu/DropMenu";
import FlagsMenu from "components/Menu/FlagsMenu";
import StarRating from "components/StarRating/StarRating";
import MenuText from "components/Typography/MenuText";
import { sanitize } from "dompurify";
import GetFlag from "helpers/GetFlag";
import i18n from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
	getMesssageItemSelector,
	getHeaderConvItemSelector
} from "redux/selectors/cockpitSelectors";
import Modal from "../../../../../components/ConfirmationDialog/index";
import C from "../../../../../constants/cockpit.js";
import GetChannelImage from "../../../../../helpers/GetChannelImage";
import cockpitActions from "../../../../../redux/actions/cockpitActions";

import {
	formatDate,
	getLabelOf,
	handleSpecialDisplayDate,
	parseHtml
} from "../../../../../helpers/utilities";
import { AttachmentDisplay } from "../../../AttachmentDisplay";
import "../../MessageItem.css";
import { DisplayReviewChoices } from "../../../DisplayReviewChoices";
import {
	GenericAttachmentList,
	MessageAttachmentsList
} from "../../../MessageItemComponents/Attachments";
import { getSenderOfMessage } from "helpers/messageUtils";
import ReactDOMServer from "react-dom/server";
import lod_ from "lodash";
import { MessageItemHeader } from "../../MessageItemHeader";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import TextSelectActionPopover from "../../TextSelectActionPopover";
import { getTsOfMessage } from "helpers/messageUtils";

import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SearchDataCrmInputCreateAffair from "helpers/actions/HSH/SearchDataCrmInputCreateAffair";
import Subject from "../Subject";
import SelectConversationDialog from "../SelectConversationDialog";
import { getMessageStyle } from "../functions";
import MessageHeaderExternalSender from "./components/MessageHeaderExternalSender";

/**
 * Default message format
 * @param {*} param0
 * @returns
 */
const Message = ({
	message,
	setSnackbarStatus,
	msgIndex,
	handleEngagedialog,
	fromHistoric,
	feedbackDisplay,
	convHistory,
	showMessageStateInfo,
	showMessageActions,
	type = "default"
}) => {
	const isExternal = message.header.source === "contact" || message.header.source === "operator";

	const dispatch = useDispatch();
	const {
		channels,
		cockpit,
		actions,
		selectedConversation,
		userStatus,
		selectedAssistantID,
		fontSize
	} = useSelector(getMesssageItemSelector, shallowEqual);
	let { countHistory } = useSelector(getHeaderConvItemSelector, shallowEqual);
	const conversation = convHistory ? convHistory : selectedConversation;
	const conversationfID = conversation?.header.fID;

	if (!lod_.has(message, "meta")) {
		message.meta = {};
	}

	/**
	 * Cockpit settings specific to user
	 */
	/**
	 * ---------------------------------------------------------------------------------
	 */
	/**
	 * STOP : ARRETEZ CE GENRE DE DEV QUI EST UNE STUPIDITE
	 */

	// let { cockpitTextVersion } = useSelector(state => state?.userStatus?.auth?.user?.cockpitSettings);

	let cockpitTextVersion = useSelector(
		state => state?.userStatus?.auth?.user?.cockpitSettings
	)?.cockpitTextVersion;
	/**
	 * ---------------------------------------------------------------------------------
	 */

	/**
	 * TODO: if we have a setState but the state is not used, then we should remove them
	 */
	const [textVersionClean, setTextVersionClean] = useState(null);
	const [showMsg, setShowMsg] = useState(message?.header?.state === "error" ? true : false);
	const [actionEnabled, setActionEnabled] = useState(true);
	const [dateFormat, setDateFormat] = useState(false);
	const [userLanguage, setUserLanguage] = useState("fr");
	const [showTooltipTextVersion, setShowTooltipTextVersion] = useState(false);
	const [menuText, setMenuText] = useState();
	const [menuTitle, setMenuTitle] = useState();

	const [draftMessage, setDraftMessage] = useState("");
	const [draftSubject, setDraftSubject] = useState("");
	const [draftDate, setDraftDate] = useState("");
	const [openModalMoveMsgNew, setOpenModalMoveMsgNew] = useState(false);
	const [openModalMoveMsgTarget, setOpenModalMoveMsgTarget] = useState(false);
	const [openModalResendMessage, setOpenModalModalResendMessage] = useState(false);

	/**
	 * Compatibility variables used in message
	 */
	const [translationButton, setTranslationButton] = useState(null);
	const [messageTo, setMessageTo] = useState([]);
	const [messageCC, setMessageCC] = useState([]);
	const [messageBCC, setMessageBCC] = useState([]);
	const [rating, setRating] = useState(null);
	const [ratingStars, setRatingStars] = useState(null);
	const [answerStory, setAnswerStory] = useState(null);
	const [urgent, setUrgent] = useState(false);
	const [allowReplay, setAllowReplay] = useState(true);

	const [openAddCategory, setOpenAddCategory] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [allCategory, setAllCategory] = useState([]);
	const [cacheAssistant, setCacheAssistant] = useState("");

	const [showAckBot, setShowAckBot] = useState(false);

	const messageItem = useRef();

	/* Action's modal controller */
	const [actionModal, setActionModal] = useState();
	const [actionModalOpen, setActionModalOpen] = useState(false);

	/**
	 * All message's contents (plain, html, ...)
	 */
	const [messageText, setMessageText] = useState({
		/**
		 * Subjects
		 */
		subject: undefined,
		/**
		 * Contents
		 */
		plain_text: undefined,
		html_text: undefined,
		cleaned: undefined
	});

	/**
	 * Unique message content
	 */
	const [messageContentText, setMessageContentText] = useState("");

	const [actionsMenu, setActionsMenu] = useState([]);

	const [messageActions, setMessageActions] = useState(
		actions
			? actions.filter(action => {
					if (action.type === "message" && action.active === true) {
						switch (action.action) {
							case "moveMsg":
								if (selectedConversation) {
									if (selectedConversation.header?.state !== "ongoing") return false;
									if (selectedConversation.header.channel !== "ML") return false;
									if (selectedConversation.agent?.uid !== userStatus.auth.user._id) return false;
									//if (!message.payload?.payload?.text) return false;
									if (message.header.source !== "contact") return false;

									var index = selectedConversation.messages.findIndex(
										msg => msg.header.mID === message.header.mID
									);

									for (let i = index + 1; i < selectedConversation.messages.length; i++) {
										if (selectedConversation.messages[i].header.source !== "contact") {
											return false;
										}
									}
									return true;
								}
						}
						return true;
					}
					return false;
				})
			: []
	);

	/**
	 * Clean text // todo : check & clean messages in the backend, verify messages before sending them to the frontend
	 * -> check for objects
	 */
	const cleanText = text => {
		if (lod_.isObject(text)) {
			return "";
		} else {
			return text;
		}
	};

	/**
	 * Fill all message's contents
	 */
	const initTextContent = () => {
		if (message.payload?.payload?.text) {
			/**
			 * New message format
			 */
			setMessageText({
				...messageText,
				subject: message.payload.payload.subject,
				plain_text: cleanText(message.payload.payload.text.plain_text),
				cleaned: cleanText(message.payload.payload.text.cleaned_text),
				html_text: cleanText(message.payload.payload.text.html_text),
				parts: message.payload.payload.text.parts
			});

			setUrgent(
				message?.payload?.header?.contex?.urgent
					? message?.payload?.header?.contex?.urgent
					: message?.payload?.header?.urgent
						? message?.payload?.header?.urgent
						: false
			);

			/**
			 * Emails can be in emailTo.to for MS or just in emailTo for others channels
			 */
			setMessageTo([message.header.to?.to] || []);
			setMessageCC(message.header.to?.cc || []);
			setMessageBCC(message.header.to?.bcc || []);

			setRatingStars(message.payload.payload.rating || null);
			setAnswerStory(message.payload.payload.replyTo?.story?.url || null);
		} else if (message.body) {
			/**
			 * Old message format
			 */
			setMessageText({
				...messageText,
				subject: message.body.topic,
				plain_text: message.body.original,
				cleaned: message.body.cleaned,
				html_text: message.body.htmlText
			});

			setUrgent(message.body.urgent);
			/**
			 * Emails can be in emailTo.to for MS or just in emailTo for others channels
			 */
			if (lod_.has(message.header, "extra.emailTo")) {
				setMessageTo(message.header.extra.emailTo?.to || message.header.extra.emailTo || []);
			}
			setMessageCC(message.header?.extra?.emailCC || []);
			setMessageBCC(message.header?.extra?.emailBCC || []);
			setRating(message.body.rating || null);
		}

		/**
		 * If we have some translations, set the menu to choose one
		 */
		setTranslationButton(message?.payload?.payload?.translate);
	};

	/**
	 * Fill message's contents
	 */
	useEffect(() => {
		initTextContent();
	}, []);
	/**
	 * Reload the message when we add a new translation
	 */
	useEffect(() => {
		initTextContent();
	}, [message?.payload?.payload?.translate]);

	/**
	 * Set text clean or not after loading the message content
	 */
	useEffect(() => {
		if (
			lod_.has(selectedConversation, "header.type") &&
			selectedConversation.header.type === "ticket"
		) {
			let htmlTextIntents = ["nointent", "lowintent", "noai", undefined];

			let messageLowComprehensionOld = htmlTextIntents.includes(
				message?.meta?.intent?.toLowerCase()
			);
			let messageLowComprehensionNew = htmlTextIntents.includes(
				message?.understand?.topic?.toLowerCase()
			);

			let messageLowComprehension = messageLowComprehensionOld || messageLowComprehensionNew;
			let messageNoClean = !messageText.cleaned || messageText.cleaned === "";

			/**
			 * If message has low comprehension or no clean, set default view to HTML
			 */

			if (
				((messageLowComprehension || messageNoClean) && cockpitTextVersion !== "clean") ||
				!messageText.parts
			) {
				setTextVersionClean(false);
			} else {
				setTextVersionClean(true);
			}
		} else {
			setTextVersionClean(false);
		}

		if (messageText.cleaned && (messageText.html_text || messageText.plain_text)) {
			/**
			 * User settings to show cleaned text or not
			 */

			let forceClean = cockpitTextVersion === "clean";
			let forceHTML = cockpitTextVersion === "html";

			if (forceClean && !forceHTML) {
				setTextVersionClean(true);
			} else if (!forceClean && forceHTML) {
				setTextVersionClean(false);
			}
		}

		/**
		 * Refresh messageTextContent to display correct text version
		 * useful when we translate a message but dont change the text version
		 */
		displayCorrectTextVersion();
	}, [messageText]);

	/**
	 * Display or not the subject
	 * @param {*} conv
	 * @returns
	 */
	function displaySubject(conv) {
		if (["GOGR"].includes(conv?.header?.channel)) {
			return false;
		}
		return true;
	}

	function displayCorrectTextVersion() {
		if (!textVersionClean && actionEnabled) {
			if (messageText.html_text || messageText.original) {
				setActionEnabled(true);
			} else {
				setActionEnabled(false);
			}
		}

		if (textVersionClean) {
			/**
			 * Show the cleaned text
			 */
			if (messageText.parts) {
				setMessageContentText(parseHtml(sanitize(messageText.parts.answer)));
			} else {
				setMessageContentText(messageText.cleaned);
			}
		} else {
			/**
			 * Show HTML text or plain text
			 */

			if (messageText.html_text) {
				let parsedReactHtml = parseHtml(sanitize(messageText.html_text));
				// If react DOM elemernt isn't in array, it won't be decoded
				if (!lod_.isArray(parsedReactHtml) && lod_.isObject(parsedReactHtml)) {
					parsedReactHtml = [parsedReactHtml];
				}
				setMessageContentText(parsedReactHtml);
			} else {
				setMessageContentText(messageText.plain_text);
			}
		}
	}

	/**
	 * Display the correct message after changing to the cleaned version or not
	 */
	useEffect(() => {
		displayCorrectTextVersion();
	}, [textVersionClean]);

	/**
	 * Set draft content
	 */
	useEffect(() => {
		let content = message.body?.repliedAnswer || messageContentText;

		if (lod_.isArray(content)) {
			content = ReactDOMServer.renderToString(content);
		}

		setDraftMessage(content);

		setDraftSubject(messageText.subject);

		let messageDate =
			message?.header?.datetime ||
			message?.header?.dateCreated?.datetime ||
			message?.header?.date_created ||
			message?.header?.dateReceived?.datetime;

		if (typeof messageDate === "string") {
			messageDate = messageDate.slice(0, -4);
			setDraftDate(messageDate);
		}
	}, [messageContentText]);

	useEffect(() => {
		let output = message.body?.output;
		if (output && Array.isArray(output)) {
			let menutext = output.filter(e => e.type === "menutext");
			setMenuText(menutext[0]?.content?.choice);
			setMenuTitle(menutext[0]?.content?.title);
		}
	}, [message.body]);

	useEffect(() => {
		if (message.payload?.header?.type === "menu") {
			setMenuText(message.payload.payload.menu.choices);
			setMenuTitle(message.payload.payload.subject);
		}
	}, [message.payload?.header?.type, message.payload?.payload]);

	useEffect(() => {
		if (userStatus.auth.user.language) {
			setUserLanguage(userStatus.auth.user.language.toLowerCase());
		}
	}, [userStatus.auth.user]);

	useEffect(() => {
		if (cockpit?.datetime_format?.format) {
			setDateFormat(cockpit.datetime_format.format);
		}
	}, [cockpit]);

	let headerConv = conversation?.header || false;

	let attachmentLinkToMsg = [];
	if (
		message.body &&
		Array.isArray(message.body.attachments) &&
		message.body.attachments.length !== 0
	) {
		attachmentLinkToMsg = message.body.attachments.filter(e => e.contentDisposition !== "inline");
	}

	if (
		message.payload?.payload?.attachments &&
		Array.isArray(message.payload.payload.attachments) &&
		message.payload.payload.attachments.length !== 0
	) {
		attachmentLinkToMsg = message.payload.payload.attachments.filter(att => {
			// Now the type is "attachment" or "inline". Content disposition is always inline so we can open the files in the browser
			// Format is described here https://gitlab.com/PiKauffmann/bp/-/blob/master/tools/pivotFormats.js#L53-77
			const newFormatTypeCorrect = att.type === "attachment";
			// In the old format, the actual type was in contentDisposition. And the type was the contentType (so different of attachment)
			const oldFormatTypeCorrect = att.contentDisposition === "attachment" && !newFormatTypeCorrect;
			return newFormatTypeCorrect || oldFormatTypeCorrect;
		});
	}

	useEffect(() => {
		if (channels) {
			let channelByAssistant = channels.filter(a => a.engage === true);
			if (channelByAssistant.length > 0) {
				if (channelByAssistant[0] !== undefined) {
					let engageableChannels = [];
					channelByAssistant.forEach(aChannel => {
						if (!engageableChannels.includes(aChannel.code)) {
							engageableChannels.push(aChannel);
						}
					});

					if (engageableChannels.length == 1) {
						dispatch(cockpitActions.saveDefaultChannel(engageableChannels[0], false));
					}
				}
			}
		}
	}, [channels]);

	/**
	 * Create all actions for the drop menu
	 */
	useEffect(() => {
		/**
		 * For the moment only the ML conversation can have a forward and resolveAndForward action dropdown menu
		 */
		const isConversationEnabledForMessagesDropDown = headerConv.channel === "ML";
		const isConversationEnabledForMessagesTransfer =
			["ML", "ZA", "FB", "WA"].includes(headerConv?.channel) || headerConv.type === "review";
		let allParsedActions = [];
		messageActions.forEach(action => {
			let tempObject = {};
			let possibleChannelToEngage = channels.filter(a => a.type === "ML");
			possibleChannelToEngage = possibleChannelToEngage.filter(a => {
				if (a.engage === true && (lod_.isNil(a?.engageFilter) || lod_.isEmpty(a?.engageFilter))) {
					return a;
				} else if (
					a.engage === true &&
					(!lod_.isNil(a?.engageFilter) || !lod_.isEmpty(a?.engageFilter))
				) {
					const keysPath = Object.keys(a.engageFilter);
					const path = keysPath[0];
					const value = a?.engageFilter[path];
					const valueContext = lod_.get(selectedConversation, path);
					if (!lod_.isNil(value) && !lod_.isNil(valueContext)) {
						if (
							lod_.isString(value) &&
							lod_.isString(valueContext) &&
							value.toLocaleLowerCase() === valueContext.toLocaleLowerCase()
						) {
							return a;
						} else if (value === valueContext) {
							return a;
						}
					}
				}
			});
			switch (action.action) {
				case "generateReply":
					tempObject = {
						name: action.name,
						action: action.action,
						mID: message.header.mID,
						apiName: action.config.apiName
					};
					break;
				case "moveMsg":
					if (selectedConversation) {
						tempObject = {
							name: action.name,
							action: action.action,
							subMenu: {
								items: []
							}
						};
						/**
						 * Move message to new conversation only if we have more than 1 msg in conv
						 */
						if (selectedConversation.messages.length > 1) {
							tempObject.subMenu.items.push({
								name: i18n.t("COC.newConversation"),
								action: "moveMsgNew"
							});
						}
						/**
						 * Move message to target fID only if there is a history
						 */
						if (countHistory > 0) {
							tempObject.subMenu.items.push({
								name: i18n.t("COC.targetedConversation"),
								action: "moveMsgTarget"
							});
						}
					}

					break;
				case "translateMessage":
					tempObject = {
						name: action.name,
						action: action.action,
						subMenu: {
							items: action.queryParams.languages
								.filter(
									language =>
										!Object.keys(message?.payload?.payload?.translate || []).includes(
											language.toUpperCase()
										)
								)
								.map((language, index) => {
									return {
										name: (
											<img
												key={index}
												src={GetFlag(language.toUpperCase())}
												alt="Filter_flag"
												width="20"
												height="20"
											/>
										),
										action: "translate",
										language
									};
								})
						}
					};
					break;
				case "forward":
				case "forwardWithSummary":
				case "forwardWithContext":
					if (!isConversationEnabledForMessagesTransfer) break;
					if (possibleChannelToEngage.length > 1) {
						tempObject = {
							name: action.name,
							action: action.action,
							config: action?.config || {},
							subMenu: {
								items: possibleChannelToEngage.map((channel, index) => {
									return {
										name: (
											<Tooltip title={channel.name}>
												<img
													src={GetChannelImage(channels, channel.code)}
													alt="Channel-logo"
													width="20"
													height="20"
													className="logo-channel"
												/>
											</Tooltip>
										),
										action: action.action,
										config: action?.config || {},
										channel
									};
								})
							}
						};
					} else if (possibleChannelToEngage[0] != null) {
						tempObject = {
							name: action.name,
							action: action.action,
							config: action?.config || {},
							channel: possibleChannelToEngage[0]
						};
					}
					break;
				case "resolveAndForward":
					if (!isConversationEnabledForMessagesDropDown) break;
					if (possibleChannelToEngage.length > 1) {
						tempObject = {
							name: action.name,
							action: action.action,
							subMenu: {
								items: possibleChannelToEngage.map((channel, index) => {
									return {
										name: (
											<Tooltip title={channel.name}>
												<img
													src={GetChannelImage(channels, channel.code)}
													alt="Channel-logo"
													width="20"
													height="20"
													className="logo-channel"
												/>
											</Tooltip>
										),
										action: action.action,
										channel
									};
								})
							}
						};
					} else if (possibleChannelToEngage[0] != null) {
						tempObject = {
							name: action.name,
							action: action.action,
							channel: possibleChannelToEngage[0]
						};
					}
					break;
				default:
					tempObject = action;
					break;
			}
			/**
			 * Display only the action that are possible
			 */
			if (!lod_.isEmpty(tempObject)) {
				allParsedActions.push(tempObject);
			}
		});

		setActionsMenu(allParsedActions);

		/**
		 * update when countHistory change because it comes in a second request
		 * and we need to wait it to display the action
		 */
	}, [messageActions, countHistory, message]);

	const handleClickEngage = (engageData, mID) => {
		if (engageData.action === "resolveAndForward") {
			dispatch(
				cockpitActions.updateConversationState("resolved", "resolved", selectedConversation)
			);
		}
		let initialDraft = {
			draftTransfer: {
				// Put data in this special place for Auth-Dev to compute the draft with theses info
				subject: draftSubject,
				date: draftDate,
				sender: getSenderOfMessage(conversation, message),
				content: draftMessage
			}
		};
		if (engageData.action === "forwardWithContext") {
			initialDraft.copyContext = true;
			initialDraft.action = "forwardWithContext";
			initialDraft.forwardConfig = engageData?.config;
		}
		if (engageData.action === "forwardWithSummary") {
			if (
				!lod_.isNil(conversation?.meta?.cockpit?.summary) &&
				!lod_.isEmpty(conversation?.meta?.cockpit?.summary)
			) {
				initialDraft.draftTransfer.summary = conversation.meta.cockpit.summary;
			}
		}
		OpenEngage(engageData.channel, initialDraft, mID);
	};

	const handleClickCopyMsgToClipboard = (conv, message) => {
		let copyLocal = JSON.parse(localStorage.getItem("presse-papier")) || [];

		let first = conv.contact?.first || "";
		let last = conv.contact?.last || "";
		let contact = first + " " + last;
		let date = getTsOfMessage(message);

		if (!conv.contact?.first && !conv.contact?.last && conv.contact?.email) {
			contact = conv.contact?.email;
		}

		copyLocal.push({
			fID: conv.header.fID,
			contact,
			mID: message?.header?.mID,
			title: message?.payload?.payload?.subject,
			date,
			type: "message",
			assistantID: selectedAssistantID
		});
		localStorage.setItem("presse-papier", JSON.stringify(copyLocal));
		dispatch(cockpitActions.setCopyClipboard());
	};

	const handleValidateModalMoveMessage = target => {
		const moveMsgObject = {
			action: "moveMsg",
			payload: {
				assistantID: selectedAssistantID,
				mID: message.header.mID,
				fID: selectedConversation.header.fID,
				agentuID: userStatus.auth.user._id,
				params: {
					targetfID: target
				}
			}
		};

		dispatch(
			cockpitActions.callMessageAction(
				moveMsgObject,
				() => {},
				error => {
					/**
					 * Error handle
					 */
					let errorMessage = error.response?.data?.error;
					if (errorMessage) {
						dispatch(errorMsg(i18n.t(`messagesAction.${errorMessage.message}`)));
					}
				}
			)
		);

		setOpenModalMoveMsgNew(false);
		setOpenModalMoveMsgTarget(false);

		dispatch(infoMsg(i18n.t("TabAction.messageIsBeingMoved")));
	};

	const handleValidateModalResendMessage = () => {
		if (conversationfID && message.header?.mID) {
			let mID = message.header.mID;
			let action = "resendMessage";
			let data = {
				header: {
					assistantID: selectedConversation?.header?.assistantID,
					fID: selectedConversation?.header?.fID
				},
				payload: {
					mID
				}
			};

			dispatch(cockpitActions.launchActionFrontV2(action, data));

			// Don't allow user to multiple replay
			setAllowReplay(false);

			setOpenModalModalResendMessage(false);
		} else {
			setSnackbarStatus({
				open: true,
				message: i18n.t("COC.resendMessageFail"),
				severity: "error"
			});
		}
	};

	/**
	 * Switch between cleaned text and HTML text
	 */
	const handleTextVersionChange = (event, newVersion) => {
		setShowTooltipTextVersion(false);

		// Toggle version clean value
		setTextVersionClean(!textVersionClean);
	};

	const handleClickMessageState = () => {
		if (!showMsg) {
			setTimeout(() => setShowMsg(false), 2000);
		}
		setShowMsg(!showMsg);
		setOpenModalModalResendMessage(true);
	};

	const OpenEngage = (channel, initialDraft, mID) => {
		handleEngagedialog(
			msgIndex,
			() => {},
			channel,
			initialDraft,
			selectedConversation.header.fID,
			mID
		);
	};

	const handleInsertMessage = () => {
		let topic;
		// If it's a message from user, we want to add RE:
		if (isExternal) {
			topic = `RE: ${messageText.subject}`;
		} else {
			// Else we want to keep the same subject
			topic = messageText.subject;
		}

		dispatch({
			type: C.SET_TOPIC_DRAFT,
			payload: topic
		});

		dispatch({
			type: C.ADD_CONTENT,
			payload: {
				content: messageText.html_text || messageText.cleaned || messageText.plain_text,
				date: draftDate,
				subject: draftSubject
			}
		});
	};

	// Display default message

	let channelImage =
		message?.header?.channel?.code || conversation?.meta?.cockpit?.channelCode || "";

	const { paperStyle, justifyDirection } = getMessageStyle(message);

	const messageErrorCode = message?.header?.error?.code;
	const messageReplayStrategy = message?.header?.error?.replayStrategy;

	let errorMessageTooltip = i18n.t(`cmErrorCodes.${messageErrorCode}`);
	if (
		errorMessageTooltip === "cmErrorCodes.undefined" || // errorCode does not exists
		errorMessageTooltip === `cmErrorCodes.${messageErrorCode}` // errorCode exists, but the translation does not exists
	) {
		// Trad fails
		errorMessageTooltip = i18n.t("COC.sentError");
	}
	const canReplay = messageReplayStrategy !== "flush" && allowReplay;

	const fullWidthDisplay =
		message?.header?.channel?.type === "AV" && message?.header?.source === "contact";

	/**
	 * Handle action when user switch message's language
	 * @param {*} e event
	 * @param {String} language DE, FR, EN, ...
	 */
	const handleChangeTranslation = (e, language) => {
		if (language === "default") {
			/**
			 * Set language to default
			 */
			initTextContent();
		} else {
			/**
			 * Set language to selected
			 */
			refreshTranslation(language);
		}
	};

	/**
	 * Set the language to selected
	 * @param {String} language DE, FR, EN, ...
	 */
	const refreshTranslation = language => {
		let translations = message.payload.payload.translate[language];

		let baseObject = {
			...messageText,
			subject: translations.subject || translations.topic || messageText.subject
		};

		/**
		 * Map all translations text (plain, html, ...) into object
		 */
		Object.keys(translations.text).map((key, index) => {
			baseObject[key] = translations.text[key];
		});

		setMessageText(baseObject);
	};
	/**
	 * Generate reply for a message with FAIQ
	 * @param {*} data
	 */
	const generateReply = data => {
		let replyObject = {
			action: "generateReply",
			payload: {
				assistantID: selectedAssistantID,
				mID: message.header.mID,
				fID: selectedConversation.header.fID,
				agentuID: userStatus.auth.user._id,
				params: {
					apiName: data.apiName
				}
			}
		};

		const onSuccess = () => {
			setSnackbarStatus({
				open: true,
				message: "Génération de la réponse en cours ...",
				severity: "info"
			});
		};

		dispatch(cockpitActions.callMessageAction(replyObject, onSuccess));
	};

	/**
	 * Send params to back to translate message
	 */
	const handleTranslateMessage = language => {
		let translationObject = {
			action: "translateMessage",
			payload: {
				assistantID: selectedAssistantID,
				mID: message.header.mID,
				fID: selectedConversation.header.fID,
				agentuID: userStatus.auth.user._id,
				params: {
					targetLanguage: language,
					currentLanguage: message.header.language
				}
			}
		};

		const onSuccess = () => {
			setSnackbarStatus({
				open: true,
				message: i18n.t("actionEvent.translationLoading"),
				severity: "info"
			});
		};

		dispatch(cockpitActions.callMessageAction(translationObject, onSuccess));
	};

	const handleInsertCategory = event => {
		if (
			cacheAssistant === "" ||
			cacheAssistant !== selectedAssistantID ||
			lod_.isEmpty(allCategory)
		) {
			const onSuccess = data => {
				setAllCategory(data || []);
				setCacheAssistant(selectedAssistantID);
			};

			dispatch(cockpitActions.callMessageCategory({ assistantID: selectedAssistantID }, onSuccess));
		}
		setOpenAddCategory(true);
		setAnchorEl(event.currentTarget);
	};

	const handleCloseCategory = () => {
		setOpenAddCategory(false);
		setAnchorEl(null);
	};

	/**
	 * Handle move message
	 */
	const handleMoveMessage = (data, target) => {
		switch (target) {
			case "target":
				// default is new conversation
				setOpenModalMoveMsgTarget(!openModalMoveMsgTarget);
				break;
			default:
				// default is new conversation
				setOpenModalMoveMsgNew(!openModalMoveMsgNew);
				break;
		}
	};

	const handleAnalyseTextEnrich = (actionData, text) => {
		const data = {
			header: {
				assistantID: message.header.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload: {
				text
			}
		};

		dispatch(cockpitActions.launchActionFrontV2("analyseTextEnrich", data));
	};

	const handleAddCategory = (status, categorySelected) => {
		let category = { code: categorySelected?.code || "", label: status };

		dispatch(
			cockpitActions.gestionCategoryMessage(
				"addCategoryMessage",
				{ category, mID: message.header?.mID },
				{
					assistantID: selectedConversation?.header?.assistantID,
					fID: selectedConversation?.header?.fID
				}
			)
		);
	};

	/**
	 * Handle action when user click on action in DropMenu
	 */
	const handleDropMenuActions = data => {
		switch (data.action) {
			case "generateReply":
				generateReply(data);
				break;
			case "translate":
				handleTranslateMessage(data.language);
				break;
			case "moveMsgNew":
				handleMoveMessage(data, "new");
				break;
			case "moveMsgTarget":
				handleMoveMessage(data, "target");
				break;
			case "forward":
			case "forwardWithContext":
			case "forwardWithSummary":
				// where is the mID ???
				handleClickEngage(data, message?.header?.mID);
				break;
			case "resolveAndForward":
				handleClickEngage(data, message?.header?.mID);
				break;
			case "copyMsg":
				handleClickCopyMsgToClipboard(selectedConversation, message);
				break;
			case "analyseTextEnrich":
				handleAnalyseTextEnrich(data, messageText?.cleaned || messageText?.plain_text);
				break;
			// HSH actions, open modal
			case "HSHsearchDataCrm":
				setActionModal(
					<SearchDataCrmInputCreateAffair
						actionObject={data}
						message={message}
						handleClose={() => {
							setActionModalOpen(false);
							setActionModal(null);
						}}
					/>
				);
				setActionModalOpen(true);
				break;
			default:
				break;
		}
	};

	/* SHOW FORWARD MESSAGE LIKE MOVE MESSAGE */
	// if (message.header?.state == "forward") {
	// 	return (
	// 		<ListItem>
	// 			<Grow in={true}>
	// 				<Box width={1} display="flex" justifyContent={justifyDirection}>
	// 					<Box
	// 						display="flex"
	// 						flexDirection="column"
	// 						maxWidth={fullWidthDisplay ? "100%" : "80%"}
	// 						width={fullWidthDisplay ? "100%" : "unset"}
	// 					>
	// 						<Box display="flex" alignItems="flex-end">
	// 							<Paper style={{ ...paperStyle }} elevation={3}>
	// 								<React.Fragment>
	// 									<Box fontStyle="italic" p={1} display="flex" alignItems="center">
	// 										<Box flexGrow={1}>
	// 											<List style={{ padding: 0 }}>
	// 												<ActionForwardMessageItem data={message}></ActionForwardMessageItem>
	// 											</List>
	// 											<Grid container display="flex" justifyContent="flex-end">
	// 												<Tooltip title={i18n.t("COC.seeMessage")} placement="bottom">
	// 													<IconButton
	// 														size="small"
	// 														onClick={() => {
	// 															dispatch(
	// 																cockpitActions.selectConversation({
	// 																	header: {
	// 																		assistantID: selectedConversation.header.assistantID,
	// 																		fID: message?.header?.move?.targetfID
	// 																	}
	// 																})
	// 															);
	// 														}}
	// 													>
	// 														<Visibility />
	// 													</IconButton>
	// 												</Tooltip>
	// 											</Grid>
	// 										</Box>
	// 									</Box>
	// 								</React.Fragment>
	// 							</Paper>
	// 						</Box>
	// 					</Box>
	// 				</Box>
	// 			</Grow>
	// 		</ListItem>
	// 	);
	// }

	const ticketReceiversInfo = (
		<>
			{/* To */}
			{Boolean(messageTo.length) && !textVersionClean && (
				<Box
					style={{ display: "flex", flexDirection: "row" }}
					fontWeight="fontWeightMedium"
					mx={1}
					mb={0.5}
				>
					{!lod_.isNil(messageTo[0]) && <>{i18n.t("COC.To")}</>}
					{Array.isArray(messageTo[0]) &&
						messageTo[0].length > 0 &&
						messageTo[0].map((e, i) => {
							return i === messageTo[0].length - 1 ? (
								<p style={{ marginLeft: "5px", fontStyle: "italic" }}>{e}</p>
							) : (
								<p style={{ marginLeft: "5px", fontStyle: "italic" }}>: {e},</p>
							);
						})}{" "}
					{!Array.isArray(messageTo[0]) && (
						<p style={{ marginLeft: "5px", fontStyle: "italic" }}>{messageTo[0]}</p>
					)}
				</Box>
			)}
			{/* CC array */}
			{Array.isArray(messageCC) && messageCC.length !== 0 && !textVersionClean && (
				<Box
					style={{ display: "flex", flexDirection: "row" }}
					fontWeight="fontWeightMedium"
					mx={1}
					mb={0.5}
				>
					{i18n.t("COC.CC")}:
					{messageCC.map((e, i) => {
						return i === messageCC.length - 1 ? (
							<p style={{ marginLeft: "10px", fontStyle: "italic" }}>{e}</p>
						) : (
							<p style={{ marginLeft: "10px", fontStyle: "italic" }}>{e},</p>
						);
					})}
				</Box>
			)}
			{/* BCC array */}
			{Array.isArray(messageBCC) && messageBCC.length !== 0 && !textVersionClean && (
				<Box
					style={{ display: "flex", flexDirection: "row" }}
					fontWeight="fontWeightMedium"
					mx={1}
					mb={0.5}
				>
					{i18n.t("COC.BCC")}:
					{messageBCC.map((e, i) => {
						return i === messageBCC.length - 1 ? (
							<p style={{ marginLeft: "10px", fontStyle: "italic" }}>{e}</p>
						) : (
							<p style={{ marginLeft: "10px", fontStyle: "italic" }}>{e},</p>
						);
					})}
				</Box>
			)}
		</>
	);

	const showCategory = () => {
		let categoriesShow = allCategory.filter(
			category =>
				!message.understand?.categories.some(
					categoryFound => categoryFound?.code === category?.code
				)
		);
		if (lod_.isEmpty(categoriesShow)) {
			return <Box>{i18n.t("COC.emptyCategory")}</Box>;
		} else {
			return (
				<Box>
					{categoriesShow
						.sort((a, b) => getLabelOf(a?.code).localeCompare(getLabelOf(b?.code)))
						.map((category, index) => {
							return (
								<Box key={index} display="flex" alignItems="center" justifyContent="space-between">
									<Box fontWeight="bold">{getLabelOf(category?.code)}</Box>
									<Box display="flex" alignItems="center">
										<IconButton onClick={() => handleAddCategory("positive", category)}>
											<SentimentSatisfiedAltIcon
												style={{
													color: "green"
												}}
											/>
										</IconButton>
										/
										<IconButton onClick={() => handleAddCategory("negative", category)}>
											<SentimentVeryDissatisfiedIcon style={{ color: "red" }} />
										</IconButton>
									</Box>
								</Box>
							);
						})}
				</Box>
			);
		}
	};

	const checkShowAckBot = () => {
		return message.header.subtype !== "mailbotackBot" || showAckBot;
	};

	return (
		<ListItem ref={messageItem} data-component-name="MessageItem">
			{/* Modal used for some actions  */}
			{actionModalOpen && actionModal}
			<Grow in={true}>
				<Box width={1} display="flex" justifyContent={justifyDirection}>
					<Box
						display="flex"
						flexDirection="column"
						maxWidth={fullWidthDisplay ? "100%" : "80%"}
						width={fullWidthDisplay ? "100%" : "unset"}
					>
						<Box display="flex" alignItems="flex-end" justifyContent="flex-end">
							<Paper elevation={3} style={paperStyle}>
								{isExternal ? (
									<React.Fragment>
										<MessageHeaderExternalSender message={message} messagePaperStyle={paperStyle} />
										<Box fontWeight="fontWeightBold" p={1} display="flex" alignItems="center">
											<Box flexGrow={1}>
												<MessageItemHeader
													message={message}
													selectedConversation={selectedConversation}
													channelImage={channelImage}
													channels={channels}
													headerConv={headerConv}
													fromHistoric={fromHistoric}
													showMessageStateInfo={showMessageStateInfo}
													msgIndex={msgIndex}
													feedbackDisplay={feedbackDisplay}
													conversation={conversation}
												/>
											</Box>
											{translationButton && (
												<FlagsMenu
													flags={Object.keys(translationButton)}
													handleChange={(e, language) => {
														handleChangeTranslation(e, language);
													}}
													defaultIcon={
														<TranslateRounded style={{ fontSize: 20 }}></TranslateRounded>
													}
												></FlagsMenu>
											)}

											{/* Filtre le contenu du message */}

											{headerConv &&
												messageText.cleaned &&
												(messageText.html_text || messageText.plain_text) && (
													<Box>
														<ToggleButtonGroup
															style={{ paddingLeft: 8 }}
															size="small"
															value={textVersionClean}
															exclusive
															onChange={handleTextVersionChange}
														>
															<Tooltip
																title={
																	!textVersionClean
																		? i18n.t("COC.showCleanMessage")
																		: i18n.t("COC.showCompleteMessage")
																}
																placement="top"
																open={showTooltipTextVersion}
																disableHoverListener
																onMouseEnter={() => setShowTooltipTextVersion(true)}
																onMouseLeave={() => setShowTooltipTextVersion(false)}
															>
																{(message.header.channel === "WA" ||
																	channelImage === "WA" ||
																	message.header.channel?.type === "WA") &&
																attachmentLinkToMsg.length == 0 ? (
																	<Box></Box>
																) : (
																	<ToggleButton size="small" value={true}>
																		{!textVersionClean ? <SubjectIcon /> : <PhotoFilterIcon />}
																	</ToggleButton>
																)}
															</Tooltip>
														</ToggleButtonGroup>
													</Box>
												)}
										</Box>
										{ticketReceiversInfo}
										{attachmentLinkToMsg.length !== 0 && !textVersionClean && (
											<Box
												style={{
													display: "flex",
													flexDirection: "column",
													flexWrap: "flexWrap"
												}}
												fontWeight="fontWeightMedium"
												mx={1}
												mb={0.5}
											>
												<p> {i18n.t("COC.Attachements")} :</p>
												<GenericAttachmentList
													arrayOfAttachments={attachmentLinkToMsg}
													mID={message.header.mID}
												/>
											</Box>
										)}

										{messageText.subject &&
											displaySubject(conversation) &&
											(!textVersionClean ? (
												<Subject topic={messageText.subject} />
											) : (
												<Box fontWeight="fontWeightMedium" mx={1} mb={0.5}>
													{messageText.subject}
												</Box>
											))}
										{rating && (
											<Box mx={1} mb={0.5}>
												{i18n.t("COC.Rating")} : {rating}
											</Box>
										)}
										{message.understand?.categories && (
											<Box display="flex">
												<CategoriesDisplay
													message={message}
													selectedConversation={selectedConversation}
													categories={message.understand?.categories}
												></CategoriesDisplay>

												<Tooltip title={i18n.t("COC.addCategory")} placement="right">
													<IconButton size="small" onClick={handleInsertCategory}>
														<AddOutlinedIcon />
													</IconButton>
												</Tooltip>

												<Popover
													open={openAddCategory}
													anchorEl={anchorEl}
													onClose={handleCloseCategory}
													anchorOrigin={{
														vertical: "center",
														horizontal: "right"
													}}
												>
													<Box
														display="flex"
														flexDirection="row"
														alignItems="center"
														padding="10px"
													>
														{lod_.isEmpty(allCategory) ? (
															<Box>Aucune catégorie disponible</Box>
														) : (
															<Box display="flex" flexDirection="column">
																{showCategory()}
															</Box>
														)}
													</Box>
												</Popover>
											</Box>
										)}
										{/* Rating stars */}
										{ratingStars && <StarRating rating={ratingStars.score} max={100}></StarRating>}
										{/* Replyto story url */}
										{answerStory && (
											<Box fontWeight="fontWeightMedium" mx={1} mb={0.5}>
												<a
													href={answerStory}
													target="_blank"
													rel="noreferrer"
													style={{ color: "black" }}
												>
													<p>{i18n.t("COC.answerStory")}</p>
												</a>
											</Box>
										)}
										<Box
											mx={1}
											className="item-img"
											style={{
												fontSize,
												lineHeight: "1.5em",
												whiteSpace:
													textVersionClean || (messageText?.plain_text && !messageText.html_text)
														? "pre-wrap"
														: "initial"
											}}
										>
											{messageContentText}
											{lod_.has(message, "header.extra.contentOfMsg") &&
												message.header.extra.contentOfMsg !== "text" &&
												(message.body.attachments || message.sent_attachments) && (
													<AttachmentDisplay
														type={message.header.extra.contentOfMsg}
														attachmentInfo={message.body.attachments || message.sent_attachments}
													/>
												)}
											{message.header?.type !== "ticket" &&
												// The rule is to display the first inline attachment if the type is not ticket
												// Warning ! This code seems to not handle multiple attachment..
												message.payload?.payload?.attachments?.length > 0 && (
													<>
														<AttachmentDisplay
															type={message.payload.payload.attachments[0].type}
															mimeType={
																message.payload.payload.attachments[0].contentType ||
																message.payload.payload.attachments[0].mimeType
															}
															attachmentInfo={message.payload.payload.attachments}
														/>
													</>
												)}
										</Box>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Box
											p={1}
											display="flex"
											fontWeight="fontWeightBold"
											alignItems="center"
											justifyContent="end"
											style={{ paddingBottom: "0px" }}
										>
											{/**
											 * TODO: Voluntaraly made 3 tests for readability ... in case of low perf make if one since they are mutualy exclusive
											 */}
											{(!message.header.typeOfMsg || message.header.typeOfMsg === "answer") &&
												!message.payload && (
													<Box flexGrow={1}>
														{i18n.t("COC.answeredBy")} {message.agent.name}
													</Box>
												)}

											{/* Case new format */}
											{message.payload && message.header.action === "REPLY" && (
												<Box flexGrow={1}>
													{message.header.subtype !== "mailbotackBot"
														? i18n.t("COC.answeredBy")
														: i18n.t("COC.byAckBot")}{" "}
													{message.header.source !== "bot" && message.header.from.name}
												</Box>
											)}
											{message.payload && message.header.action === "ENGAGE" && (
												<Box flexGrow={1}>
													{i18n.t("COC.engagedBy")} {message.header.from.name}
												</Box>
											)}
											{message.header.typeOfMsg === "transfer" && (
												<Box flexGrow={1}>
													{i18n.t("COC.transferedBy")} {message.agent.name}
												</Box>
											)}
											{message.header.typeOfMsg === "engage" && (
												<Box flexGrow={1}>
													{i18n.t("COC.engagedBy")} {message.agent.name}
												</Box>
											)}
											{headerConv && messageText.parts && (
												<Box>
													<ToggleButtonGroup
														style={{ paddingLeft: 8 }}
														size="small"
														value={textVersionClean}
														exclusive
														onChange={handleTextVersionChange}
													>
														<Tooltip
															title={
																!textVersionClean
																	? i18n.t("COC.showCleanMessage")
																	: i18n.t("COC.showCompleteMessage")
															}
															placement="top"
															open={showTooltipTextVersion}
															disableHoverListener
															onMouseEnter={() => setShowTooltipTextVersion(true)}
															onMouseLeave={() => setShowTooltipTextVersion(false)}
														>
															{(message.header.channel === "WA" ||
																channelImage === "WA" ||
																message.header.channel?.type === "WA") &&
															attachmentLinkToMsg.length == 0 ? (
																<Box></Box>
															) : (
																<ToggleButton size="small" value={true}>
																	{!textVersionClean ? <SubjectIcon /> : <PhotoFilterIcon />}
																</ToggleButton>
															)}
														</Tooltip>
													</ToggleButtonGroup>
												</Box>
											)}
											{message.header.typeOfMsg === "sourceMessage" && (
												<Box flexGrow={1}>{i18n.t("COC.sourceMessage")}</Box>
											)}
											<Box pl={1}>
												{message.header.source === "bot" &&
													(message.header.subtype === "mailbotackBot" ? (
														<Tooltip
															title={
																showAckBot
																	? i18n.t("COC.showCleanAck")
																	: i18n.t("COC.showCompleteMessage")
															}
															placement="top"
														>
															<ToggleButton
																size="small"
																value={true}
																onClick={() => setShowAckBot(!showAckBot)}
															>
																<AndroidIcon />
															</ToggleButton>
														</Tooltip>
													) : (
														<AndroidIcon fontSize="small" />
													))}
											</Box>
											{translationButton && (
												<FlagsMenu
													flags={Object.keys(translationButton)}
													handleChange={(e, language) => {
														handleChangeTranslation(e, language);
													}}
													defaultIcon={
														<TranslateRounded style={{ fontSize: 20 }}></TranslateRounded>
													}
												></FlagsMenu>
											)}
										</Box>
										{messageText.subject && checkShowAckBot() && displaySubject(conversation) ? (
											<Subject urgent={urgent} topic={messageText.subject} justify={false} />
										) : (
											<Box></Box>
										)}

										{checkShowAckBot() && ticketReceiversInfo}
										<Box>
											<MessageAttachmentsList message={message} />
										</Box>
										{menuTitle && menuText && Array.isArray(menuText) ? (
											<MenuText menuTitle={menuTitle} menuText={menuText} />
										) : message.payload ? (
											<Box
												mx={1}
												style={{
													fontSize,
													lineHeight: "1.5em",
													whiteSpace:
														textVersionClean || (messageText?.plain_text && !messageText.html_text)
															? "pre-wrap"
															: "initial"
												}}
											>
												{checkShowAckBot() && messageContentText}
												{
													// TODO Warning ! This code seems to not handle multiple attachment..
													headerConv?.type !== "ticket" &&
														message.payload?.payload?.attachments?.length > 0 && (
															<AttachmentDisplay
																type={message.payload.payload.attachments[0].type}
																mimeType={message.payload.payload.attachments[0].mimeType}
																attachmentInfo={message.payload.payload.attachments}
															/>
														)
												}
											</Box>
										) : (
											<Box mx={1}>
												<div
													dangerouslySetInnerHTML={{
														__html: sanitize(message.body.repliedAnswer)
													}}
													style={{ fontSize, lineHeight: "1.5em" }}
													className="item-img"
												/>
											</Box>
										)}
										{/* Note: Bellow is for displaying the answer of Bot for ticket. Temporary (as 29/06/2022), should be reworked with new format. */}
										{message?.header?.source === "bot" && message?.body?.content && (
											<Box mx={1}>
												<div
													dangerouslySetInnerHTML={{
														__html: sanitize(message.body.content)
													}}
													style={{ fontSize, lineHeight: "1.5em" }}
													className="item-img"
												/>
											</Box>
										)}
										{conversation &&
											conversation.header.type === "review" &&
											conversation.messages.length >= 1 && (
												<DisplayReviewChoices conversation={message} />
											)}
									</React.Fragment>
								)}
								<Box display="flex" m={1} alignItems="center">
									<Box flexGrow={1} fontSize={11}>
										{dateFormat
											? handleSpecialDisplayDate(
													message.header.ts ||
														message.header?.dateCreated?.ts ||
														message.header?.externalInfos?.dateCreated?.ts ||
														message.header?.dateReceived?.ts,
													dateFormat,
													userLanguage
												)
											: formatDate(
													message.header.datetime ||
														message.header?.dateCreated?.datetime ||
														message.header?.externalInfos?.dateCreated?.datetime ||
														message.header?.dateReceived?.datetime
												)}
									</Box>

									{/* All informations about the message action */}

									<Box>
										{showMessageActions &&
											!feedbackDisplay &&
											//headerConv?.state !== "waiting" &&
											// headerConv.type !== "conversational" && **POUR LA DEMO
											//userStatus.auth.user._id === conversation.agent.uid &&
											!fromHistoric && (
												<Box>
													{/*
													 *	Add icon of attachments for Prestashop (+)
													 */}
													{message?.payload?.payload?.notifyUnloadedAttachments && (
														<AttachFileOutlinedIcon />
													)}
													{/*
													 *	Add message to answer (+)
													 */}
													{type === "default" &&
														headerConv?.state !== "hidden" &&
														headerConv?.state !== "waiting" &&
														headerConv?.state !== "archived" &&
														headerConv.channel != "AV" && (
															<Tooltip title={i18n.t("COC.addCopyClipboard")} placement="bottom">
																<IconButton size="small" onClick={handleInsertMessage}>
																	<AddOutlinedIcon />
																</IconButton>
															</Tooltip>
														)}

													{/*
													 *	Actions forward : view origin fID
													 */}
													{type === "default" &&
														message?.header?.move &&
														message?.header?.move?.type === "forward" && (
															<Tooltip
																title={
																	message?.header?.move.originfID ===
																	selectedConversation.header.fID
																		? i18n.t("COC.showForwardfID")
																		: i18n.t("COC.showOriginfID")
																}
																placement="bottom"
															>
																<IconButton
																	size="small"
																	onClick={() => {
																		dispatch(
																			cockpitActions.selectConversation({
																				header: {
																					assistantID: selectedConversation.header.assistantID,
																					fID:
																						message?.header?.move.originfID ===
																						selectedConversation.header.fID
																							? message.header.move?.targetfID
																							: message.header.move?.originfID
																				}
																			})
																		);
																	}}
																>
																	<Visibility />
																</IconButton>
															</Tooltip>
														)}

													{/*
													 *	Actions forward : view origin fID (view of forwarded message)
													 */}
													{type === "forwarded" && (
														<Tooltip
															arrow
															title={i18n.t("COC.showOriginConversation")}
															placement="bottom"
														>
															<IconButton
																size="small"
																onClick={() => {
																	dispatch(
																		cockpitActions.selectConversation({
																			header: {
																				assistantID: selectedConversation.header.assistantID,
																				fID: message?.header?.originfID
																			}
																		})
																	);
																}}
															>
																<Adjust />
															</IconButton>
														</Tooltip>
													)}

													{/*
													 *	Actions menu : trois petis poins à côté du +
													 */}
													{type === "default" &&
														headerConv?.state !== "hidden" &&
														messageActions.length > 0 && (
															<DropMenu
																handleAction={data => {
																	handleDropMenuActions(data);
																}}
																items={actionsMenu}
															/>
														)}
													<Modal
														open={openModalMoveMsgNew}
														handleClose={() => setOpenModalMoveMsgNew(false)}
														title={i18n.t("COC.moveMsgTitle")}
														message={i18n.t("COC.moveMsgMessage")}
														cancel={i18n.t("COC.disagree")}
														validate={i18n.t("COC.agree")}
														handleValidate={() => {
															handleValidateModalMoveMessage("NEW");
														}}
													/>
													<SelectConversationDialog
														open={openModalMoveMsgTarget}
														handleClose={() => setOpenModalMoveMsgTarget(false)}
														handleValidate={handleValidateModalMoveMessage}
													/>
												</Box>
											)}
									</Box>
								</Box>
							</Paper>

							{/* All informations about the message state */}

							{message.header.source !== "contact" && showMessageStateInfo && (
								<>
									{message?.header?.state === "sending" && (
										<Tooltip title={i18n.t("COC.sending")} placement="top">
											<Box pl={1}>
												<ScheduleIcon
													fontSize="small"
													style={{
														color: "black"
													}}
												/>
											</Box>
										</Tooltip>
									)}
									{message?.header?.state === "sent" && (
										<Tooltip title={i18n.t("COC.sent")} placement="top">
											<Box pl={1}>
												<CheckCircleOutlineIcon fontSize="small" />
											</Box>
										</Tooltip>
									)}
									{message?.header?.state === "error" && (
										<Tooltip title={errorMessageTooltip} placement="top">
											<Box
												pl={1}
												onClick={canReplay ? handleClickMessageState : () => {}}
												style={{ cursor: "pointer" }}
											>
												<ErrorOutlineIcon fontSize="small" style={{ color: "red" }} />
											</Box>
										</Tooltip>
									)}
								</>
							)}
						</Box>
						{showMsg && (
							<>
								{message.header.source !== "contact" && (
									<>
										{message?.header?.state === "sending" && (
											<Fade in={showMsg}>
												<Box pt={1} pr={4} style={{ textAlign: "right" }}>
													<b>{i18n.t("COC.sending")}</b>
												</Box>
											</Fade>
										)}
										{message?.header?.state === "sent" && (
											<Fade in={showMsg}>
												<Box pt={1} pr={4} style={{ textAlign: "right" }}>
													<b>{i18n.t("COC.sent")}</b>
												</Box>
											</Fade>
										)}
										{message?.header?.state === "error" && (
											<Fade in={showMsg}>
												<Box pt={1} pr={4} style={{ textAlign: "right" }}>
													<b style={{ color: "red" }}>{errorMessageTooltip}</b>
												</Box>
											</Fade>
										)}
									</>
								)}
							</>
						)}
						{openModalResendMessage && (
							<Modal
								open={openModalResendMessage}
								handleClose={() => setOpenModalModalResendMessage(false)}
								title={i18n.t("COC.sendMessageFailed")}
								message={i18n.t("COC.resendTitle")}
								cancel={i18n.t("COC.disagree")}
								validate={i18n.t("COC.agree")}
								handleValidate={handleValidateModalResendMessage}
							/>
						)}
					</Box>
				</Box>
			</Grow>
			{!fromHistoric && showMessageStateInfo && <TextSelectActionPopover target={messageItem} />}
		</ListItem>
	);
};

export default Message;
