/* eslint-disable react/react-in-jsx-scope */
import i18n from "i18n";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import cockpitActions from "../../../redux/actions/cockpitActions";
import MessageItem from "../Message/MessageItem";
import { blue } from "@material-ui/core/colors";

const {
	Dialog,
	Box,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Slider,
	Card,
	RadioGroup,
	FormControlLabel,
	Radio,
	LinearProgress
} = require("@material-ui/core");
const { useState, useEffect } = require("react");

const TONALITY_LIST = [
	"cordial",
	"happily",
	"serious",
	"casual",
	"familiar",
	"formal",
	"polite",
	"professional"
];

const ReplyAssistantModal = ({ data = {}, open, processCode, onClose }) => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(state => state.cockpit);
	const { assistantconfig } = useSelector(state => state);
	const [selectedAnswer, setSelectedAnswer] = useState(null);

	const [currentAction, setCurrentAction] = useState(data);

	let channel = assistantconfig.channels.find(
		c => c.code === selectedConversation.header.extra.channelCode
	);
	let defaultValues = channel?.answerGenerateConfig || {};
	const areAllConfigDisabled = () => {
		let allDisabled = true;
		let keys = Object.keys(defaultValues);
		keys.forEach(key => {
			if (defaultValues[key].active) {
				allDisabled = false;
			}
		});
		return allDisabled;
	};

	const [payload, setPayload] = useState({
		emojis: defaultValues.emojisCount?.value ?? 0,
		tonality: defaultValues.answerTone?.value ?? "professional",
		youForm: defaultValues.youForm?.value ?? "vouvoiement",
		maximumWords: defaultValues.maximumWords?.value ?? 50,
		temperature: defaultValues.temperature?.value ?? 0.5
	});

	let review = selectedConversation.messages.find(m => m.header.source === "contact");

	// New way | old way | default way
	const answers =
		currentAction?.result?.meta?.generatedAnswers || currentAction?.result?.answers || [];

	const onChange = (path, value) => {
		let clonedPayload = lod_.cloneDeep(payload);
		lod_.set(clonedPayload, path, value);
		setPayload(clonedPayload);
	};

	const startActionReplyAssistant = () => {
		dispatch(
			cockpitActions.launchActionFrontV2("replyAssistant", {
				header: {
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID
				},
				payload: {
					processCode: processCode,
					parameters: {
						message: review.payload.payload.text.plain_text,
						rating: review.payload.payload.rating,
						context: selectedConversation.context,
						...payload
					}
				}
			})
		);
		// onClose();
	};

	const selectAnswer = () => {
		dispatch(
			cockpitActions.postDraft(
				selectedConversation.header.fID,
				selectedConversation.header.assistantID,
				selectedConversation.header.substate,
				{
					draftAnswer: answers[selectedAnswer],
					draftAnswerParts: {
						answer: answers[selectedAnswer],
						signature: selectedConversation?.drafts?.draftAnswerParts?.signature || "",
						replyTo: selectedConversation?.drafts?.draftAnswerParts?.replyTo || ""
					}
				}
			)
		);

		dispatch(
			cockpitActions.launchActionFrontV2("deleteConversationAction", {
				header: {
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID
				},
				payload: {
					actionUuid: currentAction.uuid
				}
			})
		);
		onClose();
	};

	const deleteResponse = () => {
		if (currentAction?.type === "draftComputed") {
			// If the actual action is a computed action, we just need to change the state
			setCurrentAction({});
		} else {
			// Remove action from conversation & frontend
			dispatch(
				cockpitActions.launchActionFrontV2("deleteConversationAction", {
					header: {
						assistantID: selectedConversation.header.assistantID,
						fID: selectedConversation.header.fID
					},
					payload: {
						actionUuid: currentAction.uuid
					}
				})
			);
			onClose();
		}
	};

	useEffect(() => {
		if (selectedConversation.actions) {
			if (currentAction.uuid) {
				let correctAction = selectedConversation.actions.find(a => a.uuid === currentAction.uuid);
				if (correctAction) {
					setCurrentAction(correctAction);
				}
			} else {
				let correctAction = selectedConversation.actions.find(
					a => a.code === "replyAssistant" && a.state === "processing"
				);
				if (correctAction) {
					setCurrentAction(correctAction);
				}
			}
		}
	}, [selectedConversation.actions]);

	useEffect(() => {
		if (!lod_.isEmpty(data)) {
			setCurrentAction(data);
		} else {
			setCurrentAction({});
			// Check if we have default generated answers in conversation.meta.generatedAnswers
			let generatedAnswers = selectedConversation.meta?.generatedAnswers;
			if (generatedAnswers) {
				setCurrentAction({
					result: {
						meta: { generatedAnswers }
					},
					state: "done",
					// Add a specific type to know that this action is computed
					// It will be used to dont delete an action when we click on "delete" button
					// => we will just change the state of current action
					type: "draftComputed"
				});
			}
		}
		setPayload({
			emojis: defaultValues.emojisCount?.value ?? 0,
			tonality: defaultValues.answerTone?.value ?? "professional",
			youForm: defaultValues.youForm?.value ?? "vouvoiement",
			maximumWords: defaultValues.maximumWords?.value ?? 50,
			temperature: defaultValues.temperature?.value ?? 0.5
		});
		setSelectedAnswer(null);
	}, [open]);

	return (
		<Dialog
			fullWidth={true}
			onClose={onClose}
			maxWidth={"lg"}
			open={open}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle>Générer une réponse</DialogTitle>
			<DialogContent>
				{/* Message item */}
				<MessageItem
					message={review}
					msgIndex={1}
					fromHistoric={true}
					convHistory={selectedConversation}
				/>
				{/* Inputs title */}
				{!lod_.isEmpty(defaultValues) && !areAllConfigDisabled() && (
					<Box mt={3}>
						<Typography variant="h6" component="h2">
							Paramètres de réponse
						</Typography>
					</Box>
				)}
				{/* Inputs */}
				<Box
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "stretch"
					}}
				>
					{/* Emojis */}
					{!lod_.isEmpty(defaultValues) && defaultValues?.emojisCount?.active && (
						<Box style={{ flex: 1 }}>
							<Card style={{ marginRight: 5, padding: 20, height: "100%" }}>
								<Typography variant="body1" fontSize="small">
									{i18n.t("COC.ACTIONS.emojis")}
								</Typography>
								<Box>
									<Box mt={1} display="flex" alignItems="start">
										<Slider
											disabled={Boolean(currentAction.state)}
											value={payload.emojis}
											marks={[
												{
													value: 0,
													label: "0"
												},
												{
													value: 1,
													label: "1"
												},
												{
													value: 2,
													label: "2"
												},
												{
													value: 3,
													label: "3"
												},
												{
													value: 4,
													label: "4"
												},
												{
													value: 5,
													label: "5"
												}
											]}
											min={0}
											max={5}
											onChange={(e, value) => {
												onChange(`emojis`, value);
											}}
										/>
									</Box>
								</Box>
							</Card>
						</Box>
					)}

					{/* Tone */}
					{!lod_.isEmpty(defaultValues) && defaultValues?.answerTone?.active && (
						<Box style={{ flex: 1 }}>
							<Card style={{ marginLeft: 5, padding: 20, height: "100%" }}>
								<FormControl fullWidth>
									<InputLabel id="select-label">{i18n.t("COC.ACTIONS.answerTone")}</InputLabel>
									<Select
										labelId="select-label"
										id="select"
										label={i18n.t("COC.ACTIONS.answerTone")}
										value={payload.tonality}
										onChange={e => {
											onChange("tonality", e.target.value);
										}}
										disabled={Boolean(currentAction.state)}
									>
										{TONALITY_LIST.map((option, index) => (
											<MenuItem key={index} value={i18n.t(`COC.ACTIONS.tonalities.${option}`)}>
												{i18n.t(`COC.ACTIONS.tonalities.${option}`)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Card>
						</Box>
					)}

					{/* Forme */}
					{!lod_.isEmpty(defaultValues) && defaultValues?.youForm?.active && (
						<Box style={{ flex: 1 }}>
							<Card style={{ marginLeft: 5, padding: 20, height: "100%" }}>
								<Typography variant="body1" fontSize="small">
									{i18n.t("COC.ACTIONS.youForm")}
								</Typography>
								<Box>
									<Box mt={1} flex="1" display="flex" alignItems="start">
										<RadioGroup
											aria-labelledby="demo-radio-buttons-group-label"
											value={payload.youForm}
											name="radio-buttons-group"
											row
										>
											<FormControlLabel
												value="vouvoiement"
												control={
													<Radio
														disabled={Boolean(currentAction.state)}
														color="primary"
														onClick={() => {
															onChange(`youForm`, "vouvoiement");
														}}
													/>
												}
												label={i18n.t("COC.ACTIONS.vouvoiement")}
											/>
											<FormControlLabel
												value="tutoiement"
												control={
													<Radio
														disabled={Boolean(currentAction.state)}
														color="primary"
														onClick={() => {
															onChange(`youForm`, "tutoiement");
														}}
													/>
												}
												label={i18n.t("COC.ACTIONS.tutoiement")}
											/>
										</RadioGroup>
									</Box>
								</Box>
							</Card>
						</Box>
					)}

					{/* Size */}
					{!lod_.isEmpty(defaultValues) && defaultValues?.maximumWords?.active && (
						<Box style={{ flex: 1 }}>
							<Card style={{ marginLeft: 5, padding: 20, height: "100%" }}>
								<Typography variant="body1" fontSize="small">
									{i18n.t("COC.ACTIONS.maximumWords")}
								</Typography>
								<Box>
									<Box mt={1}>
										<Slider
											aria-label="maximumWords"
											value={payload.maximumWords}
											step={10}
											min={20}
											max={100}
											onChange={(e, value) => {
												onChange(`maximumWords`, value);
											}}
											disabled={Boolean(currentAction.state)}
										/>

										<Box display="flex" justifyContent="space-between">
											<Typography variant="caption">{i18n.t("COC.ACTIONS.short")}</Typography>
											<Typography variant="caption">{i18n.t("COC.ACTIONS.long")}</Typography>
										</Box>
									</Box>
								</Box>
							</Card>
						</Box>
					)}

					{/* Temperature */}
					{!lod_.isEmpty(defaultValues) && defaultValues?.temperature?.active && (
						<Box style={{ flex: 1 }}>
							<Card style={{ marginLeft: 5, padding: 20, height: "100%" }}>
								<Typography variant="body1" fontSize="small">
									{i18n.t("COC.ACTIONS.temperature")}
								</Typography>
								<Box>
									<Box mt={1}>
										<Slider
											aria-label="temperature"
											value={payload.temperature}
											valueLabelDisplay="auto"
											step={0.1}
											min={0}
											max={2}
											onChange={(e, value) => {
												onChange(`temperature`, value);
											}}
											disabled={Boolean(currentAction.state)}
										/>

										<Box display="flex" justifyContent="space-between">
											<Typography variant="caption">{i18n.t("COC.ACTIONS.noCrea")}</Typography>
											<Typography variant="caption">{i18n.t("COC.ACTIONS.superCrea")}</Typography>
										</Box>
									</Box>
								</Box>
							</Card>
						</Box>
					)}
				</Box>
				{/* Waiting */}
				{!lod_.isEmpty(currentAction) && currentAction.state === "processing" && (
					<Box mt={3}>
						<Typography variant="h6" component="h2">
							{`Génération de propositions en cours`}
						</Typography>
						<Box mt={3}>
							<LinearProgress />
						</Box>
					</Box>
				)}
				{/* Results */}
				{!lod_.isEmpty(currentAction) && currentAction.state === "done" && (
					<>
						<Box mt={3}>
							<Typography variant="h6" component="h2">
								{`${answers.length} propositions générées`}
							</Typography>
						</Box>
						<Box mb={3}>
							<Typography variant="body1" component="h2">
								{`Selectionnez une proposition pour l'ajouter dans la zone de réponse`}
							</Typography>
						</Box>
						<Box
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "stretch"
							}}
						>
							{answers.map((answer, index) => {
								return (
									<Box key={index} style={{ flex: 1 }}>
										<Card
											style={{
												marginRight: 5,
												marginLeft: 5,
												padding: 20,
												height: "100%",
												backgroundColor: selectedAnswer === index ? blue[600] : "white",
												color: selectedAnswer === index ? "white" : "black",
												fontWeight: selectedAnswer === index ? "bold" : "normal",
												cursor: "pointer",
												whiteSpace: "pre-wrap"
											}}
											onClick={() => {
												setSelectedAnswer(index);
											}}
											dangerouslySetInnerHTML={{ __html: answer }}
										></Card>
									</Box>
								);
							})}
						</Box>
					</>
				)}
			</DialogContent>
			<DialogActions
				style={{
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<Box>
					{currentAction.state === "done" && (
						<Button style={{ color: "red" }} onClick={deleteResponse}>
							{i18n.t("COC.Delete")}
						</Button>
					)}
				</Box>
				<Box>
					<Button style={{ color: "red" }} onClick={onClose}>
						{i18n.t("TabAction.cancel")}
					</Button>
					{lod_.isEmpty(currentAction) && (
						<Button color="primary" autoFocus onClick={e => startActionReplyAssistant()}>
							Générer
						</Button>
					)}
					{!lod_.isEmpty(currentAction) && (
						<Button
							color="primary"
							autoFocus
							onClick={selectAnswer}
							disabled={selectedAnswer === null || currentAction.state !== "done"}
						>
							Utiliser
						</Button>
					)}
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default ReplyAssistantModal;
