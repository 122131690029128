/*eslint-disable*/
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import cx from "classnames";
import i18n from "i18n";
import PerfectScrollbar from "perfect-scrollbar";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import C from "../../constants/cockpit.js";
import cockpitActions from "../../redux/actions/cockpitActions";
import labelsActions from "../../redux/actions/labelsActions";
import routes from "../../routes/routes";
import SettingsMenu from "components/Logout/Settings.js";
import AssistantSettings from "components/Logout/AssistantSettings.js";
import { Divider } from "@material-ui/core";

var ps;

const SidebarWrapper = props => {
	let sidebarWrapper = useRef();
	useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(sidebarWrapper.current, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		} else if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}, []);

	const { className, links, handleOpenDisplayReleaseNotes, state, classes, role } = props;
	return (
		<div
			className={className}
			ref={sidebarWrapper}
			style={{ marginTop: "10px", height: "fit-content", overflow: "hidden" }}
		>
			<AssistantSettings classes={classes} state={state} />

			{links}
			{role !== "administrator" && (
				<Divider style={{ background: "#FFF", opacity: "0.3", height: "0.5px" }} variant="middle" />
			)}

			<SettingsMenu
				handleOpenDisplayReleaseNotes={handleOpenDisplayReleaseNotes}
				state={state}
				classes={classes}
			/>
		</div>
	);
};

const getCollapseStates = routes => {
	let initialState = {};
	routes.map((prop, key) => {
		if (prop.collapse) {
			initialState = {
				[prop.state]: getCollapseInitialState(prop.views),
				...getCollapseStates(prop.views),
				...initialState
			};
		}
		return null;
	});
	return initialState;
};

function getCollapseInitialState(routes) {
	for (let i = 0; i < routes.length; i++) {
		if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
			return true;
		} else if (window.location.href.indexOf(routes[i].path) !== -1) {
			return true;
		}
	}
	return false;
}

const Sidebar = props => {
	const { forceSelectConversation } = useSelector(state => state.cockpit);
	const {
		classes,
		color,
		rtlActive,
		image,
		miniActive,
		bgColor,
		handleOpenDisplayReleaseNotes,
		brandText
	} = props;
	const dispatch = useDispatch();
	const { defaultChannel } = useSelector(state => state.cockpit);
	const currentHistoryPath = props && props.history.location.pathname;
	const [disabled, setDisabled] = useState(false);
	const [state, setState] = useState({
		assistantID: props.assistantID,
		openAvatar: false,
		miniActive: true,
		previousPath: props && currentHistoryPath,
		currentPath: props && currentHistoryPath,
		...getCollapseInitialState(routes)
	});

	// verifies if routeName is the one active (in browser input)
	function activeRoute(routeName) {
		return window.location.pathname === `/admin${routeName}` ? "active" : "";
	}

	const refetch = async currentPath => {
		const abortController = new AbortController();
		if (!disabled && currentPath != "") {
			const selectedAssistant = props.selectedAssistantID;

			switch (currentPath) {
				case "/admin/cockpit": {
					//TODO one big action instead of all these actions
					dispatch(cockpitActions.setIsCockpitArchived(false));
					if (!forceSelectConversation) dispatch({ type: C.CLEAR_CONVERSATION });
					dispatch(cockpitActions.changeAgentFilter(false));
					dispatch(cockpitActions.convAgentFetchedOngoing(false));
					dispatch(cockpitActions.saveDefaultChannel(defaultChannel, false));
					if (!forceSelectConversation) dispatch(cockpitActions.setLeftTab(0));
					dispatch(cockpitActions.convAgentFetchedResolved(false));
					dispatch(labelsActions.getLabels(selectedAssistant));
					dispatch({
						type: C.RESET_FILTERS
					});
					break;
				}
				case "/admin/cockpitArchived": {
					dispatch(cockpitActions.setIsCockpitArchived(true));
					dispatch({ type: C.CLEAR_CONVERSATION });
					dispatch(cockpitActions.changeAgentFilter(false));
					dispatch(cockpitActions.convAgentFetchedOngoing(false));
					dispatch(cockpitActions.saveDefaultChannel(defaultChannel, false));
					dispatch(cockpitActions.setLeftTab(0));
					dispatch(cockpitActions.convAgentFetchedResolved(false));
					dispatch(labelsActions.getLabels(selectedAssistant));
					dispatch({
						type: C.RESET_FILTERS
					});
					break;
				}
			}
			setDisabled(true);

			setTimeout(() => {
				setDisabled(false);
			}, 1000);
			return () => {
				abortController.abort();
			};
		}
	};

	const role = props.userStatus && props.userStatus.auth && props.userStatus.auth.user.role;
	const perms = props.userStatus && props.userStatus.auth && props.userStatus.auth.user.perms;
	const allPerms = perms && Object.keys(perms).map(p => p);

	const createLinks = routes => {
		return routes.map((prop, key) => {
			let collapseRoute = prop.collapse;
			let path = prop.path;
			let hasRole = prop.hasRole;
			let hasPerms = prop.hasPerms;
			let isProfile = path === "/profile";

			if (isProfile) {
				prop.name =
					props.userStatus.auth.user.name ||
					props.userStatus.auth.user.surname ||
					props.userStatus.auth.user._id;
			}

			if (prop.redirect) {
				return null;
			}

			if (hasRole && role && !hasRole.includes(role)) {
				return null;
			} else if (hasPerms && perms !== undefined && !allPerms.some(v => v.includes(hasPerms))) {
				return null;
			}
			if (collapseRoute) {
				var st = {};
				st[prop["state"]] = !state[prop.state];

				const navLinkClasses =
					classes.itemLink +
					" " +
					cx({
						[" " + classes.collapseActive]: getCollapseInitialState(prop.views)
					});

				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]: props.miniActive && state.miniActive,
						[classes.itemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
						[classes.itemTextRTL]: rtlActive
					});

				const collapseItemText =
					classes.collapseItemText +
					" " +
					cx({
						[classes.collapseItemTextMini]: props.miniActive && state.miniActive,
						[classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
						[classes.collapseItemTextRTL]: rtlActive
					});

				const itemIcon =
					classes.itemIcon +
					" " +
					cx({
						[classes.itemIconRTL]: rtlActive
					});
				const caret =
					classes.caret +
					" " +
					cx({
						[classes.caretRTL]: rtlActive
					});

				const collapseItemMini =
					classes.collapseItemMini +
					" " +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive
					});

				return (
					<ListItem
						key={key}
						className={cx(
							{ [classes.item]: prop.iconName !== undefined },
							{ [classes.collapseItem]: prop.iconName === undefined }
						)}
					>
						<NavLink
							to={"#"}
							className={navLinkClasses}
							onClick={e => {
								e.preventDefault();
								setState(st);
							}}
						>
							{prop.iconName !== undefined ? (
								typeof prop.iconName === "string" ? (
									<Icon className={itemIcon}>{prop.iconName}</Icon>
								) : (
									<prop.iconName className={itemIcon} />
								)
							) : (
								<span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
							)}
							<ListItemText
								primary={rtlActive ? prop.rtlName : `${i18n.t(prop.name)}`}
								secondary={
									<b className={caret + " " + (state[prop.state] ? classes.caretActive : "")} />
								}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.iconName !== undefined },
									{ [collapseItemText]: prop.iconName === undefined }
								)}
							/>
						</NavLink>
						<Collapse in={state[prop.state]} timeout="auto">
							<List className={classes.collapseList}>{createLinks(prop.views)}</List>
						</Collapse>
					</ListItem>
				);
			}

			/*********************innerNavLinkClasses****************************************** */

			const innerNavLinkClasses =
				classes.collapseItemLink +
				" " +
				cx({
					[" " + classes[color]]: activeRoute(prop.path)
				});

			/*****************************navLinkClasses********************************************* */
			const navLinkClasses =
				classes.itemLink +
				" " +
				cx({
					[" " + classes[color]]: activeRoute(prop.path)
				});

			/***********************itemText******************************** */

			const itemText =
				classes.itemText +
				" " +
				cx({
					[classes.itemTextMini]: miniActive && state.miniActive,
					[classes.itemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
					[classes.itemTextRTL]: rtlActive
				});

			/*******************collapseItemText*************************** */

			const collapseItemText =
				classes.collapseItemText +
				" " +
				cx({
					[classes.collapseItemTextMini]: miniActive && state.miniActive,
					[classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
					[classes.collapseItemTextRTL]: rtlActive
				});

			/*****************itemIcon********************** */

			const itemIcon =
				classes.itemIcon +
				" " +
				cx({
					[classes.itemIconRTL]: rtlActive
				});

			const handeleClickLink = async path => {
				setState({ ...state, currentPath: `/admin${path}` });
				refetch(`/admin${path}`);
			};

			return (
				<ListItem
					key={key}
					className={cx(
						{ [classes.item]: prop.iconName !== undefined },
						{ [classes.collapseItem]: prop.iconName === undefined },
						{ [classes.logo]: isProfile }
					)}
					style={{ marginBottom: isProfile ? "15px" : "unset" }}
				>
					<NavLink
						to={prop.layout + prop.path}
						onClick={async () => await handeleClickLink(prop.path)}
						className={cx(
							{ [navLinkClasses]: prop.iconName !== undefined },
							{ [innerNavLinkClasses]: prop.iconName === undefined }
						)}
					>
						{prop.iconName !== undefined ? (
							typeof prop.iconName === "string" ? (
								<Icon className={itemIcon}>{prop.iconName}</Icon>
							) : (
								<prop.iconName className={itemIcon} />
							)
						) : (
							<span>{rtlActive ? prop.rtlMini : prop.mini}</span>
						)}
						<ListItemText
							primary={rtlActive ? prop.rtlName : `${i18n.t(prop.name)}`}
							disableTypography={true}
							className={cx(
								{ [itemText]: prop.iconName !== undefined },
								{ [collapseItemText]: prop.iconName === undefined }
							)}
						/>
					</NavLink>
				</ListItem>
			);
		});
	};

	var links = (
		<List>
			{routes && perms && role ? (
				createLinks(routes)
			) : (
				<Box display="flex" justifyContent="center" color="white">
					<CircularProgress size={30} />
				</Box>
			)}
		</List>
	);

	const drawerPaper =
		classes.drawerPaper +
		" " +
		cx({
			[classes.drawerPaperMini]: props.miniActive && state.miniActive,
			[classes.drawerPaperRTL]: rtlActive
		});
	const sidebarWrapper =
		classes.sidebarWrapper +
		" " +
		cx({
			[classes.drawerPaperMini]: props.miniActive && state.miniActive,
			[classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
		});

	/******************************************* */
	/******************************************* */
	/*****************RETURN******************** */
	/******************************************* */
	/******************************************* */

	return (
		<div>
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor={rtlActive ? "left" : "right"}
					open={props.open}
					classes={{
						paper: drawerPaper + " " + classes[bgColor + "Background"]
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					<SidebarWrapper
						classes={classes}
						className={sidebarWrapper}
						links={links}
						handleOpenDisplayReleaseNotes={handleOpenDisplayReleaseNotes}
						rtlActive={rtlActive}
					/>
					{image !== undefined ? (
						<div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
					) : null}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					onMouseOver={() => setState({ ...state, miniActive: false })}
					onMouseOut={() => setState({ ...state, miniActive: true })}
					anchor={rtlActive ? "right" : "left"}
					variant="permanent"
					open
					classes={{
						paper: drawerPaper + " " + classes[bgColor + "Background"]
					}}
				>
					<SidebarWrapper
						classes={classes}
						className={sidebarWrapper}
						links={links}
						handleOpenDisplayReleaseNotes={handleOpenDisplayReleaseNotes}
						state={state}
						brandText={brandText}
						role={role}
					/>

					{image !== undefined ? (
						<div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
					) : null}
				</Drawer>
			</Hidden>
		</div>
	);
};

Sidebar.defaultProps = {
	bgColor: "blue"
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	miniActive: PropTypes.bool,
	open: PropTypes.bool,
	handleDrawerToggle: PropTypes.func
};

SidebarWrapper.propTypes = {
	className: PropTypes.string,
	user: PropTypes.object,
	headerLinks: PropTypes.object,
	links: PropTypes.object
};

export default withStyles(sidebarStyle)(Sidebar);
