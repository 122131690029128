/* eslint-disable react/react-in-jsx-scope */
import i18n from "i18n";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import cockpitActions from "redux/actions/cockpitActions";
import DictionaryExplorer from "../DictionaryExplorer";

const {
	TextField,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
	Typography,
	Dialog,
	Box,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button
} = require("@material-ui/core");
const { useState, useEffect } = require("react");

const MappingRow = ({ k: key, result, mapping, onChange }) => {
	const { assistantconfig } = useSelector(state => state);
	const [openDictionary, setOpenDictionary] = useState(false);
	const [dictionaryAnchor, setDictionaryAnchor] = useState(null);

	const [value, setValue] = useState(result[key]);

	const getLabel = label => {
		return label?.fr ?? "Aucun";
	};

	const getDictionaryPart = (path, dictionary, words = []) => {
		let splittedPath = path?.split(".") || [];
		let dictionaryPath = path?.replaceAll(".", ".items.");
		let item = lod_.get(dictionary, dictionaryPath);

		let title = getLabel(item?.label);
		words.push(title);

		splittedPath?.pop();

		if (splittedPath == 0) {
			return words.reverse().join(" / ");
		} else {
			return getDictionaryPart(splittedPath.join("."), dictionary, words);
		}
	};

	const getLabelFromDictionary = path => {
		let completePath = getDictionaryPart(path, assistantconfig.dictionary);
		return (
			<Typography
				style={{
					fontWeight: "bold"
				}}
			>
				{completePath}
			</Typography>
		);
	};

	useEffect(() => {
		onChange(key, key);
	}, []);

	return (
		<TableRow>
			<TableCell style={{ width: "auto" }}>
				<Box>
					<Typography>
						<TextField
							variant="outlined"
							margin="dense"
							fullWidth
							value={value}
							onChange={e => {
								setValue(e.target.value);
							}}
						/>
					</Typography>
				</Box>
			</TableCell>
			<TableCell style={{ width: "40%" }}>
				{getLabelFromDictionary(lod_.get(mapping, key, null))}
			</TableCell>
			<TableCell style={{ width: "10%" }}>
				<Button
					color="primary"
					variant="contained"
					onClick={e => {
						setDictionaryAnchor(e.currentTarget);
						setOpenDictionary(true);
					}}
				>
					Modifier
				</Button>
			</TableCell>

			<DictionaryExplorer
				open={openDictionary}
				position={{
					left: dictionaryAnchor?.getBoundingClientRect().left,
					top: dictionaryAnchor?.getBoundingClientRect().top
				}}
				handleClick={dictionaryPath => {
					onChange(key, dictionaryPath);
					setOpenDictionary(false);
				}}
				handleClose={() => {
					setOpenDictionary(false);
				}}
			/>
		</TableRow>
	);
};

const Mapping = ({ data, mapping, onChange }) => {
	let result = data?.result ?? {};

	if (lod_.isEmpty(result)) {
		return (
			<Box>
				<Typography
					variant="h6"
					style={{
						textAlign: "center",
						opacity: 0.5
					}}
				>
					Aucun résultat
				</Typography>
			</Box>
		);
	}

	return (
		<Box>
			<Table
				style={{
					width: "100%"
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell>Texte trouvé</TableCell>
						<TableCell>Enregistrer dans</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.keys(result).map((key, index) => {
						return (
							<MappingRow
								key={index}
								k={key}
								mapping={mapping}
								result={result}
								onChange={onChange}
							/>
						);
					})}
				</TableBody>
			</Table>
		</Box>
	);
};

const AnalyseDocumentLlmModal = ({ data = {}, open, onClose }) => {
	const { selectedConversation } = useSelector(state => state.cockpit);
	const dispatch = useDispatch();
	const [mapping, setMapping] = useState({});

	const onChange = (path, value) => {
		setMapping(map => {
			return {
				...map,
				[path]: value
			};
		});
	};

	const deleteResponse = () => {
		dispatch(
			cockpitActions.launchActionFrontV2("deleteConversationAction", {
				header: {
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID
				},
				payload: {
					actionUuid: data.uuid
				}
			})
		);
		onClose();
	};

	const submit = () => {
		let payload = {};

		Object.keys(mapping).forEach(key => {
			let value = lod_.get(data.result, key);
			lod_.set(payload, key, value);
		});

		const dataPayload = {
			header: {
				assistantID: selectedConversation?.header?.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload
		};

		dispatch(cockpitActions.launchActionFrontV2("updateConvDictionaryPartial", dataPayload));
		deleteResponse();
	};

	return (
		<Dialog
			fullWidth={true}
			onClose={onClose}
			maxWidth={"lg"}
			open={open}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle>{`Résultat de l'analyse de document`}</DialogTitle>
			<DialogContent>
				<Mapping data={data} mapping={mapping} onChange={onChange} />
			</DialogContent>
			<DialogActions
				style={{
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<Box>
					<Button style={{ color: "red" }} onClick={deleteResponse}>
						{i18n.t("COC.Delete")}
					</Button>
				</Box>
				<Box>
					<Button style={{ color: "red" }} onClick={onClose}>
						{i18n.t("TabAction.cancel")}
					</Button>
					<Button color="primary" autoFocus onClick={submit}>
						Valider
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default AnalyseDocumentLlmModal;
