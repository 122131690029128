import Cockpit from "../pages/Cockpit/Cockpit";
import Contacts from "../pages/Contacts/Contacts";
import Profile from "../pages/Profile/Profile";

const routes = [
	{
		path: "/profile",
		name: "Profile",
		iconName: "person",
		componentName: "Profile",
		layout: "/admin",
		state: "Profile",
		fromProfile: false,
		component: Profile
	},
	{
		path: "/cockpit",
		name: "Cockpit",
		iconName: "question_answer",
		componentName: "Cockpit",
		hasRole: ["supervisor", "agent", "soloUser"],
		layout: "/admin",
		state: "Cockpit",
		hasPerms: ["write_answer"],
		component: Cockpit
	},

	{
		path: "/contacts",
		name: "Contacts",
		iconName: "contacts",
		componentName: "Contacts",
		hasRole: ["agent", "supervisor", "soloUser"],
		layout: "/admin",
		state: "Contacts",
		component: Contacts
	},

	{
		path: "/cockpitArchived",
		name: "Archives", // i18n ?
		iconName: "archive",
		componentName: "Cockpit",
		hasRole: ["supervisor", "agent", "soloUser"],
		layout: "/admin",
		state: "Cockpit",
		hasPerms: ["write_answer"],
		component: Cockpit
	}
];
export default routes;
