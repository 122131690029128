import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Popover, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import cockpitActions from "../../redux/actions/cockpitActions";
import DisplayItem from "./DisplayItem";
import { loadSuggestions } from "./business/searchDataInCrmCommons";

const SearchDataCrmInput = ({ anchorEl, handleClose, handleSubmit, actionObject }) => {
	const [searchField, setSearchField] = useState("");
	const [suggestions, setSuggestions] = useState([]);

	const dispatch = useDispatch();

	const collection = actionObject.config.collection;
	const mappingOut = actionObject.config.mappingOut;
	const shownFields = actionObject.config.shownFields;
	const nextActionsFront = actionObject.config.nextActionsFront;
	const mustMerge = actionObject.config.mustMerge;

	const { assistantID, user, selectedConversation } = useSelector(state => {
		return {
			assistantID: state.assistantconfig.assistantID,
			user: state.userStatus.auth.user,
			selectedConversation: state.cockpit.selectedConversation
		};
	});

	function handleAutocompleteChange(event, newInputValue, reason) {
		// This componenent manages its action by itself, but still call handleSubmit to close actions tabs

		if (mappingOut && newInputValue && reason === "select-option") {
			const data = {
				header: {
					assistantID: selectedConversation?.header?.assistantID,
					fID: selectedConversation?.header?.fID
				},
				payload: {}
			};

			// test mappingOut type
			if (typeof mappingOut === "string") {
				data.payload[mappingOut] = newInputValue;
			}
			if (typeof mappingOut === "object" && !Array.isArray(mappingOut)) {
				// Format is the following

				// "mappingOut": {
				// 	"context.customer.area": "area"
				// 	"context.customer.name": "name",
				// }, // mappingOut Can be an array too if multi mapping

				for (let key in mappingOut) {
					data.payload[key] = newInputValue?.[mappingOut[key]];
				}
			}

			if (mustMerge !== undefined) {
				data.payload["mustMerge"] = mustMerge;
			}

			// Check FBDB-2495 - updateDictionary is optional - If true, update the dictionary (context, contact) and not context only in Cockpit (default false)
			const actionFront = !actionObject?.config?.updateDictionary
				? "updateConvContextPartial"
				: "updateConvDictionaryPartial";

			dispatch(cockpitActions.launchActionFrontV2(actionFront, data, nextActionsFront));
		}

		handleClose();
		handleSubmit({}, actionObject.action);
	}

	function handlInputChange(event) {
		setSearchField(event.target.value);
	}

	useEffect(() => {
		if (searchField) {
			const timeoutId = setTimeout(async () => {
				const suggestionsFetched = await loadSuggestions(
					assistantID,
					user,
					searchField,
					collection
				);
				setSuggestions(suggestionsFetched);
			}, 500);
			return () => clearTimeout(timeoutId);
		}
	}, [searchField]);

	return (
		<>
			<Popover
				open={true}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right"
				}}
			>
				<Box display="flex" flexDirection="row" alignItems="center" padding="10px">
					<Autocomplete
						id="autocomplete-recipient"
						freeSolo
						options={suggestions}
						style={{ minWidth: "300px" }}
						getOptionLabel={option => option.name}
						onChange={handleAutocompleteChange}
						filterOptions={(options, state) => options}
						renderOption={(suggestedValue, option) => DisplayItem(suggestedValue, shownFields)}
						renderInput={params => (
							<TextField
								{...params}
								label={actionObject.config.searchLabel || "Rechercher"}
								variant="outlined"
								size="medium"
								autoFocus={true}
								value={searchField}
								onChange={handlInputChange}
							/>
						)}
					/>
				</Box>
			</Popover>
		</>
	);
};

export default SearchDataCrmInput;
