/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import cockpitActions from "../../../../redux/actions/cockpitActions";
import ChannelUserInfoMembershipDisplayer from "helpers/ChannelUserInfoMembershipDisplayer";
import useCopyToClipboard from "helpers/useCopyToClipboard";
import { Box, Grid, IconButton, Tooltip, Badge } from "@material-ui/core";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { withStyles } from "@material-ui/core/styles";
import i18n from "i18n";
import { getHeaderConvItemSelector } from "redux/selectors/cockpitSelectors";
import { useSelector } from "react-redux";
import PriorityIntentSentimentDisplayer from "pages/Cockpit/PriorityIntentSentimentDisplayer";

const HeaderConvItemCOLD = forwardRef(
	(
		{ setDisplayHistory, convHistory, displayHistory, setSnackbarStatus, handleOpenRightMenu },
		ref
	) => {
		let { selectedConversation } = useSelector(getHeaderConvItemSelector, shallowEqual);

		return (
			<>
				<Box bgcolor="white" m={1}>
					<Grid container>
						<Grid item xs={4} container justifyContent="center" direction="row" alignItems="center">
							<ChannelUserInfoMembershipDisplayer
								convHistory={convHistory}
								channelImage={selectedConversation.meta?.cockpit?.channelCode || ""}
								channel={selectedConversation.header.channel}
								contactNameOrEmail={
									selectedConversation.payload?.contact || selectedConversation.header.contact?.uid
								}
								FRU={selectedConversation.header.contact?.uid}
								assistantID={selectedConversation.header.assistantID}
								fID={selectedConversation.header.fID}
								displayDialog={true}
							/>{" "}
						</Grid>
						<Grid item xs={4} container justifyContent="center" direction="row" alignItems="center">
							<PriorityIntentSentimentDisplayer
								title={selectedConversation.payload.title}
								parentName={"HeaderConvItemCOLD"}
							/>
						</Grid>
						<HeaderButtonTabInfoComponent handleOpenRightMenu={handleOpenRightMenu} xs={4} />
					</Grid>
					<Grid container>
						<Grid
							item
							xs={4}
							container
							justifyContent="flex-start"
							direction="row"
							alignItems="center"
						>
							<HeaderFIDComponent
								setSnackbarStatus={setSnackbarStatus}
								displayHistory={displayHistory}
								setDisplayHistory={setDisplayHistory}
								fID={selectedConversation.header.srcfID}
							/>
						</Grid>
						<Grid item xs={8} container justifyContent="center" direction="row" alignItems="center">
							<Box flexGrow={1} display="flex" justifyContent="flex-end" mr={1}>
								<HeaderArchivedStatusComponent />
							</Box>
						</Grid>
					</Grid>
				</Box>
			</>
		);
	}
);

export default HeaderConvItemCOLD;

//TODO: use these components in HeaderConvItem Too, to not duplicate exactly same code (and for better lisibilité)
/**
 *
 * @param {*} param0
 * @returns
 */
const HeaderButtonTabInfoComponent = ({ handleOpenRightMenu, xs }) => (
	<Grid item xs={xs} container justifyContent="flex-end" direction="row" alignItems="center">
		<Box component="span" mx={0.5}>
			<Tooltip title={i18n.t("COC.MenuOpenClose")} placement="top" onClick={handleOpenRightMenu}>
				<IconButton size="small">
					{" "}
					<MoreVertOutlinedIcon />{" "}
				</IconButton>
			</Tooltip>
		</Box>
	</Grid>
);

/**
 *
 * @returns
 */
const HeaderArchivedStatusComponent = () => (
	<Box
		display="flex"
		flexWrap="wrap"
		justifyContent="flex-end"
		alignItems="center"
		borderRadius="borderRadius"
		borderColor="#9e9e9e"
	>
		<Box fontWeight="fontWeightMedium" mr={1}>
			{i18n.t("COC.archived")}
		</Box>
		<Box
			style={{
				backgroundColor: "red",
				width: 10,
				height: 10,
				borderRadius: "50%",
				margin: "auto"
			}}
		></Box>
	</Box>
);

/**
 * 	//TODO: use reducer instead of these setters
 * @param {*} param0
 * @returns
 */
const HeaderFIDComponent = ({ setSnackbarStatus, displayHistory, setDisplayHistory, fID }) => {
	let { selectedConversation, historyLoading, countHistory } = useSelector(state => state.cockpit);

	const dispatch = useDispatch();

	const [, handleCopy] = useCopyToClipboard(2000);

	useEffect(() => {
		if (historyLoading) {
			handleShowHistory();
		}
	}, [historyLoading]);

	/**
	 * Get history count for selectedConversation && handle display history
	 */
	useEffect(() => {
		if (selectedConversation) {
			setDisplayHistory(false);
		}
	}, [selectedConversation]);

	/**
	 * Reset historyLoaded state and empty history conv when switching selected conversation
	 */
	useEffect(() => {
		dispatch(cockpitActions.EmptyHistory());
	}, [selectedConversation]);

	const handleShowHistory = () => {
		setDisplayHistory(!displayHistory);
		displayHistory === true
			? setSnackbarStatus({ open: true, message: i18n.t("COC.HideHistory") })
			: setSnackbarStatus({ open: true, message: i18n.t("COC.ShowHistory") });
	};

	const handleFetchHistory = () => {
		if (historyLoading) {
			return handleShowHistory();
		}
		dispatch(cockpitActions.FetchHistory(selectedConversation));
	};

	const saveFID = () => {
		handleCopy(fID);
		setSnackbarStatus({ open: true, message: `identifiant copié ` });
	};

	const StyledBadge = withStyles(theme => ({
		badge: {
			top: 0,
			border: `2px solid ${theme.palette.background.paper}`,
			padding: "0 4px",
			backgroundColor: "#545454"
		}
	}))(Badge);

	return (
		<Box display="flex" flexDirection="row" alignItems="center">
			{fID && (
				<Tooltip title={i18n.t("COC.CopyToClipboard")}>
					<p
						style={{ margin: "0 0 0 0", paddingTop: "3px" }}
						className="header_fid"
						onClick={saveFID}
					>
						{fID}
					</p>
				</Tooltip>
			)}
			{countHistory > 0 && (
				<Box>
					<Tooltip title={i18n.t("COC.History")} placement="right-start">
						<IconButton size="small" onClick={handleFetchHistory}>
							<StyledBadge badgeContent={countHistory} color="primary" showZero={false}>
								<HistoryOutlinedIcon fontSize="small" />
							</StyledBadge>
						</IconButton>
					</Tooltip>
				</Box>
			)}
		</Box>
	);
};
