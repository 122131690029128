/* eslint-disable react/react-in-jsx-scope */
import {
	Box,
	Card,
	CircularProgress,
	IconButton,
	Tooltip,
	Typography,
	makeStyles
} from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import { Check, Close, PriorityHigh, Settings, Visibility } from "@material-ui/icons";
import { handleSpecialDisplayDate } from "helpers/utilities";
import cockpitActions from "redux/actions/cockpitActions";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";

const useStyles = makeStyles(theme => ({
	iconStatus: {
		backgroundColor: green[500],
		color: "white",
		padding: 5,
		borderRadius: "50%",
		width: 24,
		height: 24,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: 16
	},
	wrapper: {
		margin: theme.spacing(1),
		position: "relative"
	},
	fabProgress: {
		color: orange[500],
		position: "absolute",
		top: 0,
		zIndex: 1
	}
}));

const GetCorrectIcon = ({ state }) => {
	const classes = useStyles();

	switch (state) {
		case "done":
			return (
				<div className={classes.wrapper}>
					<div className={classes.iconStatus}>
						<Check style={{ fontSize: 16 }} />
					</div>
				</div>
			);
		case "processing":
			return (
				<div className={classes.wrapper}>
					<div
						className={classes.iconStatus}
						style={{
							color: orange[500],
							backgroundColor: "white"
						}}
					>
						<Settings style={{ fontSize: 16 }} />
					</div>
					<CircularProgress size={24} className={classes.fabProgress} />
				</div>
			);
		case "cancelled":
			return (
				<div className={classes.wrapper}>
					<div
						className={classes.iconStatus}
						style={{
							backgroundColor: red[400]
						}}
					>
						<Close style={{ fontSize: 16 }} />
					</div>
				</div>
			);
		default:
			return (
				<div className={classes.wrapper}>
					<div
						className={classes.iconStatus}
						style={{
							backgroundColor: red[400]
						}}
					>
						<PriorityHigh style={{ fontSize: 16 }} />
					</div>
				</div>
			);
	}
};

const ConversationAction = ({ action, onResult }) => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(state => state.cockpit);
	const { userStatus } = useSelector(state => state);

	let dateAction = handleSpecialDisplayDate(
		action.createdAt,
		"LLL",
		userStatus.auth.user.language.toLowerCase()
	);

	const cancelAction = action => {
		dispatch(
			cockpitActions.launchActionFrontV2("deleteConversationAction", {
				header: {
					assistantID: selectedConversation.header.assistantID,
					fID: selectedConversation.header.fID
				},
				payload: {
					actionUuid: action.uuid
				}
			})
		);
	};

	return (
		<Box mt={1}>
			<Card>
				<Box
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					<Box
						p={1}
						style={{
							display: "flex",
							alignItems: "center"
						}}
					>
						{/* State icon */}
						<Box mr={2} ml={1}>
							<GetCorrectIcon state={action.state} />
						</Box>
						{/* Text */}
						<Box>
							<Typography variant="body1" fontSize="small" component="p" align="left">
								{i18n.t(`COC.ACTIONS.${action.code}`)}
							</Typography>
							<Typography
								variant="body2"
								fontSize="small"
								component="p"
								align="left"
								style={{ opacity: 0.7 }}
							>
								{`Démarrée le ${dateAction}`}
							</Typography>
						</Box>
					</Box>
					<Box
						mr={2}
						style={{
							display: "flex",
							alignItems: "center"
						}}
					>
						<Tooltip placement="top" title="Annuler l'action">
							<span>
								<IconButton
									disabled={action.state !== "processing"}
									onClick={() => cancelAction(action)}
								>
									<Close style={{ fontSize: 20 }} />
								</IconButton>
							</span>
						</Tooltip>
						<Tooltip placement="top" title="Voir le résultat">
							<span>
								<IconButton
									disabled={action.state !== "done"}
									onClick={() => {
										onResult(action);
									}}
								>
									<Visibility style={{ fontSize: 20 }} />
								</IconButton>
							</span>
						</Tooltip>
					</Box>
				</Box>
			</Card>
		</Box>
	);
};

export default ConversationAction;
