export default {
	UPDATE_CONV: "UPDATE_CONV",
	SET_FORCE_SELECT_CONVERSATION: "SET_FORCE_SELECT_CONVERSATION",
	UPDATE_CONVERSATIONS: "UPDATE_CONVERSATIONS",
	ADD_MESSAGE_EVENT: "ADD_MESSAGE_EVENT",
	ADD_MESSAGE: "ADD_MESSAGE",
	UPDATE_CONVERSATION_EVENT: "UPDATE_CONVERSATION_EVENT",
	UPDATE_CONVERSATION_NOTES_EVENT: "UPDATE_CONVERSATION_NOTES_EVENT",
	POST_DRAFT: "POST_DRAFT",
	POST_DRAFT_LOADING: "POST_DRAFT_LOADING",
	UPDATE_DOCUMENTS: "UPDATE_DOCUMENTS",
	ADD_CONVERSATION_EVENT: "ADD_CONVERSATION_EVENT",
	ASSIGN_CONVERSATION: "ASSIGN_CONVERSATION",
	REJECT_CONVERSATION: "REJECT_CONVERSATION",
	SEND_ANSWER: "SEND_ANSWER",
	POST_NOTE: "POST_NOTE",
	POST_NEW_STATE: " POST_NEW_STATE",
	SET_CLIPBOARD: " SET_CLIPBOARD",
	UPDATE_HISTORY: "UPDATE_HISTORY",
	COUNT_HISTORY: "COUNT_HISTORY",
	DELETE_NOTE: "DELETE_NOTE",
	ESCALATE_CONVERSATION: "ESCALATE_CONVERSATION",
	DEESCALATE_CONVERSATION: "DEESCALATE_CONVERSATION",
	UPDATE_ESCALATION_EVENT: "UPDATE_ESCALATION_EVENT",
	REDIRECT_CONVERSATION_EVENT: "REDIRECT_CONVERSATION_EVENT",
	RATE_IA_ATTEMPT: "RATE_IA_ATTEMPT",
	BLOCK_CONVERSATION: "BLOCK_CONVERSATION",
	UPDATE_SUBSCRIBEDGROUPS: "UPDATE_SUBSCRIBEDGROUPS",
	REDIRECT_TO_USER: "REDIRECT_TO_USER",
	REDIRECT_TO_GROUP: "REDIRECT_TO_GROUP",
	ADD_ATTACHMENT: "ADD_ATTACHMENT",
	UPDATE_ATTACHMENT_PROGRESS: "UPDATE_ATTACHMENT_PROGRESS",
	CLEAR_ATTACHMENTS: "CLEAR_ATTACHMENTS",
	DELETE_ATTACHMENT: "DELETE_ATTACHMENT",
	REMOVE_ATTACHMENT_WHEN_ERROR: "REMOVE_ATTACHMENT_WHEN_ERROR",
	SET_CONVS_NUMBERS: "SET_CONVS_NUMBERS",
	START_TO_FETCH_CONVS: "START_TO_FETCH_CONVS",
	ADD_CONVERSATIONS: "ADD_CONVERSATIONS",
	ADD_CONVERSATIONS_RESOLVED: "ADD_CONVERSATIONS_RESOLVED",
	SET_ASC_FILTER: "SET_ASC_FILTER",
	SET_SUPERVISOR_FILTER: "SET_SUPERVISOR_FILTER",
	SET_AGENT_FILTER: "SET_AGENT_FILTER",
	SET_CONV_AGENT_FETCHED_ONGOING: "SET_CONV_AGENT_FETCHED_ONGOING",
	SET_CONV_AGENT_FETCHED_RESOLVED: "SET_CONV_AGENT_FETCHED_RESOLVED",
	SELECT_CONVERSATION: "SELECT_CONVERSATION",
	CLEAR_CONVERSATION: "CLEAR_CONVERSATION",
	DELETE_CONVERSATION: "DELETE_CONVERSATION",
	DELETE_CONVERSATIONS: "DELETE_CONVERSATIONS",
	EMPTY_HISTORY: "EMPTY_HISTORY",
	UPDATE_MESSAGE_STATE_EVENT: "UPDATE_MESSAGE_STATE_EVENT",
	ADD_HTML_TO_MESSAGE: "ADD_HTML_TO_MESSAGE",
	ADD_HTML_TO_MESSAGE_IN_FEEDBACK: "ADD_HTML_TO_MESSAGE_IN_FEEDBACK",
	SET_LEFT_TAB: "SET_LEFT_TAB",
	DOWNLOAD_ZIP: "DOWNLOAD_ZIP",
	GET_FEEDBACKS: "GET_FEEDBACKS",
	EMPTY_CONV_STATE: "EMPTY_CONV_STATE",
	UPDATE_FEEDBACK: "UPDATE_FEEDBACK",
	GET_CONFIG: "GET_CONFIG",
	SELECT_DEFAULT_CHANNEL: "SELECT_DEFAULT_CHANNEL",
	RESET_OPEN_ENGAGE: "RESET_OPEN_ENGAGE",
	UPDATE_CONTACT: "UPDATE_CONTACT",
	UPDATE_CONV_CONTACT: "UPDATE_CONV_CONTACT",
	UPDATE_MESSAGE_INTENT: "UPDATE_MESSAGE_INTENT",
	GET_CONVERSATION: "GET_CONVERSATION",
	SET_IS_COCKPIT_ARCHIVED: "SET_IS_COCKPIT_ARCHIVED",
	CLEAR_CONTENT: "CLEAR_CONTENT",
	ADD_CONTENT: "ADD_CONTENT",
	SET_TOPIC_DRAFT: "SET_TOPIC_DRAFT",
	CONV_LOADING: "CONV_LOADING",
	CONV_SENDING: "CONV_SENDING",
	CONV_SEND_SUCCESS: "CONV_SEND_SUCCESS",
	CONV_GET_MID: "CONV_GET_MID",
	SET_CONTENT_MESSAGE: "SET_CONTENT_MESSAGE",
	CLEAR_CONTENT_MESSAGE: "CLEAR_CONTENT_MESSAGE",
	UPDATE_AMAZON_DISPUTE: "UPDATE_AMAZON_DISPUTE",
	UPDATE_AMAZON_REQUEST_PROOF: "UPDATE_AMAZON_REQUEST_PROOF",
	UPDATE_AMAZON_REQUEST_PROOF_ISSENT: "UPDATE_AMAZON_REQUEST_PROOF_ISSENT",
	SELECT_AMAZON_REQUEST_PROOF_CONVERSATION: "SELECT_AMAZON_REQUEST_PROOF_CONVERSATION",
	ADD_AMAZON_FID_TO_CASE: "ADD_AMAZON_FID_TO_CASE",
	ADD_BL: "ADD_BL",
	ADD_POD: "ADD_POD",
	CLEAR_BL: "CLEAR_BL",
	CLEAR_POD: "CLEAR_POD",
	DELETE_BL: "DELETE_BL",
	DELETE_POD: "DELETE_POD",
	HISTORY_LOADING: "HISTORY_LOADING",
	UPDATE_CONV_MARKETPLACE: "UPDATE_CONV_MARKETPLACE",
	SCROLL_TO_SELECTED_CONVERSATION: "SCROLL_TO_SELECTED_CONVERSATION",
	CLEAR_SCROLL_TO_SELECTED_CONVERSATION: "CLEAR_SCROLL_TO_SELECTED_CONVERSATION",
	SET_SUBJECT: "SET_SUBJECT",
	SET_URGENT: "SET_URGENT",
	SET_CURRENT_CONVS_NUMBERS: "SET_CURRENT_CONVS_NUMBERS",
	SET_CONV_INTENT_FILTER: "SET_CONV_INTENT_FILTER",
	SET_CONV_GROUP_FILTER: "SET_CONV_GROUP_FILTER",
	SET_CONV_USER_FILTER: "SET_CONV_USER_FILTER",
	SET_CONV_NO_BOT_FILTER: "SET_CONV_NO_BOT_FILTER",
	SET_CONV_DIRECTION_FILTER: "SET_CONV_DIRECTION_FILTER",
	SET_CONV_PATH_CONTEXT_FILTER: "SET_CONV_PATH_CONTEXT_FILTER",
	SET_CONV_SEARCH_FILTER: "SET_CONV_SEARCH_FILTER",
	SET_CONV_START_DATE_FILTER: "SET_CONV_START_DATE_FILTER",
	SET_CONV_END_DATE_FILTER: "SET_CONV_END_DATE_FILTER",
	SET_CONV_PRIORITY_FILTER: "SET_CONV_PRIORITY_FILTER",
	SET_CONV_SENTIMENT_FILTER: "SET_CONV_SENTIMENT_FILTER",
	SET_CONV_SUBSTATE_FILTER: "SET_CONV_SUBSTATE_FILTER",
	SET_CONV_CONNECTED_FILTER: "SET_CONV_CONNECTED_FILTER",
	SET_CONV_ESCALATE_FILTER: "SET_CONV_ESCALATE_FILTER",
	SET_CONV_LANGUAGE_FILTER: "SET_CONV_LANGUAGE_FILTER",
	SET_CONV_CHANNEL_FILTER: "SET_CONV_CHANNEL_FILTER",
	RESET_FILTERS: "RESET_FILTERS",
	LOAD_FILTERS: "LOAD_FILTERS",
	SET_IS_COLD: "SET_IS_COLD",
	SET_COLD_CONVS_NUMBER: "SET_COLD_CONVS_NUMBER",
	NOT_DISPLAYABLE_ANSWER_ITEM: ["content_message_pdf", "header_pdf"],
	SET_CONVS_RELOAD: "SET_CONVS_RELOAD",
	RESET_COCKPIT: "RESET_COCKPIT",
	UPDATE_CONTEXT: "UPDATE_CONTEXT",
	INSERT_HTML: "INSERT_HTML",
	CLEAR_INSERT_HTML: "CLEAR_INSERT_HTML",
	DELETE_DRAFT: "DELETE_DRAFT",
	SET_ATTACHMENT_DRAFT: "SET_ATTACHMENT_DRAFT",
	UPDATE_CONV_STATE: "UPDATE_CONV_STATE",
	UPDATE_CONVERSATION_CONTEXT: "UPDATE_CONVERSATION_CONTEXT",
	UPDATE_CONV_CONTEXT_AND_CONTACT: "UPDATE_CONV_CONTEXT_AND_CONTACT",
	UPDATE_CONV_LIST_DISPLAY_STATUS: "UPDATE_CONV_LIST_DISPLAY_STATUS",
	COCKPIT_ANALYTICS: "COCKPIT_ANALYTICS"
};
