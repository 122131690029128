/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	ListItem,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import getVinatisPossibleStates from "../../../../helpers/clientSpecific/vinatis";

/**
 * Vinatis specific: The dialog to update an order, with the orderID, the employeeID,
 * and the target state for the order
 */
function UpdateOrderDialog({ action, handleClose, handleSubmit, orderID, employeeID }) {
	const possibleStatesVinatis = getVinatisPossibleStates();
	const [open] = React.useState(true);
	const [targetState, setTargetState] = useState(Object.keys(possibleStatesVinatis)[0]);

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return (
		<div>
			<Dialog
				fullWidth={true}
				onClose={handleClose}
				maxWidth={"sm"}
				open={open}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Mettre à jour la commande</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={3} style={{ padding: "12px" }}>
						<Grid container direction="row" alignItems="center">
							<ListItem style={{ flex: "1" }}>
								<TextField
									label={i18n.t("TabAction.commandNumber")}
									value={orderID}
									fullWidth
									disabled
								/>
							</ListItem>
							<ListItem style={{ flex: "1" }}>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label">Etat cible</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={targetState}
										label="Age"
										onChange={evt => {
											setTargetState(evt.target.value);
										}}
									>
										{Object.keys(possibleStatesVinatis).map(stateKey => {
											return (
												<MenuItem key={stateKey} value={stateKey}>
													{capitalizeFirstLetter(possibleStatesVinatis[stateKey])}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</ListItem>
						</Grid>
					</Grid>
					<Grid
						item
						container
						direction="row"
						justifyContent="flex-end"
						style={{ paddingTop: "2%" }}
					>
						<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
							{i18n.t("CD.cancel")}
						</Button>
						<Button
							onClick={() => handleSubmit({ orderID, targetState, employeeID }, action)}
							style={{ margin: "2%" }}
							color="primary"
						>
							{i18n.t("CD.validate")}
						</Button>
					</Grid>
				</DialogContent>
			</Dialog>
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(UpdateOrderDialog);
