/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "../../CockpitStyleJs/styleFilter";
import Tooltip from "@material-ui/core/Tooltip";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import { setUserOpenDialog } from "redux/reducers/filtersReducers";
import { setFormSelected } from "redux/reducers/filtersReducers";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import { Chip, Grid } from "@mui/material";
import DoneIcon from "@material-ui/icons/Done";
import { TagsList } from "components/Tags/TagsList";
import C from "constants/cockpit.js";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SearchIcon from "@material-ui/icons/Search";
import PeopleIcon from "@material-ui/icons/People";
import _lod from "lodash";
import { setApplyFiltersToUsersOpenDialog } from "redux/reducers/filtersReducers";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import userStatusActions from "../../../../redux/actions/userStatusActions";
import { InputAdornment, TextField } from "@material-ui/core";

/**
 *
 * @returns
 */
export function ApplyFiltersToUsersDialog() {
	const { users } = useSelector(state => state.userGroup);
	const { applyFiltersToUsersOpenDialog } = useSelector(state => state.filters);
	const {
		convIntentFilter,
		convUserFilter,
		convPriorityFilter,
		convSentimentFilter,
		convSubstateFilter,
		convLanguageFilter,
		convChannelFilter,
		convAscFilter,
		convSupervisorFilter,
		convAgentFilter,
		convGroupFilter,
		convConnectedFilter,
		convSearchFilter,
		convStartDateFilter,
		convEndDateFilter
	} = useSelector(state => state.cockpit);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	const dispatch = useDispatch();
	const [selected, setSelected] = useState([]);
	const [search, setSearch] = React.useState("");

	const isAllUsersChecked = selected.length === users.length;

	/**
	 *
	 */
	const handleCloseUserFilterDialog = () => {
		dispatch(setApplyFiltersToUsersOpenDialog(false));
	};

	useEffect(() => {
		if (applyFiltersToUsersOpenDialog === false) {
			setSearch("");
		}
	}, [applyFiltersToUsersOpenDialog]);

	/**
	 *
	 * @param {*} value
	 */
	const toggleSelectAll = value => {
		if (isAllUsersChecked) {
			setSelected([]);
		} else {
			setSelected(users);
		}
	};

	const handleSaveFilters = selected => {
		let dataToUpdate;
		dataToUpdate = {
			assistantID: selectedAssistantID,
			defaultListDisplayOrder:
				selectedAssistantID?.cockpit?.conv_list_display_order &&
				selectedAssistantID.cockpit.conv_list_display_order === "asc"
					? false
					: true,
			filters: {
				convIntentFilter,
				convUserFilter,
				convPriorityFilter,
				convSentimentFilter,
				convSubstateFilter,
				convLanguageFilter,
				convChannelFilter,
				convAscFilter,
				convSupervisorFilter,
				convAgentFilter,
				convGroupFilter,
				convConnectedFilter,
				convSearchFilter,
				convStartDateFilter,
				convEndDateFilter
			}
		};
		let userEmails = [];
		selected.forEach(user => userEmails.push(user._id));

		dispatch(
			userStatusActions.saveUsersFilter(
				userEmails,
				dataToUpdate,
				() => {
					dispatch(infoMsg(i18n.t("COC.filtersSaved")));
				},
				() => {
					dispatch(errorMsg(i18n.t("COC.errorFiltersSaved")));
				}
			)
		);
		handleCloseUserFilterDialog();
	};

	/**
	 *
	 * @param {*} event
	 */
	const handleSearchChange = event => {
		setSearch(event.target.value);
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={applyFiltersToUsersOpenDialog}
			onClose={() => handleCloseUserFilterDialog()}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				{i18n.t("COC.applyFilters")}
				<span style={{ float: "right" }}>
					{/* Select all toggle */}
					<Chip
						label={isAllUsersChecked ? i18n.t("COC.deselectAll") : i18n.t("COC.selectAll")}
						color={isAllUsersChecked ? "default" : "primary"}
						deleteIcon={isAllUsersChecked ? <HighlightOffIcon /> : <DoneIcon />}
						clickable={true}
						onClick={() => {
							toggleSelectAll();
						}}
						onDelete={() => {
							toggleSelectAll();
						}}
					/>
				</span>
			</DialogTitle>
			<DialogContent dividers>
				<Box m={1}>
					<TextField
						fullWidth
						label=""
						variant="outlined"
						size="small"
						onChange={handleSearchChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
				</Box>
				<Grid container>
					<TagsList
						list={users
							.filter(user => {
								if (search.trim().length >= 0) {
									let fullSearch = (user.name || "") + " " + (user.surname || "") + " " + user._id;
									return fullSearch.toLowerCase().includes(search.toLowerCase());
								} else {
									return true;
								}
							})
							.sort((a, b) => a.name.localeCompare(b.name))}
						labelField={"name"}
						surnameField={"surname"}
						titleField={"_id"}
						valueField={"_id"}
						isForm={true}
						selected={selected}
						setSelected={setSelected}
						isUser
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleCloseUserFilterDialog()} color="secondary">
					{`${i18n.t("COC.Cancel")}`}
				</Button>
				<Button
					disabled={selected.length === 0}
					onClick={() => handleSaveFilters(selected)}
					color="primary"
				>
					{`${i18n.t("COC.Apply")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
