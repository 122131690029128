import { replaceInText } from "helpers/utilsFromBack";

export function createEngageBusiness(conversation, actionObject) {
	const returnMissingData = {
		success: false,
		warn: "Il manque des données"
	};

	try {
		const channelCode = conversation.header.extra.channelCode;
		const initialDraft = {};
		if (actionObject.config.engageLinkContact) {
			const replaceEngageLinkContact = replaceInText(actionObject.config.engageLinkContact, {
				conversation: conversation
			});
			if (replaceEngageLinkContact.error) {
				return returnMissingData;
			}
			initialDraft["engageLinkContact"] = replaceEngageLinkContact.data;
		}
		if (actionObject.config.copyContext) {
			initialDraft["copyContext"] = actionObject.config.copyContext;
		}
		if (actionObject.config.topic) {
			initialDraft["topic"] = actionObject.config.topic;
		}
		if (actionObject.config.priority) {
			initialDraft["priority"] = actionObject.config.priority;
		}
		if (Array.isArray(actionObject.config.cc)) {
			let replaceError = false;
			initialDraft["emailCC"] = actionObject.config.cc.map(cc => {
				const ccReplace = replaceInText(cc, {
					conversation: conversation
				});
				if (ccReplace.error) {
					replaceError = true;
				}
				return ccReplace.data;
			});
			if (replaceError) {
				return returnMissingData;
			}
		}
		if (actionObject.config.answerCode) {
			initialDraft["answerCode"] = actionObject.config.answerCode;
		}

		return { success: true, channelCode, initialDraft };
	} catch (error) {
		return { success: false, warn: "Erreur inconnue" };
	}
}
