import { handleSpecialDisplayDate } from "helpers/utilities";
import axios from "axios";
import { traceError } from "../../helpers/utilities";

const endPoint = process.env.REACT_APP_AMBACK + "/api/v1/";

/**
 * Get statistics and format them
 * @param {int} range how many days to display before today
 */
const getDatas = (selectedUser, assistantID, range) => {
	var today = new Date();
	var min_day = String(today.getDate() - (range - 1)).padStart(2, "0");
	var min_month = String(today.getMonth() + 1).padStart(2, "0");
	var min_year = today.getFullYear();

	var newDate = new Date(min_year, min_month - 1, min_day);

	var minTimeStamp = newDate.getTime();
	var maxTimeStamp = Date.now();

	fetch(selectedUser, assistantID, maxTimeStamp, minTimeStamp);
};

/**
 * Axios request to get messages in timestamp interval
 * @param {Int} maxTs max timestamp
 * @param {Int} minTs min timestamp
 * @returns
 */
const fetch = async (selectedUser, assistantID, maxTs, minTs) => {
	let url = `${endPoint}profileStatistics`;

	try {
		const request = await axios.post(
			url,
			{
				assistantID: assistantID,
				minTs: minTs,
				maxTs: maxTs,
				agent: selectedUser.email
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `${selectedUser.token}`,
					UserEmail: selectedUser.email
				}
			}
		);
		return request;
	} catch (err) {
		traceError(`fetch-Message ${selectedUser?.email} failed: ${err.message}`);
	}
};

export default {
	fetch,
	getDatas
};
