import { changeFavicon } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { getNotificationSelector } from "redux/selectors/notificationSelector";
import { shallowEqual, useSelector } from "react-redux";

const basicFavicon = "/fAIbrik_favicon.png";
const notificationFavicon = "/fAIbrik_favicon_notif.png";

// This component is in charge of managing the notifications to update the favicon on certain events
// The favicon must stay the same, original one, if user is focused on the page
const NotificationFavicon = props => {
	const { lastNotification } = useSelector(getNotificationSelector, shallowEqual);
	const [isFocus, setIsFocus] = useState(true);

	useEffect(() => {
		if (!isFocus) {
			changeFavicon(notificationFavicon);
		}
	}, [lastNotification]);

	useEffect(() => {
		if (isFocus) {
			changeFavicon(basicFavicon);
		}
	}, [isFocus]);

	// Event when user is focus on tab
	const onFocus = () => {
		setIsFocus(true);
	};

	// Event when user is not focus on tab
	const onBlur = () => {
		setIsFocus(false);
	};

	useEffect(() => {
		// Component initialization
		window.addEventListener("focus", onFocus);
		window.addEventListener("blur", onBlur);

		// Calls onFocus when the window first loads
		onFocus();
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener("focus", onFocus);
			window.removeEventListener("blur", onBlur);
		};
	}, []);

	return null;
};

export default NotificationFavicon;
