import { Box, Grid, Grow, IconButton, List, ListItem, Paper, Tooltip } from "@material-ui/core";
import React from "react";
import ActionEventMessageItem from "./ActionEventMessageItem";
import { Visibility } from "@material-ui/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMessageStyle } from "./functions";
import { t } from "i18next";
import { getMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import cockpitActions from "redux/actions/cockpitActions";

/**
 * Moved message component
 * @param {*} param0
 * @returns
 */
const MovedMessage = ({ message }) => {
	const dispatch = useDispatch();
	const { selectedConversation } = useSelector(getMesssageItemSelector, shallowEqual);

	const fullWidthDisplay =
		message?.header?.channel?.type === "AV" && message?.header?.source === "contact";

	const { paperStyle, justifyDirection } = getMessageStyle(message);

	return (
		<ListItem>
			<Grow in={true}>
				<Box width={1} display="flex" justifyContent={justifyDirection}>
					<Box
						display="flex"
						flexDirection="column"
						maxWidth={fullWidthDisplay ? "100%" : "80%"}
						width={fullWidthDisplay ? "100%" : "unset"}
					>
						<Box display="flex" alignItems="flex-end">
							<Paper style={{ ...paperStyle }} elevation={3}>
								<React.Fragment>
									<Box fontStyle="italic" p={1} display="flex" alignItems="center">
										<Box flexGrow={1}>
											<List style={{ padding: 0 }}>
												<ActionEventMessageItem data={message}></ActionEventMessageItem>
											</List>
											<Grid container display="flex" justifyContent="flex-end">
												<Tooltip title={t("COC.seeMessage")} placement="bottom">
													<IconButton
														size="small"
														onClick={() => {
															dispatch(
																cockpitActions.selectConversation({
																	header: {
																		assistantID: selectedConversation.header.assistantID,
																		fID: message.payload?.header?.context?.movedInfoTo?.to?.fID
																	}
																})
															);
														}}
													>
														<Visibility />
													</IconButton>
												</Tooltip>
											</Grid>
										</Box>
									</Box>
								</React.Fragment>
							</Paper>
						</Box>
					</Box>
				</Box>
			</Grow>
		</ListItem>
	);
};

export default MovedMessage;
