/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Box from "@material-ui/core/Box";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import ImageSearchOutlinedIcon from "@material-ui/icons/ImageSearchOutlined";
import { getACleanURI } from "./utilities";
import { Grid } from "@material-ui/core";
import AttachmentPreview from "./AttachmentPreview";

export default props => {
	return (
		<Box maxWidth="100%" width="100%" p={1}>
			<fieldset
				style={{
					borderRadius: "4px",
					borderColor: "#EEEEEE",
					minWidth: "0"
				}}
			>
				<legend style={{ fontSize: "1em" }}>
					<b>
						<Box display="flex" flexDirection="row" alignItems="center">
							<span style={{ fontSize: "16px", fontFamily: "Roboto" }}>{props.title}</span>
							{""}
							{props.allAttachments.length > 0 && (
								<Box display="flex" flexDirection="row" alignItems="center">
									{/* DownloadIcon */}
									<Tooltip
										title={props.downloadAll}
										placement="top"
										onClick={!props.inProgress ? props.handleFetchReceiveAllAtt : undefined}
									>
										<IconButton
											style={{
												paddingLeft: "16px",
												paddingRight: "8px",
												color: "rgba(0, 0, 0, 0.54)",
												borderColor: "transparent",
												backgroundColor: "transparent"
											}}
											size="small"
										>
											{!props.inProgress ? (
												<SystemUpdateAltOutlinedIcon size="small" />
											) : (
												<CircularProgress size="14px" />
											)}
										</IconButton>
									</Tooltip>

									{/* Show Inline Filter Icon */}
									{props.showSmallInlineFilterButton === true && (
										<Box>
											<ToggleButtonGroup size="small">
												<Tooltip
													title={
														props.activeInlineFilter === true
															? props.showSmallInline
															: props.hideSmallInline
													}
													placement="top"
												>
													<ToggleButton
														onClick={props.handleActiveInlineFilter}
														fontSize="small"
														value={props.activeInlineFilter}
														style={{
															paddingLeft: "8px",
															paddingRight: "16px",
															color: "rgba(0, 0, 0, 0.54)",
															borderColor: "transparent",
															backgroundColor: "transparent"
														}}
													>
														{props.activeInlineFilter === true ? (
															<ImageSearchOutlinedIcon />
														) : (
															<CropOriginalIcon />
														)}
													</ToggleButton>
												</Tooltip>
											</ToggleButtonGroup>
										</Box>
									)}
								</Box>
							)}
						</Box>
					</b>
				</legend>

				{/* Display 2 attachments in a row */}
				<Grid container spacing={1}>
					{props.attachmentsFiltered.length > 0 ? (
						props.attachmentsFiltered.map((child, index) => {
							return (
								<Grid item xs={6} key={`attachment-${index}`}>
									<AttachmentPreview
										attachement={child}
										mID={child.mID}
										url={getACleanURI(child.url, "browser")}
										download={props.customDownloadProcess}
									/>
								</Grid>
							);
						})
					) : (
						<div style={{ fontSize: "16px", fontFamily: "Roboto" }}>{props.emptyMsg}</div>
					)}
				</Grid>
			</fieldset>
		</Box>
	);
};
