/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import {
	Box,
	Button,
	Grid,
	Tooltip,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Select,
	InputLabel,
	FormControl,
	MenuItem,
	TextField
} from "@material-ui/core";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "hooks/useForm";
import CardFooter from "components/Card/CardFooter";
import { Form } from "hooks/useForm";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Controls from "components/Controls/Controls";
import { Help } from "@material-ui/icons";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import useCopyToClipboard from "helpers/useCopyToClipboard";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import ConfirmationDialog from "components/ConfirmationDialog";

// Function Recusive to have child for front
const RecursiveComponent = ({
	configChild,
	nameChild,
	values,
	handleInputChangeValidate,
	userLanguage,
	root,
	customErrors,
	PI,
	FRU
}) => {
	const renderChildren = () => {
		return Object.entries(configChild).map(([childKey, childValue]) => {
			if (typeof childValue === "object") {
				const rootPath = root === "" ? childKey : `${root}.${childKey}`;

				return (
					<React.Fragment key={rootPath}>
						{childValue.type !== "level" ? (
							<InpuType
								child={`${nameChild}.${rootPath}`}
								PI={PI}
								key={`${nameChild}.${rootPath}`}
								type={childValue.type}
								whitelist={childValue.whitelist}
								whitelistDynamic={childValue.whitelistDynamic}
								label={childValue[userLanguage] || childKey}
								FRU={FRU}
								value={lod_.get(values, `${nameChild}.${rootPath}`)}
								customErrors={customErrors[`${nameChild}.${rootPath}`]}
								onChange={e => handleInputChangeValidate(e, childValue, `${nameChild}.${rootPath}`)}
							/>
						) : (
							<RecursiveComponent
								values={values}
								configChild={childValue}
								handleInputChangeValidate={handleInputChangeValidate}
								userLanguage={userLanguage}
								nameChild={nameChild}
								root={rootPath}
								FRU={FRU}
								customErrors={customErrors}
							/>
						)}
					</React.Fragment>
				);
			}
			return null;
		});
	};

	return (
		<Accordion style={{ paddingTop: "10px" }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{configChild[userLanguage]}
			</AccordionSummary>
			<AccordionDetails>{renderChildren()}</AccordionDetails>
		</Accordion>
	);
};

const InpuType = ({
	child,
	key,
	label,
	value,
	onChange,
	customErrors,
	type,
	PI,
	isEmpty,
	FRU,
	whitelist = false,
	whitelistDynamic = false
}) => {
	const [, handleCopy] = useCopyToClipboard(2000);
	const dispatch = useDispatch();
	const copyValue = valueCopy => {
		handleCopy(valueCopy);
		dispatch(infoMsg(i18n.t("COC.CopyToClipboard")));
		//	setSnackbarStatus({ open: true, message: `identifiant copié ` });
	};
	let enable = PI;
	if (isEmpty) {
		enable = true;
	}

	const assistantIDs = useSelector(state => state?.assistantconfig?.assistantID);
	let apiSettings = useSelector(state => state?.assistantconfig?.APIs);
	const assistantConfig = useSelector(state => state?.assistantconfig);
	const user = useSelector(state => state?.userStatus?.auth?.user);
	const crmBackEndpoint = process.env.REACT_APP_CRMBACK;

	async function loadSuggestions(apiKey, searchText, collection, searchFields) {
		let querryData = [];
		searchFields.forEach(field => {
			const query = {};
			query[field] = { $regex: searchText };
			querryData.push(query);
		});

		let data = JSON.stringify({
			query: {
				$or: querryData
			}
		});
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `${crmBackEndpoint}/apiExt/v1/ressource/${collection}/items`,
			headers: {
				apiKey,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			},
			data: data
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {});
		return result || [];
	}

	const [valueAuto, setValueAuto] = useState(value);
	const [optionAuto, setOptionAuto] = useState([]);

	const getOptionLabel = option => {
		const label = whitelistDynamic.params.searchFields.map(key => option[key]).join(" - ");
		return label;
	};

	const onChangeCRM = (event, newValue, option) => {
		if (option === "select-option") {
			setValueAuto(newValue[whitelistDynamic.params.attribute]);
			event = { target: { name: child, value: newValue[whitelistDynamic.params.attribute] } };
			onChange(event);
		}
	};

	const ValueChange = async valueC => {
		setValueAuto(valueC);

		let apiConfig = apiSettings.filter(key => key.name === whitelistDynamic.apiName)[0].config;
		let apiKeyConfig = apiConfig.apiKey;

		let result = await loadSuggestions(
			apiKeyConfig,
			valueC,
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields
		);

		setOptionAuto(result);
	};

	const whiteListItem = () => {
		if (whitelist && !lod_.isEmpty(whitelist)) {
			return (
				<FormControl style={{ width: "100%" }}>
					<InputLabel id="select-label">{label}</InputLabel>
					<Select
						labelId="select-label"
						id="select"
						value={value || ""}
						onChange={onChange}
						name={child}
						disabled={!enable}
					>
						{whitelist.map((option, index) => (
							<MenuItem key={index} value={option}>
								{option}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		} else if (whitelistDynamic && !lod_.isEmpty(whitelistDynamic)) {
			return (
				<div>
					<Autocomplete
						id="auto"
						disablePortal
						name={child}
						key={key}
						disabled={!enable}
						options={optionAuto}
						onChange={onChangeCRM}
						getOptionLabel={getOptionLabel}
						inputValue={valueAuto}
						renderOption={(suggestedValue, option) =>
							DisplayItem(suggestedValue, whitelistDynamic.params.searchFields)
						}
						renderInput={params => (
							<TextField {...params} label={label} onChange={e => ValueChange(e.target.value)} />
						)}
					/>
				</div>
			);
		} else {
			return (
				<Controls.Input
					name={child}
					key={key}
					label={label}
					value={value}
					onClick={() => copyValue(value)}
					onChange={onChange}
					disabled={!enable}
				/>
			);
		}
	};

	if (type === "phone") {
		return (
			<>
				<Controls.Phone
					name={child}
					label={i18n.t("CONTACT.phone")}
					key={key}
					defaultCountry={"fr"}
					value={value}
					disabled={!enable}
					onClick={() => copyValue(value)}
					onChange={onChange}
					assistantConfig={assistantConfig}
					user={user}
					FRU={FRU}
				/>
				<p style={{ color: "red" }}>{customErrors}</p>
			</>
		);
	} else if (type === "date") {
		return (
			<>
				<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
					<KeyboardDatePicker
						style={{ width: "100%" }}
						name={child}
						format="dd/MM/yyyy"
						key={key}
						label={label}
						value={value || null}
						disabled={!enable}
						onClick={() => copyValue(value)}
						onChange={onChange}
						KeyboardButtonProps={{
							"aria-label": "change date"
						}}
					/>
				</MuiPickersUtilsProvider>
				<p style={{ color: "red" }}>{customErrors}</p>
			</>
		);
	} else if (type === "boolean") {
		return (
			<Controls.Switch
				name={child}
				key={key}
				label={label}
				color="primary"
				disabled={!enable}
				checked={value}
				onChange={onChange}
			/>
		);
	} else {
		return (
			<>
				{whiteListItem()}
				<p style={{ color: "red" }}>{customErrors}</p>
			</>
		);
	}
};

function FormContact({
	state,
	contactFiche,
	handleSave,
	handleCreate,
	handleDelete,
	showDelete,
	numberConversation,
	isEmpty,
	PI,
	deleteContact = false
}) {
	/**
	 * Call at every change on inputs, check for errors
	 * @param {*} values
	 * @returns
	 */
	let validate = ff => {
		const errors = {};
		//validateData();
		return errors;
	};

	const [hasErrors, setHasErrors] = useState(false);
	const [customErrors, setCustomErrors] = useState({});

	const handleInputChangeValidate = (e, value, child) => {
		if (value?.blacklist) {
			if (value.blacklist.includes(e.target.value)) {
				setCustomErrors({ ...customErrors, [child]: i18n.t("CONTACT.blackListContact") });
				setHasErrors(true);
			} else {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			}
		}
		if (value?.whitelist) {
			if (
				value.whitelist.includes(e.target.value) ||
				e.target.value === "" ||
				value.whitelist.length === 0
			) {
				let copyErrors = lod_.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			} else {
				setCustomErrors({ ...customErrors, [child]: i18n.t("CONTACT.whitelistContact") });
				setHasErrors(true);
			}
		}
		if (value?.type === "date") {
			e = { target: { name: child, value: e } };
		}
		if (value?.type === "phone") {
			e.target.value = e.target.value.replace(/\s/g, "");
		}
		if (value?.type === "boolean") {
			e = { target: { name: child, value: e.target.checked } };
		}
		handleInputChange(e);
	};

	let { values, errors, setValues, handleInputChange } = useForm({}, true, validate);

	const [contextContact, setContextContact] = useState(false);
	const [contextDictionary, setContextDictionary] = useState(false);
	const [matchValueContact, setmatchValueContact] = useState();
	const [contextDictionaryValue, setmatchValueContactValue] = useState();
	const { auth } = useSelector(state => state.userStatus);
	const userLanguage = auth?.user?.language?.toLowerCase() || "fr";
	const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
	const [openConfirmSave, setOpenConfirmSave] = useState(false);

	useEffect(() => {}, [PI]);

	/**
	 * Set users infos into values form when contact has loaded or loaded empty user to create him
	 */
	useEffect(() => {
		if (!lod_.isEmpty(contactFiche)) {
			if (contactFiche.FRU !== "" && !PI && !isEmpty) {
				setmatchValueContactValue(contactFiche);
				setContextContact(true);
			} else if (contactFiche.FRU !== "" && PI) {
				setValues(contactFiche);
			} else {
				setContextContact(false);
			}
		} else if (lod_.isEmpty(contactFiche) && isEmpty) {
			let dictionaryCreate = state.assistantconfig.dictionary.contact.items;
			let dictionaryEmpty = getStatsKeys(dictionaryCreate);
			let matchEmptyValu = getValueContact(dictionaryEmpty);
			matchEmptyValu.forcenew = false;
			matchEmptyValu.blacklist = false;
			dictionaryEmpty.forcenew = false;
			dictionaryEmpty.blacklist = false;
			setmatchValueContact(dictionaryEmpty);
			setValues(matchEmptyValu);
		}
	}, [contactFiche]);

	/**
	 * Test if dictionary items is not Nil
	 */
	useEffect(() => {
		if (!lod_.isNil(state.assistantconfig.dictionary.contact.items)) {
			setContextDictionary(true);
		} else {
			setContextDictionary(false);
		}
	}, [state.assistantconfig.dictionary.contact.items]);

	/**
	 * Set users whith values of CRM et put in form
	 */
	useEffect(() => {
		if (contextContact && contextDictionary) {
			let dictionaryContact = state.assistantconfig.dictionary.contact.items;

			let matchContact = getStatsKeys(dictionaryContact);
			let valuesMatch = getValueContact(matchContact);

			if (!lod_.isNil(contextDictionaryValue?.FRU)) {
				matchContact.FRU = contextDictionaryValue.FRU;
				valuesMatch.FRU = contextDictionaryValue.FRU;
			}

			setmatchValueContact(matchContact);
			setValues(valuesMatch);
		}
	}, [contextContact, contextDictionary]);

	/**
	 * @param {Object} contextAssistandID dictionary contact of assisstandID
	 * @param {Object} result the dictionary contact with values
	 * @param {String} root for children dictionary
	 * @returns {result} Skeleton of form with values of CRM
	 */
	function getStatsKeys(contextAssistandID, result = {}, root = "") {
		//Foreach context and contact of the assistant
		for (let key in contextAssistandID) {
			//Check if "items" exist and is an object
			const isParent = contextAssistandID[key].type === "level";
			const fieldValue = lod_.get(contextAssistandID, key);
			const type = fieldValue.type;
			const blacklist = fieldValue.blacklist;
			const whitelist = fieldValue.whitelist;
			const whitelistDynamic = fieldValue?.whitelistDynamic;
			const display = fieldValue?.display;

			const personalInformation = fieldValue.personalInformation;
			let valueResult = { ...fieldValue.label, type };
			if (blacklist) {
				valueResult = { ...valueResult, blacklist };
			}
			if (whitelist) {
				valueResult = { ...valueResult, whitelist };
			}
			if (whitelistDynamic) {
				valueResult = { ...valueResult, whitelistDynamic };
			}
			if (!lod_.isNil(personalInformation)) {
				valueResult = { ...valueResult, personalInformation };
			}
			if (!lod_.isNil(display)) {
				valueResult = { ...valueResult, display };
			}

			const path = root === "" ? key : `${root}.${key}`;
			if (display === true) {
				lod_.set(result, path, valueResult);
			}
			//Create path step to step
			if (!isParent) {
				//Check if context data has analytics .
				let valueEmptyType;
				if (type === "date") {
					valueEmptyType = null;
				} else if (type === "number") {
					valueEmptyType = 0;
				} else {
					valueEmptyType = "";
				}
				let value = valueEmptyType;
				if (display === true) {
					if (!isEmpty && !PI) {
						value = lod_.get(contextDictionaryValue, path) || valueEmptyType;
					}

					valueResult = { ...valueResult, value };
					lod_.set(result, path, valueResult);
				}
			} else {
				for (let indexLabel in contextAssistandID[key].items) {
					const nextRoot = root === "" ? `${key}` : root + `.${key}`;
					getStatsKeys(contextAssistandID[key].items, result, nextRoot);
				}
			}
		}
		return result;
	}

	/**
	 * @param {Object} contactValueForm dictionary contact of assisstandID with values
	 * @param {Object} result the contact with values edit / create
	 * @param {String} root for children dictionary
	 * @returns {result} Skeleton of values Form with values of CRM
	 */
	function getValueContact(contactValueForm, result = {}, root = "") {
		for (let key in contactValueForm) {
			const isParent = contactValueForm[key]?.type === "level";
			if (!isParent) {
				if (typeof contactValueForm[key] === "object") {
					const fieldValue = contactValueForm[key].value;
					const path = root === "" ? key : `${root}.${key}`;
					lod_.set(result, path, fieldValue);
				}
			} else {
				for (let index in contactValueForm[key]) {
					if (typeof contactValueForm[key][index] === "object") {
						const nextRoot = root === "" ? `${key}` : root + `.${key}`;
						getValueContact(contactValueForm[key], result, nextRoot);
					}
				}
			}
		}
		return result;
	}

	const cancelCreation = () => {
		state.handleCloseEditContactDialog();
	};

	/**
	 * Handle submit form
	 */
	const handleSubmit = () => {
		if (contactFiche === undefined || lod_.isEmpty(contactFiche)) {
			handleCreate(values);
		} else {
			handleSave(values);
		}
	};

	const handleCloseDelete = () => {
		setOpenConfirmDelete(false);
	};

	const handleCloseSave = () => {
		setOpenConfirmSave(false);
	};

	return (
		<Grid item xs={12} container direction="column" data-cy="formContainer">
			<Form className="form">
				<CardBody faibrik>
					<GridContainer>
						<GridItem xs={12}>
							<Box style={{ display: "flex", flexDirection: "column" }}>
								{!lod_.isEmpty(values) ? (
									Object.keys(matchValueContact).map((child, key) => (
										<Box key={key} style={{ paddingTop: "10px" }}>
											<>
												{matchValueContact[child].type !== "level" ? (
													<>
														{child !== "FRU" && (
															<InpuType
																child={child}
																label={matchValueContact[child][userLanguage] || child}
																key={key}
																whitelist={matchValueContact[child]?.whitelist}
																whitelistDynamic={matchValueContact[child]?.whitelistDynamic}
																type={matchValueContact[child].type}
																value={values[child]}
																PI={PI}
																isEmpty={isEmpty}
																customErrors={customErrors[child]}
																onChange={e =>
																	handleInputChangeValidate(e, matchValueContact[child], child)
																}
																FRU={contactFiche.FRU}
															></InpuType>
														)}
													</>
												) : (
													<>
														<RecursiveComponent
															configChild={matchValueContact[child]}
															nameChild={child}
															handleInputChangeValidate={handleInputChangeValidate}
															userLanguage={userLanguage}
															values={values}
															PI={PI}
															root=""
															customErrors={customErrors}
															FRU={contactFiche.FRU}
														/>
													</>
												)}
											</>
										</Box>
									))
								) : (
									<>
										<Box></Box>
									</>
								)}
							</Box>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Form>
			<Grid item container direction="row" justifyContent="flex-end" style={{ paddingTop: "2%" }}>
				<Button onClick={cancelCreation} style={{ margin: "2%" }} color="primary">
					{i18n.t("CD.cancel")}
				</Button>
				{PI && (
					<>
						<Button
							onClick={() => setOpenConfirmSave(true)}
							disabled={hasErrors}
							style={{ margin: "2%" }}
							color="primary"
						>
							{i18n.t("CD.save")}
						</Button>
						{openConfirmSave && (
							<ConfirmationDialog
								open={openConfirmSave}
								handleClose={handleCloseSave}
								title={i18n.t("CONTACT.edit")}
								handleValidate={handleSubmit}
								message={i18n.t("CONTACT.confirmEdditContact")}
								cancel={i18n.t("CONTACT.disagree")}
								validate={i18n.t("CONTACT.agree")}
							/>
						)}
					</>
				)}
				{isEmpty && (
					<>
						<Button
							onClick={() => setOpenConfirmSave(true)}
							disabled={hasErrors}
							style={{ margin: "2%" }}
							color="primary"
						>
							{i18n.t("CD.save")}
						</Button>
						{openConfirmSave && (
							<ConfirmationDialog
								open={openConfirmSave}
								handleClose={handleCloseSave}
								title={i18n.t("CONTACT.addContact")}
								handleValidate={handleSubmit}
								message={i18n.t("CONTACT.confirmAddContact")}
								cancel={i18n.t("CONTACT.disagree")}
								validate={i18n.t("CONTACT.agree")}
							/>
						)}
					</>
				)}
				{state.contact !== null && numberConversation !== 0 && deleteContact ? (
					<>
						<Button
							onClick={() => setOpenConfirmDelete(true)}
							style={{ margin: "2%" }}
							color="secondary"
						>
							{i18n.t("CD.delete")}
						</Button>

						{openConfirmDelete && (
							<ConfirmationDialog
								open={openConfirmDelete}
								handleClose={handleCloseDelete}
								title={i18n.t("CONTACT.deleteCRMContact")}
								handleValidate={handleDelete}
								message={i18n.t("CONTACT.deleteContact")}
								cancel={i18n.t("CONTACT.disagree")}
								validate={i18n.t("CONTACT.agree")}
							/>
						)}
					</>
				) : (
					<></>
				)}
			</Grid>
		</Grid>
	);
}

const DisplayItem = (suggestedValue, shownFields = ["name"]) => {
	return (
		<Box>
			{shownFields.map((key, index) => {
				return (
					<>
						{index === 0 && (
							<Box key={`shownFields_${index}`} fontWeight="fontWeightRegular">
								{lod_.get(suggestedValue, key)}
							</Box>
						)}
						{index !== 0 && (
							<Box key={`shownFields_${index}`} fontStyle="oblique" fontWeight="fontWeightLight">
								{lod_.get(suggestedValue, key)}
							</Box>
						)}
					</>
				);
			})}
		</Box>
	);
};

export default FormContact;
