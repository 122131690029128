import webchat from "../assets/img/logo/channels/webchat.svg";
import facebook from "../assets/img/logo/channels/facebook.svg";
import instagram from "../assets/img/logo/channels/instagram.svg";
import mail from "../assets/img/logo/channels/mail.svg";
import telegram from "../assets/img/logo/channels/telegram.svg";
import whatsapp from "../assets/img/logo/channels/whatsapp.svg";
import freshdesk from "../assets/img/logo/channels/freshdesk.svg";
import websocket from "../assets/img/logo/channels/websocket.svg";
import zendesk from "../assets/img/logo/channels/zendesk.svg";
import sms from "../assets/img/logo/channels/sms.svg";
import crisp from "../assets/img/logo/channels/crisp.svg";
import veepee from "../assets/img/logo/channels/veepee.svg";
import gallerielafayette from "../assets/img/logo/channels/gallerielafayette.png";
import zalando from "../assets/img/logo/channels/zalando.svg";
import redoute from "../assets/img/logo/channels/redoute.png";
import amazon from "../assets/img/logo/channels/amazon.svg";
import avisverifie from "../assets/img/logo/channels/avisverifie.png";
import mirakl from "../assets/img/logo/channels/mirakl.svg";
import interrogation from "../assets/img/logo/channels/interrogation.svg";
import voice from "../assets/img/logo/channels/voice.svg";
import eci from "../assets/img/logo/channels/eci.png";
import livechat from "../assets/img/logo/channels/livechat.png";
import purchase from "../assets/img/logo/channels/purchase.png";
import paypal from "../assets/img/logo/channels/paypal.svg";
import store from "redux/store";
import hermes from "../assets/img/logo/channels/hermes.png";
import trustpilot from "../assets/img/logo/channels/trustpilot.png";

/**
 * Channels logos -> format(svg) in assets/img/logo/channels
 * @param {*} channels
 * @param {*} channelCodeOrTTypeOrMarketplace
 * @param {*} channel TYPE in case this channel is not anymore in config, we can return the  logo
 * @returns for a channel code it will return the channel logo or logoURL if exists, for a channel type or sourceMarketplace it returns the right logo
 */
export default function GetChannelImage(channels, channelCodeOrTTypeOrMarketplace, channel) {
	let actualChannel = store
		.getState()
		.assistantconfig?.channels?.filter(
			channel => channel.code === channelCodeOrTTypeOrMarketplace
		)[0];

	if (actualChannel && actualChannel.logoURL) {
		return actualChannel.logoURL;
	}
	if (actualChannel) {
		channelCodeOrTTypeOrMarketplace = actualChannel.type;
	}

	switch (channelCodeOrTTypeOrMarketplace) {
		case "WB":
			return webchat;
		case "FB":
			return facebook;
		case "IS":
			return instagram;
		case "WS":
			return websocket;
		case "FD":
			return freshdesk;
		case "ZD":
			return zendesk;
		case "CR":
			return crisp;
		case "MAIL":
		case "ML":
			return mail;
		case "TG":
			return telegram;
		case "WA":
			return whatsapp;
		case "SMS":
			return sms;
		case "PH":
			return hermes;
		case "PK":
		case "VEEPEE":
			return veepee;
		case "GALERIES LAFAYETTE":
			return gallerielafayette;
		case "ZALANDO":
			return zalando;
		case "LR":
		case "LA REDOUTE":
			return redoute;
		case "AMAZON":
		case "AZ":
			return amazon;
		case "AVIS VERIFIES":
			return avisverifie;
		case "MK":
			return mirakl;
		case "VO":
			return voice;
		case "EL CORTE INGLES":
			return eci;
		case "LC":
			return livechat;
		case "PO":
			return purchase;
		case "CA":
			return purchase;
		case "PP":
			return paypal;
		case "TP":
			return trustpilot;
		// case "RMS":	--> Deprecated. Use Phone
		// 	return hermes;
		default:
			if (channel) {
				return GetChannelImage(channels, channel);
			} else {
				return interrogation;
			}
	}
}
