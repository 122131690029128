/*eslint-disable eqeqeq*/
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";
import contactActions from "redux/actions/contactActions";
import { FormControl, FormGroup, FormControlLabel, Checkbox, FormLabel } from "@material-ui/core";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	MySnackbar,
	deleteContactCRM,
	deleteConversationContact
} from "../../../helpers/utilities";
import { wsEvent } from "redux/actions/ws";
const endPoint = process.env.REACT_APP_AMBACK + "/api/v1/";
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex"
	},
	formControl: {
		margin: theme.spacing(3)
	}
}));

function ConfirmationDeleteConvDialog(props) {
	const [open, setOpen] = useState(false);
	const [loading] = useState(false);
	const [contact, setContact] = useState(props.contact);
	const [loadingFetchAttachment, setLoadingFetchAttachment] = useState(false);
	const [numberAttachment, setNumberAttachment] = useState(false);
	const [sizeAttachment, setSizeAttachment] = useState(false);
	const [snackbarStatus, setSnackbarStatus] = React.useState({
		open: false,
		message: ""
	});
	const dispatch = useDispatch();

	const user = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;
	const [stateCheckbox, setStateCheckbox] = React.useState({
		conversations: true,
		pieces_jointes: true
	});
	const [deleteContact, setDeleteContact] = useState(false);

	useEffect(() => {
		setOpen(props.isDeleteDialogOpen);
	}, [props.isDeleteDialogOpen]);

	const classes = useStyles();

	// useEffect(() => {
	// 	socket.on("ACK_delete_conv", event => {
	// 		if (event.status === 202) {
	// 			setSnackbarStatus({
	// 				open: true,
	// 				message: props.t("CONTACT.ongoingRequest")
	// 			});
	// 		} else {
	// 			setSnackbarStatus({
	// 				open: true,
	// 				message: props.t("CONTACT.failedDeleteConvContact")
	// 			});
	// 		}
	// 		//  retour amb affichage slider
	// 		setTimeout(() => {
	// 			//Close modal after snackBar
	// 			handleClose();
	// 		}, 200);
	// 	});
	// }, [socket]);

	useEffect(() => {
		(async () => {
			await getAttachmentsInformations();
		})();
		// eslint-disable-next-line
	}, []);

	const handleChange = event => {
		setStateCheckbox({
			...stateCheckbox,
			[event.target.name]: event.target.checked
		});
	};

	const handleDeleteContactState = event => {
		setDeleteContact(!deleteContact);
	};

	const getAttachmentsInformations = async () => {
		setLoadingFetchAttachment(true);

		let ML_id = props.contact && props.contact.email ? props.contact.email : false;
		let FRUFromContact = props.contact && props.contact.FRU;
		if (!ML_id || !FRUFromContact) {
			return;
		}
		if (user && (ML_id || FRUFromContact)) {
			let url = `${endPoint}${props.selectedAssistantID}/contact/attachments`;

			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email,
					contactEmail: props.contact.email,
					FRU: props.contact.FRU
				},
				method: "POST"
			});

			if (res.status == "200") {
				setNumberAttachment(res.data?.countAttachments || 0);
				setSizeAttachment(res.data?.sizeAttachments || 0);
			}
		}
		setLoadingFetchAttachment(false);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ open: false, message: "" });
	};

	const handleDeleteConversation = async () => {
		if (deleteContact) {
			let res = await deleteContactCRM(endPoint, props.selectedAssistantID, contact.id, user);

			if (stateCheckbox.conversations === false) {
				if (res && res.status === 200) {
					setSnackbarStatus({
						open: true,
						message: props.t("CONTACT.contactDeleted")
					});
				} else if (res && res.name === "Error") {
					setSnackbarStatus({
						open: true,
						message: props.t("CONTACT.failedDeleteContact")
					});
				}
				setTimeout(() => {
					//Close modal after snackBar
					handleClose();
				}, 500);
			}
		}
		if (stateCheckbox.conversations === true) {
			try {
				await deleteConversationContact(
					process.env.REACT_APP_AMBACK,
					props.selectedAssistantID,
					contact,
					user
				);
				setSnackbarStatus({
					open: true,
					message: props.t("CONTACT.ongoingRequest")
				});
			} catch (error) {
				setSnackbarStatus({
					open: true,
					message: props.t("CONTACT.failedDeleteConvContact")
				});
			}
			setTimeout(() => {
				//Close modal after snackBar
				handleClose();
			}, 200);
		}
		props.getContacts();
	};

	const handleClose = () => {
		setOpen(false);
		props.getContacts();
		props.handleCloseDeleteDialog(false);
		props.handleCloseContactModal();
	};

	return (
		<>
			<MySnackbar
				style={{ top: "65px" }}
				open={snackbarStatus.open}
				onClose={handleCloseSnackbar}
				autoHideDuration={1500}
				message={snackbarStatus.message}
				transition="slide"
				direction="left"
				timeout={{
					enter: 200
				}}
			/>

			<Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">{props.t("CONTACT.disclaimer")}</DialogTitle>
				<DialogContent>
					<FormControl
						/*  component="fieldset" */
						className={classes.formControl}
					>
						<FormLabel component="legend" focused={false}>
							<p style={{ color: "#c22e2e" }}>{props.t("CONTACT.disclaimerDeleteConversation")}</p>
						</FormLabel>
						{loadingFetchAttachment && numberAttachment && sizeAttachment ? (
							<CircularProgress />
						) : (
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={stateCheckbox.conversations}
											onChange={handleChange}
											name="conversations"
										/>
									}
									label={props.t("CONTACT.labelDeleteConversation")}
								/>

								<FormControlLabel
									disabled
									control={
										<Checkbox
											checked={stateCheckbox.conversations}
											onChange={handleChange}
											name="pieces_jointes"
										/>
									}
									label={
										numberAttachment !== false && sizeAttachment !== false
											? props.t("CONTACT.delete") +
											  " " +
											  numberAttachment +
											  " " +
											  props.t("CONTACT.attachments") +
											  ". " +
											  props.t("CONTACT.sizeTotal") +
											  " : " +
											  sizeAttachment
											: props.t("CONTACT.deleteAttachments")
									}
								/>

								<FormControlLabel
									control={
										<Checkbox
											checked={deleteContact}
											onChange={handleDeleteContactState}
											name="conversations"
										/>
									}
									label={props.t("CONTACT.deleteCRMContact")}
								/>
							</FormGroup>
						)}
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleDeleteConversation}
						color="primary"
						autoFocus
						disabled={loading ? true : false}
					>
						{loading ? (
							<CircularProgress size={20} />
						) : (
							<p style={{ margin: "0 0 0 0" }}> {props.t("CONTACT.confirm")}</p>
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

function mapStateToProps(stateCheckbox) {
	return stateCheckbox;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch),
		contactActions: bindActionCreators(contactActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ConfirmationDeleteConvDialog);
