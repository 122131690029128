module.exports = {
	computeAgentName
};

/**
 * Compute agent name from user object to put in conversation.agent.name (the displayed one)
 * @param {Object} user - user object from mongoDB collection "user"
 * @returns {String} - agent name
 */
function computeAgentName(user) {
	return `${user?.surname || ""} ${user?.name || ""}`.trim();
}
