import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clear } from "../../redux/reducers/snackMsgsReducers";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackbarStore() {
	const { isCold, isCockpitArchived } = useSelector(state => state.cockpit);
	const { info, error, coldError, duration } = useSelector(state => state.snackMsgs);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [durationSnack, setDurationSnack] = useState(4000);
	const [message, setMessage] = useState(null);
	const [coldMessage, setColdMessage] = useState();
	const [severity, setSeverity] = useState();

	useEffect(() => {
		if (info || error) {
			if (duration) {
				setDurationSnack(duration);
			} else if (duration === false) {
				setDurationSnack(null);
			} else {
				setDurationSnack(4000);
			}
			setOpen(true);
			setMessage(info ? info : error);
			if (info) {
				setSeverity("info");
			} else setSeverity("error");

			dispatch(clear());
		} else if (coldError) {
			setSeverity("error");
			setColdMessage(coldError);
			setOpen(true);
			dispatch(clear());
		}
	}, [info, error, coldError]);

	const handleCloseSnackbar = () => {
		setOpen(false);
	};

	const messageToDisplay = coldMessage && isCold && isCockpitArchived ? coldMessage : message;

	return (
		messageToDisplay && (
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={open}
				onClose={handleCloseSnackbar}
				autoHideDuration={durationSnack}
			>
				<Alert onClose={handleCloseSnackbar} severity={severity}>
					{messageToDisplay}
				</Alert>
			</Snackbar>
		)
	);
}
