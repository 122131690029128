import C from "../../constants/engage.js";

const engageReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.ENGAGE_EMAIL: {
			return { ...currentState, emailSent: action.payload };
		}
		case C.ENGAGE_SMS: {
			return { ...currentState, SMSSent: action.payload };
		}
		case C.SET_ENGAGE_CONTACT: {
			return { ...currentState, contact: action.payload };
		}

		default:
			return currentState;
	}
};
export default engageReducers;
