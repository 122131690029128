/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { Dialog, DialogContent, DialogTitle, Grid, TextField, ListItem } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import FormAction from "../../FormAction";

function ExportConversationToGedDialog(props) {
	const [open] = React.useState(true);

	let schema = props.schema;
	let uiSchema = props.uiSchema;
	let incomingData = props.incomingData;

	return (
		<div>
			<Dialog
				fullWidth={true}
				onClose={props.handleClose}
				maxWidth={"sm"}
				open={open}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">Exporter conversation à la GED</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={3} style={{ padding: "12px" }}>
						<Grid container direction="row" alignItems="center">
							<ListItem style={{ flex: "1" }}>
								<TextField label="Nom Prénom" value={incomingData.TOTIE_LIB} fullWidth disabled />
							</ListItem>
							<ListItem style={{ flex: "1" }}>
								<TextField label="Numéro tiers" value={incomingData.TOTIE_COD} fullWidth disabled />
							</ListItem>
						</Grid>
						{open && (
							<FormAction
								action={props.action}
								handleSubmit={props.handleSubmit}
								handleCancel={props.handleClose}
								schema={schema}
								uiSchema={uiSchema}
								data={incomingData}
							/>
						)}
					</Grid>
				</DialogContent>
			</Dialog>
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(ExportConversationToGedDialog);
