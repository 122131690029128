import React, { useEffect, useState } from "react";
import GetChannelImage from "../../helpers/GetChannelImage";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Button,
	InputLabel,
	Select,
	FormControl,
	TextField
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import SignalCellular0BarIcon from "@material-ui/icons/SignalCellular0Bar";
import SignalCellular1BarIcon from "@material-ui/icons/SignalCellular1Bar";
import SignalCellular2BarIcon from "@material-ui/icons/SignalCellular2Bar";
import SignalCellular3BarIcon from "@material-ui/icons/SignalCellular3Bar";
import { Clear } from "@material-ui/icons";
import lod_ from "lodash";

import SignalCellularConnectedNoInternet4BarIcon from "@material-ui/icons/SignalCellularConnectedNoInternet4Bar";
import i18n from "i18n";
import engageActions from "../../redux/actions/engageActions";
import Controls from "components/Controls/Controls";
import cockpitActions from "../../redux/actions/cockpitActions";
import { validatePhone } from "helpers/utilities";
import { EngageSearchContact } from "./EngageEditContact";
import { Autocomplete } from "@material-ui/lab";
import { realNowUtc } from "helpers/utilities";
import { getLabelOf } from "helpers/utilities";
import { regexPhoneNumber } from "helpers/utilities";
import { regexMail } from "helpers/utilities";

const myDateTime = require("luxon");

/**
 * EngageDropDown propose different channel to start an engage conversation
 * @param {array} possibleChannels List of possible engage channels (Could be computed internally?)
 * @param {object} anchorEl Where to attach this little dropDown
 * @param {function} setAnchorEl attach this little dropDown somewhere or close if null/empty
 * @param {function} handleCloseDropDown Function to call to close this dropdown (Could be done internally?)
 * @param {function} handleOpenEngageDialog Function to open the old engage dialog
 * @param {object} initialDraft The initial draft for the engage, can be empty
 */
export default function EngageDropDown({
	possibleChannels,
	anchorEl,
	setAnchorEl,
	initialDraft,
	handleOpenEngageDialog,
	engageFromContext = false
}) {
	const { channels } = useSelector(state => state.assistantconfig);
	const dispatch = useDispatch();
	const [openPhone, setOpenPhone] = useState(false);
	const [openTask, setOpenTask] = useState(false);
	const [selectedChannelCode, setSelectedChannelCode] = useState(null);

	/**
	 * Select a channel and start engage process
	 * @param {object} channel the channel with many info like channelCode, type
	 */
	const handleSelectChannel = channel => {
		// New engage Epic https://faibrikplatform.atlassian.net/browse/FBDB-577
		const channelCode = channel?.code;

		const channelType = channel?.type;
		const channelSubType = channel?.subtype;

		if (engageFromContext === true) {
			handleOpenEngageDialog(channel);
		} else {
			if (channelType === "PH" && engageFromContext === false) {
				setSelectedChannelCode(channelCode);
				setOpenPhone(true);
			} else if (channelType === "CA" && channelSubType === "task" && engageFromContext === false) {
				setSelectedChannelCode(channelCode);
				setOpenTask(true);
			} else {
				if (channelCode) {
					dispatch(engageActions.createEngageConversation(channelCode, initialDraft));
					if (initialDraft?.engageLinkContact) {
						// This is an engage from contact page, then redirect to cockpit page
						window.location.href = "/admin/cockpit";
					}
				}
				setAnchorEl(false);
			}
		}
	};

	const handleClosePhone = () => {
		setOpenPhone(false);
		setAnchorEl(false);
	};

	const handleCloseTask = () => {
		setOpenTask(false);
		setAnchorEl(false);
	};

	const handleClose = () => {
		setAnchorEl(false);
	};

	return (
		<>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{possibleChannels.map((channel, index) => {
					return (
						<Tooltip
							key={index}
							title={`${i18n.t("COC.EngageTitle")}   ${channel.name}`}
							placement="top-start"
						>
							<MenuItem
								value={channel}
								key={channel.type}
								onClick={() => handleSelectChannel(channel)}
							>
								<Box>
									<IconButton size="small">
										<Box component="span">
											<img
												src={GetChannelImage(channels, channel.code)}
												alt="channel-logo"
												width="20"
												height="20"
												className="logo-channel"
											/>
										</Box>
									</IconButton>
								</Box>
							</MenuItem>
						</Tooltip>
					);
				})}
			</Menu>
			<EngagePhone
				handleClosePhone={handleClosePhone}
				openPhone={openPhone}
				dispatch={dispatch}
				channelCode={selectedChannelCode}
			/>
			<EngageTask
				handleCloseTask={handleCloseTask}
				openTask={openTask}
				dispatch={dispatch}
				channelCode={selectedChannelCode}
			/>
		</>
	);
}

/**
 * EngagePhone set phone value to start an engage conversation phone
 * @param {function} openPhone Function to call to close this dropdown (Could be done internally?)
 * @param {function} handleClosePhone Function to open the old engage dialog
 * @param {object} dispatch The initial draft for the engage, can be empty
 */
export function EngagePhone({ handleClosePhone, openPhone, dispatch, channelCode }) {
	const [valuePhone, setValuePhone] = useState("");
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);

	const [isValid, setIsValid] = useState(false);

	const handleLaunch = () => {
		let data = {
			header: {
				assistantID: selectedAssistantID
			},
			payload: {
				phone: valuePhone,
				channelCode
			}
		};

		dispatch(cockpitActions.launchActionFrontV2("triggerOutboundCall", data, null, "event"));

		handleClosePhone();
	};

	const handleChange = value => {
		setValuePhone(value.replace(/\s/g, ""));

		// Check if the entered value matches the phone format
		let result = validatePhone(value.replace(/\s/g, ""));

		setIsValid(result);
	};

	return (
		<Dialog
			open={openPhone}
			onClose={handleClosePhone}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{i18n.t("COC.EngagePhone")} </DialogTitle>
			<DialogContent>
				<Controls.Phone
					label={i18n.t("CONTACT.phone")}
					defaultCountry={"fr"}
					value={valuePhone}
					onChange={e => handleChange(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={handleClosePhone}>
					{`${i18n.t("COC.Cancel")}`}
				</Button>

				<Button color="primary" onClick={handleLaunch} disabled={!isValid}>
					{i18n.t("COC.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

/**
 * EngagePhone set phone value to start an engage conversation phone
 * @param {function} openTask Function to call to close this dropdown (Could be done internally?)
 * @param {function} handleCloseTask Function to open the old engage dialog
 * @param {object} dispatch The initial draft for the engage, can be empty
 */
export function EngageTask({ handleCloseTask, openTask, dispatch, channelCode }) {
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);

	const [valueActivationDateTask, setValueActivationDateTask] = useState(myDateTime.DateTime.now());
	const [valueTargetDateTask, setValueTargetDateTask] = useState(
		myDateTime.DateTime.now().plus({ days: 3 })
	);
	const [isValid, setIsValid] = useState(false);
	const [topicTask, setTopicTask] = useState("");
	const [topicChoiceTask, setTopicChoiceTask] = useState("");
	const [priorityTask, setPriorityTask] = useState(null);
	const [contactTask, setContactTask] = useState({});
	const [noteTask, setNoteTask] = useState("");
	const { intents } = useSelector(state => state.assistantconfig); // Possible intentions list
	const { groups } = useSelector(state => state.userGroup); // Possible intentions list

	const handleLaunch = () => {
		if (channelCode) {
			let data = {
				topic: topicChoiceTask,
				priority: priorityTask,
				task: {
					noteTask,
					contactTask,
					valueActivationDateTask,
					valueTargetDateTask
				}
			};

			if (!lod_.isNil(groups) && !lod_.isEmpty(groups)) {
				let skillDefault = groups.filter(skill => {
					if (skill?.isDefault === true) {
						return skill;
					}
				});
				if (!lod_.isEmpty(skillDefault)) data.task.skillDefault = skillDefault[0]?._id;
			}
			dispatch(engageActions.createEngageConversation(channelCode, data));
		}
		handleCloseTask();
	};

	const handleChange = event => {
		setPriorityTask(event.target.value);
	};

	/**
	 *
	 * @param {*} e
	 */
	const handleDelete = e => {
		setTopicTask("");
		setTopicChoiceTask("");
	};

	const handleAutocompleteChange = (evt, newValue, reason) => {
		if (!newValue?.name) {
			return;
		} else {
			setTopicTask(getLabelOf(newValue?.name));
			setTopicChoiceTask(newValue?.name);
		}
	};

	const handleChangeContact = newValue => {
		setContactTask(newValue);
	};

	/**
	 *
	 * @returns
	 */
	const validateContact = () => {
		return !regexMail(contactTask) || !regexPhoneNumber(contactTask);
	};

	useEffect(() => {
		if (
			lod_.isEmpty(topicChoiceTask) ||
			lod_.isNil(priorityTask) ||
			lod_.isNil(valueActivationDateTask) ||
			lod_.isNil(valueTargetDateTask) ||
			lod_.isEmpty(contactTask) ||
			lod_.isEmpty(noteTask)
		) {
			setIsValid(false);
		} else {
			let valid = true;
			if (typeof contactTask === "string") {
				valid = validateContact();
			}
			setIsValid(valid);
		}
	}, [
		topicChoiceTask,
		priorityTask,
		contactTask,
		noteTask,
		valueActivationDateTask,
		valueTargetDateTask
	]);

	/**
	 *
	 * @param {*} evt
	 */
	const handleTopicInputChange = evt => {
		let newValue = evt.target.value;
		setTopicTask(newValue);
	};

	return (
		<Dialog
			open={openTask}
			onClose={handleCloseTask}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth
			maxWidth={"lg"}
		>
			<DialogTitle id="alert-dialog-title">{i18n.t("COC.EngageTask")} </DialogTitle>
			<DialogContent style={{ height: "65vh" }}>
				<Box style={{ display: "flex", flexDirection: "column" }}>
					<Box style={{ marginBottom: "10px" }}>
						<EngageSearchContact handleChangeContact={newValue => handleChangeContact(newValue)} />
					</Box>
					<Box style={{ marginBottom: "10px" }}>
						<Autocomplete
							id="combo-box-demo"
							options={intents}
							freeSolo
							inputValue={topicTask}
							disableClearable
							onChange={handleAutocompleteChange}
							getOptionLabel={option => getLabelOf(option.name)}
							renderOption={option => (
								<Box>
									<Box fontWeight="fontWeightRegular">{getLabelOf(option.name)}</Box>
									<Box fontStyle="oblique" fontWeight="fontWeightLight">
										{option.description}
									</Box>
								</Box>
							)}
							renderInput={params => (
								<Box style={{ position: "relative" }}>
									<TextField
										{...params}
										label={i18n.t("COC.choiceTopic")}
										variant="outlined"
										size="small"
										onChange={handleTopicInputChange}
										error={lod_.isEmpty(topicChoiceTask) ? true : false}
									/>
									{/* </Box> */}
									<IconButton
										size="small"
										onClick={e => handleDelete()}
										style={{ position: "absolute", right: "10px", top: "5px" }}
									>
										<Clear />
									</IconButton>
								</Box>
							)}
						/>
					</Box>
					<Box style={{ marginBottom: "10px" }}>
						<FormControl fullWidth variant="outlined">
							<InputLabel
								style={{ color: lod_.isNil(priorityTask) ? "red" : null }}
								id="demo-simple-select-label"
							>
								{i18n.t("COC.priority")}
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={priorityTask}
								label={i18n.t("COC.priority")}
								onChange={handleChange}
								error={lod_.isNil(priorityTask) ? true : false}
							>
								<MenuItem value={1}>
									<SignalCellular0BarIcon
										fontSize="small"
										style={{
											color: "black",
											marginRight: "2px"
										}}
									/>
									{i18n.t("convItemPriority.minimal")}
								</MenuItem>
								<MenuItem value={2}>
									<SignalCellular1BarIcon
										fontSize="small"
										style={{
											color: "black",
											marginRight: "2px"
										}}
									/>{" "}
									{i18n.t("convItemPriority.low")}
								</MenuItem>
								<MenuItem value={3}>
									<SignalCellular2BarIcon
										fontSize="small"
										style={{
											color: "blue",
											marginRight: "2px"
										}}
									/>
									{i18n.t("convItemPriority.medium")}
								</MenuItem>
								<MenuItem value={4}>
									<SignalCellular3BarIcon
										fontSize="small"
										style={{
											color: "orange",
											marginRight: "2px"
										}}
									/>
									{i18n.t("convItemPriority.high")}
								</MenuItem>
								<MenuItem value={5}>
									<SignalCellularConnectedNoInternet4BarIcon
										fontSize="small"
										style={{ color: "red", marginRight: "2px" }}
									/>
									{i18n.t("convItemPriority.critical")}
								</MenuItem>
							</Select>
						</FormControl>
					</Box>
					<Box style={{ marginBottom: "10px" }}>
						<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
							<KeyboardDatePicker
								style={{ width: "100%" }}
								format="dd/MM/yyyy"
								label={i18n.t("COC.dateActivation")}
								value={valueActivationDateTask}
								onChange={date => setValueActivationDateTask(date)}
								error={lod_.isNil(valueActivationDateTask) ? true : false}
							/>
						</MuiPickersUtilsProvider>
					</Box>
					<Box style={{ marginBottom: "10px" }}>
						<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
							<KeyboardDatePicker
								style={{ width: "100%" }}
								format="dd/MM/yyyy"
								label={i18n.t("COC.dateTarget")}
								value={valueTargetDateTask}
								onChange={date => setValueTargetDateTask(date)}
								error={lod_.isNil(valueTargetDateTask) ? true : false}
							/>
						</MuiPickersUtilsProvider>
					</Box>
					<Box style={{ color: lod_.isEmpty(noteTask) ? "red" : null }}>
						{i18n.t("COC.taskNote")}
					</Box>
					<textarea
						style={{
							height: 80,
							resize: "none",
							width: "100%",
							minHeight: "200px",
							maxHeight: 0,
							fontSize: "16px",
							fontFamily: "Roboto",
							border: lod_.isEmpty(noteTask) ? "solid 1px red" : "solid 1px #ccc"
						}}
						value={noteTask}
						onChange={e => setNoteTask(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={handleCloseTask}>
					{`${i18n.t("COC.Cancel")}`}
				</Button>

				<Button color="primary" onClick={handleLaunch} disabled={!isValid}>
					{i18n.t("COC.confirm")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
