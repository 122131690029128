/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import i18n from "i18next";
import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import GetFlag from "../../helpers/GetFlag";
import { getUploadS3SignedUrlImage, uploadS3FromSignedUrl } from "../../helpers/s3";
import settings from "../../helpers/settingFunctions";
import { fr } from "suneditor/src/lang";

const useStyles = makeStyles(theme => ({}));

function EditProfile(props) {
	const suneditor = useRef();

	const userStatus = useSelector(state => state.userStatus);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);

	const [nameError, setNameError] = React.useState(false);
	const [langue, setLangue] = React.useState(
		props.user && props.user.language ? props.user.language : "FR"
	);
	const [fontSize, setFontSize] = React.useState(props.user.fontSize || 14);
	const defaultImage = "https://cdn.pixabay.com/photo/2016/08/31/11/54/user-1633249_960_720.png";
	const [error, setError] = React.useState(false);
	const [helperTextOnError, setHelperTextOnError] = React.useState("");
	const classes = useStyles();
	const [profileImageFile, setProfileImageFile] = React.useState("");
	const [imagePreviewUrl, setImagePreviewUrl] = React.useState("");
	const [loadingProfileImage, setLoadingProfileImage] = useState(false);
	const [isProfileImageChanged, setIsProfileImageChanged] = useState(false);

	const propsUser = userStatus.auth;

	const [signature, setSignature] = React.useState({
		assistantID: props.mySign ? props.mySign.assistantID : "",
		signature: props.mySign ? props.mySign.signature : ""
	});

	const [userFields, setUserFields] = useState({
		name: propsUser.user.name,
		surname: propsUser.user.surname,
		title: propsUser.user.title,
		mobilePhone: propsUser.user.mobilePhone,
		directPhone: propsUser.user.directPhone,
		openingHours: propsUser.user.openingHours,
		picture: propsUser.user && propsUser.user.picture,
		signatures: propsUser.user && propsUser.user.signatures
	});

	const handleFieldChange = event => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		setUserFields({ ...userFields, [name]: value });
	};

	const handleChangeSignature = content => {
		setSignature({ ...signature, signature: content });
	};

	const handleChangeLanguage = e => {
		setLangue(e.target.value);
	};

	const handleChangeFontSize = e => {
		setFontSize(e.target.value);
	};

	/**
	 * Returns a boolean if the fileExtension is in an array of extension
	 * @param {string} fileType type of profileImageFile (png,jpeg,etc...)
	 * @param {Array} validExt (array of valid extension)
	 */
	let getValidExtension = (fileType, validExt) => {
		return validExt.some(v => fileType.includes(v));
	};

	/**
	 * Get the profileImageFile and valid format && size then display error if wrong
	 * @param {Object} file the file to check with html file-input format
	 */
	const validateFile = file => {
		const validExt = ["png", "jpeg", "jpg"];
		const fileType = file && file.type.split("/")[1];
		const size = file && Math.round(file.size / 1024);
		const type = fileType && getValidExtension(fileType, validExt);
		let valid = true;

		if (size >= 2048) {
			setHelperTextOnError(i18n.t("profile.errorUploadSize"));
			setError(true);
			valid = false;
		}
		if (!type) {
			setHelperTextOnError(i18n.t("profile.errorUploadType"));
			setError(true);
			valid = false;
		}
		return valid;
	};

	/**
	 * Upload the file to s3 on client-side
	 * @param {Object} profileImageFile the file to upload with html file-input format
	 */
	async function uploadProfileImageToS3(profileImageFile) {
		let fileName = profileImageFile.name;
		let contentType = profileImageFile.type;
		let size = profileImageFile.size;

		try {
			const compositeData = await getUploadS3SignedUrlImage(fileName, contentType, size, "profile");

			await uploadS3FromSignedUrl(compositeData.signedUrl, profileImageFile);
			return compositeData;
		} catch (error) {
			setHelperTextOnError(i18n.t("profile.errorDuringUpload"));
			setError(true);
		}
	}

	/**
	 * Upload the file to s3 on client-side
	 * @param {Object} imageFile the file to upload with html file-input format
	 */
	async function uploadImageToS3(imageFile) {
		let fileName = imageFile.name;
		let contentType = imageFile.type;
		let size = imageFile.size;

		try {
			const compositeData = await getUploadS3SignedUrlImage(
				fileName,
				contentType,
				size,
				"profileSignature"
			);

			await uploadS3FromSignedUrl(compositeData.signedUrl, imageFile);
			return compositeData;
		} catch (error) {
			setHelperTextOnError(i18n.t("profile.errorDuringUpload"));
			setError(true);
		}
	}

	/**
	 * Function to set image profile before uploding it to s3
	 * @param {*} e
	 */
	const handleImageChange = e => {
		e.preventDefault();
		setHelperTextOnError(" ");

		let reader = new FileReader();
		let newFile = e.target.files[0];
		reader.onloadend = () => {
			if (validateFile(newFile)) {
				setProfileImageFile(newFile);
				setIsProfileImageChanged(true);
				setImagePreviewUrl(reader.result);
			} else {
				setLoadingProfileImage(false);
			}
		};
		if (newFile) {
			reader.readAsDataURL(newFile);
		}
	};

	/**
	 * Removes the profile image and set the object value to null
	 */
	const handleImageRemove = () => {
		setProfileImageFile(null);
		setIsProfileImageChanged(true);
		setImagePreviewUrl(null);
		setUserFields({ ...userFields, picture: null });
	};

	/**
	 * Save the profile to the store and the backend/mongo
	 */
	async function handleSaveProfile() {
		let defaultBalise = "<p><br></p>";
		let valid = true;
		let noEmptySign = signature.signature !== "";
		let emptyAssistantId = signature.assistantID === "";

		if (userFields.name.length === 0) {
			setNameError(true);
			valid = false;
		}
		if (valid && noEmptySign && emptyAssistantId) {
			if (userFields.signatures !== undefined) {
				let array = { ...userFields, language: langue, picture: userFields.picture?.url };
				let newArray = [
					...array.signatures,
					{ assistantID: selectedAssistantID, signature: signature.signature }
				];
				array = { ...array, signatures: newArray };
				await handleSaveProfileFinal(array);
			} else {
				let array = {
					...userFields,
					signatures: [{ assistantID: selectedAssistantID, signature: signature.signature }],
					language: langue,
					picture: userFields.picture?.url
				};
				await handleSaveProfileFinal(array);
			}
			return;
		}
		if (valid && signature.signature !== defaultBalise && signature.assistantID !== "") {
			let array = { ...userFields, language: langue };
			const index = userFields.signatures.findIndex(s => s.assistantID === selectedAssistantID);
			let newArray = array.signatures;
			newArray[index] = signature;
			await handleSaveProfileFinal(array);
			return;
		} else {
			if (signature.signature === defaultBalise) {
				let array = { ...userFields, language: langue, picture: userFields.picture, fontSize };
				const index = array.signatures.findIndex(s => s.assistantID === selectedAssistantID);
				let newArray = { ...array, signatures: array.signatures.filter((s, i) => index !== i) };
				await handleSaveProfileFinal(newArray);
			} else {
				let array = { ...userFields, language: langue, picture: userFields.picture, fontSize };
				await handleSaveProfileFinal(array);
			}
		}
	}

	/**
	 * Perform the final actions to save the profile image, and fire save on the parent component
	 * @param {Object} array New values for profile
	 */
	const handleSaveProfileFinal = async function (array) {
		if (isProfileImageChanged && imagePreviewUrl !== null) {
			setLoadingProfileImage(true);
			let compositeData = await uploadProfileImageToS3(profileImageFile);
			props.handleSaveProfile({ ...array, picture: compositeData.fileObject });
			setLoadingProfileImage(false);
		} else {
			await props.handleSaveProfile(array);
		}
	};

	/**
	 * Component that manage the display of the profile image in the editProfile dialog
	 */
	function ImageProfileDisplay() {
		let imageSource;

		if (imagePreviewUrl) {
			imageSource = imagePreviewUrl;
		} else {
			if (userFields.picture?.url) {
				imageSource = userFields.picture.url;
			} else {
				imageSource = defaultImage;
			}
		}

		return (
			<img className="picture-src" alt="default" width="150px" height="150px" src={imageSource} />
		);
	}

	const getSunEditorInstance = sunEditor => {
		suneditor.current = sunEditor;
	};

	/**
	 * On paste we want to replace url with a anchor to create a clickable link
	 * @param {object} event javascript ClipboardEvent
	 */
	const onPaste = event => {
		const clipboardData = event.clipboardData || window.clipboardData;
		const pastedData = clipboardData.getData("Text");
		const linkExpression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
		const emailExpression =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (pastedData.match(linkExpression)) {
			suneditor.current.insertHTML(`<a href="${pastedData}">${pastedData}</a>`);
			return false;
		} else if (pastedData.match(emailExpression)) {
			suneditor.current.insertHTML(`<a href="mailto:${pastedData}">${pastedData}</a>`);
			return false;
		}

		return true;
	};

	return (
		<FormControl>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={props.open}
				onClose={props.handleCloseUserEditor}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title" onSubmit={handleSaveProfile}>
					{props.mode === "edit" ? "Edit Profile" : ""}
				</DialogTitle>
				<DialogContent dividers>
					<Box m={2} display="flex">
						{settings.getDisplayState("surname") && (
							<Box flexGrow="1" mr={0.5}>
								<TextField
									name="surname"
									label={i18n.t("EDIT_FORM.surname")}
									variant="outlined"
									value={userFields.surname}
									onChange={handleFieldChange}
									error={nameError}
									helpertextonerror={nameError ? i18n.t("EDIT_FORM.error_messages.noName") : ""}
									fullWidth
								/>
							</Box>
						)}

						{settings.getDisplayState("name") && (
							<Box flexGrow="1" ml={0.5}>
								<TextField
									name="name"
									label={i18n.t("EDIT_FORM.name")}
									variant="outlined"
									value={userFields.name}
									onChange={handleFieldChange}
									error={nameError}
									helpertextonerror={nameError ? i18n.t("EDIT_FORM.error_messages.noName") : ""}
									fullWidth
								/>
							</Box>
						)}
					</Box>
					<Box m={2}>
						{settings.getDisplayState("title") && (
							<Box flexGrow="1" ml={0.5}>
								<TextField
									name="title"
									label={i18n.t("EDIT_FORM.title")}
									variant="outlined"
									value={userFields.title}
									onChange={handleFieldChange}
									fullWidth
								/>
							</Box>
						)}
					</Box>

					<div className="picture-container">
						<FormControl component="fieldset" error={error} className={classes.formControl}>
							{loadingProfileImage ? (
								<CircularProgress size={40} />
							) : (
								<div className="picture-display">
									<IconButton
										className="picture-delete"
										aria-label="delete"
										size="small"
										onClick={handleImageRemove}
									>
										<ClearIcon />
									</IconButton>
									<div className="picture">
										<input type="file" onChange={handleImageChange} />
										<ImageProfileDisplay></ImageProfileDisplay>
									</div>
								</div>
							)}
							<FormHelperText>{helperTextOnError}</FormHelperText>
						</FormControl>
					</div>

					{settings.getDisplayState("openingHours") && (
						<Box m={2}>
							<TextField
								variant="outlined"
								fullWidth
								name="openingHours"
								label={i18n.t("EDIT_FORM.openingHours")}
								value={userFields.openingHours}
								onChange={handleFieldChange}
							/>
						</Box>
					)}

					<Box m={2} display="flex">
						{settings.getDisplayState("language") && (
							<Box flexGrow="1" mr={0.5}>
								<FormControl variant="outlined" className={classes.formControl} fullWidth>
									<InputLabel id="language_label">{i18n.t("EDIT_FORM.language")}</InputLabel>
									<Select
										labelId="language_label"
										value={langue}
										onChange={handleChangeLanguage}
										label={i18n.t("EDIT_FORM.language")}
									>
										{props.langues.map(language => {
											return (
												<MenuItem key={`menu-option-${language}`} value={language}>
													<ListItemIcon>
														<img
															src={GetFlag(language)}
															alt="EditProfil_flag"
															width="30"
															height="30"
															style={{ objectFit: "contain" }}
														/>
													</ListItemIcon>
													<span style={{ verticalAlign: "top" }}>
														{i18n.t(`EDIT_FORM.languages.${language}`)}
													</span>
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Box>
						)}
						<Box flexGrow="1" ml={0.5}>
							<FormControl
								variant="outlined"
								style={{ height: "100%" }}
								className={classes.formControl}
								fullWidth
							>
								<InputLabel id="fontSize_label">{i18n.t("EDIT_FORM.fontSize")}</InputLabel>
								<Select
									labelId="fontSize_label"
									value={fontSize}
									onChange={handleChangeFontSize}
									label={i18n.t("EDIT_FORM.fontSize")}
									style={{ textAlign: "center", flex: 1 }}
								>
									{[10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(size => {
										return (
											<MenuItem key={`menu-option-${size}`} value={size}>
												<span style={{ flex: 1, textAlign: "center" }}>{size}</span>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Box>
					</Box>
					<Box m={2} display="flex">
						{settings.getDisplayState("mobilePhone") && (
							<Box flexGrow="1" mr={0.5}>
								<TextField
									name="mobilePhone"
									label={i18n.t("EDIT_FORM.mobilePhone")}
									variant="outlined"
									value={userFields.mobilePhone}
									onChange={handleFieldChange}
									error={nameError}
									helpertextonerror={nameError ? i18n.t("EDIT_FORM.error_messages.noName") : ""}
									fullWidth
								/>
							</Box>
						)}
						{settings.getDisplayState("directPhone") && (
							<Box flexGrow="1" ml={0.5}>
								<TextField
									name="directPhone"
									label={i18n.t("EDIT_FORM.directPhone")}
									variant="outlined"
									value={userFields.directPhone}
									onChange={handleFieldChange}
									fullWidth
								/>
							</Box>
						)}
					</Box>
					{settings.getDisplayState("signature") && (
						<div
							style={{
								paddingBottom: 20,
								marginLeft: "17px",
								marginRight: "15px",
								minHeight: "500px"
							}}
						>
							<SunEditor
								getSunEditorInstance={getSunEditorInstance}
								onImageUploadBefore={(files, info, uploadHandler) => {
									uploadImageToS3(files[0]).then(compositeData => {
										const { name, size } = files[0];

										uploadHandler({
											result: [
												{
													url: compositeData?.fileObject?.url,
													name,
													size
												}
											]
										});
									});
								}}
								onPaste={onPaste}
								showToolbar={true}
								onChange={handleChangeSignature}
								setDefaultStyle="height: 500px; font-size: 14px"
								setContents={signature?.signature || ""}
								setOptions={{
									buttonList: [
										["bold", "underline", "italic", "strike", "fontSize", "fontColor"],
										["list", "align", "horizontalRule"],
										["link", "table", "image"],
										["codeView"],
										["-right", "fullScreen"]
									],
									showPathLabel: false,
									lang: fr
								}}
							/>
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleCloseUserEditor} color="primary">
						{i18n.t("EDIT_FORM.cancel")}
					</Button>
					{props.mode === "edit" && (
						<Button
							disabled={loadingProfileImage}
							onClick={async () => {
								await handleSaveProfile();
							}}
							color="primary"
						>
							{i18n.t("EDIT_FORM.save")}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</FormControl>
	);
}

export default EditProfile;
