import "./style.css";

import lod_ from "lodash";

import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Grid,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Checkbox,
	Typography,
	IconButton,
	Tooltip
} from "@material-ui/core";

import cockpitActions from "../../../redux/actions/cockpitActions";
import { handleSpecialDisplayDate } from "helpers/utilities";
import i18n from "i18n";
import DisplayItem from "../DisplayItem";
import { loadSuggestions } from "../business/searchDataInCrmCommons";
import { getTsOfMessage } from "helpers/messageUtils";
import { Close, Visibility } from "@material-ui/icons";
import { FileIcon, defaultStyles } from "react-file-icon";
import { getFileExtension, getFileType } from "../FileActions";
import { computeAgentName } from "helpers/conversationUtils";

/**
 * Draggable and Resizable modal
 * @param {*} props
 * @returns
 */
function PaperComponent(props) {
	const [width, setWidth] = useState(1000);
	const [height, setHeight] = useState(600);

	const maxWidth = window.innerWidth / 1.3;
	const maxHeight = window.innerHeight / 1.3;

	return (
		<div className="pointerEventsAll">
			<Draggable bounds="body" cancel={'[class*="MuiDialogContent-root"]'} handle=".handle">
				<ResizableBox
					width={width}
					height={height}
					handle={<span className="custom-handle" />}
					minConstraints={[400, 400]}
					maxConstraints={[maxWidth, maxHeight]}
					style={{
						backgroundColor: "white",
						overflow: "visible",
						boxShadow: "rgb(0 0 0 / 25%) 0px 0px 20px 7px",
						borderRadius: "10px",
						borderBottomRightRadius: "0px"
					}}
					{...props}
				></ResizableBox>
			</Draggable>
		</div>
	);
}
/**
 *
 * @param {String} element : Actual edited element in input
 * @param {Array} elements : List of elements to update (ex: if we are in "objet", we want to update "family", "nature" and "objet")
 * @param {String} label : Label of the input
 * @param {Object} affaire : Affaire object
 * * * * * * *
 * @param {*} others : Lists of function herited from parent
 * @returns
 */
const AutoCompleteField = ({
	element,
	disabledInput = false,
	elements,
	label,
	affaire,
	shownFields,
	suggestions,
	handlInputChange,
	handleAutocompleteChange
}) => {
	const [item, setItem] = useState({
		[element]: ""
	});

	useEffect(() => {
		if (affaire[element] !== undefined) {
			setItem(affaire);
		}
	}, [affaire]);

	return (
		<Autocomplete
			/* Value in input */
			getOptionLabel={option => {
				return option[element] || "";
			}}
			/* Render menu */
			disabled={disabledInput}
			renderOption={(suggestedValue, option) => DisplayItem(suggestedValue, shownFields)}
			/* On change */
			value={item}
			onChange={(event, newInputValue, reason) =>
				handleAutocompleteChange(event, newInputValue, reason, elements, element)
			}
			/*  */
			disablePortal
			id="combo-box-demo"
			options={suggestions}
			renderInput={params => (
				<TextField
					{...params}
					label={label}
					variant="outlined"
					fullWidth
					onChange={e => handlInputChange(e, element)}
				/>
			)}
		/>
	);
};

/**
 * Attachement component
 * @param {*} param0
 * @returns
 */
const AttachementItem = ({ attachement, attachements, handleSelectAttachement }) => {
	const [checked, setChecked] = useState(attachements.includes(attachement));

	let attachementType = attachement.contentType || attachement.type;

	const handleDisplayPicture = attachment => {
		let { name = "Fichier / File : ", url } = attachment;

		let type = ` type='${attachementType}'`;

		const css = `<html><head><title>${name}</title>`;
		let img =
			'<object style="width:100%;height:100%;margin:0 auto;" data="' + url + '"' + type + "/>";
		let body = '<body style="width:100%;height:100%;margin:0 auto;">';

		if (type.indexOf("pdf") === -1) {
			body =
				'<body style="margin: 0;transform: translate(-50%, -50%);left: 50%;top: 50%;position: absolute;">';
			img =
				'<object style="max-width:100vw;max-height:100vh;margin:0 auto;" data="' +
				url +
				'"' +
				type +
				"/>";
		}
		const popup = window.open("");
		popup.document.write(css);
		popup.document.write(body);
		popup.document.write(img);
		popup.document.write("</body></html>");
		popup.document.close();
	};

	const handleSelect = () => {
		handleSelectAttachement(attachement);
	};

	useEffect(() => {
		setChecked(attachements.includes(attachement));
	}, [attachements]);

	return (
		<Box
			className="attachementContainer"
			style={{
				border: checked ? "" : "1px solid #ccc",
				backgroundColor: checked ? "rgb(238 238 238)" : "white"
			}}
			sx={{ m: 1, p: 1 }}
			onClick={() => handleSelect()}
		>
			{attachementType?.includes("image") ? (
				<Box
					component="img"
					className="attachementContainerImage"
					alt={attachement.name}
					src={attachement.url}
				/>
			) : (
				<div className="fileIconContainer">
					<FileIcon
						extension={getFileExtension(attachement.name).replace(".", "")}
						{...defaultStyles[getFileExtension(attachement.name).replace(".", "").toLowerCase()]}
					/>
				</div>
			)}

			<Box>{attachement.name}</Box>
			<Box className="attachementContainerCheckbox">
				<Checkbox checked={checked} color="primary" />
				<Box className="attachementContainerView">
					<Tooltip placement="top" title="Visualiser">
						<IconButton
							onClick={e => {
								e.stopPropagation();
								handleDisplayPicture(attachement);
							}}
						>
							<Visibility />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
		</Box>
	);
};

/**
 * HSH Specific action to create an affair
 * @param {function} props.handleClose - Close the modal
 * @param {object} props.actionObject - Action object
 * @param {object|null} props.message - Message object
 * @returns {JSX.Element} - JSX component
 */
const SearchDataCrmInputCreateAffair = ({ handleClose, actionObject, message = null }) => {
	const [searchField, setSearchField] = useState("");
	const [searchFieldNature, setSearchFieldNature] = useState("");
	const [searchFieldObjet, setSearchFieldObjet] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [suggestionsNature, setSuggestionsNature] = useState([]);
	const [suggestionsObjet, setSuggestionsObjet] = useState([]);
	const [affaire, setAffaire] = useState({});
	const [commentaire, setCommentaire] = useState("");
	const [selectedEsi, setSelectedEsi] = useState("");
	const [emergency, setEmergency] = useState(3);
	const dispatch = useDispatch();
	// All attachements of the last message (cannot be modified)
	const [attachements, setAttachements] = useState([]);
	// Attachements selected by the user (can be modified)
	const [selectedAttachements, setSelectedAttachements] = useState([]);

	const [customText, setCustomText] = useState("");

	const [selectedOption, setSelectedOption] = useState("");

	const collection = actionObject.config.collection;
	const mappingOut = actionObject.config.mappingOut;
	const mustMerge = actionObject.config.mustMerge;
	const listFields = actionObject.config?.listFields;
	const { assistantID, user, selectedConversation, userLanguage } = useSelector(state => {
		return {
			assistantID: state.assistantconfig.assistantID,
			user: state.userStatus.auth.user,
			selectedConversation: state.cockpit.selectedConversation,
			userLanguage: state.userStatus.auth.user.language
		};
	});
	const selectValue = lod_.get(selectedConversation, listFields) || {};

	useEffect(() => {
		// lastMsgIn is the given message or the last message of the conversation
		let lastMsgIn =
			message ??
			lod_
				.cloneDeep(selectedConversation.messages)
				.reverse()
				.find(message => message.header.source === "contact");

		if (lastMsgIn) {
			let commentaireBuild = "";
			let allAttachements = [];

			if (getTsOfMessage(lastMsgIn)) {
				commentaireBuild +=
					(actionObject.config.commentaryInfo ?? "") +
					handleSpecialDisplayDate(
						getTsOfMessage(lastMsgIn),
						"DD MMM YYYY",
						userLanguage ? userLanguage : "fr"
					) +
					" - \n";
			}

			if (
				lastMsgIn.header.version === 5 ||
				lastMsgIn.header.format_version === 4 ||
				!lod_.isNil(lastMsgIn?.payload?.payload)
			) {
				commentaireBuild += lastMsgIn.payload.payload.text.cleaned_text ?? "";
				allAttachements = lastMsgIn.payload.payload.attachments;
			} else {
				commentaireBuild += lastMsgIn.body.cleaned ?? "";
				allAttachements = lastMsgIn.body.attachments;
			}

			// Set attachements values
			setSelectedAttachements(allAttachements || []);
			setAttachements(allAttachements || []);

			setCommentaire(commentaireBuild);
		}
	}, []);

	function handleSelectAttachement(attachement) {
		if (selectedAttachements.includes(attachement)) {
			setSelectedAttachements(selectedAttachements.filter(item => item !== attachement));
		} else {
			setSelectedAttachements([...selectedAttachements, attachement]);
		}
	}

	async function load(field, aggregate = null, sort = null) {
		const suggestionsFetched = await loadSuggestions(
			assistantID,
			user,
			field,
			collection,
			sort,
			50,
			aggregate
		);
		return suggestionsFetched;
	}

	useEffect(async () => {
		const suggest = await load(
			searchField,
			[
				{ $group: { _id: "$famille", doc: { $first: "$$ROOT" } } },
				{
					$replaceRoot: { newRoot: "$doc" }
				}
			],
			"famille:asc"
		);
		setSuggestions(suggest || []);
	}, [searchField]);

	useEffect(async () => {
		const suggest = await load(
			searchFieldNature,
			[
				{ $match: { famille: affaire?.famille || "" } },
				{ $group: { _id: "$nature", doc: { $first: "$$ROOT" } } },
				{
					$replaceRoot: { newRoot: "$doc" }
				}
			],
			"nature:asc"
		);
		setSuggestionsNature(suggest);
	}, [searchFieldNature]);

	useEffect(async () => {
		const suggest = await load(
			searchFieldObjet,
			[
				{ $match: { nature: affaire?.nature || "" } },
				{ $group: { _id: "$objet", doc: { $first: "$$ROOT" } } },
				{
					$replaceRoot: { newRoot: "$doc" }
				}
			],
			"objet:asc"
		);
		setSuggestionsObjet(suggest);
	}, [searchFieldObjet]);

	// This componenent manages its action by itself, but still call handleSubmit to close actions tabs
	async function handleAutocompleteChange(event, newInputValue, reason, mandatory = [], element) {
		// Fields to no
		let optionnals = ["famille", "nature", "objet"];
		let blacklist = [];

		if (reason === "clear") {
			if (element) {
				let clear = {};
				if (element === "famille") {
					clear = { famille: "", objet: "", nature: "", codeCourtNature: "" };
					setSearchField("");
					setSearchFieldNature("");
					setSearchFieldObjet("");
				} else if (element === "nature") {
					clear = { objet: "", nature: "", codeCourtNature: "" };
					setSearchFieldNature("");
					setSearchFieldObjet("");
				} else {
					clear = { [element]: "" };
				}
				setAffaire(affaire => {
					return {
						...affaire,
						...clear
					};
				});
			}
			return;
		}

		if (mappingOut && newInputValue && reason === "select-option") {
			let values = lod_.cloneDeep(newInputValue);

			const data = {
				payload: {}
			};

			let optionnalsNotInLevel = lod_.difference(optionnals, mandatory);

			if (element === "famille") {
				for (let opt of optionnalsNotInLevel) {
					values[opt] = "";
				}

				if (values?.codeCourtNature) {
					values.codeCourtNature = "";
				}
			} else if (element === "nature") {
				values.objet = "";
			}

			// remove blacklisted fields
			for (let black of blacklist) {
				values[black] = affaire[black] || "";
			}

			// test mappingOut type
			if (typeof mappingOut === "string") {
				data.payload[mappingOut] = values;
			}

			if (typeof mappingOut === "object" && !Array.isArray(mappingOut)) {
				// Format is the following

				// "mappingOut": {
				// 	"context.customer.area": "area"
				// 	"context.customer.name": "name",
				// }, // mappingOut Can be an array too if multi mapping

				for (let key in mappingOut) {
					data.payload[key] = values?.[mappingOut[key]];
				}
			}

			if (mustMerge !== undefined) {
				data.payload["mustMerge"] = mustMerge;
			}

			setAffaire(data.payload[mappingOut]);

			if (element === "famille") {
				const suggest = await load(
					"",
					[
						{ $match: { famille: newInputValue?.famille || "" } },
						{ $group: { _id: "$nature", doc: { $first: "$$ROOT" } } },
						{
							$replaceRoot: { newRoot: "$doc" }
						}
					],
					"nature:asc"
				);
				setSuggestionsNature(suggest);
			}

			if (element === "nature") {
				const suggest = await load(
					"",
					[
						{
							$match: { famille: affaire?.famille || "", nature: newInputValue?.nature || "" }
						},
						{ $group: { _id: "$objet", doc: { $first: "$$ROOT" } } },
						{
							$replaceRoot: { newRoot: "$doc" }
						}
					],
					"objet:asc"
				);
				setSuggestionsObjet(suggest);
			}
		}
	}

	function handlInputChange(event, row) {
		setSearchField(event.target.value);
	}

	function handlInputChangeNature(event, row) {
		setSearchFieldNature(event.target.value);
	}

	function handlInputChangeObjet(event, row) {
		let value = event.target.value.toUpperCase();
		setSearchFieldObjet(value);
		let clone = lod_.cloneDeep(affaire);
		clone.objet = value;
		setAffaire(clone);
	}

	function handleCommentaryChange(event) {
		setCommentaire(event.target.value);
	}

	function handleSelectEmergency(event) {
		setEmergency(event.target.value);
	}

	function createAffaire(event) {
		const data = {
			header: {
				assistantID: selectedConversation?.header?.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload: {}
		};
		data.payload[mappingOut] = affaire;
		data.payload["context.esi.patrimoine"] = selectedEsi;
		data.payload.emergency = emergency;
		data.payload.externalId = user.external_id;
		data.payload.commentaire = commentaire;
		data.payload.attachements = selectedAttachements;
		data.payload.agentSender = {
			uid: user._id,
			name: computeAgentName(user)
		};
		dispatch(cockpitActions.launchActionFrontV2("HSHCreateAffair", data));
		handleClose();
	}

	const handleChange = event => {
		const value = event.target.value;

		// Réinitialiser le texte personnalisé lorsqu'une option autre que la dernière est sélectionnée
		if (value !== "custom") {
			setSelectedEsi(value);
			setSelectedOption(value);
			setCustomText("");
		} else {
			setSelectedOption(value);
		}
	};

	const handleCustomTextChange = event => {
		setCustomText(event.target.value.toUpperCase());
		setSelectedEsi(event.target.value.toUpperCase());
	};

	return (
		<Dialog
			open
			aria-labelledby="draggable-dialog-title"
			fullWidth
			maxWidth="lg"
			hideBackdrop={true}
			PaperComponent={PaperComponent}
			style={{
				pointerEvents: "none"
			}}
		>
			<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title" className="handle">
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					<div>{i18n.t("TabAction.createAffair")}</div>
					<div>
						<IconButton onClick={handleClose}>
							<Close />
						</IconButton>
					</div>
				</div>
			</DialogTitle>
			<DialogContent className="handle">
				<Grid container justifyContent="space-between">
					<Grid container spacing={1}>
						{/* Famille */}
						<Grid item xs={4}>
							<Box>
								<AutoCompleteField
									element="famille"
									elements={["famille"]}
									label={i18n.t("TabAction.family")}
									affaire={affaire}
									shownFields={["famille"]}
									suggestions={suggestions}
									handlInputChange={handlInputChange}
									handleAutocompleteChange={handleAutocompleteChange}
								/>
							</Box>
						</Grid>
						{/* Nature */}
						<Grid item xs={4}>
							<Box>
								<AutoCompleteField
									disabledInput={!affaire?.famille}
									element="nature"
									elements={["nature"]}
									label={i18n.t("TabAction.nature")}
									affaire={affaire}
									shownFields={["nature"]}
									suggestions={suggestionsNature}
									handlInputChange={handlInputChangeNature}
									handleAutocompleteChange={handleAutocompleteChange}
								/>
							</Box>
						</Grid>
						{/* Objet */}
						<Grid item xs={4}>
							<Box>
								<AutoCompleteField
									disabledInput={!affaire?.nature}
									element="objet"
									elements={["objet"]}
									label={i18n.t("TabAction.topic")}
									affaire={affaire}
									shownFields={["objet"]}
									suggestions={suggestionsObjet}
									handlInputChange={handlInputChangeObjet}
									handleAutocompleteChange={handleAutocompleteChange}
								/>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={1}
					justifyContent="space-between"
					style={{ marginTop: "5px", marginBottom: "5px" }}
				>
					{/* Urgence */}
					<Grid item xs={6}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="demo-simple-select-label">{i18n.t("TabAction.emergency")}</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={emergency}
								label={i18n.t("TabAction.emergency")}
								onChange={handleSelectEmergency}
							>
								<MenuItem value={1}>Très urgent</MenuItem>
								<MenuItem value={2}>Urgent</MenuItem>
								<MenuItem value={3}>Normal</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{/* ESI */}
					<Grid item xs={6}>
						<FormControl fullWidth variant="outlined">
							{selectedOption !== "custom" && (
								<>
									<InputLabel id="demo-simple-select-label">ESI</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										label="ESI"
										onChange={handleChange}
										value={selectedOption}
									>
										{Object.entries(selectValue).map(([key, value]) => {
											return (
												key !== "patrimoine" && (
													<MenuItem key={key} value={value}>
														{key} : {value}
													</MenuItem>
												)
											);
										})}
										<MenuItem value="custom">ESI personalisée</MenuItem>
									</Select>
								</>
							)}
							{selectedOption === "custom" && (
								<TextField
									id="custom-text"
									label="ESI personalisée"
									value={customText}
									onChange={handleCustomTextChange}
									fullWidth
									InputProps={{
										endAdornment: (
											<IconButton
												onClick={() => {
													setSelectedOption(null);
													setSelectedEsi("");
												}}
												size="small"
											>
												<Close />
											</IconButton>
										)
									}}
									variant="outlined"
								/>
							)}
						</FormControl>
					</Grid>
				</Grid>

				<TextField
					id="outlined-multiline-static"
					label={i18n.t("TabAction.commentary")}
					multiline
					minRows={10}
					variant="outlined"
					fullWidth
					value={commentaire}
					onChange={handleCommentaryChange}
					InputLabelProps={{ shrink: commentaire }}
				/>

				<Box sx={{ flexGrow: 1, mt: 2 }}>
					<Typography variant="h6" gutterBottom>
						Pièces jointes
					</Typography>
					<Box
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "center"
						}}
					>
						{lod_.isEmpty(attachements) && (
							<Box>
								<Typography>
									<i>Aucune pièce jointe trouvée</i>
								</Typography>
							</Box>
						)}
						{attachements.map((attachement, index) => {
							return (
								<AttachementItem
									key={index}
									attachement={attachement}
									attachements={selectedAttachements}
									handleSelectAttachement={handleSelectAttachement}
								/>
							);
						})}
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button style={{ color: "red" }} onClick={handleClose}>
					{i18n.t("TabAction.cancel")}
				</Button>
				<Button
					color="primary"
					autoFocus
					onClick={e => createAffaire(e)}
					disabled={!commentaire || !selectedEsi || !affaire || !emergency}
				>
					{i18n.t("TabAction.actionCreateAffair")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SearchDataCrmInputCreateAffair;
