import settings from "../settings.json";

const getChannels = () => {
	return settings.channels;
};

const getLanguages = () => {
	return settings.languages;
};

const getFlags = () => {
	return settings.flags;
};

const getRoles = () => {
	return settings.roles;
};

// const getFlagCode = (countryCode) => {
//     const flagCode = settings.flags[countryCode];
//     if (flagCode) {
//         return flagCode;
//     } else {
//         return countryCode;
//     }
// }

const getDisplayState = info => {
	if (settings && settings.informationToShow) {
		const setting = settings.informationToShow.find(information => information.key === info);
		return setting ? setting.state : true;
	}
	return true;
};

export default {
	getDisplayState,
	getLanguages,
	getRoles,
	getFlags,
	getChannels
};
