import React, { useEffect, useState, useRef } from "react";
import {
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Popper,
	Tooltip,
	Zoom
} from "@material-ui/core";

import i18n from "i18n";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ArrowRight, MoreVertRounded } from "@material-ui/icons";
import { ListItem } from "@mui/material";
import { Box } from "@mui/system";

/**
 * <DropMenu
 *  handleAction={(data) => {} }   --> Return selected item
 *  items=[
 *    {
 *      "name": "Display name",
 *      "action": "action"
 *    },
 *    {
 *      "name": "Display name two",
 *      "action": "actionTwo"
 *    },
 *    {
 *      "name": "More actions",
 *      "action": "moreActions",
 *      "subMenu": [
 *        {
 *          "name": "Sub action 1",
 *          "action": "subAction1"
 *        },
 *        {
 *          "name": "Sub action 2",
 *          "action": "subAction2",
 *        }
 *      ]
 *    }
 * ]
 * >
 * </DropMenu>
 *
 * Item must have params : name, action. If subMenu is set, it must have params : name, action, subMenu.
 * You can also add your custom params.
 * name param can be anything (string, number, DOM element, etc.)
 *
 * DropMenu can be controlled by custom element with param controller and setController (refer to custom element anchor)
 */

/**
 *
 * @param {*} param0
 * @returns
 */
const DropMenu = ({ items, handleAction, customOpen = null }) => {
	const [open, setOpen] = useState(false);
	const openRef = useRef(null);
	const prevOpen = useRef(open);
	/**
	 *
	 */
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			openRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	return (
		<React.Fragment>
			{customOpen ? (
				<Box ref={openRef} onClick={() => setOpen(prev => !prev)}>
					{customOpen}
				</Box>
			) : (
				<IconButton size="small" ref={openRef} onClick={() => setOpen(prev => !prev)}>
					<MoreVertRounded />
				</IconButton>
			)}
			<Popper
				style={{ zIndex: 1 }}
				open={open}
				anchorEl={openRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{() => (
					<ClickAwayListener onClickAway={() => setOpen(false)}>
						<Paper>
							{items.map((item, index) => (
								<MyMenuItem key={index} item={item} handleAction={handleAction} setOpen={setOpen} />
							))}
						</Paper>
					</ClickAwayListener>
				)}
			</Popper>
		</React.Fragment>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
const MyMenuItem = ({ item, handleAction, setOpen }) => {
	const Component = item.subMenu?.items?.length > 0 ? MultiLevel : SingleLevel;
	return <Component item={item} handleAction={handleAction} setOpen={setOpen} />;
};

/**
 *
 * @param {*} param0
 * @returns
 */
const SingleLevel = ({ item, handleAction, setOpen }) => {
	return (
		<ListItem button>
			<ListItemText
				primary={item.name}
				onClick={() => {
					handleAction(item);
					setOpen(false);
				}}
			/>
		</ListItem>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
const MultiLevel = ({ item, handleAction, setOpen }) => {
	const children = item.subMenu.items;
	const [open, setSubOpen] = useState(false);
	const openRef = useRef(null);
	const prevOpen = useRef(open);
	function handleClose(val) {
		setOpen(val);
		setSubOpen(val);
	}
	/**
	 *
	 */
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			openRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	return (
		<React.Fragment>
			<div onMouseLeave={() => setSubOpen(false)}>
				<ListItem button ref={openRef} onMouseEnter={() => setSubOpen(true)}>
					<ListItemText primary={<>{item.name}</>} />
					<ArrowRight />
				</ListItem>
				<Popper
					style={{ zIndex: 1 }}
					placement={"right"}
					open={open}
					anchorEl={openRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{() => (
						<Paper>
							{children.map((child, key) => (
								<Zoom
									key={key}
									in={open}
									className={"subModule"}
									style={{ cursor: "pointer", transitionDelay: `${key}25ms` }}
								>
									<Box
										p={1.5}
										onClick={() => {
											handleAction(child);
											handleClose(false);
										}}
									>
										{child.name}
									</Box>
								</Zoom>
							))}
						</Paper>
					)}
				</Popper>
			</div>
		</React.Fragment>
	);
};
export default DropMenu;
