import { configureStore } from "@reduxjs/toolkit";
import apiMiddleware from "./middleware/api";
import wsMiddleware from "./middleware/ws";
import rootReducer from "./reducers/index";
import { s3 } from "./reducers/s3Reducers";
import { ws } from "./reducers/wsReducers";
import { notification } from "./reducers/notificationReducers";
import { conversation } from "./reducers/conversationReducers";
import { snackMsgs } from "./reducers/snackMsgsReducers";
import { answers } from "./reducers/answersReducers";
import { filters } from "./reducers/filtersReducers";

export const preloadedState = {
	error: {
		disconnect: false,
		message: "",
		severity: "error"
	},
	labels: {},
	userStatus: {},
	assistants: [],
	assistantconfig: [],
	assistantconfigCatalog: [],
	engage: [],
	selectedAssistantID: null,
	answers,
	cockpit: {
		conversations: [],
		documents: [],
		conversationsFetched: false,
		attachments: [],
		convHistory: [],
		countHistory: 0,
		convHistoryArchived: [],
		isLoading: false,
		nbWaitingConvs: 0,
		nbOngoingConvs: 0,
		nbResolvedConvs: 0,
		nbArchivedConvs: 0,
		nbHiddenConvs: 0,
		nbLiveConvs: 0,
		convAscFilter: false,
		convSupervisorFilter: true,
		convAgentFilter: true,
		convAgentFetchedOngoing: false,
		convAgentFetchedResolved: false,
		convIntentFilter: [],
		convGroupFilter: [],
		convUserFilter: [],
		convSearchFilter: "",
		convStartDateFilter: null,
		convEndDateFilter: null,
		convPriorityFilter: null,
		convSentimentFilter: null,
		convConnectedFilter: true, //By default
		convSubstateFilter: null,
		convEscalationFilter: null,
		convLanguageFilter: null,
		convChannelFilter: null,
		selectedConversation: null,
		leftTab: 0,
		feedbacks: [],
		defaultChannel: {},
		canOpenEngage: false,
		isCockpitArchived: false, // Is the cockpit display the archived one,
		postDraftLoading: false,
		addedContent: null,
		topicDraft: null,
		convSending: false,
		currentMessage: null,
		convLoading: false,
		historyLoading: false,
		scrollToSelectedConversation: false,
		urgent: false,
		nbCurrentConvs: [],
		nbColdConvs: 0,
		isCold: false,
		convsReload: false,
		htmlToInsert: null,
		isConvListMinimized: false
	},
	userGroup: {
		groups: [],
		users: [],
		onlineUsers: [],
		groupsAreFetching: true
	},
	releaseNotes: {},
	s3,
	snackMsgs,
	ws,
	notification,
	conversation,
	filters
};

const store = configureStore({
	reducer: rootReducer,
	preloadedState,
	devTools: process.env.NODE_ENV === "development" || process.env.REACT_APP_ENV === "test1",
	middleware: getDefaultMiddleware => {
		const middlewares = getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		});
		middlewares.push(apiMiddleware);
		middlewares.push(wsMiddleware);
		return middlewares;
	}
});

export default store;
