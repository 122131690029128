import React from "react";
import axios from "axios";
import store from "../redux/store";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
		try {
			const storeState = store.getState();
			const endPoint = process.env.REACT_APP_AMBACK;

			const errorInfos = `User ${storeState?.userStatus?.auth?.user?.email} of ${storeState?.selectedAssistantID}. Selected conversation is ${storeState?.cockpit?.selectedConversation?.header?.fID}. Checking ${storeState?.cockpit?.nbCurrentConvs?.[1]} ${storeState?.cockpit?.nbCurrentConvs?.[0]} conversations. On page ${window.location.href}`;

			const errorMessage = `${error.message} with errorInfos ${errorInfos}`;
			const errorStack = JSON.stringify(error?.stack);
			const errorComponentStack = errorInfo?.componentStack;

			const options = {
				url: `${endPoint}/errorCockpit`,
				method: "POST",
				data: {
					errorMessage,
					errorStack,
					errorComponentStack
				}
			};
			axios(options);
		} catch (error) {
			// console.error(error);
		}
	}

	render() {
		return this.props.children;
	}
}

export default ErrorBoundary;
