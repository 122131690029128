import C from "../../constants/routes.js";

const routesReducers = function (currentState = [], action) {
	switch (action.type) {
		case C.UPDATE_ROUTES: {
			let route = action.payload;
			let existingRouteIndex = currentState.findIndex(r => r.path === route.path);
			if (existingRouteIndex !== -1) {
				return currentState.map(r => (r.path === route.path ? route : r));
			} else {
				return { ...currentState, route };
			}
		}
		case "SET_FROM_USER": {
			const routes = [...currentState];
			const index = routes.findIndex(route => route.name === "Profile");
			const route = { ...routes[index], fromProfile: action.payload.value };
			routes[index] = route;

			return [...routes];
		}

		default:
			return currentState;
	}
};

export default routesReducers;
