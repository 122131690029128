/* eslint-disable no-fallthrough */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ChannelUserInfoMembershipDisplayer from "../../../../helpers/ChannelUserInfoMembershipDisplayer";
import { getConvItemSelector } from "../../../../redux/selectors/cockpitSelectors";
import C from "../../../../constants/cockpit";
import { formatDate } from "helpers/utilities";
import PriorityIntentSentimentDisplayer from "pages/Cockpit/PriorityIntentSentimentDisplayer";

const ConvItemRendererCOLD = ({ measure, conv }) => {
	const { selectedConversation } = useSelector(getConvItemSelector, shallowEqual);
	const dispatch = useDispatch();

	//to remeasure dynamically the height of the row
	useEffect(() => {
		measure();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conv]);

	/**
	 *
	 * @param {*} conv
	 */
	const handleSelectConversation = conv => {
		dispatch({
			type: C.SELECT_CONVERSATION,
			payload: conv
		});
	};
	// see log message at the end
	if (conv === null || conv === undefined) return;

	return (
		<ListItem
			button
			selected={selectedConversation?.header?.fID === conv.header.fID}
			onClick={() => {
				handleSelectConversation(conv);
			}}
		>
			<ListItemText>
				<Box width={1}>
					<Box display="flex" alignItems="center">
						<ChannelUserInfoMembershipDisplayer
							channelImage={conv?.meta?.cockpit?.channelCode || ""}
							channel={conv?.header?.channel}
							contactNameOrEmail={conv.payload?.contact}
							FRU={conv?.contact?.uid}
						/>
					</Box>
					<Box fontWeight="fontWeightBold" display="flex">
						<PriorityIntentSentimentDisplayer
							title={conv?.payload?.title}
							parentName={"ConvItemRendererCOLD"}
						/>
					</Box>
					<Box display="flex" alignItems="center" flexWrap="wrap">
						{
							<Box flexGrow={1} fontSize={11}>
								{formatDate(conv.header.last)}
							</Box>
						}
						{conv.header.srcfID ? (
							<Box flexGrow={6} fontSize={11}>
								{conv.header.srcfID}
							</Box>
						) : (
							<></>
						)}
						<Box>
							{conv.payload?.hasAttachment && <AttachFileOutlinedIcon />}
							{conv.payload?.hasNote && (
								<NoteOutlinedIcon style={{ transform: "rotate(270deg)" }} />
							)}
						</Box>
					</Box>
				</Box>
			</ListItemText>
		</ListItem>
	);
};

export default ConvItemRendererCOLD;
