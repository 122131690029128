/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";
import lod_ from "lodash";
import labelsActions from "../../redux/actions/labelsActions.js";
import i18n from "i18next";

import {
	Checkbox,
	DialogActions,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip
} from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import MessageIcon from "@material-ui/icons/Message";
import ForumIcon from "@material-ui/icons/Forum";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import GetChannelImage from "helpers/GetChannelImage.js";
import { realNowFromTS } from "helpers/utilities.js";
import { getRcvSntAtt, getRcvSntAttAll } from "./CockpitTabInfo/CockpitTabInfo.js";
import { infoMsg } from "redux/reducers/snackMsgsReducers.js";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		overflow: "auto"
	},
	listSection: {
		backgroundColor: "inherit"
	},
	ul: {
		backgroundColor: "inherit",
		padding: 0
	}
}));

function CopyToClipboardDialog(props) {
	const classes = useStyles();

	const [data, setData] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const dispatch = useDispatch();
	const { selectedAssistantID } = useSelector(state => state);

	const { currentMessage, selectedConversation } = useSelector(state => state.cockpit);
	const { assistantconfig } = useSelector(state => state);
	const { auth } = useSelector(state => state.userStatus);

	useEffect(() => {
		let storage = JSON.parse(localStorage.getItem("presse-papier"));
		if (!lod_.isNil(storage)) {
			setData(storage.filter(filter => filter.assistantID === selectedAssistantID));
		} else {
			setData([]);
		}
	}, [localStorage.getItem("presse-papier")]);

	// When close pop-up reset selected items
	const handleClose = () => {
		props.handleCloseCopyToClipboardDialog();
		setSelectedItems([]);
	};

	// Delete all items in localStorage
	const deleteAllItems = () => {
		setSelectedItems([]);
		let storage = JSON.parse(localStorage.getItem("presse-papier"));
		if (!lod_.isNil(storage)) {
			let filter = storage.filter(filter => filter.assistantID !== selectedAssistantID);
			setData([]);
			localStorage.setItem("presse-papier", JSON.stringify(filter));
		} else {
			setData([]);
		}
		dispatch(cockpitActions.setCopyClipboard());
	};

	const addAllItems = () => {
		dispatch(infoMsg("Ajout en cours"));
		data
			.filter(filter => filter.assistantID === selectedAssistantID)
			.forEach(item => {
				if (item.type === "image") {
					dispatch(
						cockpitActions.clickToUpload(
							item.url,
							auth.user.email,
							assistantconfig.assistantID,
							currentMessage.mID || "",
							item.name
						)
					);
				} else if (item.type === "conversation") {
					convToPDF(item);
				}
			});
	};

	const addAllItemsAndClear = () => {
		dispatch(infoMsg("Ajout en cours"));

		data
			.filter(filter => filter.assistantID === selectedAssistantID)
			.forEach(item => {
				if (item.type === "image") {
					dispatch(
						cockpitActions.clickToUpload(
							item.url,
							auth.user.email,
							assistantconfig.assistantID,
							currentMessage.mID || "",
							item.name
						)
					);
				} else if (item.type === "conversation") {
					convToPDF(item);
				}
			});
		deleteAllItems();
	};

	// If i select one item set in array the index for delete later
	const handleToggleItem = index => {
		setSelectedItems(prevSelected => {
			if (prevSelected.includes(index)) {
				return prevSelected.filter(selectedIndex => selectedIndex !== index);
			} else {
				return [...prevSelected, index];
			}
		});
	};

	// Delete selected items in local storage
	const handleDeleteSeletedItems = () => {
		let storage = JSON.parse(localStorage.getItem("presse-papier"));

		const parsedIndices = selectedItems.map(index => parseInt(index, 10));

		parsedIndices.sort((a, b) => b - a);

		parsedIndices.forEach(index => {
			if (index >= 0 && index < storage.length) {
				storage.splice(index, 1);
			}
		});

		setData(storage);
		setSelectedItems([]);
		localStorage.setItem("presse-papier", JSON.stringify(storage));
		dispatch(cockpitActions.setCopyClipboard());
	};

	const handleDeleteSeletedItem = index => {
		let storage = JSON.parse(localStorage.getItem("presse-papier"));

		storage.splice(index, 1);

		setData(storage);
		localStorage.setItem("presse-papier", JSON.stringify(storage));
		dispatch(cockpitActions.setCopyClipboard());
	};

	const handleAddSeletedItems = () => {
		dispatch(infoMsg("Ajout en cours"));

		let storage = JSON.parse(localStorage.getItem("presse-papier"));

		const parsedIndices = selectedItems.map(index => parseInt(index, 10));

		parsedIndices.sort((a, b) => b - a);

		parsedIndices.forEach(index => {
			if (index >= 0 && index < storage.length) {
				if (storage[index].type === "image") {
					dispatch(
						cockpitActions.clickToUpload(
							storage[index].url,
							auth.user.email,
							assistantconfig.assistantID,
							currentMessage.mID || "",
							storage[index].name
						)
					);
				} else if (storage[index].type === "conversation") {
					convToPDF(storage[index]);
				}
			}
		});
	};

	const handleAddSeletedItem = item => {
		dispatch(infoMsg("Ajout en cours"));

		if (item.type === "image") {
			dispatch(
				cockpitActions.clickToUpload(
					item.url,
					auth.user.email,
					assistantconfig.assistantID,
					currentMessage.mID || "",
					item.name
				)
			);
		} else if (item.type === "conversation") {
			convToPDF(item);
		} else if (item.type === "message") {
			// no code here
		}
	};

	function convertTimestampToFormattedDate(timestamp) {
		let date = realNowFromTS(timestamp);

		// Dividir la cadena en fecha y hora
		var partes = date.datetime.split(" ");
		var fechaPartes = partes[0].split("-");
		var horaPartes = partes[1].split(":");

		// Construir la cadena en el formato deseado
		var fechaFormateada =
			fechaPartes[2] +
			"/" +
			fechaPartes[1] +
			"/" +
			fechaPartes[0] +
			" " +
			horaPartes[0] +
			":" +
			horaPartes[1];

		return fechaFormateada;
	}

	function convToPDF(item) {
		let successCB = () => {};
		let failCB = () => {};

		let action = "downloadConversationPDFToClipboard";
		let rcvAtt = [];
		let sntAtt = [];

		dispatch(
			cockpitActions.doAnAction(
				item.fID,
				item.isArchivedConv,
				null,
				action,
				rcvAtt,
				sntAtt,
				null,
				false,
				null,
				false,
				successCB,
				failCB
			)
		);
	}

	function convWhithAttachementsToPDF(item) {
		let successCB = () => {};
		let failCB = () => {};

		let action = "downloadConversationPDFWithAttachementsToClipboard";

		dispatch(
			cockpitActions.doAnAction(
				item.fID,
				item.isArchivedConv,
				null,
				action,
				item.rcvAttAll,
				item.rcvAttAll,
				null,
				false,
				null,
				false,
				successCB,
				failCB
			)
		);
	}

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={props.open}
			onClose={props.handleCloseCopyToClipboardDialog}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{i18n.t("COC.upCopyClipboard")}</DialogTitle>
			<DialogContent
				style={{
					height: "65vh",
					display: "flex",
					flexDirection: "column",
					justifyContent: lod_.isEmpty(data) ? "center" : "none"
				}}
				dividers
			>
				{lod_.isEmpty(data) ? (
					<Box display="flex" alignItems="center" width={1} justifyContent="center">
						<Typography>{i18n.t("COC.noElementCopyClipboard")}</Typography>
					</Box>
				) : (
					<Box>
						<Box style={{ display: "flex" }}>
							<Box>
								{selectedItems.length !== 0 && selectedItems.length}
								{selectedItems.length !== 0 && (
									<Button onClick={handleDeleteSeletedItems} disabled={selectedItems.length === 0}>
										{i18n.t("COC.delete")}
									</Button>
								)}
								{selectedItems.length !== 0 && selectedItems.length}

								{selectedItems.length !== 0 && (
									<Button onClick={handleAddSeletedItems} disabled={selectedItems.length === 0}>
										{i18n.t("COC.addCopyClipboard")}
									</Button>
								)}
							</Box>
							<Box>
								<Button onClick={deleteAllItems}> {i18n.t("COC.DeleteAll")}</Button>
								<Button onClick={addAllItems}> {i18n.t("COC.addAll")}</Button>
								<Button onClick={addAllItemsAndClear}> {i18n.t("COC.addAllAndTrash")}</Button>
							</Box>
						</Box>
						<List>
							{data
								.filter(filter => filter.assistantID === selectedAssistantID)
								.map((item, index) => (
									<>
										<Box style={{ display: "flex" }}>
											<ListItem key={index} button onClick={() => handleToggleItem(index)}>
												<Checkbox checked={selectedItems.includes(index)} color="primary" />

												{item.type === "message" ? (
													<>
														<ListItemAvatar>
															<MessageIcon />
														</ListItemAvatar>
														<Tooltip title={i18n.t("COC.idConv")}>
															<ListItemText
																primary={item.title + " " + `(${item.contact})`}
																secondary={
																	convertTimestampToFormattedDate(item.date) +
																	" message de la conversation :" +
																	item.fID
																}
															/>
														</Tooltip>
													</>
												) : item.type === "conversation" ? (
													<>
														<ListItemAvatar>
															<img
																src={GetChannelImage([], item?.code?.toUpperCase())}
																alt="Channel-logo"
																width="30"
																height="30"
																className="logo-channel"
															/>
														</ListItemAvatar>
														<Tooltip title={i18n.t("COC.idMessage")}>
															<ListItemText
																primary={item.title + " " + `(${item.contact})`}
																secondary={
																	convertTimestampToFormattedDate(item.date) + " " + item.fID
																}
															/>
														</Tooltip>
													</>
												) : (
													<>
														<ListItemAvatar>
															<AttachFileIcon />
														</ListItemAvatar>

														<Tooltip title={i18n.t("COC.infoPJ")}>
															<ListItemText primary={item.name} secondary={item.fID} />
														</Tooltip>
													</>
												)}
											</ListItem>
											<Box
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													width: "10%"
												}}
											>
												<Tooltip title={i18n.t("COC.addCopyClipboard")} placement="bottom">
													<IconButton size="small" onClick={() => handleAddSeletedItem(item)}>
														<AddIcon />
													</IconButton>
												</Tooltip>
												{item.type === "conversation" && (
													<Tooltip
														title={i18n.t("COC.addWithAttachementsCopyClipboard")}
														placement="bottom"
													>
														<IconButton
															size="small"
															onClick={() => convWhithAttachementsToPDF(item)}
														>
															<AddToPhotosIcon />
														</IconButton>
													</Tooltip>
												)}

												<Tooltip title={i18n.t("COC.deleteCopyClipboard")} placement="bottom">
													<IconButton size="small" onClick={() => handleDeleteSeletedItem(index)}>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
											</Box>
										</Box>
									</>
								))}
						</List>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary" style={{ marginRight: 27 }}>
					{`${i18n.t("COC.CancelCopy")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch),
		labelsActions: bindActionCreators(labelsActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(CopyToClipboardDialog);
