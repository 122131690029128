/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import NoteOutlinedIcon from "@material-ui/icons/NoteOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import i18n from "i18n";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import cockpitActions from "redux/actions/cockpitActions";

import "../assets/css/HistoricList.css";
import ChannelUserInfoMembershipDisplayer from "./ChannelUserInfoMembershipDisplayer";
import AttachmentsContainer from "../helpers/AttachmentsContainer";
import { downloadColdFile } from "./utilities";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		marginBottom: "10px",
		marginTop: "10px",
		borderRadius: "15%"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: "33.33%",
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: "black"
	},
	AccordionDetails: {
		marginBottom: "10px"
	}
}));

function HistoricListCold({ conversation, userStatus }) {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);
	const user = userStatus?.auth?.user;

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	let displayedDate = (conversation?.header?.start || "").substring(0, 16);
	let displayedTitle = (conversation?.payload?.title || "").substring(0, 40);

	return (
		<Box>
			<Accordion
				className={classes.root}
				TransitionProps={{ unmountOnExit: true }}
				expanded={expanded === "panel1"}
				onChange={handleChange("panel1")}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					style={{ backgroundColor: "#e7f2ff" }}
				>
					<Box className={classes.heading}>
						<ChannelUserInfoMembershipDisplayer
							// convHistory={[]}
							channelImage={conversation?.meta?.cockpit?.channelCode || ""}
							contactNameOrEmail={conversation?.payload?.contact || ""}
							FRU={conversation?.contact?.uid}
							channel={conversation?.header?.channel}
							fID={conversation?.header?.fID}
							assistantID={conversation?.header?.assistantID}
						/>
						<Grid container>
							<Grid
								item
								xs={6}
								container
								justifyContent="flex-start"
								direction="row"
								alignItems="center"
							>
								<Box width="25em">{(conversation?.header?.fID || "").substring(0, 16)}</Box>
								<Box width="25em">{i18n.t("COLD.cold")}</Box>
							</Grid>
						</Grid>
					</Box>

					<Box xs={4} justifyContent="center" direction="column" alignItems="center">
						<Box textAlign="center" width="20em">
							{displayedDate}
						</Box>
						<Box width="20em" textAlign="center">
							{displayedTitle}
						</Box>
					</Box>

					<Grid
						container
						display="flex"
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
					>
						{conversation?.payload?.hasAttachment && <AttachFileOutlinedIcon />}
						{conversation?.payload?.hasNote && (
							<NoteOutlinedIcon style={{ transform: "rotate(270deg)" }} />
						)}
					</Grid>
				</AccordionSummary>
				<AccordionDetails className={classes.AccordionDetails}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Grid container spacing={2} direction="column">
								<Grid item>
									<span>{i18n.t("COLD.downloadConversation")}:</span>
								</Grid>
								<Grid item>
									<a
										onClick={() => downloadColdFile(conversation?.payload?.PDFLink)}
										style={{ color: "black" }}
									>
										<IconButton aria-label="delete" size="medium">
											<GetAppIcon fontSize="inherit" />
										</IconButton>
									</a>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Grid container spacing={2} direction="column">
								<Grid item>
									<span>{i18n.t("COLD.downloadFiles")}:</span>
								</Grid>
								<Grid item>
									<AttachmentsContainer
										maxHeight="50%"
										attachmentsFiltered={conversation?.payload?.attachments}
										allAttachments={conversation?.payload?.attachments}
										title={i18n.t("COLD.titleAttachemnts")}
										emptyMsg={i18n.t("COC.noFile")}
										type="sent"
										downloadAll={i18n.t("COC.downloadAll")}
										showAll={i18n.t("COC.showAll")}
										activeInlineFilter={false}
										inProgress={false}
										customDownloadProcess={downloadColdFile}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		cockpitActions: bindActionCreators(cockpitActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(HistoricListCold);
