import React from "react";
import { Box } from "@material-ui/core";
import mapPNG from "../../assets/img/logo/default/map.png";
import i18n from "i18next";
import { getACleanURI } from "helpers/utilities";

export function AttachmentDisplay({ type, mimeType, attachmentInfo }) {
	const displayMedia = (type, attachmentInfo) => {
		if (mimeType) {
			if (mimeType.indexOf("image") >= 0) {
				return (
					<img
						src={getACleanURI(attachmentInfo.url)}
						alt={attachmentInfo.name}
						style={{ maxWidth: "100%" }}
					/>
				);
			} else if (mimeType.indexOf("video") >= 0) {
				return <video controls src={getACleanURI(attachmentInfo.url)} />;
			} else if (mimeType.indexOf("audio") >= 0) {
				return (
					<audio controls>
						<source src={getACleanURI(attachmentInfo.url)} type={mimeType} />
						Your browser does not support the audio element.
					</audio>
				);
			} else {
				return (
					<div>
						<a href={getACleanURI(attachmentInfo.url)} target="_blank" rel="noreferrer">
							{attachmentInfo.name}
						</a>
					</div>
				);
			}
		} else {
			switch (type) {
				case "file":
					return (
						<div>
							<a href={getACleanURI(attachmentInfo.url)} target="_blank" rel="noreferrer">
								{attachmentInfo.name}
							</a>
						</div>
					);
				case "image" || "sticker":
					return <img src={getACleanURI(attachmentInfo.url)} alt={attachmentInfo.name} />;
				case "video":
					return <video controls src={getACleanURI(attachmentInfo.url)} />;
				case "contacts": {
					let formattedName = attachmentInfo?.contactName?.formattedName;
					let email = attachmentInfo?.emails[0]?.email;
					let phones = attachmentInfo?.phones[0]?.phone;

					return (
						<div>
							<p style={{ fontStyle: "italic", paddingTop: "10px" }}>Contact :</p>
							<p>{formattedName}</p>
							<p>{email}</p>
							<p>{phones}</p>
						</div>
					);
				}

				case "voice":
					return (
						<audio controls>
							<source src={getACleanURI(attachmentInfo.url)} type="audio/ogg" />
							Your browser does not support the audio element.
						</audio>
					);
				case "location": {
					let longitude = attachmentInfo?.longitude;
					let latitude = attachmentInfo?.latitude;
					let address = attachmentInfo?.address;

					return (
						<Box display="flex" justifyContent="center" flexDirection="column">
							<a
								style={{ padding: "10px" }}
								target="_blank"
								rel="noreferrer"
								href={`https://maps.google.com/?q=${latitude},${longitude}`}
							>
								<img src={mapPNG} alt="map" />
							</a>
							<p>{i18n.t("COC.address") + " : " + address}</p>
						</Box>
					);
				}

				default:
					return <></>;
			}
		}
	};
	return (
		<>
			<Box p={2}>
				{Array.isArray(attachmentInfo) &&
					attachmentInfo.length > 0 &&
					displayMedia(type, attachmentInfo[0])}
			</Box>
		</>
	);
}
