/* eslint-disable no-case-declarations */
import { preloadedState } from "redux/store.js";
import C from "../../constants/userStatus.js";

const userStatusReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.RESET_USERSTATUS: {
			return preloadedState.userStatus;
		}
		case C.ADD_USER_DATA: //ADD
			const userData = action.payload;
			return { auth: { ...userData, isAuthenticated: true } };

		case C.SAVE_USER_DATA: //SAVE && UPDATE
			const userupdate = action.payload;
			return {
				auth: { ...currentState.auth, user: { ...currentState.auth.user, ...userupdate } }
			};
		case C.SAVE_USER_PROFILE: //SAVE && UPDATE
			const userProfileUpdate = [
				...currentState.auth.user.cockpitConvFiltersSaved.filter(
					filter => filter.assistantID != action.payload.assistantID
				),
				action.payload
			];
			return {
				auth: {
					...currentState.auth,
					user: { ...currentState.auth.user, cockpitConvFiltersSaved: userProfileUpdate }
				}
			};

		case C.HIDE_RELEASE_NOTE: // Toggle display on/off for the actual release note in DB
			const hideReleaseNote = action.payload;
			return {
				auth: { ...currentState.auth, user: { ...currentState.auth.user, ...hideReleaseNote } }
			};

		case C.HIDE_SESSION_RELEASE_NOTE: // Hide release note at the page's refresh for the user session (*with cookies)
			const hideReleaseNotesForSession = action.payload;
			return {
				auth: {
					...currentState.auth,
					user: { ...currentState.auth.user, ...hideReleaseNotesForSession }
				}
			};

		default:
			return currentState;
	}
};

export default userStatusReducers;
