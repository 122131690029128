import lod_ from "lodash";
import React from "react";
import i18n from "i18n";

import { Box, Typography } from "@material-ui/core";

/**
 * 
 * @param {object} props.message test
 * @param {object} props.messagePaperStyle test
 * @returns 
 */
const MessageHeaderExternalSender = ({ message, messagePaperStyle }) => {
	return (
		<>
			{!lod_.isNil(message.header.externalSender) && (
				<Box
					p={1}
					style={{
						backgroundColor: messagePaperStyle.headerBackgroundColor,
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center"
					}}
				>
					<Typography variant="caption" style={{ fontSize: 11 }}>
						{`${i18n.t("COC.sentBy")}${message.header.externalSender}`}
					</Typography>
				</Box>
			)}
		</>
	);
};

export default MessageHeaderExternalSender;
