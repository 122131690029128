/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import SettingsIcon from "@material-ui/icons/Settings";
import lod_ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, compose } from "redux";
import userGroupActions from "../../redux/actions/userGroupActions.js";
import userStatusActions from "../../redux/actions/userStatusActions";
import "../../assets/css/language-selector.css";
import "../../assets/css/profil.css";
import i18n from "../../i18n";
import settings from "../../helpers/settingFunctions";
import EditProfile from "../Profile/EditProfil";
import { UserProfileComponent } from "components/UserProfileComponent";
import { AvatarProfile } from "components/UserProfileComponent";
import { BarChartOutlined } from "@material-ui/icons";

import { DataGrid } from "@material-ui/data-grid";
import "../../assets/css/profil.css";

import FetchMessages from "./FetchMessages.js";
import { getLanguagesByAssistant, handleSpecialDisplayDate } from "../../helpers/utilities.js";
import SnackbarStore from "pages/Cockpit/SnackbarStore.js";
import {
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({}));

function Profile(props) {
	const userGroupsIDs = useSelector(state => state.userStatus?.auth?.user?.groups);
	const groups = useSelector(state => state.userGroup?.groups);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	const { cockpitAnalytics } = useSelector(state => state.profile);
	const [loadingAnalytics, setLoadingAnalytics] = useState(false);
	const classes = useStyles();
	const [selectedUser, setSelectedUser] = useState({});
	const [editProfile, setEditProfile] = useState({ open: false });
	const [showAlert, setShowAlert] = useState({ open: false, success: true });
	const [languages, setLanguages] = useState(["FR", "EN"]);
	const [signatures, setSignatures] = useState([]);
	const [mySign, setMysign] = useState("");
	const [dataGridRows, setDataGridRows] = useState([]);
	const [isAssistantSwitched, setIsAssistantSwitched] = useState(false);

	const userGroups = groups?.filter(group => userGroupsIDs.includes(group._id));

	useEffect(() => {
		if (props.userStatus.auth && props.userStatus.auth.user) {
			setSelectedUser(props.userStatus.auth.user);
		}
	}, [props.userStatus.auth]);

	useEffect(() => {
		setIsAssistantSwitched(true);
	}, [selectedAssistantID]);

	/**
	 * Get signatures depends current assistant id
	 */

	useEffect(() => {
		if (
			props.userStatus.auth &&
			props.userStatus.auth.user &&
			props.userStatus.auth.user.signatures
		) {
			let signatures = props.userStatus.auth.user.signatures;

			let mySignature =
				signatures &&
				signatures.length > 0 &&
				signatures !== undefined &&
				signatures.filter(s => s.assistantID === selectedAssistantID).map(s => s);
			setSignatures(signatures);
			setMysign(mySignature[0]);
		} else {
			setSignatures("");
			setMysign("");
		}
	}, [props.userStatus.auth, selectedAssistantID, mySign]);

	/**
	 * Load profile statistics
	 */
	useEffect(() => {
		if (lod_.isEmpty(selectedUser)) return;
		const rangeInDays = 8;
		setLoadingAnalytics(true);
		FetchMessages.getDatas(selectedUser, selectedAssistantID, rangeInDays);
	}, [selectedAssistantID, selectedUser, isAssistantSwitched]);

	useEffect(() => {
		if (cockpitAnalytics && cockpitAnalytics.length > 0) {
			var newRows = [];

			const format = props?.assistantconfig?.cockpit?.date_format?.format || "LL";
			const language = props?.userStatus?.auth?.user?.language?.toLowerCase() || "FR";

			for (let group of cockpitAnalytics) {
				let formatedDate = handleSpecialDisplayDate(group.ts, format, language).replace(
					" 00:00",
					""
				);

				newRows.push({
					id: group.ts,
					raw_date: group.date,
					date: formatedDate,
					receive: group.totalMessagesCount || 0,
					treatTotal: group.totalResolvedCount || 0,
					treatSelf: group.totalSelfResolvedCount || 0
				});
			}
			setDataGridRows(newRows);
			setLoadingAnalytics(false);
			setIsAssistantSwitched(false);
		} else {
			setDataGridRows([]);
			setLoadingAnalytics(false);
			setIsAssistantSwitched(false);
		}
	}, [cockpitAnalytics]);

	useEffect(() => {
		setValueMessage(
			props?.userStatus?.auth?.user?.cockpitSettings?.cockpitTextVersion || "default"
		);
		setValueDisplay(props?.userStatus?.auth?.user?.cockpitSettings?.displayEditor || false);
		setValueOrder(
			props?.userStatus?.auth?.user?.cockpitSettings?.forceMessageSortNewerToOlder || false
		);
		setValueSubject(
			props?.userStatus?.auth?.user?.cockpitSettings?.defaultTopicOrSubject || "default"
		);
	}, [props?.userStatus?.auth?.user?.cockpitSettings]);

	const handleCloseUserEditor = () => {
		setEditProfile({ open: false });
	};

	const handleOpenUserEditor = user => {
		setEditProfile({
			open: true,
			mode: "edit",
			user
		});
	};
	const hideAlert = () => {
		setShowAlert({ open: false, success: true });
	};

	const handleSaveProfile = userToUpdate => {
		setSelectedUser({ ...selectedUser, picture: "" });
		if (userToUpdate !== selectedUser.language) {
			const newLanguage = userToUpdate.language.toLowerCase();
			i18n.changeLanguage(newLanguage);
			localStorage.setItem("lang", newLanguage);
		}
		props.userStatusActions.saveUserdata(
			userToUpdate,
			() => {
				setShowAlert({ open: true, success: true });
			},
			() => {
				setShowAlert({ open: true, success: false });
			}
		);
		setEditProfile({ open: false });
	};

	const getRole = searchedRole => {
		const role = settings.getRoles().find(role => role.value === searchedRole);
		if (role) {
			return i18n.t(`EDIT_FORM.roles.${role.code}`);
		} else {
			return searchedRole;
		}
	};

	const [valueMessage, setValueMessage] = useState(
		props?.userStatus?.auth?.user?.cockpitSettings?.cockpitTextVersion || "default"
	);
	const [valueDisplay, setValueDisplay] = useState(
		props?.userStatus?.auth?.user?.cockpitSettings?.displayEditor || true
	);

	const [valueOrder, setValueOrder] = useState(
		props?.userStatus?.auth?.user?.cockpitSettings?.forceMessageSortNewerToOlder || false
	);

	const [valueSubject, setValueSubject] = useState(
		props?.userStatus?.auth?.user?.cockpitSettings?.defaultTopicOrSubject || "default"
	);

	const dispatch = useDispatch();

	const handleChangeMessage = e => {
		changeOption("formatMessage", e.target.value);

		//dispatch(cockpitActions.changeFormatMessage(channelCode, initialDraft));
		setValueMessage(e.target.value);
	};

	const handleChangeDisplay = e => {
		changeOption("displayMessage", e.target.value);

		setValueDisplay(e.target.value);
	};

	const handleChangeOrder = e => {
		changeOption("orderMessage", e.target.value);
		setValueOrder(e.target.value);
	};

	const handleChangeSubject = e => {
		changeOption("subjectMessage", e.target.value);
		setValueSubject(e.target.value);
	};

	const changeOption = (action, value) => {
		dispatch(userStatusActions.saveOptionUser({ action, value }));
	};

	return (
		<Fragment>
			<Box
				className="customScrollBar"
				overflow="auto"
				bgcolor="white"
				borderRadius={4}
				border={"1px solid #DBDBDB"}
				boxShadow="0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
			>
				<Grid item xs={12}>
					<Box component="div" width="100%" height="97vh" display="flex" flexDirection="column">
						{selectedUser && (
							<Box height="100%" p={2} m={1}>
								<Box
									component="div"
									width="90%"
									height="71vh"
									display="flex"
									flexDirection="column"
								>
									<Box display="flex" flexWrap="wrap" alignItems="center" mb={1} ml={10}>
										<AvatarProfile
											user={selectedUser}
											role={getRole(selectedUser.role)}
											classes={classes}
										/>
									</Box>
									<Grid container>
										<Grid item xs={4}>
											<Box overflow="auto" className="customScrollBar" ml={10}>
												<UserProfileComponent
													settings={settings}
													user={selectedUser}
													displayPhone={true}
													classes={classes}
													signature={mySign?.signature}
												/>
											</Box>
										</Grid>
										<Grid item xs={8}>
											<Box display="flex" alignItems="center" my={2} mt={3}>
												<SettingsIcon />
												<Box ml={1} fontWeight="fontWeightBold">
													{`${i18n.t("EDIT_FORM.profilOption")} : `}
												</Box>
											</Box>

											<Box fontSize="h5.fontSize" fontWeight="fontWeightBold" m={1}>
												<Box style={{ alignSelf: "center" }} className={classes.imgSign}>
													<FormControl>
														<FormLabel id="demo-row-radio-buttons-group-label">
															{i18n.t("EDIT_FORM.titleOptionMessage")}
														</FormLabel>
														<RadioGroup
															row
															onChange={e => handleChangeMessage(e)}
															aria-labelledby="demo-row-radio-buttons-group-label"
															name="row-radio-buttons-group"
															value={valueMessage}
														>
															<FormControlLabel
																value="default"
																control={<Radio />}
																label={i18n.t("EDIT_FORM.defaultOption")}
															/>
															<FormControlLabel
																value="clean"
																control={<Radio />}
																label={i18n.t("EDIT_FORM.optionTextClean")}
															/>
															<FormControlLabel
																value="html"
																control={<Radio />}
																label={i18n.t("EDIT_FORM.optionTextHtml")}
															/>
														</RadioGroup>
													</FormControl>
												</Box>

												<Box style={{ alignSelf: "center" }} className={classes.imgSign}>
													<FormControl>
														<FormLabel id="demo-row-radio-buttons-group-label">
															{i18n.t("EDIT_FORM.titleOptionDisplay")}
														</FormLabel>
														<RadioGroup
															onChange={e => handleChangeDisplay(e)}
															row
															aria-labelledby="demo-row-radio-buttons-group-label"
															name="row-radio-buttons-group"
															value={valueDisplay}
														>
															<FormControlLabel
																value={true}
																control={<Radio />}
																label={i18n.t("EDIT_FORM.oppenDisplay")}
															/>
															<FormControlLabel
																value={false}
																control={<Radio />}
																label={i18n.t("EDIT_FORM.hiddenDisplay")}
															/>
														</RadioGroup>
													</FormControl>
												</Box>
												<Box style={{ alignSelf: "center" }} className={classes.imgSign}>
													<FormControl>
														<FormLabel id="demo-row-radio-buttons-group-label">
															{i18n.t("EDIT_FORM.titleOptionOrder")}
														</FormLabel>
														<RadioGroup
															onChange={e => handleChangeOrder(e)}
															row
															aria-labelledby="demo-row-radio-buttons-group-label"
															name="row-radio-buttons-group"
															value={valueOrder}
														>
															<FormControlLabel
																value={false}
																control={<Radio />}
																label={i18n.t("EDIT_FORM.orderOptionDefault")}
															/>
															<FormControlLabel
																value={true}
																control={<Radio />}
																label={i18n.t("EDIT_FORM.orderOption")}
															/>
														</RadioGroup>
													</FormControl>
												</Box>
												<Box style={{ alignSelf: "center" }} className={classes.imgSign}>
													<FormControl>
														<FormLabel id="demo-row-radio-buttons-group-label">
															{i18n.t("EDIT_FORM.titleOptionSubject")}
														</FormLabel>
														<RadioGroup
															onChange={e => handleChangeSubject(e)}
															row
															aria-labelledby="demo-row-radio-buttons-group-label"
															name="row-radio-buttons-group"
															value={valueSubject}
														>
															<FormControlLabel
																value={"default"}
																control={<Radio />}
																label={i18n.t("EDIT_FORM.defaultOption")}
															/>
															<FormControlLabel
																value={"subject"}
																control={<Radio />}
																label={i18n.t("EDIT_FORM.subjectOption")}
															/>
															<FormControlLabel
																value={"topic"}
																control={<Radio />}
																label={i18n.t("EDIT_FORM.topicOption")}
															/>
														</RadioGroup>
													</FormControl>
												</Box>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Box>
						)}
					</Box>
				</Grid>
			</Box>
			{editProfile.open && (
				<EditProfile
					open={editProfile.open}
					mode={editProfile.mode}
					handleCloseUserEditor={handleCloseUserEditor}
					handleSaveProfile={handleSaveProfile}
					langues={languages}
					userLanguage={selectedUser.language}
					userMobilePhone={selectedUser.mobilePhone}
					userDirectPhone={selectedUser.directPhone}
					user={selectedUser}
					signatures={signatures}
					mySign={mySign}
				/>
			)}
			<SweetAlert
				success={showAlert.success}
				error={!showAlert.success}
				title={showAlert.success ? i18n.t("alert.save") : i18n.t("alert.cannot")}
				timeout={2000}
				show={showAlert.open}
				onConfirm={hideAlert}
				onCancel={hideAlert}
				allowEscape={true}
				customButtons={
					<Button className="success" onClick={hideAlert}>
						{i18n.t("alert.confirm")}
					</Button>
				}
			/>
			<SnackbarStore />
		</Fragment>
	);
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return {
		userStatusActions: bindActionCreators(userStatusActions, dispatch),
		userGroupActions: bindActionCreators(userGroupActions, dispatch)
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Profile);
