import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withTranslation } from "react-i18next";
import userStatusActions from "../../redux/actions/userStatusActions";
import { Star } from "@material-ui/icons";
import "./StarRating.css";

function StarRating(props) {
	var ratingNote = props.rating;
	var maxNote = props.max || 5;

	if (maxNote != 5) {
		ratingNote = (ratingNote * 5) / maxNote;
		maxNote = 5;
	}

	const CustomStar = props => {
		var decimal = props.decimal;
		if (decimal > 1) decimal = 1;
		if (decimal < 0) decimal = 0;
		return (
			<div className="stars-container">
				<div className="stars-row-container">
					<Star
						className="star-base"
						style={{
							color: "gold",
							clipPath: "inset(0 " + (1 - decimal) * 100 + "% 0 0)"
						}}
					></Star>
				</div>

				<div className="stars-row-container">
					<Star
						className="star-base"
						style={{
							color: "lightGrey",
							clipPath: "inset(0 0 0 " + decimal * 100 + "%)"
						}}
					></Star>
				</div>
			</div>
		);
	};

	function loopStars() {
		var stars = [];
		var note = ratingNote;
		for (let i = 0; i < maxNote; i++) {
			stars.push(<CustomStar key={i} decimal={note}></CustomStar>);
			note--;
		}
		return stars;
	}

	return (
		<div className="stars">
			{loopStars()}
			<div className="writtedNote">
				({Math.round(ratingNote * 100) / 100}/{maxNote})
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return { userStatusActions: bindActionCreators(userStatusActions, dispatch) };
}
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(StarRating);
