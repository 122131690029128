import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Box, makeStyles } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";

export default function Phone(props) {
	const {
		assistantConfig,
		name,
		value,
		onChange,
		label,
		defaultCountry,
		disabled,
		disabledDropdown,
		readOnly,
		...others
	} = props;

	// imite MUI input style
	const useStyles = makeStyles(theme => ({
		phoneInput: {
			"& input": {
				border: "none",
				borderBottom: "1px solid #888888",
				"&:focus": {
					borderBottom: `2px solid ${theme.palette.primary.main}`
				}
			}
		}
	}));
	const classes = useStyles();

	const handleChange = val => {
		onChange({
			target: {
				name,
				value: val || ""
			}
		});
	};

	return (
		<Box display="flex" alignItems="center">
			<div>
				{assistantConfig.cockpit?.nationalPhone ? (
					<>
						<div>{label}</div>
						{/* new phone number input */}
						<PhoneInput
							name={name}
							value={value}
							onChange={handleChange}
							defaultCountry={defaultCountry.toUpperCase()} //force uppercase
							limitMaxLength
							disabled={disabled}
							disabledDropdown={disabledDropdown}
							readOnly={readOnly}
							className={classes.phoneInput}
							international={false}
							{...others}
						/>
					</>
				) : (
					//legacy MUI phone number input
					<MuiPhoneNumber
						variant="outlined"
						fullWidth
						name={name}
						label={label}
						value={value}
						onChange={handleChange}
						defaultCountry={defaultCountry}
						disabled={disabled}
						disabledDropdown={disabledDropdown}
						readOnly={readOnly}
						{...others}
						style={{ flex: 1, marginRight: 1 }}
					/>
				)}
			</div>
		</Box>
	);
}
