import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withTranslation } from "react-i18next";
import userStatusActions from "../../redux/actions/userStatusActions";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import i18n from "i18next";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import "./CategoriesDisplay.css";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { getLabelOf } from "./../../helpers/utilities";
import cockpitActions from "../../redux/actions/cockpitActions";

function CategoriesDisplay(props) {
	const dispatch = useDispatch();

	var categories = props.categories;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const [selectedCategory, setSelectedCategory] = useState(null);

	const handleClick = (event, category) => {
		setAnchorEl(event.currentTarget);
		setSelectedCategory(category);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSwitch = categorySelected => {
		dispatch(
			cockpitActions.gestionCategoryMessage(
				"changeCategorySatusMessage",
				{ category: categorySelected, mID: props?.message.header?.mID },
				{
					assistantID: props?.selectedConversation?.header?.assistantID,
					fID: props?.selectedConversation?.header?.fID
				}
			)
		);
		setAnchorEl(null);
	};

	const handleDelete = categorySelected => {
		dispatch(
			cockpitActions.gestionCategoryMessage(
				"deleteCategorySatusMessage",
				{ category: categorySelected, mID: props?.message.header?.mID },
				{
					assistantID: props?.selectedConversation?.header?.assistantID,
					fID: props?.selectedConversation?.header?.fID
				}
			)
		);
		setAnchorEl(null);
	};

	return (
		<Box mx={1} className="categories-display">
			{categories
				.sort((a, b) => getLabelOf(a?.code).localeCompare(getLabelOf(b?.code)))
				.map((categorySelected, index) => {
					return (
						<Box
							key={index}
							className={"cat-shape cat-intent-" + categorySelected?.label}
							display="flex"
							alignItems="center"
						>
							<Box
								key={index}
								style={{ cursor: "pointer" }}
								onClick={event => handleClick(event, categorySelected)}
							>
								{getLabelOf(categorySelected?.code)}
							</Box>
						</Box>
					);
				})}

			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right"
				}}
				onClose={handleClose}
			>
				<Tooltip title={i18n.t("COC.switchStatutCategory")} placement="top">
					<MenuItem onClick={() => handleSwitch(selectedCategory)}>
						<AutorenewIcon />
					</MenuItem>
				</Tooltip>

				<Tooltip title={i18n.t("COC.deleteCategory")} placement="top">
					<MenuItem onClick={() => handleDelete(selectedCategory)}>
						<HighlightOffIcon />
					</MenuItem>
				</Tooltip>
			</Menu>
		</Box>
	);
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return { userStatusActions: bindActionCreators(userStatusActions, dispatch) };
}
export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(CategoriesDisplay);
