/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import i18n from "i18n";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getConvsContainerSelector } from "redux/selectors/cockpitSelectors";
import { assistantHasApiCold } from "helpers/utilities";
import C from "../../../constants/cockpit.js";
import {
	Check,
	ChatBubbleOutlineOutlined,
	ArchiveOutlined,
	VisibilityOffOutlined,
	HourglassEmptyOutlined,
	AssignmentIndOutlined,
	CloudDownloadOutlined
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

export default props => {
	const {
		isCockpitArchived,
		hasLiveChat,
		nbWaitingConvs,
		nbOngoingConvs,
		nbLiveConvs,
		nbResolvedConvs,
		nbArchivedConvs,
		nbHiddenConvs,
		leftTab,
		nbColdConvs,
		nbCurrentConvs //example: ["waiting", 15], delivered to  the store by getScrollPaginatedConversations()
	} = useSelector(getConvsContainerSelector, shallowEqual);
	const dispatch = useDispatch();
	let tabList;

	if (!isCockpitArchived) {
		// Default cockpit
		tabList = [
			{
				number: 0,
				label: `${i18n.t("COC.Pending")}`,
				count: "waiting" === nbCurrentConvs[0] ? nbCurrentConvs[1] : nbWaitingConvs,
				icon: <HourglassEmptyOutlined />
			},
			{
				number: 1,
				label: `${i18n.t("COC.Inprogress")}`,
				count: "ongoing" === nbCurrentConvs[0] ? nbCurrentConvs[1] : nbOngoingConvs,
				icon: <AssignmentIndOutlined />
			}
		];
		if (hasLiveChat)
			tabList.push({
				number: 3,
				label: `${i18n.t("COC.Live")}`,
				count: "live" === nbCurrentConvs[0] ? nbCurrentConvs[1] : nbLiveConvs,
				icon: <ChatBubbleOutlineOutlined />
			});
		tabList.push({
			number: 2,
			label: `${i18n.t("COC.Resolved")}`,
			count: "resolved" === nbCurrentConvs[0] ? nbCurrentConvs[1] : nbResolvedConvs,
			icon: <Check />
		});
	} else {
		// Cockpit component for archived conversations
		tabList = [
			{
				number: 0,
				label: `${i18n.t("COC.archived")}`,
				count: "archived" === nbCurrentConvs[0] ? nbCurrentConvs[1] : nbArchivedConvs,
				icon: <ArchiveOutlined />
			},
			{
				number: 1,
				label: `${i18n.t("COC.hidden")}`,
				count: "hidden" === nbCurrentConvs[0] ? nbCurrentConvs[1] : nbHiddenConvs,
				icon: <VisibilityOffOutlined />
			}
		];
		if (assistantHasApiCold())
			tabList.push({
				number: 4,
				label: `${i18n.t("COC.cold")}`,
				count: "cold" === nbCurrentConvs[0] ? nbCurrentConvs[1] : nbColdConvs,
				icon: <CloudDownloadOutlined />
			});
	}

	return (
		<Box display="flex" alignItems="center" justifyContent="center" mt={1}>
			<Tabs
				value={leftTab}
				onChange={(event, newTab) => {
					if (newTab !== leftTab) props.handleTabChange(newTab);
				}}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				style={{
					width: "100%"
				}}
			>
				{tabList.map(tab => (
					<Tab
						key={`tab_${tab.number}`}
						style={{ minWidth: 20, fontSize: "14px", flex: 1 }}
						label={
							<Tooltip title={tab.label} placement="top">
								<div style={{ display: "flex", alignItems: "center" }}>
									{tab.icon}&nbsp;({tab.count})
								</div>
							</Tooltip>
						}
						value={tab.number}
						onClick={() => {
							if (leftTab === tab.number) dispatch({ type: C.SET_CONVS_RELOAD, payload: true });
						}}
					/>
				))}
			</Tabs>
		</Box>
	);
};
