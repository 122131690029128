import React, { useEffect, useState, useRef } from "react";
import { ListItemText, Paper, Popper, Zoom } from "@material-ui/core";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ArrowRight, MoreVertRounded } from "@material-ui/icons";
import { ListItem } from "@mui/material";
import { Box } from "@mui/system";
import store from "redux/store";

const DictionaryExplorer = ({ handleClick, open, position, handleClose, validTypes }) => {
	let assistantConfig = store.getState().assistantconfig;

	function mustDisplayItem(item) {
		const showOnlyEditable = item?.actions?.edit !== false;
		return showOnlyEditable;
	}

	function mustEnableItem(item) {
		if (!validTypes) return true;
		const showOnlyValidTypes = validTypes.includes(item.type);
		return showOnlyValidTypes;
	}

	return (
		<Popper
			open={open}
			style={{
				zIndex: 9999,
				position: "fixed",
				top: position.top,
				left: position.left
			}}
		>
			{() => (
				<ClickAwayListener onClickAway={handleClose}>
					<Paper>
						{Object.keys(assistantConfig.dictionary).map((key, index) => {
							const item = assistantConfig.dictionary[key];

							if (mustDisplayItem(item)) {
								return (
									<MyMenuItem
										style={{ zIndex: 9999 }}
										key={index}
										path={key}
										item={item}
										handleAction={path => {
											handleClick(path);
										}}
										setOpen={true}
										mustDisplayItem={mustDisplayItem}
										mustEnableItem={mustEnableItem}
									/>
								);
							}

							return <></>;
						})}
					</Paper>
				</ClickAwayListener>
			)}
		</Popper>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
const MyMenuItem = ({ item, path, handleAction, setOpen, mustDisplayItem, mustEnableItem }) => {
	const Component = item.items && Object.keys(item.items).length > 0 ? MultiLevel : SingleLevel;
	return (
		<Component
			style={{ zIndex: 1 }}
			path={path}
			item={item}
			handleAction={handleAction}
			setOpen={setOpen}
			mustDisplayItem={mustDisplayItem}
			mustEnableItem={mustEnableItem}
		/>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
const SingleLevel = ({ item, path, handleAction }) => {
	return (
		<ListItem button>
			<ListItemText
				primary={item.label.fr}
				onClick={() => {
					handleAction(path);
				}}
			/>
		</ListItem>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
const MultiLevel = ({ item, path, handleAction, mustDisplayItem, mustEnableItem }) => {
	const children = item.items;
	const [open, setOpen] = useState(false);
	const openRef = useRef(null);
	const prevOpen = useRef(open);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			openRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	const renderChildren = (child, key, mustDisplayItem, mustEnableItem) => {
		if (child.items) {
			return (
				<MultiLevel
					key={key}
					item={child}
					path={`${path}.${key}`}
					handleAction={path => handleAction(path)}
					mustDisplayItem={mustDisplayItem}
					mustEnableItem={mustEnableItem}
				/>
			);
		}
		const enabled = mustEnableItem(child);

		return (
			<ListItem
				button
				disabled={!enabled}
				onClick={() => {
					if (enabled) {
						handleAction(`${path}.${key}`);
						setOpen(false);
					}
				}}
			>
				<ListItemText primary={<>{child.label.fr}</>} />
			</ListItem>
		);
	};

	return (
		<React.Fragment>
			<div
				onMouseLeave={() => {
					setOpen(false);
				}}
			>
				<ListItem button ref={openRef} onMouseEnter={() => setOpen(true)}>
					<ListItemText primary={<>{item.label.fr}</>} />
					<ArrowRight />
				</ListItem>
				<Popper
					style={{ zIndex: 1 }}
					placement={"right"}
					open={open}
					anchorEl={openRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{() => (
						<Paper style={{ zIndex: 1, width: "250px" }}>
							{Object.entries(children)
								.filter(([key, child]) => mustDisplayItem(child))
								.map(([key, child]) => renderChildren(child, key, mustDisplayItem, mustEnableItem))}
						</Paper>
					)}
				</Popper>
			</div>
		</React.Fragment>
	);
};
export default DictionaryExplorer;
