/* eslint-disable no-fallthrough */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import Box from "@material-ui/core/Box";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getConvItemSelector } from "../../../redux/selectors/cockpitSelectors";
import cockpitActions from "../../../redux/actions/cockpitActions";
import C from "../../../constants/cockpit";

import {
	formatDate,
	getNameAgent,
	handleSpecialDisplayDate,
	testSizeFile
} from "../../../helpers/utilities";
import i18n from "i18n";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	TextField,
	Typography
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { fetchUploadImageToS3WithoutReducer } from "redux/reducers/s3Reducers.js";
import { FileIcon, defaultStyles } from "react-file-icon";
import FileActions, {
	getFileExtension,
	mimeTypeCompute
} from "../../../helpers/actions/FileActions";
import AddIcon from "@material-ui/icons/Add";
import { ListItem } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import lod_ from "lodash";
import { Tooltip } from "@material-ui/core";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import TextSelectActionPopover from "../Message/TextSelectActionPopover";
import Autosave from "../Autosave";
import DragAndDrop from "../../Cockpit/DragAndDrop";
import { generateRandomId } from "helpers/AttachmentsUtil";
import { TrendingUp } from "@material-ui/icons";
import { TagsList } from "components/Tags/TagsList";

/**
 *
 * @param {*} param0
 * @returns
 */
export const NoteComponent = ({ index, notes, note }) => {
	const { selectedConversation, leftTab } = useSelector(state => state.cockpit);

	const { cockpit } = useSelector(state => state.assistantconfig);
	const userStatus = useSelector(state => state.userStatus);
	const { userGroup } = useSelector(state => state);
	const dispatch = useDispatch();

	const fID = selectedConversation?.header?.fID;
	const substate = selectedConversation?.header?.substate;
	const assistantID = selectedConversation?.header.assistantID;

	const userLanguage = userStatus?.auth?.user?.language
		? userStatus.auth.user.language.toLowerCase()
		: "fr";

	const dateFormat = {
		datetime: cockpit?.datetime_format?.format,
		date: cockpit?.date_format?.format
	};

	let lastNote = lod_.first(notes);
	let user = userStatus?.auth?.user;

	let noteAuthor = user?._id;
	let nickname =
		user && user.surname ? user.surname + " " + user.name : user.nickname + " " + user.name;

	let noteAuthorName = getNameAgent(userGroup?.users, note.author);

	/**
	 *
	 * @param {*} note
	 */
	const handleRemoveNote = (index, note) => {
		if (selectedConversation) {
			dispatch(
				cockpitActions.deleteNote({ noteIndex: notes?.length - 1, note }, selectedConversation)
			);
		}
	};

	const handleAddFileDraft = fileNote => {
		let draftAttachement = selectedConversation?.drafts?.attachments;
		let file = fileNote;
		file.needToCopyToBucket = true;
		let newDraftValues = {
			attachments: [...draftAttachement, file]
		};

		dispatch(cockpitActions.postDraft(fID, assistantID, substate, newDraftValues));
		dispatch({
			type: C.ADD_ATTACHMENT,
			payload: file
		});
	};

	/**
	 *
	 * @param {*} note
	 * @return label of title
	 */
	const groupEsclatedNote = note => {
		return !lod_.isNil(note?.escalationGroup?.name)
			? " " + i18n.t("COC.groupEscalated") + " " + note?.escalationGroup?.name
			: "";
	};

	/**
	 *
	 * @param {*} note
	 * @return label of title
	 */
	const getTitleOfNote = note => {
		return note?.type === "escalatedDone"
			? i18n.t("COC.noteWritterEscalatedDone") + " " + noteAuthorName
			: i18n.t("COC.noteWritter") + " " + noteAuthorName;
	};

	return (
		note && (
			<ListItem>
				<Box width={1}>
					<Paper
						elevation={3}
						style={{
							backgroundColor:
								nickname === note?.author || note?.author === noteAuthor
									? "white"
									: "rgb(231, 231, 231)"
						}}
					>
						<Box p={1}>
							<Box display="flex" alignItems="center" justifyContent="space-between">
								{note?.type === "escalated"
									? i18n.t("COC.noteWritterEscalated") +
										" " +
										noteAuthorName +
										groupEsclatedNote(note)
									: getTitleOfNote(note)}

								<Box>
									{note?.type === "attachement" && (
										<>
											<FileActions
												attachment={{
													// Default attachment attributes
													...note?.attachement,
													// Need to recompute this attributes
													name: note?.attachement.name,
													url: note?.attachement.url,
													mimeType: mimeTypeCompute(note?.attachement)
												}}
											/>
											<Tooltip title={i18n.t("COC.addFileNoteInDraft")} placement="top">
												<IconButton
													size="small"
													onClick={() => handleAddFileDraft(note?.attachement)}
												>
													<AddIcon />
												</IconButton>
											</Tooltip>
										</>
									)}
									{note === lastNote &&
										(leftTab === 1 || leftTab === 2) &&
										(nickname === note.author || note.author === noteAuthor) && (
											<IconButton size="small" onClick={() => handleRemoveNote(index, note)}>
												<DeleteOutlineOutlinedIcon />
											</IconButton>
										)}
								</Box>
							</Box>
							{/** THIS BLOCK IS FOR OLD NOTE FORMAT WITH NO TYPE **/}
							{!note?.type && (
								<Box my={1} fontWeight="fontWeightMedium" fontStyle="italic">
									{note.text}
								</Box>
							)}
							{/***************************************************/}
							{note?.type === "text" && (
								<Box my={1} fontWeight="fontWeightMedium" fontStyle="italic">
									{note.text}
								</Box>
							)}

							{note?.type === "escalated" && (
								<Box my={1} fontWeight="fontWeightMedium" fontStyle="italic">
									{note.text}
								</Box>
							)}

							{note?.type === "escalatedDone" && (
								<Box my={1} fontWeight="fontWeightMedium" fontStyle="italic">
									{note.text}
								</Box>
							)}
							{note?.type === "attachement" && (
								<Box my={1} fontWeight="fontWeightMedium" fontStyle="italic">
									<AttachementItem attachement={note.attachement} />
								</Box>
							)}
							<Box display="flex" justifyContent="flex-end">
								<Box>
									{dateFormat
										? handleSpecialDisplayDate(
												note.ts || note.timestamp,
												dateFormat.datetime,
												userLanguage
											)
										: formatDate(note.datetime)}
								</Box>
							</Box>
						</Box>
					</Paper>
				</Box>
			</ListItem>
		)
	);
};

/**
 *
 */
export const NotesComponent = ({ searchFilter, contactAnsDocSearchFilter, notes }) => {
	const componentRef = useRef();

	const filterCondition = note => {
		if (searchFilter) {
			return (
				contactAnsDocSearchFilter.trim().length === 0 ||
				(note &&
					note.text &&
					note.text.toLowerCase().includes(contactAnsDocSearchFilter.toLowerCase())) ||
				(note.author &&
					note.author.toLowerCase().includes(contactAnsDocSearchFilter.toLowerCase())) ||
				(note.datetime &&
					note.datetime.toLowerCase().includes(contactAnsDocSearchFilter.toLowerCase()))
			);
		} else return true;
	};

	/**
	 *
	 */
	const emptyMessage = message => {
		return (
			<Box
				height="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
				fontStyle="italic"
			>
				{message}
			</Box>
		);
	};

	return (
		<Box data-component-name="NotesComponent" ref={componentRef}>
			{notes && notes.length > 0
				? notes
						.sort((a, b) => b.ts - a.ts)
						.filter(note => filterCondition(note))
						.map((note, index) => (
							<div key={"note" + index}>
								<NoteComponent index={index} note={note} notes={notes} />
							</div>
						))
				: emptyMessage(i18n.t("COC.empty_notes"))}

			<TextSelectActionPopover target={componentRef} />
		</Box>
	);
};

/**
 * Attachement component
 * @param {*} param0
 * @returns
 */
const AttachementItem = ({ attachement }) => {
	let attachementType = attachement.contentType || attachement.type;

	return (
		<Box
			className="attachementContainer"
			style={{
				cursor: "auto",
				border: "1px solid #ccc",
				backgroundColor: "white"
			}}
			sx={{ m: 1, p: 1 }}
		>
			{attachementType?.includes("image") ? (
				<Box
					component="img"
					alt={attachement.name}
					src={attachement.url}
					style={{
						maxWidth: "100%",
						maxHeight: "100%",
						objectFit: "contain"
					}}
				/>
			) : (
				<div className="fileIconContainer">
					<FileIcon
						extension={getFileExtension(attachement.name).replace(".", "")}
						{...defaultStyles[getFileExtension(attachement.name).replace(".", "").toLowerCase()]}
					/>
				</div>
			)}

			<Box>{attachement.name}</Box>
		</Box>
	);
};

export default NotesComponent;

export const NoteInputComponent = () => {
	const { selectedConversation } = useSelector(state => state.cockpit);
	const { auth } = useSelector(state => state.userStatus);
	const { selectedAssistantID } = useSelector(state => state);
	var fileInputRef = useRef();

	const dispatch = useDispatch();
	const componentRef = useRef();
	const [isFocus, setIsFocused] = useState(false);
	const [openGroupDialog, setOpenGroupDialog] = useState(false);
	const [selected, setSelected] = useState([]);

	const [note, setNote] = useState("");

	const handleSaveANote = str => {
		if (selectedConversation) {
			dispatch(cockpitActions.saveNote(str, selectedConversation));
		}
	};

	const handleNoteChange = event => {
		if (event.target.value.trim().length === 0) {
			dispatch(cockpitActions.saveNote(event.target.value, selectedConversation));
		}
		setNote(event.target.value);
	};

	const handlePostANote = () => {
		if (selectedConversation && note.trim().length > 0) {
			dispatch(cockpitActions.postNote(note, selectedConversation, "text"));
			setNote("");
		}
	};

	const handlePostANoteEscalated = selectedGroup => {
		if (selectedConversation && note.trim().length > 0) {
			dispatch(cockpitActions.postNote({ note, selectedGroup }, selectedConversation, "escalated"));
			setNote("");
		}
	};

	const handlePostANoteEscalatedFinish = () => {
		if (selectedConversation && note.trim().length > 0) {
			dispatch(cockpitActions.postNote(note, selectedConversation, "escalatedDone"));
			setNote("");
		}
	};

	useEffect(() => {
		setNote(selectedConversation?.drafts?.draftNote?.text || "");
		setSelected([]);
	}, [selectedConversation.header.fID]);

	/**
	 *
	 * @param {*} str
	 */
	const AutoSaveDraft = str => {
		if (isFocus) handleSaveANote(str);
	};

	const draggFile = files => {
		addAttachmentsToMessage(files);
	};

	const handleAddAttachment = () => {
		fileInputRef.current.click();
	};

	const readFileHandler = e => {
		const files = e.target?.files || e;
		addAttachmentsToMessage(files);
		fileInputRef.current.value = "";
	};

	/**
	 * Upload and links the file to the message
	 * @param {Object} files Files object from input html
	 */
	const addAttachmentsToMessage = files => {
		if (files.length > 1) {
			dispatch(errorMsg(i18n.t("COC.lengthListAttachement")));
		} else {
			Array.from(files).forEach(file => {
				let fileSize = -1;
				if (file) {
					fileSize = file.size;
				}

				let sizeAllowed = testSizeFile(fileSize);

				if (sizeAllowed === true) {
					const id = generateRandomId().next().value;
					const onSuccess = (signedUrl, file) => {
						let noteAttachement = file;
						dispatch(cockpitActions.postNote(noteAttachement, selectedConversation, "attachement"));
					};
					dispatch(
						fetchUploadImageToS3WithoutReducer(
							selectedAssistantID,
							file,
							"noteItem",
							{
								fID: selectedConversation.header.fID
							},
							onSuccess
						)
					);
				} else {
					dispatch(errorMsg(sizeAllowed));
				}
			});
		}
	};

	/**
	 * Send escalate action to NYO for conversation with group selected
	 */
	const applyEscalate = () => {
		setOpenGroupDialog(false);
		handlePostANoteEscalated(selected[0]);

		const data = {
			header: {
				assistantID: selectedConversation?.header?.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload: {
				group: selected[0]
			}
		};
		dispatch(cockpitActions.launchActionFrontV2("escalateConv", data));
	};

	/**
	 * Send escalate action to NYO for conversation with group selected
	 */
	const applyFinishEscalate = () => {
		const data = {
			header: {
				assistantID: selectedConversation?.header?.assistantID,
				fID: selectedConversation?.header?.fID
			},
			payload: {}
		};

		handlePostANoteEscalatedFinish();
		dispatch(cockpitActions.launchActionFrontV2("escalateDoneConv", data));
	};

	return (
		<Box data-component-name="NoteInputComponent" flexGrow={2}>
			<DragAndDrop currentChannel={selectedConversation?.header.channel} handleDrop={draggFile}>
				<Box flexGrow={2} ref={componentRef}>
					<TextField
						value={note}
						onChange={handleNoteChange}
						onKeyUp={() => setIsFocused(true)}
						fullWidth
						variant="outlined"
						multiline
						rows={15}
					/>
				</Box>
			</DragAndDrop>
			<Box display="flex" flexDirection="row">
				<Box>
					<Tooltip title={i18n.t("COC.saveNote")} placement="bottom">
						<IconButton disabled={!(note.trim().length > 0)} onClick={handlePostANote} size="small">
							<SendIcon />
						</IconButton>
					</Tooltip>
				</Box>
				<Box>
					<Tooltip title={i18n.t("COC.addAttachmentToNote")} placement="bottom">
						<IconButton onClick={() => handleAddAttachment()} size="small">
							<AttachFileIcon />
						</IconButton>
					</Tooltip>
					<input
						type="file"
						multiple
						style={{ display: "none" }}
						onChange={readFileHandler}
						ref={fileInputRef}
					/>
				</Box>
				<Box>
					<Tooltip title={i18n.t("COC.escaladeToNote")} placement="bottom">
						<IconButton
							disabled={!(note.trim().length > 0)}
							onClick={() => setOpenGroupDialog(true)}
							size="small"
						>
							<TrendingUp />
						</IconButton>
					</Tooltip>
				</Box>
				<GroupDialog
					openGroupDialog={openGroupDialog}
					setOpenGroupDialog={setOpenGroupDialog}
					selected={selected}
					setSelected={setSelected}
					applyEscalate={applyEscalate}
				/>
				{selectedConversation?.header?.isEscalated === true && (
					<Box>
						<Tooltip title={i18n.t("COC.finishEscalade")} placement="bottom">
							<IconButton
								disabled={!(note.trim().length > 0)}
								onClick={() => applyFinishEscalate()}
								size="small"
							>
								<TrendingUp style={{ color: "green" }} />
							</IconButton>
						</Tooltip>
					</Box>
				)}

				{isFocus && <Autosave AutoSaveDraft={AutoSaveDraft} answer={note} />}
				<TextSelectActionPopover target={componentRef} />
			</Box>
		</Box>
	);
};

/**
 *
 * @returns
 */
function GroupDialog({
	openGroupDialog,
	setOpenGroupDialog,
	selected,
	setSelected,
	applyEscalate
}) {
	const { groups } = useSelector(state => state.userGroup);
	const { selectedConversation } = useSelector(state => state.cockpit);
	const [isEmpty, setIsEmpty] = useState(false);

	/**
	 *
	 */
	const handleCloseGroupDialog = () => {
		setSelected([]);
		setOpenGroupDialog(false);
	};

	useEffect(() => {
		if (openGroupDialog === true) {
			let checkEmptyGroup = groups.filter(
				group =>
					group?.isEscalation === true &&
					selectedConversation?.header?.escalationGroup[0] !== group?._id
			);

			if (lod_.isEmpty(checkEmptyGroup)) {
				setIsEmpty(true);
			} else {
				setIsEmpty(false);
			}
		}
	}, [openGroupDialog]);

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={openGroupDialog}
			onClose={() => handleCloseGroupDialog()}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{i18n.t("COC.EscalateChoice")} </DialogTitle>
			<DialogContent dividers>
				<Grid container>
					<TagsList
						list={groups
							.filter(
								group =>
									group?.isEscalation === true &&
									selectedConversation?.header?.escalationGroup[0] !== group?._id
							)
							.sort((a, b) => a.name.localeCompare(b.name))}
						labelField={"name"}
						titleField={"description"}
						valueField={"_id"}
						isForm={true}
						selected={selected}
						setSelected={setSelected}
						multiple={false}
					/>

					{isEmpty && (
						<Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
							<Typography component={"span"} style={{ whiteSpace: "pre-line" }}>
								{i18n.t("COC.EscalateChoiceEmpty")}
							</Typography>
						</Box>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleCloseGroupDialog()} color="secondary">
					{`${i18n.t("COC.Cancel")}`}
				</Button>
				<Button disabled={selected?.length === 0} onClick={() => applyEscalate()} color="primary">
					{`${i18n.t("COC.validate")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
