import { createSlice } from "@reduxjs/toolkit";
import { generateRandomId } from "helpers/AttachmentsUtil.js";
import { api } from "redux/actions/api";
import C from "../../constants/cockpit.js";

const initialState = {
	isUploading: false,
	loading: false,
	uploading: false
};

export const s3 = initialState;

const slice = createSlice({
	name: "s3",
	initialState,
	reducers: {
		fetchUploadProfileImageToS3Loading: (state, action) => {
			state.loading = action.payload;
			state.isUploading = action.payload || state.uploading;
		},
		uploadS3FromSignedUrlLoading: (state, action) => {
			state.uploading = action.payload;
			state.isUploading = action.payload || state.loading;
		}
	}
});

export default slice.reducer;

// ACTIONS

/**
 * Perform the HTTP call to upload the file to S3 with a signedUrl (from the back) and the file to upload
 * @param {string} signedUrl the signed url (with token) received from the back
 * @param {Object} file the file to upload with format from upload input
 * @returns the s3 http response
 */
export function uploadS3FromSignedUrl(signedUrl, file, onSuccess) {
	return api({
		type: `${slice.name}/uploadS3FromSignedUrl`,
		trackLoading: true,
		customUrl: signedUrl,
		headers: {
			"Content-Type": file.type
		},
		method: "PUT",
		data: file,
		onSuccess
	});
}

/**
 * Upload the file to s3 on client-side
 * @param {Object} file the file to upload with html file-input format
 */
export function fetchUploadImageToS3(assistantID, file, context, data, action = C.ADD_ATTACHMENT) {
	const { name, size, type } = file;

	const id = generateRandomId().next().value;
	const onSuccess = (signedUrl, file) => ({
		type: action,
		payload: {
			...file
		}
	});

	return api({
		type: `${slice.name}/fetchUploadImageToS3`,
		trackLoading: true,
		url: "/api/v1/s3/uploadSignedUrl",
		method: "POST",
		data: {
			assistantID,
			fileName: name,
			contentType: type,
			size,
			context,
			...data
		},
		onSuccess: response => [
			uploadS3FromSignedUrl(response.signedUrl, file, () => {
				return onSuccess(response.signedUrl, response.fileObject);
			})
		]
	});
}

/**
 * Upload the file to s3 on client-side
 * @param {Object} file the file to upload with html file-input format
 */
export function fetchUploadImageToS3WithoutReducer(assistantID, file, context, data, onSuccess) {
	const { name, size, type } = file;

	return api({
		type: `${slice.name}/fetchUploadImageToS3`,
		trackLoading: true,
		url: "/api/v1/s3/uploadSignedUrl",
		method: "POST",
		data: {
			assistantID,
			fileName: name,
			contentType: type,
			size,
			context,
			...data
		},
		onSuccess: response => [
			uploadS3FromSignedUrl(response.signedUrl, file, () => {
				return onSuccess(response.signedUrl, response.fileObject);
			})
		]
	});
}
