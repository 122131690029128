import C from "../../constants/engage.js";
import store from "../store.js";
import axios from "axios";
import lod_ from "lodash";
import { api } from "./api.js";
import { errorMsg } from "redux/reducers/snackMsgsReducers.js";
import i18n from "i18n.js";
const myDateTime = require("luxon");

// require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;

const actions = {
	sendSMS: function (SMSdata, state, substate, successCb, failCb) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;

			let data = {
				email: user.email,
				name:
					user && user.surname ? user.surname + " " + user.name : user.nickname + " " + user.name,
				SMSdata,
				state,
				substate
			};

			return dispatch => {
				axios(endPoint + "/api/v1/sendSMS", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						dispatch({
							type: C.ENGAGE_SMS,
							payload: data
						});
						if (res.status === 200) {
							successCb();
						} else {
							failCb();
						}
					})
					.catch(error => {
						failCb();
					});
			};
		}
	},

	sendConversational: function (SMSdata, successCb, failCb) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;

			const assistantID = SMSdata.assistantID;
			const channelType = SMSdata.aChannel.type;
			const channelCode = SMSdata.aChannel.code;
			const channel = {
				type: channelType,
				code: channelCode
			};
			const type = "conversational";
			const action = "ENGAGE";
			const version = 5;
			const from = {
				id: user._id,
				name: user.name
			};
			const phone = SMSdata.recipient.replaceAll(" ", ""); //.replaceAll("+", "");
			const to = {
				id: phone, //TODO, maybe this can be handle way better when agent clicked on a suggested user (then we can use the correct id maybe)
				phone
			};
			const source = "cockpit";
			const currentNow = myDateTime.DateTime.now();
			const dateReceived = {
				ts: currentNow.toMillis(),
				datetime: currentNow.toISO()
			};
			const text = {
				plain_text: SMSdata.richText
			};
			const externalInfos = {
				phone
			};

			const pivotMessage = {
				header: {
					action,
					version,
					assistantID,
					channel,
					type,
					from,
					to,
					source,
					dateReceived,
					externalInfos
				},
				payload: {
					header: {
						type: "content"
					},
					payload: {
						text
					}
				}
			};

			const data = pivotMessage;

			return dispatch => {
				axios(endPoint + "/api/v1/sendConversational", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						dispatch({
							type: C.ENGAGE_SMS,
							payload: data
						});
						if (res.status === 200) {
							successCb();
						} else {
							failCb();
						}
					})
					.catch(error => {
						failCb();
					});
			};
		}
	},

	// New engage Epic https://faibrikplatform.atlassian.net/browse/FBDB-577
	createEngageConversation: function (
		channelCode,
		initialDraft,
		originFID = null,
		originMID = null,
		channel = null,
		parentfID = null
	) {
		const storeState = store.getState();

		const data = {
			channelCode,
			assistantID: storeState.selectedAssistantID
		};

		if (initialDraft) {
			data.initialDraft = initialDraft;
		}

		if (originFID) {
			data.originFID = originFID;
		}

		if (originMID) {
			data.originMID = originMID;
		}

		if (parentfID) {
			data.parentfID = parentfID;
		}

		if (channel) {
			data.channel = channel;
		}
		return dispatch => {
			dispatch(
				api({
					type: "/api/v1/conversation/engage/create",
					url: "/api/v1/conversation/engage/create",
					method: "POST",
					data,
					onSuccess: () => {},
					onFailure: () => [errorMsg(i18n.t("COC.createEngageFailed"))]
				})
			);
		};
	},

	setEngageContact: function (contact) {
		return dispatch => {
			dispatch({
				type: "SET_ENGAGE_CONTACT",
				payload: contact
			});
		};
	}
};
export default actions;
