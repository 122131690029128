/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Popover } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button2 from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAnswerItems } from "redux/reducers/answersReducers";
import "../../assets/css/language-selector.css";
import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";
import C from "../../constants/cockpit.js";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";
import cockpitActions from "../../redux/actions/cockpitActions";
import labelsActions from "../../redux/actions/labelsActions";
import selectedAssistantActions from "../../redux/actions/selectedAssistantActions";
import userGroupActions from "../../redux/actions/userGroupActions";

const useStyles = makeStyles(styles);

function AssistantMenu() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [mounted, setMounted] = React.useState(true);
	const [showPopover, setShowPopover] = React.useState(false);
	let selectedAssistantIDDescription;
	const currentUserPerms = useSelector(state => state.userStatus.auth);
	const { forceSelectConversation } = useSelector(state => state.cockpit);
	const selectedAssistantID = useSelector(state => state.selectedAssistantID);
	const assistants = useSelector(state => state.assistants);

	for (let assist of assistants) {
		if (assist.assistantID === selectedAssistantID) {
			selectedAssistantIDDescription = assist.description;
			break;
		}
	}

	React.useEffect(() => {
		const abortController = new AbortController();

		setTimeout(() => {
			setMounted(true);
		}, 1200);

		const clearAllData = () => {
			//Set selectedConversation to null
			if (!forceSelectConversation) dispatch({ type: C.CLEAR_CONVERSATION });
			//Empty history
			dispatch(cockpitActions.EmptyHistory());
			//Get answers
			dispatch(updateAnswerItems(selectedAssistantID));
			//Get labels
			dispatch(labelsActions.getLabels(selectedAssistantID));

			dispatch(assistantconfigActions.getConfigByAssistant(selectedAssistantID));
			// remove WS userss --> Do not seems to be called
			dispatch({
				type: "ws/clear",
				payload: true
			});

			// Change url to cockpit page, used if we switch assistant from other tab (from cockpitArchived for example)
			window.history.replaceState(null, "", "/admin/cockpit");

			if (currentUserPerms.user && currentUserPerms.user.perms) {
				if (
					currentUserPerms.user.perms.write_usergroup ||
					currentUserPerms.user.perms.write_assignconv
				) {
					dispatch(userGroupActions.updateUsersGroups(selectedAssistantID));
				} else {
					dispatch(userGroupActions.updateGroups(selectedAssistantID));
				}
			}
		};

		if (selectedAssistantID && mounted) {
			clearAllData();
			return () => {
				abortController.abort();
				setMounted(false);
			};
		}
	}, [selectedAssistantID, currentUserPerms]);

	const handleFetch = async assistant => {
		let currentassistant = await selectedAssistantID;
		if (assistant === currentassistant) {
			return;
		} else {
			dispatch(cockpitActions.setLeftTab(0));
			dispatch(assistantconfigActions.getConfigByAssistant(assistant));
			dispatch(selectedAssistantActions.updateSelectedAssistant(assistant));
			setShowPopover(false);
		}
	};

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpenPopover = event => {
		if (showPopover === false) {
			setAnchorEl(event.currentTarget);
			setShowPopover(true);
		}
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
		setShowPopover(false);
	};

	return (
		<>
			{!mounted ? (
				<div className={classes.progress} style={{ alignSelf: "flex-end" }}>
					<CircularProgress size={30} />
				</div>
			) : (
				<>
					{selectedAssistantID && assistants.length > 0 ? (
						<Box m={0.5} className={classes.filterButton}>
							<Button2
								onClick={handleOpenPopover}
								cursor="pointer"
								style={{
									minHeight: "50px",
									minWidth: "150px",
									cursor: "pointer",
									color: "whitesmoke",
									border: "none"
								}}
								size="small"
								variant="outlined"
							>
								{selectedAssistantIDDescription}
							</Button2>
							<Popover
								PaperProps={{
									style: {
										backgroundColor: "transparent",
										boxShadow: "none"
									}
								}}
								open={showPopover}
								onClick={handleClosePopover}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center"
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center"
								}}
							>
								{assistants.map(
									(assistant, index) =>
										assistant.assistantID !== selectedAssistantID && (
											<Box key={`assistant ${index}`}>
												<Button2
													onClick={() => handleFetch(assistant.assistantID)}
													style={{
														minHeight: "50px",
														width: "200px",
														backgroundColor: "#333333",
														color: "#FFF",
														borderColor: "#FFF"
													}}
													size="small"
													variant="outlined"
												>
													{assistant.description}
												</Button2>
											</Box>
										)
								)}
							</Popover>
						</Box>
					) : (
						<CircularProgress size={30} />
					)}
				</>
			)}
		</>
	);
}

export default AssistantMenu;
