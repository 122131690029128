import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { ClickAwayListener, MenuList, Paper, Popper } from "@material-ui/core";

import { CancelOutlined, Flag } from "@material-ui/icons";

import Box from "@material-ui/core/Box";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import GetFlag from "helpers/GetFlag";

/**
 * Props list :
 *
 * @param {[String]} props.flags List of flags to display
 * @param {Function(event, flag)} props.handleChange Function to call when a flag is selected or unselected
 * @param {Element} props.defaultIcon Customize icon (default is a flag)
 * @param {Boolean} props.canCancel If user can unselect selected flag (default is true)
 * @param {Boolean} props.cancelButton Display cancel/default button on top of the list (default is true)
 * @returns FlagsMenu
 */
const FlagsMenu = props => {
	const [openMenu, setOpenMenu] = React.useState(false);
	const anchorRefMenu = React.useRef(null);
	const [selectedFlag, setSelectedFlag] = useState(null);

	/**
	 * Default icon
	 */
	const ActiveIcon = () => {
		if (!selectedFlag) {
			if (props.defaultIcon) {
				return props.defaultIcon;
			} else {
				return <Flag style={{ fontSize: 20 }}></Flag>;
			}
		} else {
			return (
				<Box>
					<img src={GetFlag(selectedFlag.toUpperCase())} alt="Filter_flag" width="20" height="20" />
				</Box>
			);
		}
	};

	/**
	 * Handle close menu
	 */
	const handleCloseMenu = event => {
		if (anchorRefMenu.current && anchorRefMenu.current.contains(event.target)) {
			return;
		}
		setOpenMenu(false);
	};

	/**
	 * Handle change of selected flag
	 */
	const handleChange = (e, flag) => {
		if (props.canCancel === false && flag == selectedFlag) return;

		if (flag === "default") {
			setSelectedFlag(null);
			props.handleChange(e, "default");
		} else if (flag === selectedFlag) {
			setSelectedFlag(null);
			props.handleChange(e, "default");
		} else {
			setSelectedFlag(flag);
			props.handleChange(e, flag);
		}

		if (props.canCancel === false) {
			if (flag != selectedFlag) {
				handleCloseMenu(e);
			}
		} else {
			handleCloseMenu(e);
		}
	};

	return (
		<>
			<ToggleButton
				onClick={() => setOpenMenu(!openMenu)}
				aria-haspopup="menu"
				ref={anchorRefMenu}
				value={openMenu}
				size="small"
			>
				<ActiveIcon></ActiveIcon>
			</ToggleButton>
			<Popper
				open={openMenu}
				anchorEl={anchorRefMenu.current}
				style={{ zIndex: "500" }}
				role={undefined}
				transition
				disablePortal
			>
				{() => (
					<ClickAwayListener onClickAway={handleCloseMenu}>
						<MenuList mt={0} mb={0} pt={0} autoFocusItem={openMenu} id="menu-list-grow-language">
							<Paper>
								<ToggleButtonGroup
									orientation="vertical"
									exclusive
									onChange={handleChange}
									size="small"
								>
									{props.cancelButton != false && (
										<ToggleButton value="default" key="default">
											<CancelOutlined></CancelOutlined>
										</ToggleButton>
									)}

									{props.flags.map((flag, index) => {
										return (
											<ToggleButton
												value={flag}
												key={flag}
												style={selectedFlag === flag ? { background: "#0000001f" } : {}}
											>
												<img
													src={GetFlag(flag.toUpperCase())}
													alt="Filter_flag"
													width="20"
													height="20"
												/>
											</ToggleButton>
										);
									})}
								</ToggleButtonGroup>
							</Paper>
						</MenuList>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(FlagsMenu);
