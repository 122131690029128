import axios from "axios";
import lod_ from "lodash";
import React, { useEffect } from "react";
import { Box } from "@material-ui/core";

const endPoint = process.env.REACT_APP_AMBACK + "/api/v1/";

export default class SuggestionsManager {
	constructor(assistantID, user, input) {
		this.assistantID = assistantID;
		this.input = input;
		this.user = user;
	}

	async loadSuggestions(onSuccess, onError, phone = false, filterForwardDomains = null) {
		let url = `${endPoint}${this.assistantID}/contacts?page=${1}&limit=${20}`;
		let replacedSearch = this.input.replace(";", ",");
		replacedSearch = replacedSearch.replace("+", "");
		let splittedQuery = replacedSearch.split(",").map(value => {
			return value.trim();
		});
		let lastEmail = splittedQuery[splittedQuery.length - 1];

		if (phone) {
			url += `&required=phone`;
		}

		if (this.input) {
			url += `&search=${lastEmail}`;
		}

		if (!lod_.isNil(filterForwardDomains)) {
			url += `&filterForwardDomains=[${lod_.toString(filterForwardDomains)}]`;
		}

		try {
			const res = await axios.get(url, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.user.token}`,
					UserEmail: this.user.email
				}
			});
			let suggestions = res.data.elements;
			onSuccess(suggestions);
		} catch (err) {
			// onError(err);
		}
	}

	getDisplayName(contact) {
		try {
			if (contact.type === "list") {
				if (contact.name) {
					return contact.name;
				} else {
					return "";
				}
			}

			if (contact.first || contact.last) {
				return (contact.last?.toUpperCase() + " " + contact.first?.toLowerCase()).trim();
			} else if (contact.name) {
				return contact.name;
			} else {
				return "";
			}
		} catch (error) {
			return "";
		}
	}

	getDisplayEmail(contact, phone) {
		try {
			if (contact.type === "list") {
				return "";
			}

			if (phone) {
				return contact.phone;
			}

			if (contact.email) {
				return contact.email;
			}

			return "";
		} catch (error) {
			return "";
		}
	}

	display(suggestedValue, option, phone = false) {
		return (
			<Box>
				<Box fontWeight="fontWeightRegular">{this.getDisplayName(suggestedValue)}</Box>
				<Box fontStyle="oblique" fontWeight="fontWeightLight">
					{this.getDisplayEmail(suggestedValue, phone)}
				</Box>
			</Box>
		);
	}

	format(newValue, phone = false) {
		let splittedEmails = this.input.split(",").map(value => {
			return value.trim();
		});
		let startEmails = splittedEmails.slice(0, splittedEmails.length - 1);
		let newValues = "";
		if (!phone) {
			newValues = newValue.email
				.replace(";", ",")
				.split(",")
				.map(value => {
					return value.trim();
				});
		} else {
			newValues = newValue.phone
				.replace(";", ",")
				.split(",")
				.map(value => {
					return value.trim();
				});
		}
		startEmails.push(...newValues);
		return startEmails.join(", ") + ", ";
	}
}
