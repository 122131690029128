module.exports = {
	getDateOfMessage,
	getTsOfMessage,
	getSubjectOfMessage,
	getContentOfMessage,
	getSenderOfMessage
};

function getDateOfMessage(message) {
	if (message?.header?.datetime) {
		// old format
		return message.header.datetime;
	}
	if (message?.header?.dateCreated?.datetime) {
		// new format
		return message.header.dateCreated.datetime;
	}
}

function getTsOfMessage(message) {
	let movedInfos = message.payload?.header?.context?.movedInfoTo;

	if (movedInfos && movedInfos?.date?.ts) {
		return movedInfos.date.ts;
	}

	if (message?.header?.externalInfos?.dateCreated?.ts) {
		// new format, most precise
		return message.header.externalInfos.dateCreated.ts;
	}
	if (message?.header?.dateReceived?.ts) {
		// new format
		return message.header.dateReceived.ts;
	}
	if (message?.header?.ts) {
		// old format
		return message.header.ts;
	}
	if (message?.header.dateCreated?.ts) {
		return message.header.dateCreated.ts;
	}
}

function getSubjectOfMessage(message) {
	if (message?.body?.topic) {
		// old format
		return message.body.topic;
	}
	if (message?.payload?.payload?.subject) {
		// new format
		return message.payload.payload.subject;
	}
}

function getContentOfMessage(message) {
	if (message?.body?.htmlText) {
		// old format
		return message.body.htmlText;
	}
	if (message?.payload?.payload?.text?.html_text) {
		// new format
		return message.payload.payload.text.html_text;
	}
}

/**
 * Return the name / mail of the person who wrote the message that we forward / copy
 * @param {*} conversation
 * @param {*} message
 * @returns
 */
function getSenderOfMessage(conversation, message) {
	// If it's a outgoing message then we do not disclose the name of the agent
	if (message?.header?.action === "REPLY" || message?.header?.action === "ENGAGE") return;

	// the writer of the originale message
	if (message?.header?.from?.id) {
		return message.header.from.id;
	}
	if (message?.header?.extra?.emailFrom) {
		return message.header.extra.emailFrom;
	}
	if (conversation?.contact?.first && conversation?.contact?.last) {
		return `${conversation?.contact?.first} ${conversation?.contact?.last}`;
	}
	if (conversation?.contact?.name) {
		return conversation.contact.name;
	}
	if (conversation?.contact?.email) {
		return conversation.contact.email;
	}
}
