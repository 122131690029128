/* eslint-disable no-case-declarations */
import C from "../../constants/cockpit";

const profileReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.COCKPIT_ANALYTICS:
			return {
				...currentState,
				cockpitAnalytics: action.payload
			};
		default:
			return currentState;
	}
};

export default profileReducers;
