/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "../../CockpitStyleJs/styleFilter";
import Tooltip from "@material-ui/core/Tooltip";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState } from "react";
import { Chip, Grid } from "@mui/material";
import DoneIcon from "@material-ui/icons/Done";
import { TagsList } from "components/Tags/TagsList";
import { setIntentOpenDialog } from "redux/reducers/filtersReducers";
import C from "constants/cockpit.js";
import _lod from "lodash";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { getLabelOf } from "helpers/utilities";
import { InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

/**
 *
 */
export default ({ role }) => {
	const dispatch = useDispatch();
	const { convIntentFilter, isCold } = useSelector(state => state.cockpit);
	const classes = useStyles();
	let intentSet = convIntentFilter.length !== 0;

	return !isCold && (role === "agent" || role === "supervisor" || role === "soloUser") ? (
		<Tooltip title={i18n.t("COC.intentFilter")} placement="top">
			<Box className={classes.filterButton} mt={1}>
				<ToggleButtonGroup exclusive size="small">
					<ToggleButton
						value={true}
						fontSize="small"
						onClick={() => {
							dispatch(setIntentOpenDialog(true));
						}}
						style={intentSet ? { color: "black", background: "#0000001f" } : {}}
					>
						<ListAltRoundedIcon fontSize="small" />
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
		</Tooltip>
	) : (
		<></>
	);
};

/**
 *
 * @returns
 */
export function IntentFilterDialog() {
	const { convIntentFilter } = useSelector(state => state.cockpit);
	const { intents } = useSelector(state => state.assistantconfig); // Possible intentions list

	const { intentOpenDialog } = useSelector(state => state.filters);
	const dispatch = useDispatch();
	const [selected, setSelected] = useState([]); // Selected intentions to filter conversations
	const [search, setSearch] = React.useState("");

	const isAllIntentsChecked = selected && intents && selected.length === intents.length;
	/**
	 *
	 */
	useEffect(() => {
		if (intents && convIntentFilter.length > 0) {
			const filteredList = intents.filter(intent => convIntentFilter.includes(intent.name));
			setSelected(filteredList);
		} else if (intents) setSelected([]);
	}, [intents, convIntentFilter]);

	/**
	 *
	 */
	const handleClose = () => {
		dispatch(setIntentOpenDialog(false));
	};

	/**
	 *
	 */
	const handleSaveFilters = clear => {
		if (!_lod.isNil(selected)) {
			let selectedIntentsName = selected.map(intent => intent.name);
			dispatch({
				type: C.SET_CONV_INTENT_FILTER,
				payload: clear ? [] : selectedIntentsName
			});
		}
		handleClose();
	};

	/**
	 *
	 */
	const handleDisableFilters = () => {
		setSelected([]);
		handleSaveFilters(true);
	};

	useEffect(() => {
		if (intentOpenDialog === false) {
			setSearch("");
		}
	}, [intentOpenDialog]);

	/**
	 *
	 * @param {*} value
	 */
	const toggleSelectAll = value => {
		if (isAllIntentsChecked) {
			setSelected([]);
		} else {
			setSelected(intents);
		}
	};

	/**
	 *
	 * @param {*} event
	 */
	const handleSearchChange = event => {
		setSearch(event.target.value);
	};

	return (
		<Dialog
			fullWidth
			maxWidth="md"
			open={intentOpenDialog}
			onClose={() => handleClose()}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">
				{i18n.t("COC.intentFilter")}{" "}
				<span style={{ float: "right" }}>
					{/* Select all toggle */}
					<Chip
						label={isAllIntentsChecked ? i18n.t("COC.deselectAll") : i18n.t("COC.selectAll")}
						color={isAllIntentsChecked ? "default" : "primary"}
						deleteIcon={isAllIntentsChecked ? <HighlightOffIcon /> : <DoneIcon />}
						clickable={true}
						onClick={() => {
							toggleSelectAll();
						}}
						onDelete={() => {
							toggleSelectAll();
						}}
					/>
				</span>
			</DialogTitle>
			<DialogContent dividers>
				<Box m={1}>
					<TextField
						fullWidth
						label=""
						variant="outlined"
						size="small"
						value={search}
						onChange={handleSearchChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
				</Box>
				<Grid container>
					<TagsList
						list={
							intents &&
							intents
								.filter(intent => {
									if (search.trim().length >= 0) {
										let fullSearch = (intent.name || "") + " " + (getLabelOf(intent.name) || "");
										return fullSearch.toLowerCase().includes(search.toLowerCase());
									} else {
										return true;
									}
								})
								.sort((a, b) => getLabelOf(a.name).localeCompare(getLabelOf(b.name)))
						}
						labelField={"name"}
						titleField={"description"}
						valueField={"id"}
						isForm={true}
						selected={selected}
						setSelected={setSelected}
						isIntent
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleDisableFilters()} color="primary">
					{`${i18n.t("COC.DisableFilter")}`}
				</Button>
				<Button onClick={() => handleClose()} color="secondary">
					{`${i18n.t("COC.Cancel")}`}
				</Button>
				<Button
					disabled={selected.length === 0}
					onClick={() => handleSaveFilters()}
					color="primary"
				>
					{`${i18n.t("COC.ApplyFilters")}`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
