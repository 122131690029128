import Input from "./Input";
import Button from "./Button";
import Switch from "./Switch";
import FormMenu from "./Menu";
import ArrayList from "./ArrayList";
import Popper from "./PopperLogos";
import Phone from "./Phone";

const Controls = {
	Input,
	Button,
	Switch,
	FormMenu,
	ArrayList,
	Popper,
	Phone
};

export default Controls;
