import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex"
	},
	paper: {
		marginRight: theme.spacing(2)
	},
	dropDown: {
		flexDirection: "column",
		zIndex: " 999!important",
		padding: "10px"
	},
	filterButton: {
		padding: "3px"
	},
	transformBottom: {
		transformOrigin: "center bottom"
	},
	transformTop: {
		transformOrigin: "center top"
	}
}));
export default useStyles;
