import React from "react";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import {
	Badge,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
	withStyles
} from "@material-ui/core";
import { getEditMesssageItemSelector } from "redux/selectors/cockpitSelectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import cockpitActions from "../redux/actions/cockpitActions";
import { errorMsg } from "redux/reducers/snackMsgsReducers";
import i18n from "i18n";
import LinearProgress from "@material-ui/core/LinearProgress";
import CloseButton from "@material-ui/icons/Close";

import { getACleanURI, invalidFileExtensions, traceError } from "../helpers/utilities";

export const Attachment = propsAtt => {
	let { selectedConversation, attachments, selectedAssistantID } = useSelector(
		getEditMesssageItemSelector,
		shallowEqual
	);
	const dispatch = useDispatch();

	const openAttachment = async propsAtt => {
		const { fileName, mimeType } = propsAtt;

		const file = attachments.length > 0 && attachments.filter(e => e.name === fileName)[0].file;
		let url = getFileUrl(mimeType, file);
		if (url) {
			try {
				window.open(url);
			} catch (e) {
				traceError(`openAttachment-${url} failed: ${e.message}`);
				// console.error("error", e);
			}
		} else if (propsAtt.url) {
			try {
				window.open(getACleanURI(propsAtt.url));
			} catch (e) {
				traceError(`openAttachment-${propsAtt.url} (props) failed: ${e.message}`);
				// console.error("error", e);
			}
		}
	};

	const formatBytes = (a, b = 2) => {
		if (0 === a) return "0 Bytes";
		const c = 0 > b ? 0 : b;
		const d = Math.floor(Math.log(a) / Math.log(1024));
		return (
			parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
			" " +
			["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
		);
	};

	function getFileUrl(type, file) {
		let url;
		const fileExtension = file?.name.split(".").pop();
		let typeExist = type !== "" ? type : fileExtension;
		if (typeExist && file) {
			try {
				if (invalidFileExtensions.includes(fileExtension)) {
					const msg = `${file.name} ${i18n.t("COC.noTsupportedPreview")}`;
					dispatch(errorMsg(msg));
					traceError(`getFileUrl-${msg}`);
				} else {
					url = (window.URL || window.webkitURL).createObjectURL(file);
				}
			} catch (err) {
				traceError(`getFileUrl-${err.message}`);
			}
		}
		return url;
	}

	const deleteAttachment = (id, fileName) => {
		dispatch(
			cockpitActions.deleteFile(id, fileName, selectedConversation?._id, selectedAssistantID)
		);
	};
	const MAX_LODING = 100;

	return (
		<span
			style={{ margin: "5px", width: "20%", backgroundColor: "#00000011", borderRadius: "10px" }}
		>
			<Button
				style={{ textTransform: "none", width: "100%" }}
				endIcon={
					<CloseButton
						onClick={() => {
							deleteAttachment(propsAtt.attachmentId, propsAtt.fileName);
						}}
					/>
				}
			>
				<span onClick={() => openAttachment(propsAtt)} style={{ width: "100%", textAlign: "left" }}>
					<Box>{propsAtt.fileName}</Box>
					<Typography color="primary" style={{ fontSize: "12px", fontWeight: "bold" }}>
						{propsAtt.size ? formatBytes(propsAtt.size) : ""}
					</Typography>
				</span>
			</Button>
		</span>
	);
};

export default function AttachmentsList({ attachments, edit }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const nb = attachments.length;

	const openAttachment = async url => {
		try {
			window.open(url);
		} catch (e) {
			traceError(`openAttachment-${url} failed: ${e.message}`);
			// console.error("error", e);
		}
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const StyledBadge = withStyles(theme => ({
		badge: {
			top: 0,
			border: `2px solid ${theme.palette.background.paper}`,
			padding: "0 4px",
			backgroundColor: "#545454",
			color: "white"
		}
	}))(Badge);

	return (
		<div>
			<IconButton
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
				style={{ float: "right" }}
			>
				<StyledBadge badgeContent={nb}>
					<AttachFileIcon
						style={{
							fontSize: "16px",
							color: "black"
						}}
					/>
				</StyledBadge>
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{attachments.map((attachment, index) => (
					<MenuItem
						key={index}
						onClick={() => {
							if (!edit) openAttachment(attachment.url);
						}}
						className={"attachmentList"}
					>
						{edit ? (
							<Attachment
								progress={attachment.progress}
								fileName={attachment.name}
								key={index}
								attachmentId={attachment.id}
								size={attachment.size}
								mimeType={attachment.mimeType}
								path={attachment.path}
								contentType={attachment.contentType}
								url={getACleanURI(attachment.url)}
							/>
						) : (
							attachment.name
						)}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
