import React from "react";
import { IconButton, ListItem, Popover } from "../../../node_modules/@material-ui/core/index";
import { Typography } from "../../../node_modules/@material-ui/core/index";
import { Button } from "../../../node_modules/@material-ui/core/index";
import { Grid } from "../../../node_modules/@material-ui/core/index";
import { Box } from "../../../node_modules/@material-ui/core/index";

export default function FormPopper(props) {
	const [open, setOpen] = React.useState(false);
	const { name, list, label, values, value, onChange } = props;

	const handleClick = event => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChange = logoURL => {
		onChange(convertToDefEventParam(name, logoURL));
		setOpen(false);
	};

	const getLogo = values => {
		const logoUrl =
			`https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_` +
			(values.type || values.channel) +
			`.png`;

		return logoUrl;
	};

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	return (
		<div>
			<Typography>{label}</Typography>
			<IconButton onClick={handleClick} style={{ margin: "10px" }}>
				<img src={value || getLogo(values)} style={{ width: "50px" }} />
			</IconButton>
			<Popover
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "center",
					horizontal: "center"
				}}
			>
				<Grid container style={{ margin: "10px", display: "flex" }}>
					{list.map(logo => (
						<Grid item key={logo.name} style={{ alignItems: "center" }}>
							<IconButton onClick={() => handleChange(logo.logoURL)}>
								<img src={logo.logoURL} style={{ width: "40px", margin: "5px" }} />
							</IconButton>
						</Grid>
					))}
				</Grid>
			</Popover>
		</div>
	);
}
