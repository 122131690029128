/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import i18n from "i18n";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { Box } from "@mui/system";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import DateFilterDialog from "./DateFilterDialog";
import CalendarTodayRoundedIcon from "@material-ui/icons/CalendarTodayRounded";
import useStyles from "pages/Engage/StyleEngage";
import store from "redux/store";

/**
 *
 * @returns
 */
const DateFilter = () => {
	const { convStartDateFilter, convEndDateFilter, selectedAssistantID } = useSelector(
		state => state.cockpit
	);
	const [openDateFilterDialog, setOpenDateFilterDialog] = useState(false);

	const classes = useStyles();
	const dateSet = convStartDateFilter || convEndDateFilter;
	/**
	 * Reset when switching assistantID
	 */
	useEffect(() => {
		setOpenDateFilterDialog(false);
	}, [selectedAssistantID]);

	const handleCloseDateFilterDialog = () => {
		setOpenDateFilterDialog(false);
	};

	return (
		<>
			{/* Filtre Calendrier/date */}
			<Tooltip title={i18n.t("COC.DateFilter")} placement="top">
				<Box className={classes.filterButton} mt={1}>
					<ToggleButtonGroup exclusive size="small">
						<ToggleButton
							value={dateSet !== null && dateSet}
							type="datetime-local"
							fontSize="small"
							onClick={() => setOpenDateFilterDialog(true)}
							style={dateSet ? { color: "black", background: "#0000001f" } : {}}
						>
							<CalendarTodayRoundedIcon fontSize="small" />
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>
			</Tooltip>

			<DateFilterDialog
				handleCloseDateFilterDialog={handleCloseDateFilterDialog}
				openDialog={openDateFilterDialog}
			/>
		</>
	);
};
export default DateFilter;
