import lod_ from "lodash";

import React from "react";

import { Box } from "@material-ui/core";

const DisplayItem = (suggestedValue, shownFields = ["name"]) => {
	return (
		<Box>
			{shownFields.map((key, index) => {
				return (
					<>
						{index === 0 && (
							<Box key={`shownFields_${index}`} fontWeight="fontWeightRegular">
								{lod_.get(suggestedValue, key)}
							</Box>
						)}
						{index !== 0 && (
							<Box key={`shownFields_${index}`} fontStyle="oblique" fontWeight="fontWeightLight">
								{lod_.get(suggestedValue, key)}
							</Box>
						)}
					</>
				);
			})}
		</Box>
	);
};

export default DisplayItem;
