import "./Tags.css";
import React from "react";
import { Tooltip } from "@material-ui/core";
import { Chip } from "@mui/material";
import DoneIcon from "@material-ui/icons/Done";
import { getLabelOf } from "helpers/utilities.js";
import { getCockpitTabInfoSelector } from "redux/selectors/cockpitSelectors";

/**
 *
 * @param {*} param0
 * @returns
 */
export const TagsList = ({
	list,
	labelField,
	surnameField,
	titleField,
	isForm,
	scroll,
	align = "center",
	selected,
	setSelected,
	isIntent,
	isUser,
	multiple = true
}) => {
	/**
	 *
	 * @param {*} value
	 * @returns
	 */
	const toggleSelect = value => () => {
		if (selected && multiple === true) {
			const currentIndex = selected.indexOf(value);
			const newSelected = [...selected];

			if (currentIndex === -1) {
				newSelected.push(value);
			} else {
				newSelected.splice(currentIndex, 1);
			}

			setSelected(newSelected);
		}
		if (selected && multiple === false) {
			const newSelected = [value];

			setSelected(newSelected);
		}
	};
	let tagListScrollStyle = {
		display: "flex",
		justifyContent: "left",
		listStyle: "none",
		margin: 0
	};
	if (scroll) {
		tagListScrollStyle.flexWrap = "nowrap";
		tagListScrollStyle.overflow = "auto";
	} else {
		tagListScrollStyle.flexWrap = "wrap";
		tagListScrollStyle.justifyContent = align;
		tagListScrollStyle.width = "100%";
	}

	return (
		<span
			className={isForm ? "tagListForm" : "tagListScroll"}
			style={
				!isForm
					? tagListScrollStyle
					: {
							display: "flex",
							flexWrap: "wrap",
							width: "100%"
					  }
			}
		>
			{list?.map(tag => {
				let label = "";
				if (isUser) {
					label = tag[labelField] + " " + tag[surnameField];
				} else {
					label = tag[labelField];
				}

				const title = tag[titleField];
				return (
					<Tooltip title={title} key={label}>
						<Chip
							style={{ textTransform: isUser ? "capitalize" : "initial" }}
							label={isIntent ? getLabelOf(label) : label}
							icon={isForm ? selected.includes(tag) ? <DoneIcon /> : <></> : <></>}
							color={isForm ? (selected.includes(tag) ? "primary" : "default") : "default"}
							clickable={isForm ? true : false}
							onClick={toggleSelect(tag)}
						/>
					</Tooltip>
				);
			})}
		</span>
	);
};
