import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/cardFooterStyle.js";

const useStyles = makeStyles(styles);

export default function CardFooter(props) {
	const classes = useStyles();
	const {
		className,
		children,
		plain,
		profile,
		pricing,
		testimonial,
		stats,
		chart,
		product,
		faibrik,
		...rest
	} = props;
	const cardFooterClasses = classNames({
		[classes.cardFooter]: true,
		[classes.cardFooterPlain]: plain,
		[classes.cardFooterProfile]: profile || testimonial,
		[classes.cardFooterPricing]: pricing,
		[classes.cardFooterTestimonial]: testimonial,
		[classes.cardFooterStats]: stats,
		[classes.cardFooterChart]: chart || product,
		[classes.cardFaibrik]: faibrik,
		[className]: className !== undefined
	});
	return (
		<div className={cardFooterClasses} {...rest}>
			{children}
		</div>
	);
}

CardFooter.propTypes = {
	className: PropTypes.string,
	plain: PropTypes.bool,
	profile: PropTypes.bool,
	pricing: PropTypes.bool,
	testimonial: PropTypes.bool,
	stats: PropTypes.bool,
	chart: PropTypes.bool,
	product: PropTypes.bool,
	faibrik: PropTypes.bool,
	children: PropTypes.node
};
